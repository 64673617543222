import { ReactComponent as CloseIcon } from "@assets/images/cms/ic-close.svg";
import { ReactComponent as PencilIcon } from "@assets/images/cms/ic-pencil.svg";
import { ReactComponent as DotsIcon } from "@assets/images/cms/ic-three-dots.svg";
import { ReactComponent as TrashIcon } from "@assets/images/cms/ic-trash.svg";
import unionIcon from "@assets/images/cms/ic-union.svg";
import { ReactComponent as VisibleIcon } from "@assets/images/cms/ic-visible.svg";
import dropdownToggleIcon from "@assets/images/ic-dropdown-toggle.svg";
import React, { useState } from "react";
import { Accordion, Button, Modal, useAccordionButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { blogSlice } from "redux/blog/blogSlice";
import { BlogPageType, BlogStatus } from "redux/blog/constants";
import {
  ButtonDropdownActions,
  DropdownActions,
  DropdownItemContainer,
  DropdownItemContent,
  Spacer,
} from "../../components/SetupPage/components/SiteMap/styles";
import {
  CategoryListItemConteiner,
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
  StyledCustomToggle,
} from "./styles";
import {
  fetchCategory,
  fetchSubcategory,
  updateCategory,
  updateSubcategory,
} from "redux/blog/blogService";

export function CategoryListItem(props) {
  const { setBlogPageType, setCategoryEditData } = blogSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  const [categoryToDeleteDisable, setCategoryToDeleteDisable] = useState();
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const filteredCategories = useSelector(
    (state) => state.blog.filteredCategories
  );
  const isFiltering = useSelector((state) => state.blog.isFiltering);
  const filterStatus = useSelector((state) => state.blog.filterStatus);
  const selectedCategoriesAndSubcategories = useSelector(
    (state) => state.blog.selectedCategoriesAndSubcategories
  );

  const CustomToggle = React.forwardRef((props, ref) => {
    const { onClick, "aria-expanded": isOpenIcon } = props;
    return (
      <ButtonDropdownActions
        style={{ padding: "0" }}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {isOpenIcon ? <CloseIcon /> : <DotsIcon />}
      </ButtonDropdownActions>
    );
  });

  function AccordionCustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => null);

    return (
      <StyledCustomToggle type="button" onClick={decoratedOnClick}>
        {children}
      </StyledCustomToggle>
    );
  }

  const handleUpdateCategory = (category) => {
    let entity = {};
    Object.assign(entity, category);
    entity.status = category?.status !== "ENABLED" ? "ENABLED" : "DISABLED";
    if (entity?.categoryId) {
      dispatch(updateSubcategory(entity)).then(() => {
        handleClose();
        dispatch(fetchSubcategory()).then(() => {
          dispatch(
            blogSlice.actions.setCategoriesFilteredByStatus(filterStatus)
          );
        });
      });
    } else {
      dispatch(updateCategory(entity)).then(() => {
        handleClose();
        dispatch(fetchCategory()).then(() => {
          dispatch(
            blogSlice.actions.setCategoriesFilteredByStatus(filterStatus)
          );
        });
      });
    }
  };

  const handleDeleteCategory = () => {
    let entity = {};
    Object.assign(entity, categoryToDeleteDisable);
    entity.status = "TRASH";
    if (entity?.categoryId) {
      dispatch(updateSubcategory(entity)).then(() => {
        dispatch(
          blogSlice.actions.setSelectedCategoriesAndSubcategories("none")
        );
        handleClose();
        dispatch(fetchSubcategory()).then(() => {
          dispatch(
            blogSlice.actions.setCategoriesFilteredByStatus(filterStatus)
          );
        });
      });
    } else {
      dispatch(updateCategory(entity)).then(() => {
        dispatch(
          blogSlice.actions.setSelectedCategoriesAndSubcategories("none")
        );
        handleClose();
        dispatch(fetchCategory()).then(() => {
          dispatch(
            blogSlice.actions.setCategoriesFilteredByStatus(filterStatus)
          );
        });
      });
    }
  };

  const handleClose = () => {
    setCategoryToDeleteDisable(null);
    setAction("");
    setShow(false);
  };

  const handleShow = (category, action) => {
    setCategoryToDeleteDisable(category);
    setAction(action);
    setShow(true);
  };

  const handleSelectCategory = (category) => {
    dispatch(blogSlice.actions.setSelectedCategoriesAndSubcategories(category));
  };

  return (
    <>
      <Accordion defaultActiveKey={props.data.children[0] ? "0" : "1"}>
        <StyledCard>
          {((isFiltering && filteredCategories.includes(props.data.parent)) ||
            !isFiltering) && (
            <StyledCardHeader>
              <AccordionCustomToggle eventKey="0">
                <img src={dropdownToggleIcon} />
              </AccordionCustomToggle>
              <CategoryListItemConteiner>
                <div style={{ width: "250px" }}>
                  <input
                    type="checkbox"
                    id={props.data.parent.id}
                    name={props.data.parent.id}
                    value={props.data.parent.id}
                    className="mx-2"
                    checked={selectedCategoriesAndSubcategories.includes(
                      props.data.parent
                    )}
                    onChange={() => handleSelectCategory(props.data.parent)}
                  />
                  <span>{props.data.parent.name}</span>
                </div>
                <div style={{ width: "100px" }}>
                  <span>-</span>
                </div>
                <div style={{ width: "100px" }}>
                  <span>-</span>
                </div>
                <DropdownActions style={{ minWidth: "60px" }}>
                  <DropdownActions.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    Custom toggle
                  </DropdownActions.Toggle>
                  <DropdownActions.Menu>
                    <DropdownActions.Item
                      onClick={() => {
                        dispatch(setCategoryEditData(props.data.parent));
                        dispatch(setBlogPageType(BlogPageType.EDIT_CATEGORY));
                      }}
                    >
                      <DropdownItemContainer>
                        <DropdownItemContent>
                          <PencilIcon />
                          <span>Editar</span>
                        </DropdownItemContent>
                      </DropdownItemContainer>
                    </DropdownActions.Item>
                    <Spacer />
                    {props.data.parent?.status !== BlogStatus.ENABLED ? (
                      <DropdownActions.Item
                        onClick={() => {
                          handleUpdateCategory(props.data.parent);
                        }}
                      >
                        <DropdownItemContainer>
                          <DropdownItemContent>
                            <VisibleIcon />
                            <span>Habilitar</span>
                          </DropdownItemContent>
                        </DropdownItemContainer>
                      </DropdownActions.Item>
                    ) : (
                      <DropdownActions.Item
                        onClick={() =>
                          handleShow(props.data.parent, "Desabilitar")
                        }
                      >
                        <DropdownItemContainer>
                          <DropdownItemContent>
                            <VisibleIcon />
                            <span>Desabilitar</span>
                          </DropdownItemContent>
                        </DropdownItemContainer>
                      </DropdownActions.Item>
                    )}
                    <Spacer />
                    {props.data.parent?.status !== BlogStatus.TRASH && (
                      <>
                        <DropdownActions.Item
                          onClick={() =>
                            handleShow(props.data.parent, "Excluir")
                          }
                        >
                          <DropdownItemContainer>
                            <DropdownItemContent>
                              <TrashIcon />
                              <span>Excluir</span>
                            </DropdownItemContent>
                          </DropdownItemContainer>
                        </DropdownActions.Item>
                        <Spacer />
                      </>
                    )}
                  </DropdownActions.Menu>
                </DropdownActions>
              </CategoryListItemConteiner>
            </StyledCardHeader>
          )}
          <Accordion.Collapse eventKey="0">
            <StyledCardBody className="mb-2">
              {props.data.children.map((child) => {
                if (
                  (isFiltering && filteredCategories.includes(child)) ||
                  !isFiltering
                )
                  return (
                    <div className="d-flex mb-2">
                      <img alt="union" src={unionIcon} className="mx-1" />
                      <CategoryListItemConteiner>
                        <div style={{ width: "250px" }}>
                          <input
                            type="checkbox"
                            id={child.id}
                            name={child.id}
                            value={child.id}
                            className="mx-2"
                            checked={selectedCategoriesAndSubcategories.includes(
                              child
                            )}
                            onChange={() => handleSelectCategory(child)}
                          />
                          <span>{child.name}</span>
                        </div>
                        <div
                          style={{
                            width: "100px",
                            position: "relative",
                            left: "50px",
                          }}
                        >
                          <span>-</span>
                        </div>
                        <div
                          style={{
                            width: "250px",
                            position: "relative",
                            left: "30px",
                          }}
                        >
                          <span>{props.data.parent.name}</span>
                        </div>
                        <DropdownActions style={{ minWidth: "50px" }}>
                          <DropdownActions.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                            Custom toggle
                          </DropdownActions.Toggle>
                          <DropdownActions.Menu>
                            <DropdownActions.Item
                              onClick={() => {
                                dispatch(setCategoryEditData(child));
                                dispatch(
                                  setBlogPageType(BlogPageType.EDIT_SUBCATEGORY)
                                );
                              }}
                            >
                              <DropdownItemContainer>
                                <DropdownItemContent>
                                  <PencilIcon />
                                  <span>Editar</span>
                                </DropdownItemContent>
                              </DropdownItemContainer>
                            </DropdownActions.Item>
                            <Spacer />
                            {child?.status !== BlogStatus.ENABLED ? (
                              <DropdownActions.Item
                                onClick={() => {
                                  handleUpdateCategory(child);
                                }}
                              >
                                <DropdownItemContainer>
                                  <DropdownItemContent>
                                    <VisibleIcon />
                                    <span>Habilitar</span>
                                  </DropdownItemContent>
                                </DropdownItemContainer>
                              </DropdownActions.Item>
                            ) : (
                              <DropdownActions.Item
                                onClick={() => handleShow(child, "Desabilitar")}
                              >
                                <DropdownItemContainer>
                                  <DropdownItemContent>
                                    <VisibleIcon />
                                    <span>Desabilitar</span>
                                  </DropdownItemContent>
                                </DropdownItemContainer>
                              </DropdownActions.Item>
                            )}
                            <Spacer />
                            {child?.status !== BlogStatus.TRASH && (
                              <>
                                <DropdownActions.Item
                                  onClick={() => handleShow(child, "Excluir")}
                                >
                                  <DropdownItemContainer>
                                    <DropdownItemContent>
                                      <TrashIcon />
                                      <span>Excluir</span>
                                    </DropdownItemContent>
                                  </DropdownItemContainer>
                                </DropdownActions.Item>
                                <Spacer />
                              </>
                            )}
                          </DropdownActions.Menu>
                        </DropdownActions>
                      </CategoryListItemConteiner>
                    </div>
                  );
              })}
            </StyledCardBody>
          </Accordion.Collapse>
        </StyledCard>
      </Accordion>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`${action} ${
            categoryToDeleteDisable?.categoryId ? "sub-categoria" : "categoria"
          }`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você tem ceteza que deseja {action?.toLowerCase()} essa{" "}
          {categoryToDeleteDisable?.categoryId ? "sub-categoria" : "categoria"}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={(e) =>
              action === "Excluir"
                ? handleDeleteCategory()
                : handleUpdateCategory(categoryToDeleteDisable)
            }
          >
            {action}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
