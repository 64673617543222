import { ReactComponent as CloneIcon } from "@assets/images/cms/ic-clone.svg";
import { ReactComponent as CloseIcon } from "@assets/images/cms/ic-close.svg";
import { ReactComponent as CopyIcon } from "@assets/images/cms/ic-copy-url.svg";
import { ReactComponent as CurretDownIcon } from "@assets/images/cms/ic-curret-down.svg";
import { ReactComponent as CurretUpIcon } from "@assets/images/cms/ic-curret-up.svg";
import { ReactComponent as HomeIcon } from "@assets/images/cms/ic-home.svg";
import { ReactComponent as MoveIcon } from "@assets/images/cms/ic-move.svg";
import { ReactComponent as PencilIcon } from "@assets/images/cms/ic-pencil.svg";
import { ReactComponent as PlusIcon } from "@assets/images/cms/ic-plus-alt.svg";
import { ReactComponent as DotsIcon } from "@assets/images/cms/ic-three-dots.svg";
import { ReactComponent as TrashIcon } from "@assets/images/cms/ic-trash.svg";
import { ReactComponent as VisibleIcon } from "@assets/images/cms/ic-visible.svg";
import { v4 as uuidv4 } from "uuid";
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deletePage, fetchGetPages } from "@redux/page/pageService";
import { updatePage, fetchSiteMap, fetchPageById } from "redux/cms/cmsService";
import { cmsSlice } from "redux/cms/cmsSlice";
import { PageStatus } from "redux/cms/constants";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import { setDuplicateComponent } from "redux/cms/cmsSlice";
import {
  AddButton,
  ButtonAddPage,
  ButtonDropdownActions,
  Checkbox,
  Container,
  ContainerLeaf,
  ContainerLeafChild,
  ContainerLeafParent,
  ContainerTree,
  Description,
  DropdownActions,
  DropdownItemContainer,
  DropdownItemContent,
  Select,
  SelectContainer,
  SelectLabel,
  SelectWrap,
  Separetor,
  Spacer,
} from "./styles";

const TreeItem = ({
  onSelectCallback,
  selectedItems,
  isInitOpen,
  children,
  idxParent,
  idxChild,
  parent,
  child,
  displayModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, toggleItemOpen] = useState(isInitOpen);
  const selectedLink = useSelector((state) => state?.cms?.selectedLink);
  const headerEditMode = useSelector((state) => state?.cms?.isHeadersEditing);
  const footerEditMode = useSelector((state) => state?.cms?.isFooterEditing);

  const CustomToggle = React.forwardRef((props, ref) => {
    const { onClick, "aria-expanded": isOpenIcon } = props;
    return (
      <ButtonDropdownActions
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {isOpenIcon ? <CloseIcon /> : <DotsIcon />}
      </ButtonDropdownActions>
    );
  });
  const copyTextToClipboard = (text) => {
    navigator.clipboard?.writeText(text);
    dispatch(displaySnackbar({ message: "URL copiada!", type: "info" }));
  };
  const enableDisablePage = () => {
    const status =
      child.status === PageStatus.PUBLISHED
        ? PageStatus.DRAFT
        : PageStatus.PUBLISHED;
    const page = { ...child, status: status };
    dispatch(updatePage(page));
  };

  return (
    <ContainerLeaf>
      <ContainerLeafParent theme={{ mode: idxParent > 0 ? "show" : "hide" }}>
        <div className="leaf" onClick={() => toggleItemOpen((prev) => !prev)}>
          {idxParent < 2 && (
            <div className="icon-container">
              <>{isOpen ? <CurretUpIcon /> : <CurretDownIcon />}</>
            </div>
          )}
          {(headerEditMode || footerEditMode) && (
            <Checkbox
              onChange={() => onSelectCallback()}
              checked={selectedItems.some((p) => p.id === child.id)}
              type="checkbox"
              disabled={selectedLink.type !== "internalLinks"}
            />
          )}
          <div className="label">{child.name}</div>
        </div>
        {!headerEditMode && !footerEditMode && (
          <DropdownActions>
            <DropdownActions.Toggle
              as={CustomToggle}
              id="dropdown-custom-components"
            >
              Custom toggle
            </DropdownActions.Toggle>
            <DropdownActions.Menu>
              <DropdownActions.Item
                onClick={() => {
                  history.push(`cms/canvas?id=${child.id}`);
                }}
              >
                <DropdownItemContainer>
                  <DropdownItemContent>
                    <PencilIcon />
                    <span>Editar</span>
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
              <Spacer />
              <DropdownActions.Item href="#/action-1">
                <DropdownItemContainer>
                  <DropdownItemContent>
                    <CloneIcon />
                    <span>Clonar</span>
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
              <Spacer />
              <DropdownActions.Item
                onClick={() => {
                  enableDisablePage();
                }}
              >
                <DropdownItemContainer>
                  <DropdownItemContent>
                    {child.status === PageStatus.PUBLISHED ? (
                      <>
                        <VisibleIcon />
                        <span>Desabilitar</span>
                      </>
                    ) : (
                      <>
                        <VisibleIcon />
                        <span>Habilitar</span>
                      </>
                    )}
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
              <Spacer />
              <DropdownActions.Item
                onClick={() => {
                  dispatch(fetchPageById(child.id)).then(() => {
                    dispatch(setDuplicateComponent("moving"));
                  });
                }}
              >
                <DropdownItemContainer>
                  <DropdownItemContent>
                    <MoveIcon />
                    <span>Mover</span>
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
              <Spacer />
              <DropdownActions.Item
                onClick={() => {
                  displayModal(child.id);
                }}
              >
                <DropdownItemContainer>
                  <DropdownItemContent>
                    <TrashIcon />
                    <span>Excluir</span>
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
              <Spacer />
              <DropdownActions.Item
                onClick={() => {
                  copyTextToClipboard(`${window.location.origin}/${child.url}`);
                }}
              >
                <DropdownItemContainer>
                  <DropdownItemContent>
                    <CopyIcon />
                    <span>Copiar url</span>
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
            </DropdownActions.Menu>
          </DropdownActions>
        )}
      </ContainerLeafParent>

      <ContainerLeafChild theme={{ mode: idxChild > 0 ? "show" : "hide" }}>
        {isOpen && children}
      </ContainerLeafChild>
    </ContainerLeaf>
  );
};

const SitMap = () => {
  const { addFooterItems, addHeaderItems, setParentId, updateHomepage } =
    cmsSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  const siteTreeState = useSelector((state) => state?.cms?.siteMap);
  const statusPage = useSelector((state) => state?.page?.status);
  const statusCMS = useSelector((state) => state?.cms?.status);
  const headerTypeState = useSelector((state) => state?.cms?.headersType);
  const headerEditMode = useSelector((state) => state?.cms?.isHeadersEditing);
  const footerEditMode = useSelector((state) => state?.cms?.isFooterEditing);
  const pageEditMode = useSelector((state) => state?.cms?.isPageCreation);
  const headerPage = useSelector((state) => state?.cms?.headerPage);
  const footerPage = useSelector((state) => state?.cms?.footerPage);
  const selectedLink = useSelector((state) => state?.cms?.selectedLink);
  const inputHomePageState = useSelector(
    (state) => state?.cms?.inputHomePageState
  );
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [addedItems, setAddedItems] = useState([]);
  const [siteTree, setSiteTree] = useState([]);
  const [show, setShow] = useState(false);
  const [pageId, setPageid] = useState();
  const [isAddEnable, setIsAddEnable] = useState(true);

  useEffect(() => {
    setIsAddEnable(!headerEditMode && !footerEditMode && !pageEditMode);
  }, [headerEditMode, footerEditMode, pageEditMode]);

  const handleCheckClick = (item) => {
    let isSelected = false;
    if (addedItems.filter((p) => p.id === item.id).length > 0) {
      setAddedItems((prev) => prev.filter((p) => p.id !== item.id));
    } else {
      isSelected = true;
      setAddedItems((prev) => [
        ...prev,
        { id: item.id, name: item.name, url: item.url },
      ]);
    }
    setSiteTree((prev) =>
      prev.reduce((acc, cur) => {
        if (cur.id === item.id) {
          acc.push({ ...cur, selected: isSelected });
        } else {
          if (cur.childsPage) {
            cur.childsPage.reduce((accSub, curSub) => {
              if (curSub.id === item.id) {
                accSub.push({ ...curSub, selected: isSelected });
              } else {
                accSub.push(curSub);
              }

              return accSub;
            }, []);
          }
          acc.push(cur);
        }
        return acc;
      }, [])
    );
  };
  const handleAddClick = () => {
    const externalAddedItems = [{ id: Math.random(), ...selectedLink }];
    if (headerEditMode) {
      selectedLink.type === "externalLinks"
        ? dispatch(
            addHeaderItems({ selectedMenu, addedItems: externalAddedItems })
          )
        : dispatch(addHeaderItems({ selectedMenu, addedItems }));
    } else {
      selectedLink.type === "externalLinks"
        ? dispatch(
            addFooterItems({ selectedMenu, addedItems: externalAddedItems })
          )
        : dispatch(addFooterItems({ selectedMenu, addedItems }));
    }
    addedItems.map((e) => handleCheckClick(e));
    setSelectedMenu(-1);
  };
  const handleModal = (state) => {
    setPageid(state);
  };
  useEffect(() => {
    setSiteTree(siteTreeState);
  }, [siteTreeState]);
  useEffect(() => {
    if (pageId) setShow(true);
  }, [pageId]);
  useEffect(() => {
    if (
      statusPage === "deletePage-success" ||
      statusCMS === "updatePage-success" ||
      statusCMS === "createPage-success"
    ) {
      dispatch(fetchGetPages());
      dispatch(fetchSiteMap());
    }
  }, [statusPage, statusCMS]);
  const handleDeletePage = () => {
    dispatch(deletePage(pageId));
    setShow(false);
  };
  const headerType = {
    HEADER: 1,
    HEADER_NAV: 2,
  };
  const createTree = (current, parent, idxParent, idxChild) => (
    <TreeItem
      id={current.name}
      key={uuidv4()}
      onSelectCallback={() => handleCheckClick(current)}
      isInitOpen={idxParent === 0}
      selectedItems={addedItems}
      name="tree"
      idxParent={idxParent}
      idxChild={idxChild}
      parent={parent}
      child={current}
      displayModal={handleModal}
    >
      {current?.childsPage?.length > 0 ? (
        <>
          {current?.childsPage?.map((branch, idx) => {
            return (
              <React.Fragment key={uuidv4()}>
                <>{createTree(branch, current, idxParent + 1, idx + 1)}</>
              </React.Fragment>
            );
          })}
          {idxParent < 2 && isAddEnable && (
            <ButtonAddPage
              onClick={() => {
                dispatch(setParentId(current.id));
                history.push(`/cms/canvas`);
              }}
            >
              <PlusIcon />
            </ButtonAddPage>
          )}
        </>
      ) : (
        <>
          {idxParent < 2 && isAddEnable && (
            <ButtonAddPage
              onClick={() => {
                dispatch(setParentId(current.id));
                history.push(`/cms/canvas`);
              }}
            >
              <PlusIcon />
            </ButtonAddPage>
          )}
        </>
      )}
    </TreeItem>
  );

  const handleSelectMenu = (e) => {
    dispatch(updateHomepage(e.target.value));
  };

  let initial_page_options = [];

  siteTreeState?.forEach((item) => {
    initial_page_options.push(
      <option value={item.url} key={uuidv4()} name={item.name}>
        {item.name}
      </option>
    );
    item.childsPage.forEach((item2) => {
      initial_page_options.push(
        <option value={item2.url} key={uuidv4()} name={item2.name}>
          {item.name} - {item2.name}
        </option>
      );
      item2.childsPage.forEach((item3) =>
        initial_page_options.push(
          <option value={item3.url} key={uuidv4()} name={item3.name}>
            {item.name} - {item2.name} - {item3.name}
          </option>
        )
      );
    });
  });

  initial_page_options.sort((a, b) => {
    return a.name >= b.name;
  });

  return (
    <Container>
      <Description className={headerEditMode || footerEditMode ? "px-2" : ""}>
        {headerEditMode
          ? "Selecione uma ou mais páginas na árvore abaixo e, caso exista, selecione um parentesco para a(s) página(s) selecionada(s)."
          : "Edite ou adicione páginas no esquema abaixo. Arraste a página que está editando para posicioná-la onde deseja no site."}
      </Description>
      <ContainerTree>
        <HomeIcon className="icon-home" />
        {siteTree.map((item, index) => createTree(item, {}, 0, index))}
        {isAddEnable && (
          <ButtonAddPage
            onClick={() => {
              dispatch(setParentId());
              history.push(`/cms/canvas`);
            }}
          >
            <PlusIcon />
          </ButtonAddPage>
        )}
      </ContainerTree>
      {(headerEditMode || footerEditMode) && (
        <>
          <Separetor />
          {(headerTypeState === headerType.HEADER_NAV || footerEditMode) && (
            <SelectWrap>
              <SelectContainer>
                <SelectLabel>
                  {footerEditMode ? "Seção" : "Parentesco"}
                </SelectLabel>
                <Select
                  value={selectedMenu}
                  onChange={(value) => setSelectedMenu(value.target.value)}
                >
                  <option value="-1">Selecionar</option>
                  {headerEditMode
                    ? headerPage?.navMenu?.map((e, i) => (
                        <option key={uuidv4()} value={e.id}>
                          {e.name}
                        </option>
                      ))
                    : footerPage?.section?.map((e, i) => (
                        <option key={uuidv4()} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                </Select>
              </SelectContainer>
            </SelectWrap>
          )}
          <AddButton onClick={handleAddClick}>Adicionar ao Menu</AddButton>
          <Separetor />
          <SelectWrap
            style={
              inputHomePageState === "error" ? { border: "1px solid red" } : {}
            }
          >
            <SelectContainer>
              <SelectLabel>Página Inicial</SelectLabel>
              <Select
                value={headerPage?.homePageURL}
                onChange={(value) => handleSelectMenu(value)}
              >
                <option value="-1">Selecione</option>
                {initial_page_options}
              </Select>
            </SelectContainer>
          </SelectWrap>
        </>
      )}
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Excluir página</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você tem certeza que deseja excluir essa página?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleDeletePage();
            }}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SitMap;
