import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  ImageSection4,
  Section,
  Text,
  Title,
  Subtitle,
  ImageSection4Wrapper,
  Button,
} from "../Secao";
import { templateImageUrl } from "redux/cms/constants";

export const Secao4A = ({ properties, mode, variant }) => {
  return (
    <Section
      theme={{ mode: mode, variant: variant }}
      className="align-items-center"
    >
      <div className="maxSectionWidth w-100">
        <Title theme={{ mode: mode }} className="mb-3">
          {properties[0].value}
        </Title>
        <div className="section4DivsContainer">
          <ImageSection4Wrapper className={mode === "default" ? "" : ""}>
            <ImageSection4
              src={
                properties[1].properties[0].value === ""
                  ? templateImageUrl
                  : properties[1].properties[0].value
              }
              theme={{ mode: mode }}
              alt=""
            />
          </ImageSection4Wrapper>
          <div
            className={
              "section4TextContainer " + (mode === "default" ? "w-100" : "")
            }
          >
            <Subtitle theme={{ mode: mode }} className="mb-1">
              <b>{properties[1].properties[1].value}</b>
            </Subtitle>
            <Text
              theme={{ mode: mode }}
              dangerouslySetInnerHTML={{
                __html: properties[1].properties[2].value,
              }}
            ></Text>
          </div>
          <div
            className={
              "d-flex section4ButtonContainer " +
              (mode === "default" ? "w-100" : "")
            }
          >
            <Button
              theme={{ mode: mode, variant: variant }}
              href={properties[2].properties[0].value}
              target="_blank"
              className={
                "d-flex align-items-center justify-content-center " +
                (mode === "default" ? "w-100" : "")
              }
            >
              <Subtitle theme={{ mode: mode }}>
                <b>{properties[2].properties[1].value}</b>
              </Subtitle>
            </Button>
          </div>
        </div>
      </div>
    </Section>
  );
};

Secao4A.defaultProperties = {
  mode: "dafault",
};

export default Secao4A;
