export const BlogPageType = {
  POST_LIST: "post-list",
  POST_PAGE: "post-page",
  CATEGORY_LIST: "category-list",
  ADD_CATEGORY: "add-category",
  EDIT_CATEGORY: "edit-category",
  ADD_SUBCATEGORY: "add-subcategory",
  EDIT_SUBCATEGORY: "edit-subcategory",
  FEATURED_PAGE: "featured-page",
};
export const BlogStatus = {
  ENABLED: "ENABLED",
  DELETED: "DELETED",
  DISABLED: "DISABLED",
  DRAFT: "DRAFT",
  TRASH: "TRASH",
  PUBLISHED: "PUBLISHED",
};
