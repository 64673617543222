import React from "react";
import { Nav } from "react-bootstrap";
import { StyledHeader, TopLink, TopNavbar } from "./styles";

const Header = ({ data }) => {
  const topMenu = data?.map((topMenu, i) => {
    return (
      <TopLink href={topMenu.url} key={`header${i}`}>
        {topMenu.name}
      </TopLink>
    );
  });

  return (
    <StyledHeader>
      {topMenu && (
        <TopNavbar>
          <Nav>{topMenu}</Nav>
        </TopNavbar>
      )}
    </StyledHeader>
  );
};
export default Header;
