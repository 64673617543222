import React from "react";
import { ContentBlueHeaderBackground } from "./styles";

export function ContentBlueHeader(props) {
  return (
    <ContentBlueHeaderBackground>
        <h1>{props.data.title}</h1>
        <p>{props.data.subtitle}</p>
    </ContentBlueHeaderBackground>
  )
}