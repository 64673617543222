import { LocationCardAccordionBodyBackground } from "./styles";

export function LocationCardAccordionBody(props) {
  return (
    <LocationCardAccordionBodyBackground>
      <hr />
      <div className="d-flex justify-content-between">
        <p className="mb-0">Detalhes do local</p>
        <a href="/reservas">
          <b>Fazer uma reserva &gt;</b>
        </a>
      </div>
      <hr />
      <div className="d-flex">
        <img src="/ic-phone.svg" alt={props.data[0].img.alt} />
        <a className="phone" href={"tel:" + props.data[1].branchStationPhone}>
          <div className="d-flex align-items-center my-2">
            <p>
              <b>{props.data[1].branchStationPhone}</b>
            </p>
            <img
              className="img-external-link"
              src="/ic-external.svg"
              alt={props.data[0].img.alt}
            />
          </div>
        </a>
      </div>
      <div className="d-flex">
        <img src="/ic-clock.svg" alt={props.data[0].img.alt} />
        <p>
          <b>
            Horas de hoje • {props.data[1].todayOpen} para{" "}
            {props.data[1].todayClose}
          </b>
        </p>
      </div>
    </LocationCardAccordionBodyBackground>
  );
}
