import { Container } from "react-bootstrap";
import styled from "styled-components";

export const StyledClosestAgenciesSuggestionCard = styled(Container)`
    font-family: "Poppins", sans-serif;
    display: flex;
    height: 260px;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 20px;
    flex-direction: column;
    background-color: white;
    h3{
        font-weight: bold;
        font-size: 16px;
        color: #213B73;
    }
    p{
        font-size: 14px;
        color: #213B73;
        margin: 10px 0;
    }
    a{
        color: #8C4C9E;
        text-decoration: none;
        font-weight: bold;
    }
    @media (max-width: 992px) {
        margin: 30px 0;
    }
`;

export const ClosestAgenciesSuggestionButton = styled.div`
    background-color: #8C4C9E;
    display: flex;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    margin: 20px 0;
`;

export const ClosestAgenciesSuggestionContent = styled.div`
    display: flex;
    justify-content: space-between;
`;