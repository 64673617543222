import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const StyledVehicleDetails = styled.div`
  background-color: #005daa;
  padding: 40px 0;
  h1,
  h4,
  p,
  hr {
    font-family: "Poppins", sans-serif;
    color: #fff;
  }
`;
export const VehicleDetailsDesktop = styled(Container)`
  @media (max-width: 991px) {
    display: none;
  }
`;
export const VehicleDetailsMobile = styled(Container)`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
`;
export const VehicleDetailsTitle = styled.h1`
  font-size: 26px;
  font-weight: 700;
  @media (max-width: 991px) {
    font-size: 17px;
  }
`;
export const VehicleDetailsImage = styled(Col)`
  display: flex;
  img {
    width: 100%;
    height: auto;
  }
`;
export const VehicleDetailsSpecs = styled(Row)`
  padding-top: 40px;
  @media (max-width: 991px) {
    padding-top: 20px;
    display: flex;
  }
`;
export const VehicleDetailsSubtitle = styled.h4`
  font-size: 17px;
  font-weight: 700;
`;
export const VehicleDetailsIcon = styled.img`
  display: flex;
  fill: white;
  width: 16px;
`;
export const VehicleDetailsText = styled.p`
  font-size: 14px;
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;
export const VehicleDetailsSpecsText = styled.p`
  font-size: 14px;
  font-weight: 700;
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;
