import { BackOfficeHeader } from "components/BackOfficeHeader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserCreation } from "../components/UserCreation";

export function UserCreationPage(props) {
    const history = useHistory();
    const loggedUser = useSelector(state => state.auth.userLogged);
    if (!loggedUser) {
        history.push("/backoffice/login");
    }
    if(loggedUser?.roles[0] !== "ROLE_ADMIN"){
        history.push("/cms");
    }

    return (
        <>
            <BackOfficeHeader />
            <UserCreation />
        </>
    )
}