import styled from "styled-components";
import { Col, Row, DropdownButton } from "react-bootstrap";
import backgroundImage from "assets/images/bg-footer.svg";
import dropDownImageBefore from "assets/images/ic-dropdown-arrow-expand.svg";
import dropDownImageAfter from "assets/images/ic-dropdown-arrow-compress.svg";

export const FooterBackground = styled.div`
  color: #213b73;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  background: url(${backgroundImage}) no-repeat left bottom;
  background-size: 250px;
  hr {
    opacity: 1;
  }
`;

export const FooterMenuCol = styled(Col)`
  a {
    color: #213b73;
    text-decoration: none !important;
  }
`;

export const FooterMenu = styled.ul`
  padding-bottom: 15px !important;
  text-decoration: none !important;
  list-style: none;
  ul li::marker {
    list-style: none;
    color: red;
  }
`;

export const FooterMenuName = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`;

export const FooterMenusDesktop = styled(Row)`
  display: flex;
  margin-bottom: 150px;
  @media (max-width: 991px) {
    display: none;
  }
  hr {
    opacity: 1;
  }
`;

export const FooterMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
  hr {
    margin: 0;
    opacity: 1;
  }
  svg {
    transform: scaleY(-1);
  }
`;

export const FooterDropdown = styled(DropdownButton)`
  display: flex;
  padding-top: 5px;
  button {
    border: none;
    background: url(${dropDownImageBefore}) no-repeat 97%;
    color: #213b73 !important;
    font-weight: bold;
    width: 100%;
    text-align: left;
    background-color: transparent !important;
  }

  li {
    padding: 10px;
  }

  hr {
    margin: 0;
    color: #213b73;
  }

  &.show {
    button {
      background: url(${dropDownImageAfter}) no-repeat 97%;
    }
  }

  .dropdown-menu.show {
    padding: 0;
    width: 100%;
    a {
      margin: 5px 0;
      color: #213b73;
      text-decoration: none;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
`;

export const FooterCopyright = styled.p`
  margin-top: 15px;
  text-align: end;
`;
