import { ReactComponent as SetaIcon } from "@assets/images/cms/ic-seta.svg";
import { BackOfficeHeader } from "components/BackOfficeHeader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { fetchSiteMap } from "redux/cms/cmsService";
import DragNDrop from "./components/DragNDrop";
import PagePosition from "./components/PagePosition";
import SetupPage from "./components/SetupPage";
import Routes from "./routes";
import {
  BackButtonLink,
  BodyContainer,
  BodyItemContainer,
  Container,
  HeaderContainer
} from "./styles";

const CmsPage = (props) => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.auth.userLogged);
  if (!loggedUser) {
    history.push("/backoffice/login");
  }
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(fetchSiteMap());
  }, []);

  const selectParentPageState = useSelector(
    (state) => state?.cms?.selectParentPage
  );

  return (
    <>
      <Container>
        <HeaderContainer>
          <BackOfficeHeader data={props.data.headerData} />
        </HeaderContainer>
        {location.pathname !== "/cms" && (
          <BackButtonLink href="/cms">
            <SetaIcon />
            <span>Voltar</span>
          </BackButtonLink>
        )}
        <BodyContainer>
          <DragNDrop>
            <BodyItemContainer theme={{ mode: "left" }}>
              {selectParentPageState === "duplicate" ||
              selectParentPageState === "moving" ? (
                <PagePosition />
              ) : (
                <SetupPage />
              )}
            </BodyItemContainer>
            <BodyItemContainer theme={{ mode: "rigth" }}>
              <Routes />
            </BodyItemContainer>
          </DragNDrop>
        </BodyContainer>
      </Container>
    </>
  );
};

export default CmsPage;
