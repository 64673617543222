import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@api/api";
import getAxios from "api/api";

export const fetchVehicles = createAsyncThunk(
  "vehicles/fetchVehicles",
  async () => {
    const response = await getAxios().get("/vehicles");
    return response.data;
  }
);
