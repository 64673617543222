import React from "react";
import { Container, Nav } from "react-bootstrap";
import {
  Hamburguer,
  HamburguerMenu,
  HeaderLogo,
  MainDropdown,
  MainDropdownItem,
  MainLink,
  MainNavbar,
  ResponsiveNavbar,
  StyledHeader,
} from "./styles";

const HeaderNav = ({ data, logo }) => {
  const mainMenu = data?.map((mainMenu, i) => {
    if (mainMenu.subLinks) {
      return (
        <div key={`mainMenu${i}`}>
          <MainDropdown title={mainMenu.name} align={{ lg: "end" }}>
            {mainMenu.subLinks.map((submenuItems, idx) => (
              <div key={`mainMenu${i}-${idx}`}>
                <MainDropdownItem
                  href={submenuItems.url}
                  alt={submenuItems.name}
                >
                  {submenuItems.name}
                </MainDropdownItem>
                {idx < mainMenu.subLinks.length - 1 ? (
                  <hr style={{ margin: "0 15px", opacity: 0.25 }} />
                ) : null}
              </div>
            ))}
          </MainDropdown>
        </div>
      );
    } else {
      return (
        <div key={`mainMenu${i}`}>
          <MainLink href={mainMenu.url}>{mainMenu.name}</MainLink>
        </div>
      );
    }
  });

  return (
    <StyledHeader>
      <MainNavbar>
        <a href="/" alt="Alamo">
          <HeaderLogo>
            <img src={logo} alt="logo" />
          </HeaderLogo>
        </a>
        <Nav className="ms-auto">{mainMenu}</Nav>
      </MainNavbar>

      <ResponsiveNavbar expand="xxl">
        <a href="/" alt="Alamo">
          <HeaderLogo>
            <img src={logo}  alt="logo" />
          </HeaderLogo>
        </a>

        <Hamburguer aria-controls="responsive-navbar-nav" />
        <HamburguerMenu id="responsive-navbar-nav">
          <Nav>
            <Container>
              {mainMenu}
              <hr style={{ margin: "0px" }} />
            </Container>
          </Nav>
        </HamburguerMenu>
      </ResponsiveNavbar>
    </StyledHeader>
  );
};
export default HeaderNav;
