import styled from "styled-components";
import { ReactComponent as CityImage } from "@assets/images/city.svg";
import { ReactComponent as BoatImage } from "@assets/images/boat.svg";
import { ReactComponent as PlaneImage } from "@assets/images/plane.svg";

export const AgencyFilterBackground = styled.div`
  background-color: #213b73;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  padding: 30px 43px 55px;
  @media (max-width: 768px) {
    padding: 30px 15px 55px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  padding: 32px 97px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: stretch;
  width: 100%;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
export const ItemContainer = styled.button`
  min-width: 350px;
  padding: 8px;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-content: center;
`;
export const TextContainer = styled.div`
  padding: 8px;
  color: #8c4c9e;
  font-weight: bold;
  font-size: 17px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
export const PopularCityContainer = styled.div`
  padding: 8px;
  font-weight: bold;
  font-size: 17px;
  min-width: 350px;
  padding: 8px;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-content: center;
  a {
    text-decoration: none;
    color: #8c4c9e;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
export const IconContainer = styled.div`
  background-color: #005ca9;
  display: flex;
  border-radius: 3px;
  padding: 8px;
  height: 46px;
  width: 46px;
  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;
export const HeaderContent = styled.div`
  padding: 13px 0;
  width: 100%;
  font-size: 17px;
  color: #213b73;
  font-weight: bold;
  border-bottom: solid 1px #213b73;
  margin-bottom: 28px;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 18px;
  }
`;

export const CityIcon = styled(CityImage)`
  fill: #fef100;
  width: 30px;
`;

export const BoatIcon = styled(BoatImage)`
  fill: #fef100;
`;

export const PlaneIcon = styled(PlaneImage)`
  fill: #fef100;
`;

export const CityText = styled.span`
  color: #213b73;
  min-width: 350px;
  padding: 8px;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-content: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
