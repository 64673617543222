import {Col, Row} from "react-bootstrap";
import styled from "styled-components";
import {palettes} from "../../../../assets/theme";

export const PostListContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0 4%;
`;
export const PostListItemContainer = styled(Col)`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;
export const PostListRow = styled(Row)`
    width: 100%;
`;

export const Subtitle = styled.h3`
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: normal;
    margin-left: 70px;
    color: #213B73;
    a{
        font-size: 17px;
        text-decoration: underline;
    }
    @media (max-width: 768px) {
        font-size: 17px;
    }
`;

export const Spacer = styled.hr`
  background: ${palettes.tints_blue_darker};
  height: 1px;
  margin: 65px;
  opacity: 1;
`;
