import { Card } from "react-bootstrap";
import styled from "styled-components";

export const PostCard = styled(Card)`
    border: none;
    border-radius: 0;
    max-width: 275px;
    margin: 0 auto;
    .card-img-top {
        border-radius: 0;
        height: 170px;
    }
    .card-body {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .card-footer {
        padding: 20px 0;
        background-color: #fff;
        border-top: solid 1px #213B73;
    }

    @media (max-width: 768px) {
        max-width: 375px;
        .card-img-top {
            height: 208px;
        }
    }
`;

export const PostListItemCategory = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
    p{
        margin: 0;
        margin-right: 5px;
        border: solid 1px #A6ADB4;
        border-radius: 32px;
        color: #A6ADB4;
        font-family: "Poppins", sans-serif;
        font-size: 9px;
        font-weight: bold;
        padding: 10px;
    }
`;