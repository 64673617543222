import React from "react";
import { Col } from "react-bootstrap";
import { StyledButton } from "../Button/styles.js";
import { Paragraph } from "../Global/Texts/styles.js";
import {
	WrongTurnWarningContainer,
	WrongTurnWarningImageContainer,
	WrongTurnWarningTextBody,
} from "./styles.js";

export function WrongTurnWarning(props) {
	return (
		<WrongTurnWarningContainer>
			<Col lg={6} sm={8} xs={12}>
				<WrongTurnWarningTextBody>
					<Paragraph dangerouslySetInnerHTML={{ __html: props.data.body }} />
				</WrongTurnWarningTextBody>
				<div>
					<a href='/'>
						<StyledButton color={props.data.button.color} colortext="#213B73">
							{props.data.button.text}
						</StyledButton>
					</a>
				</div>
			</Col>
			<Col lg={6} sm={4} xs={12}>
				<WrongTurnWarningImageContainer>
					<img
						src={props.data.image.src}
						alt={props.data.image.alt}
					/>
				</WrongTurnWarningImageContainer>
			</Col>
		</WrongTurnWarningContainer>
	);
}
