import styled from "styled-components";
import { Button } from "react-bootstrap";


export const TagFilterContainer = styled.div`
    display: flex;
    margin: 20px 4.5%;
    flex-wrap: wrap;
    @media (max-width: 992px) {    
        justify-content: center;
    }
    @media (max-width: 765px) {
        flex-wrap: nowrap;    
        overflow: scroll;
    }
`;

export const TagFilterLink = styled(Button)`
    display: flex;
    align-items: center;
    max-height: 31px;
    min-width: fit-content;
    padding: 20px;
    margin: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    background-color: ${(props) => props.backgrondColor};
    color: ${(props) => props.color};
    border: 1px solid #005DAA;
    border-radius: 32px;
`;