import React from "react";
import Header from "@cms-common/Header";
import HeaderNav from "@cms-common/HeaderNav";
import Footer from "@cms-common/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchHeaders, fetchFooter } from "redux/cms/cmsService";

export function StaticPageEngine(props) {
    const dispatch = useDispatch()
    const headerPageState = useSelector((state) => state?.cms?.headerPage);
    const footerPageState = useSelector((state) => state?.cms?.footerPage);

    React.useEffect(() => {
        dispatch(fetchHeaders());
        dispatch(fetchFooter());
    }, []);

    return(
        <>
        <Header data={headerPageState?.topMenu} />
        <HeaderNav data={headerPageState?.navMenu} logo={headerPageState?.image} />
        {props.components}
        <Footer data={footerPageState?.section}/>
        </>
    )
}