import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ClosestAgenciesSuggestionCard } from "../ClosestAgenciesSuggestionCard";
import {
	StyledClosestAgenciesSuggestionBlock,
	ClosestAgenciesSuggestionHeader,
} from "./styles";

export function ClosestAgenciesSuggestionBlock(props) {
	const cards = props.data.cards.map((card, i) =>
		<>
			<Col lg={4} md={12} xs={12} key={i}>
				<ClosestAgenciesSuggestionCard data={card} />
			</Col>
		</>
	)
	return (
		<StyledClosestAgenciesSuggestionBlock color={props.data.css.backgroundColor}>
			<Container>
				<ClosestAgenciesSuggestionHeader color={props.data.css.color} linkColor={props.data.css.linkColor}>
					<h2>Agências de alguel mais próximas</h2>
					<a href="/">Exibir Mapa</a>
				</ClosestAgenciesSuggestionHeader>
				<Row>
					{cards}
				</Row>
			</Container>
		</StyledClosestAgenciesSuggestionBlock>
	);
}
