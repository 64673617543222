import Footer from "@cms-common/Footer";
import { BlogHeader } from "components/BlogHeader";
import { HorizontalImageTitleInfoAndButtons } from "components/HorizontalImageTitleInfoAndButtons";
import { LoaderComponent } from "components/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchSubcategory } from "redux/blog/blogService";
import { fetchCategory } from "redux/blog/blogService";
import { BlogStatus } from "redux/blog/constants";
import { fetchHighlights } from "redux/cms/cmsService";
import { setSubcategory } from "redux/postList/postListSlice";
import { setPostHighlight } from "redux/postList/postListSlice";
import BlogHighlight from "../components/BlogHighlight";
import { BreadCrumb } from "../components/BreadCrumb";
import { PostHighlight } from "../components/PostHighlight";
import { PostList } from "../components/PostList";
import mock from "./Resources/example";
import { getHighlights } from "redux/blog/blogService";
import { getLastFivePosts } from "redux/postList/postListService";
import { fetchFooter } from "redux/cms/cmsService";
import { Section } from "../../CMS/components/SetupPage/components/Templates/common/Secao";

export function BlogHomepage(props) {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const postListItem = useSelector((state) => state?.postList?.postListItem);
  const postHighlight = useSelector((state) => state?.postList?.postHighlight);
  const posts = useSelector((state) => state?.postList);
  const blogHighlights = useSelector((state) => state?.blog?.highlights);
  const user = useSelector((state) => state?.auth?.user);
  const footerPageState = useSelector((state) => state?.cms?.footerPage);

  useEffect(() => {
    dispatch(setSubcategory(undefined));
    dispatch(getLastFivePosts());
    dispatch(fetchCategory(BlogStatus.ENABLED));
    dispatch(fetchSubcategory(BlogStatus.ENABLED));
    dispatch(fetchHighlights());
    dispatch(getHighlights(user));
    dispatch(fetchFooter());
  }, []);
  useEffect(() => {
    if (postListItem?.length > 0) {
      dispatch(setPostHighlight());
    }
  }, [postListItem]);
  return (
    <>
      {posts.status === "loading" && <LoaderComponent />}
      <BlogHeader data={mock.headerData} />
      <BreadCrumb data={mock.breadCrumbData} />
      {/* {categoryId && <TagFilter categoryId={categoryId} />} */}
      {postHighlight?.id && (
        <PostHighlight data={postHighlight} className={"mb-2"} />
      )}
      <Section>
        <PostList className="maxSectionWidth" />
      </Section>
      {blogHighlights && (
        <BlogHighlight
          properties={blogHighlights}
          mode={"default"}
          variant={"blue"}
          className={""}
        />
      )}
      <HorizontalImageTitleInfoAndButtons
        data={mock.horizontalImageTitleInfoAndButtonsData}
      />

      <Footer data={footerPageState?.section} />
    </>
  );
}

export default BlogHomepage;
