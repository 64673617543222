import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { cmsSlice } from "redux/cms/cmsSlice";

export function WarningModal(props) {
    const dispatch = useDispatch();
    const WarningModalState = useSelector((state) => state?.cms?.WarningModal);
    const { setWarningModal } = cmsSlice.actions;

    const handleClose = () => dispatch(setWarningModal(false));
    
    return (
        <>
            <Modal show={WarningModalState} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}