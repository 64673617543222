import React from "react";
import { Section, Title, Text, Subtitle } from "../Secao";
import { Container } from "react-bootstrap";

export const Secao1B = ({ properties, mode, variant }) => {
  return (
    <Section theme={{ mode: mode, variant: variant }} color="white">
      <Container fluid="lg" className="maxSectionWidth px-0">
        <Subtitle theme={{ mode: mode }}>
          <b>{properties[0].value}</b>
        </Subtitle>
        <Title theme={{ mode: mode }}>{properties[1].value}</Title>
        <Text
          theme={{ mode: mode }}
          dangerouslySetInnerHTML={{ __html: properties[2].value }}
        ></Text>
      </Container>
    </Section>
  );
};

Secao1B.defaultProperties = {
  mode: "dafault",
};

export default Secao1B;
