import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StyledButton } from "../Button/styles";
import LocationAllCard from "./LocationAllCard";
import {
  LocationAllBackground,
  LocationAllButtonCol,
  LocationAllCardsRow,
  LocationAllHeader,
  LocationAllText
} from "./styles";

export function LocationAll(props) {
  return (
    <LocationAllBackground>
      <Container>
        <Row>
          <LocationAllHeader>
            <Col xl={10} lg={9} md={8} sm={6} xs={12}>
              <LocationAllText id="title">
                Agências de aluguel de carros
              </LocationAllText>
            </Col>
            <LocationAllButtonCol xl={2} lg={3} md={4} sm={6} xs={12}>
              <StyledButton color="#8C4C9E">
                <LocationAllText id="subtitle">
                  Fazer uma reserva
                </LocationAllText>
              </StyledButton>
            </LocationAllButtonCol>
          </LocationAllHeader>
        </Row>
        <LocationAllCardsRow>
          <LocationAllCard />
        </LocationAllCardsRow>
      </Container>
    </LocationAllBackground>
  );
}
