import styled from "styled-components";

export const ContentBlockText = styled.div`
    font-family: "Poppins", sans-serif;
    color: #213B73;
    padding: 1.5rem;
    h1{
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 25px;
    }
    p{ 
        font-size: 22px;
    }
    @media (max-width: 768px) {
        padding: 1rem;
        h1{
            font-size: 17px;
            margin-bottom: 8px;
        }
        p{
            font-size: 14px;
        }
    }
`;