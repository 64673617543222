import styled from "styled-components";

export const FAQContentPageBody = styled.div`
    width: 90%;
    padding-left: 5%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ul{
        list-style: disc !important;
    }

    @media (max-width: 768px) {
        padding: 24px;
        width: 100%;
        h1{
            margin-bottom: 22px !important;
        }
    }
`;