import { StyledButton } from "@components/Button/styles";
import { OtherVehicles } from "@components/OtherVehicles";
import { VehicleCard } from "@components/VehicleCard";
import { VehicleFilter } from "@components/VehicleFilter";
import {
  getFilteredVehicles,
  setFilterCategoryQuery,
  setFilterCountry
} from "@redux/vehicle/vehiclesSlice";
import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  CarList,
  OtherVehiclesContainer,
  VehicleWidgetButton,
  VehicleWidgetContainer,
  VehicleWidgetDropdown,
  VehicleWidgetDropdownButton,
  VehicleWidgetFilterCol,
  VehicleWidgetTextDropdownButton
} from "./styles";

export function VehicleWidget(props) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const vehicleId = query.get("id");
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState({
    code: "BR",
    name: "Brasil"
  });
  const [countries, setCountries] = useState([]);
  const [otherCategories, setOtherCategories] = useState();
  const [category, setCategory] = useState();
  const locationState = useSelector((state) => state.location);
  const vehicleState = useSelector((state) => state.vehicle);
  const filterState = useSelector((state) => state.vehicle?.filter);

  useEffect(() => {
    const vehicle = vehicleState.vehicles.find(
      (x) => x.id.toString() === vehicleId
    );
    if (vehicle) {
      dispatch(setFilterCategoryQuery(vehicle.otavehCategory));
      setCategory(
        vehicleState.categories.find((x) => x.id === vehicle.otavehCategory)
      );
      setOtherCategories(
        vehicleState.categories
          .map((x) => {
            const v = vehicleState.vehicles.find(
              (y) => y.otavehCategory === x?.id && y.country === vehicle.country
            );
            if (v) return { ...v, categoryName: x.name };
            else return null;
          })
          .filter(
            (x) => x != null && x.otavehCategory !== vehicle.otavehCategory
          )
      );
    }
  }, [vehicleState.categories]);

  useEffect(() => {
    setCountries(locationState?.countries);
  }, [locationState]);

  useEffect(() => {
    dispatch(getFilteredVehicles());
  }, [filterState]);

  const carList = (list, category) => {
    return list.map((car, idx) => (
      <Col lg={4} md={6} sm={12} className="my-3" key={`carList-${idx}`}>
        <VehicleCard data={{ ...car, category }} />
      </Col>
    ));
  };

  const headerCategory = (category, size) => {
    switch (category.Id) {
      case "1":
        return (
          <span>
            Esteja você procurando um carro alugado de tamanho médio, grande ou
            com baixo consumo de combustível, você encontrará o veículo perfeito
            na frota de carros da Alamo. Drive Happy®!
          </span>
        );
      case "5":
        return (
          <span>
            Precisa de um veículo espaçoso para transportar cargas? Ou talvez
            você esteja procurando espaço extra para as pernas? A Alamo tem
            picapes perfeitas para qualquer ocasião.
          </span>
        );
      case "3":
        return (
          <span>
            De viagens em família no fim de semana a explorar o campo, a Alamo
            tem o SUV ideal para famílias para sua próxima viagem.
          </span>
        );
      case "2":
        return (
          <span>
            Planeje férias em família convenientes e econômicas com a ajuda da
            Alamo. Temos aluguel de vans grandes o suficiente para toda a
            família. Comece sua viagem na Alamo and Drive Happy®!
          </span>
        );

      default:
        return <h3>{`${category.name} (${size}) `}&gt;</h3>;
    }
  };

  const categoryList = (category, index) => {
    const vehicles = vehicleState?.filteredVehicles?.filter(
      (x) => x?.otavehCategory === category?.id
    );
    if (vehicles && vehicles.length > 0) {
      return (
        <Row key={`categoryList-${index}`}>
          {headerCategory(category, vehicles.length)}

          {carList(vehicles, category?.name)}
        </Row>
      );
    }
    return <div key={`categoryList-${index}`}></div>;
  };

  return (
    <VehicleWidgetContainer>
      <Container className="py-4">
        <Row>
          <Col md={12} lg={10}>
            <h1>
              {category
                ? `${category.name} de aluguel no ${filterState.country?.name}`
                : `Opções de carro de aluguel no Brasil`}
            </h1>
            {category && (
              <h5>
                <a href="/veiculos" className="viewAll">
                  View All Vehicles&#62;
                </a>
              </h5>
            )}
          </Col>
          <VehicleWidgetButton md={12} lg={2}>
            <StyledButton
              href="/reservas"
              color={props.data.button.color}
              colortext={props.data.button.colorText}
            >
              {" "}
              {props.data.button.text}
            </StyledButton>
          </VehicleWidgetButton>
        </Row>
      </Container>
      <Container>
        <Row>
          <VehicleWidgetFilterCol xs={12} lg={4}>
            <VehicleFilter
              data={props.data.filter}
              hasQueryCategory={vehicleId > 0}
            />
          </VehicleWidgetFilterCol>
          {!vehicleId && (
            <VehicleWidgetDropdown id="responsive">
              <VehicleWidgetTextDropdownButton>
                <Col lg={4} xs={1}>
                  <p>PAÍS:</p>
                </Col>
                <Col lg={8} xs={1}>
                  <VehicleWidgetDropdownButton
                    id="dropdown-basic-button"
                    title={selectedCountry.name}
                  >
                    {countries?.map((item, idx) => {
                      return (
                        <div key={idx}>
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedCountry(item);
                              dispatch(setFilterCountry(item));
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </div>
                      );
                    })}
                  </VehicleWidgetDropdownButton>
                </Col>
              </VehicleWidgetTextDropdownButton>
            </VehicleWidgetDropdown>
          )}
          <Col xs={12} lg={8}>
            <hr />
            <VehicleWidgetTextDropdownButton>
              <Col xs={8} lg={9}>
                <h4>
                  {category
                    ? `${category.name} de aluguel no ${filterState.country?.name}`
                    : `Opções de carro de aluguel no Brasil`}
                </h4>
                {category && (
                  <h6>
                    <a href="/veiculos" className="viewAll">
                      View All Vehicles&#62;
                    </a>
                  </h6>
                )}
              </Col>
              <Col xs={4} lg={3}>
                {!vehicleId && (
                  <VehicleWidgetDropdown>
                    <VehicleWidgetTextDropdownButton>
                      <Col lg={4} xs={1}>
                        <p>PAÍS:</p>
                      </Col>
                      <Col lg={8} xs={11}>
                        <VehicleWidgetDropdownButton
                          id="dropdown-basic-button"
                          title={selectedCountry.name}
                        >
                          {countries?.map((item, idx) => {
                            return (
                              <div key={idx}>
                                <Dropdown.Item
                                  onClick={() => {
                                    setSelectedCountry(item);
                                    dispatch(setFilterCountry(item));
                                  }}
                                >
                                  {item.name}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                              </div>
                            );
                          })}
                        </VehicleWidgetDropdownButton>
                      </Col>
                    </VehicleWidgetTextDropdownButton>
                  </VehicleWidgetDropdown>
                )}
                <VehicleWidgetFilterCol id="responsive">
                  <VehicleFilter data={props.data.filter} />
                </VehicleWidgetFilterCol>
              </Col>
            </VehicleWidgetTextDropdownButton>
            <hr />
            <CarList data={props.data.carList}>
              {vehicleState?.categories.map((category, idx) => 
                categoryList(category, idx)
              )}
            </CarList>
          </Col>
        </Row>
      </Container>
      {vehicleId && otherCategories?.length > 0 && (
        <OtherVehiclesContainer>
          <OtherVehicles
            data={{
              ...props.data.otherVehiclesData,
              otherCategories: otherCategories
            }}
          />
        </OtherVehiclesContainer>
      )}
    </VehicleWidgetContainer>
  );
}
