import styled from "styled-components";

export const Container = styled.div`
  margin-left: 20px;
  min-height: 100%;
  min-width: 800px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 180px;
    width: 4px;
    background: #a6adb4;
  }
`;
