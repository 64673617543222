import React from "react";
import { Container } from "react-bootstrap";
import {
  OtherVehiclesImages,
  OtherVehiclesRow,
  OtherVehiclesTitle,
  StyledOtherVehicles
} from "./styles";

export function OtherVehicles(props) {
  return (
    <StyledOtherVehicles>
      <Container>
        <OtherVehiclesTitle>
          Explore outros tipos de veículos
        </OtherVehiclesTitle>
        <OtherVehiclesRow>
          {props.data?.otherCategories?.map(
            ({ smallImage, categoryName, id }, index) => (
              <OtherVehiclesImages key={`OtherVehicles${index}`}>
                <h5>{categoryName}</h5>
                <img src={smallImage} alt="Explore novos carros!" />
                <a href={`/vehicles?id=${id}`}>Opções de visualização &#62;</a>
              </OtherVehiclesImages>
            )
          )}
        </OtherVehiclesRow>
      </Container>
    </StyledOtherVehicles>
  );
}
