import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateTemplate } from "@redux/cms/cmsSlice";
import {
  ComponentWapper,
  ComponentParentWapper,
  GreyRetangle,
  Title,
  Paragraph,
  Component,
  Spacer,
  InputWapper,
  ImageContainer,
  ImageLabel,
  Image,
  AddSectionButton,
} from "./styles";
import Accordion from "../../../Accordion";
import AccordionItem from "../../../Accordion/AccordionItem";
import AccordionHeader from "../../../Accordion/AccordionHeader";
import AccordionBody from "../../../Accordion/AccordionBody";
import EditorHtml from "pages/CMS/components/EditorHtml";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";

export const textAreaAutoResize = (e) => {
  e.target.style.height = "auto";
  e.target.style.height = e.target.scrollHeight + "px";
};

const Heading = ({ data, index, indexParent }) => {
  const [inputState, setInputState] = useState(data.value);
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch(
      updateTemplate({
        index,
        indexParent,
        newData: { ...data, value: value.target.value },
      })
    );
  };
  return (
    <InputWapper className="mr10">
      <GreyRetangle />
      <div className="content">
        <span>{data?.displayName}</span>
        <textarea
          value={inputState || ""}
          onChange={(e) => setInputState(e.target.value)}
          placeholder="Nome da Pagina"
          onInput={textAreaAutoResize}
          onFocus={textAreaAutoResize}
          onBlur={handleChange}
          autoFocus
        />
      </div>
    </InputWapper>
  );
};
const Block = ({ data, index }) => {
  const templatePage = useSelector((state) => state.cms.templatePage);
  return (
    <InputWapper className="mr10">
      <GreyRetangle />
      <ComponentParentWapper>
        <span>{data?.displayName}</span>
        {data?.displayName === "Botão 1" &&
          (templatePage.current.name === "Secao6B" ||
            templatePage.current.name === "Secao6A") && (
            <span>
              <b>
                Deixar campos abaixo em branco caso nao deseje mostrar o botao
              </b>
            </span>
          )}
        {data.properties.map((e, i) => (
          <React.Fragment key={`Block-${i}`}>
            {handelComponentSelection(e, i, index)}
          </React.Fragment>
        ))}
      </ComponentParentWapper>
    </InputWapper>
  );
};
const ListItem = ({ data, index, handleChange }) => {
  const [inputState, setInputState] = useState(data.value);
  const [inputState2, setInputState2] = useState(data.properties[0].value);
  return (
    <InputWapper className="mr10">
      <GreyRetangle />
      <ComponentParentWapper>
        <span>{data.displayName}</span>
        <InputWapper className="mr10">
          <GreyRetangle />
          <div className="content">
            <span>{data?.displayName}</span>
            <textarea
              value={inputState || ""}
              onChange={(value) =>
                setInputState(value.target.value, data.type, index)
              }
              placeholder="Nome da Pagina"
              onInput={textAreaAutoResize}
              onFocus={textAreaAutoResize}
              autoFocus
              onBlur={(value) =>
                handleChange(value.target.value, data.type, index)
              }
            />
          </div>
        </InputWapper>
        {data.properties.map((e, i) => (
          <InputWapper className="mr10" key={`Block-${i}`}>
            <GreyRetangle />
            <div className="content">
              <span>{e?.displayName}</span>
              <textarea
                value={inputState2 || ""}
                onChange={(value) =>
                  setInputState2(value.target.value, data.type, index)
                }
                placeholder="Nome da Pagina"
                onInput={textAreaAutoResize}
                onFocus={textAreaAutoResize}
                autoFocus
                onBlur={(value) =>
                  handleChange(value.target.value, e.type, index)
                }
              />
            </div>
          </InputWapper>
        ))}
      </ComponentParentWapper>
    </InputWapper>
  );
};
const BlockList = ({ data, index }) => {
  const dispatch = useDispatch();

  const handleButtonTextChande = (value, type, i) => {
    let newData = JSON.parse(JSON.stringify({ ...data }));
    if (type === "item") {
      newData.properties[i].value = value;
    } else newData.properties[i].properties[0].value = value;
    dispatch(
      updateTemplate({
        index,
        undefined,
        newData,
      })
    );
  };
  const addSection = () => {
    let newData = JSON.parse(JSON.stringify({ ...data }));
    newData.properties.push({
      displayName: `Botão${newData.properties.length}`,
      hasChanged: false,
      type: "item",
      value: "Nome do item",
      properties: [
        {
          displayName: `Texto do botão ${newData.properties.length}`,
          type: "string",
          value:
            "Aqui vai um texto descritivo do terceiro. Este texto pode ser mais longo e detalhado.",
          hasChanged: true,
        },
      ],
    });
    dispatch(
      updateTemplate({
        index,
        undefined,
        newData,
      })
    );
  };
  const dataProperties = data.properties.slice(1);
  return (
    <>
      <Heading data={data.properties[0]} index={0} indexParent={index} />
      <InputWapper className="mr10">
        <GreyRetangle />
        <ComponentParentWapper>
          <span>{data.displayName}</span>
          {dataProperties.map((e, i) => (
            <React.Fragment key={`Block-${i}`}>
              <ListItem
                data={e}
                index={i + 1}
                handleChange={handleButtonTextChande}
              />
            </React.Fragment>
          ))}
        </ComponentParentWapper>
      </InputWapper>
      <InputWapper className="mr10">
        <GreyRetangle />
        <AddSectionButton onClick={addSection}>
          Adicionar Seção
        </AddSectionButton>
      </InputWapper>
    </>
  );
};
const ImageComponent = ({ data, index, indexParent, parentName }) => {
  const templatePage = useSelector((state) => state?.cms?.templatePage);
  const dispatch = useDispatch();

  const onImageChange = (value) => {
    let files = value.target.files;
    let reader = new FileReader();

    if (files?.length > 0) {
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        const typeImg = `.${e.target.result.split(";")[0].split("/")[1]}`;
        const compatibleTypes = [".png", ".jpg", ".jpeg", ".bmp", ".webp"];
        const newData = {
          ...data,
          value: e.target.result,
          typeImg,
        };
        compatibleTypes.includes(typeImg)
          ? dispatch(
              updateTemplate({
                index,
                indexParent,
                newData,
                templatePage,
              })
            )
          : dispatch(
              displaySnackbar({
                message:
                  "Formato de imagem não suportado, formatos suportados: .png, .jpg, .jpeg, .bmp, .webp",
                type: "error",
              })
            );
      };
    }
  };
  return (
    <InputWapper className="mr10">
      <GreyRetangle />
      <ImageContainer>
        <span>{data?.displayName}</span>
        {data?.value && <img src={data.value} alt="item" />}
        <ImageLabel htmlFor={`${parentName}-${indexParent}`}>
          Alterar imagem
        </ImageLabel>
        <Image
          type="file"
          id={`${parentName}-${indexParent}`}
          name={`${parentName}-${indexParent}`}
          onChange={onImageChange}
        />
      </ImageContainer>
    </InputWapper>
  );
};
const RichTextEditor = ({ data, index, indexParent }) => {
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch(
      updateTemplate({
        index,
        indexParent,
        newData: { ...data, value: value },
      })
    );
  };
  return (
    <InputWapper className="mr10">
      <GreyRetangle />
      <div className="content">
        <span>Texto do corpo</span>
        <EditorHtml
          value={data.value || ""}
          onChange={handleChange}
          placeholder="Nome da Pagina"
        />
      </div>
    </InputWapper>
  );
};
const handelComponentSelection = (data, index, indexParent) => {
  let body = null;
  switch (data.type) {
    case "string":
    case "link":
      body =
        data.displayName === "Body" ||
        data.displayName === "Texto do Card 1" ||
        data.displayName === "Texto do Card 2" ||
        data.displayName === "Texto do Card 3" ? (
          <RichTextEditor data={data} index={index} indexParent={indexParent} />
        ) : (
          <Heading
            key={`Heading${index}`}
            data={data}
            index={index}
            indexParent={indexParent}
          />
        );
      break;
    case "bloco":
    case "button":
    case "card":
      body = <Block key={`Block${index}`} data={data} index={index} />;
      break;
    case "list":
      body = <BlockList key={`BlockList${index}`} data={data} index={index} />;
      break;
    case "image":
      body = (
        <ImageComponent
          key={`ImageComponent${index}`}
          data={data}
          index={index}
          indexParent={indexParent}
          parentName={data.displayName}
        />
      );
      break;
    default:
      body = null;
  }
  return body;
};

function TemplateEdition() {
  const templatePage = useSelector((state) => state?.cms?.templatePage);
  return (
    <div>
      <Title>Editar conteúdo</Title>
      <Paragraph>
        Altera as informações do seu template através dos blocos de conteúdo
      </Paragraph>
      {templatePage && (
        <div>
          <Accordion keepOtherOpen={true}>
            <AccordionItem>
              <ComponentWapper>
                <GreyRetangle />
                <AccordionHeader
                  style={{ marginLeft: "10px" }}
                  hasTransparentBackground
                  name={`item-${1}`}
                  title={templatePage?.current?.name}
                />
                <AccordionBody name={`item-${1}`}>
                  <Component>
                    <Spacer />
                    {templatePage?.current?.properties?.map((e, i) =>
                      handelComponentSelection(e, i, undefined)
                    )}
                  </Component>
                </AccordionBody>
              </ComponentWapper>
            </AccordionItem>
          </Accordion>
        </div>
      )}
    </div>
  );
}

export default TemplateEdition;
