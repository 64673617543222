import { BackOfficeHeaderContainer, BackOfficeHeaderImageText } from "./styles";
import loginIcon from "@assets/images/ic-login.svg";
import { MainDropdown, MainDropdownItem } from "components/Header/styles";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "redux/login/auth/authSlice";

export function BackOfficeHeader() {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state?.auth?.userLogged);
  const logout = () => {
    dispatch(setLogout());
    window.location.href = "/backoffice/login";
  };

  return (
    <BackOfficeHeaderContainer>
      <BackOfficeHeaderImageText>
        <img
          src="https://cdn.discordapp.com/attachments/586009156537024523/896097961837199360/logo.png"
          alt="Logo Alamo"
          className="logo"
        />
        <a href="/CMS" className="mx-3">
          CMS
        </a>
        {loggedUser?.roles?.includes("ROLE_ADMIN") && (
          <a className="mx-3" href="/backoffice/administracao-de-usuario">
            Administrar usuários
          </a>
        )}
      </BackOfficeHeaderImageText>
      <BackOfficeHeaderImageText>
        <img src={loginIcon} alt="Icone Login" />
        {loggedUser?.username ? (
          <MainDropdown title={loggedUser.username} align={{ lg: "end" }}>
            <MainDropdownItem href="/backoffice/pagina-de-perfil">
              Página de perfil
            </MainDropdownItem>
            <hr style={{ margin: "0 15px", opacity: 0.25 }} />
            <button onClick={logout}>
              <MainDropdownItem>Logout</MainDropdownItem>
            </button>
            <hr style={{ margin: "0 15px", opacity: 0.25 }} />
          </MainDropdown>
        ) : (
          <a href="/backoffice/login">Login</a>
        )}
      </BackOfficeHeaderImageText>
    </BackOfficeHeaderContainer>
  );
}
