import { useState } from "react";
import { useDispatch } from "react-redux";
import icon from "@assets/images/ic-checkbox.png";
import { setFilterCategory } from "@redux/vehicle/vehiclesSlice";
import { CheckboxContainer } from "./styles";

export function Checkbox({ value }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  function handleChecked() {
    setChecked(!checked);

    dispatch(setFilterCategory(value));
  }

  const checkboxState =
    checked === false ? { display: "none" } : { display: "block" };

  return (
    <>
      <CheckboxContainer>
        <button onClick={handleChecked}>
          <img src={icon} alt="checkbox" style={checkboxState} />
        </button>
      </CheckboxContainer>
    </>
  );
}
