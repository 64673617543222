import React from "react";
import { Col} from "react-bootstrap";
import {ImageTextCard} from "./ImageTextCard/index";
import { ThreeImageTextsContainer } from "./styles";
export function ThreeImageTexts(props) {
    return (
        <ThreeImageTextsContainer>
            <Col lg={4} md={8} xs={12}>
                <ImageTextCard data={props.data.cards[0]}/>
            </Col>
            <Col lg={4} md={8} xs={12}>
                <ImageTextCard data={props.data.cards[1]}/>
            </Col>
            <Col lg={4} md={8} xs={12}>
                <ImageTextCard data={props.data.cards[2]}/>
            </Col>
        </ThreeImageTextsContainer>
    )
}