import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setSubcategory } from "redux/postList/postListSlice";
import { TagFilterContainer, TagFilterLink } from "./styles";

export function TagFilter(props) {
  const [subcategoryName, setSubcategoryName] = useState(null);
  const history = useHistory();
  const { category } = useParams();
  const dispatch = useDispatch();
  const blogState = useSelector((state) => state.blog);

  useEffect(() => {
    setSubcategoryName(blogState.selectedSubcategory);
  }, [blogState]);

  function handleClick(path, id) {
    if (props.hasCategory) {
      dispatch(setSubcategory(path));
      return;
    }
    history.push(
      props.hasCategory
        ? `/blog/categoria/${category}?subcategory=${path}`
        : `/blog/categoria/${path}`
    );
  }

  function handleActive(id) {
    if (props.hasCategory) {
      return id === subcategoryName;
    }
    return id === category;
  }

  return (
    <Container fluid="xxl">
      <TagFilterContainer>
        {props.data.tagList.map((tag, i) => (
          <TagFilterLink
            key={i}
            style={{
              backgroundColor: handleActive(tag.path) ? "#005DAA" : "#fff",
              color: handleActive(tag.path) ? "#FEF100" : "#005DAA"
            }}
            onClick={() => handleClick(tag.path, tag.id)}
          >
            {tag.name}
          </TagFilterLink>
        ))}
      </TagFilterContainer>
    </Container>
  );
}
