import React, { createContext, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
export const DragNDropContext = createContext();

const DragNDrop = ({ children }) => {
  const [movedItem, setMovedItem] = useState({});
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    setMovedItem(result);
  };
  return (
    <DragNDropContext.Provider value={{ movedItem, setMovedItem }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>{children}</DragDropContext>
    </DragNDropContext.Provider>
  );
};

export default DragNDrop;
