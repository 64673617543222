import { Col, Image } from "react-bootstrap";
import styled from "styled-components";

export const ImageTitleTextBlockAndButtonBackground = styled.div`
    padding: 30px;
    color: #213B73;
    font-family: 'Poppins', sans-serif;
    h4{
        font-size: 26px;
        font-weight: bold;
    }
    p{
        font-size: 17px;
        padding: 15px 0;
    }
    hr{
        border: 1px solid #213B73;
        width: 100%;
        margin-top: 30px;
        opacity: 0.5;
    }
`;

export const ImageTitleTextBlockAndButtonImage = styled(Col)`
    display: flex;
    justify-content: end;
    @media (max-width: 991px) {
        justify-content: start;
    }
`;

export const ImageTitleTextBlockAndButtonText = styled(Col)`
    padding: 30px 20px 10px 10px;
`;

export const ImageTitleTextBlockAndButtonMainImage = styled(Image)`
    height: 180px;
    width: 180px;
`;

export const ImageTitleTextBlockAndButtonButton = styled(Col)`
    display: flex;
    align-items: center;
    a{
        text-decoration: none;
        width: 100%;
    }
    button{
        width: 100%;
    }
`;