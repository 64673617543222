import styled from "styled-components";

export const LinksToFAQBackground = styled.div`
    font-size: 17px;
    padding: 10px;
    p{
       font-weight: 700;
       color: #213B73;
       margin:0;
    }
    a{
        color: #7A498F;
        line-height: 2.4;
    }
`;