import styled from "styled-components";

export const ThreeImageTextsContainer = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 992px) {
        flex-direction: column;
        padding: 20px;
        justify-content: center;
    }
`;
