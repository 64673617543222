import { StaticPageEngine } from "pages/StaticPageEngine";
import { BreadCrumb } from "../../components/BreadCrumb";
import { ImageHeader } from "../../components/ImageHeader";
import { LocationMainAll } from "../../components/LocationMainAll";
import { PbkWidget } from "../../components/Widget";

export function Locations(props) {

  const components =
    <>
      <BreadCrumb data={props.data.breadCrumbData} />
      <ImageHeader data={props.data.imageHeaderData} />
      <LocationMainAll />
      <PbkWidget />
    </>


  return (
    <StaticPageEngine components={components} />
  );
}

export default Locations;