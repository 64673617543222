import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPage, fetchSiteMap, updatePage } from "redux/cms/cmsService";
import { cmsSlice } from "redux/cms/cmsSlice";
import { PageStatus, PageTypes } from "redux/cms/constants";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import {
  Container,
  PageConfigButton,
  PageConfigContainer,
  PageTypeButton,
  PageTypeContainer,
} from "./styles";

import { WarningModal } from "components/Global/WarningModal";
import { isUrlValidated } from "pages/CMS/pages/Canvas/components/PageSEO/urlValidation";
import { useHistory } from "react-router-dom";

const PageType = () => {
  const { setPageType, setDuplicateComponent, setWarningModal } =
    cmsSlice.actions;
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const pageType = useSelector((state) => state?.cms?.pageType);
  const canvasPageState = useSelector((state) => state?.cms?.canvasPage);
  const url = useSelector((state) => canvasPageState?.url);

  const [novaUrl, setIsNovaUrl] = useState(false);

  const isPageCreation = useSelector((state) => state?.cms?.isPageCreation);
  const handleWarningModal = (state) => dispatch(setWarningModal(true));
  const handlePageSubmit = (status) => {
    setStatus(status);
    const page = { ...canvasPageState, status: status };
    if (page?.id) {
      dispatch(updatePage(page));
    } else {
      dispatch(createPage(page));
    }
  };
  const footerState = useSelector((state) => state.cms.status);
  const errorCode = useSelector((state) => state.cms.errorCode);
  useEffect(() => {
    let message;
    let type;
    switch (footerState) {
      case "updatePage-failed":
      case "createPage-failed":
        message =
          status === PageStatus.DRAFT
            ? "Erro ao salvar a pagina"
            : "Erro ao publicar a pagina" +
              (errorCode === 409 ? ": URL já utilizada em outra página" : "");
        type = "error";
        break;
      case "updatePage-success":
      case "createPage-success":
        message =
          status === PageStatus.DRAFT
            ? "Pagina salva com sucesso"
            : "Pagina publicada com sucesso";
        type = "success";
        dispatch(fetchSiteMap());
        break;

      default:
        return;
    }
    if (message) {
      dispatch(displaySnackbar({ message, type }));
    }
  }, [footerState]);

  return (
    <Container>
      <WarningModal
        title="Não foi possível salvar esta página!"
        body="Existem campos inválidos que impedem o salvamento/publicação desta página."
      />
      <PageTypeContainer>
        <PageTypeButton
          href="/cms"
          theme={{
            mode: pageType === PageTypes.WEB_PAGE ? "enabled" : "disabled",
          }}
          onClick={() => {
            setIsNovaUrl(true);
            dispatch(setPageType(PageTypes.WEB_PAGE));
            document.location.reload(history.push("/cms"));
          }}
        >
          Website
        </PageTypeButton>

        <PageTypeButton
          theme={{ mode: pageType === PageTypes.BLOG ? "enabled" : "disabled" }}
          onClick={() => {
            dispatch(setPageType(PageTypes.BLOG));
            history.push("/cms/post-list");
          }}
        >
          Blog
        </PageTypeButton>
      </PageTypeContainer>

      {pageType === "web-page" && novaUrl === false ? (
        isPageCreation && (
          <PageConfigContainer>
            <PageConfigButton
              theme={{ mode: "inverse" }}
              onClick={() => {
                dispatch(setDuplicateComponent("duplicate"));
              }}
            >
              Duplicar página
            </PageConfigButton>
            <PageConfigButton
              theme={{ mode: "inverse" }}
              onClick={() =>
                isUrlValidated(url)
                  ? handlePageSubmit(PageStatus.DRAFT)
                  : handleWarningModal()
              }
            >
              Salvar rascunho
            </PageConfigButton>
            <PageConfigButton
              theme={{ mode: "normal" }}
              onClick={() =>
                isUrlValidated(url)
                  ? handlePageSubmit(PageStatus.PUBLISHED)
                  : handleWarningModal()
              }
            >
              Publicar página
            </PageConfigButton>
          </PageConfigContainer>
        )
      ) : (
        <style> display: none </style>
      )}
    </Container>
  );
};

export default PageType;
