import { Container, Row, Col } from "react-bootstrap";
import { PostHighlightContainer, PostCategory } from "./styles";

export function PostHighlight(props) {
  return (
    <PostHighlightContainer>
      <Container>
        <div className="d-flex justify-content-evenly">
          <Row>
            <Col className="d-flex justify-content-end" lg={7} md={12}>
              <img src={props.data.image} alt="Post" className="img-fluid" />
            </Col>
            <Col lg={5} md={12}>
              <div className="d-flex flex-column pt-3">
                <div className="d-flex mb-3">
                  {props.data.categories.map((category, index) => (
                    <PostCategory key={index}>{category}</PostCategory>
                  ))}
                </div>
                <h2>
                  <b>{props.data.postTitle}</b>
                </h2>
                <p>{props.data.postText}</p>
                <hr />
                <a href={"/blog/publicacao/" + props.data.url}>
                  Leia Mais &gt;
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </PostHighlightContainer>
  );
}
