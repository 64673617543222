import { VehicleCategoryTitleAndSubtitleContainer } from "./styles";

export function VehicleCategoryTitleAndSubtitle(props) {
  const { ehiwebName } = props.data?.subCategory;
  return (
    <VehicleCategoryTitleAndSubtitleContainer>
      <h1>{`${ehiwebName} Aluguel`}</h1>
      <h4>
        {`Verificar disponibilidade de ${ehiwebName}`}
        <span>{props.data.span}</span>
      </h4>
    </VehicleCategoryTitleAndSubtitleContainer>
  );
}
