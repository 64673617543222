import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blogSlice } from "redux/blog/blogSlice";
import { BlogPageType } from "redux/blog/constants";
import { cmsSlice } from "redux/cms/cmsSlice";
import { PageTypes } from "redux/cms/constants";

import { LoaderComponent } from "components/Loader";
import { useLocation } from "react-router-dom";
import { fetchCategory, fetchSubcategory } from "redux/blog/blogService";
import { fetchPostById } from "redux/postList/postListService";
import PageContent from "./components/PageContent";
import PostSEO from "./components/PostSEO";
import { Container } from "./styles";

const PostPage = () => {
  const { setBlogPageType } = blogSlice.actions;
  const { setPageType } = cmsSlice.actions;

  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pageId = query.get("id");
  const pageLoader = useSelector((state) => state?.postList);
  useEffect(() => {
    dispatch(setBlogPageType(BlogPageType.POST_PAGE));
    dispatch(setPageType(PageTypes.BLOG));
    dispatch(fetchCategory());
    dispatch(fetchSubcategory());
  }, []);
  useEffect(() => {
    if (pageId) dispatch(fetchPostById(pageId));
  }, [pageId]);
  return (
    <Container>
      {pageLoader?.status === "loading" && <LoaderComponent />}
      <PostSEO />
      <PageContent />
    </Container>
  );
};

export default PostPage;
