import { Container, Row, Col } from "react-bootstrap";
import StaticMap from "@components/StaticMap";
import {
  LocationDetailIcons,
  LocationDetailBackground,
  LocationDetailImageText,
  LocationDetailDetailButton,
  LocationDetalMapButtonsRow,
} from "./styles";
import { LocationDetailAcordion } from "../Global/Dropdowns/LocationDetailAcordion/index";
import mapPinIcon from "@assets/images/ic-map-pin.svg";
import phoneIcon from "@assets/images/ic-phone.svg";
import carIcon from "@assets/images/ic-car.svg";

export function LocationDetail(props) {
  const mtel = (v) => {
    if (props.data2?.branchStationCountry === "BR") {
      v = v.replace(/\D/g, "");
      v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
      v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    }
    return v;
  };
  return (
    <LocationDetailBackground>
      <Container>
        <h3>{props.data2?.branchName}</h3>
        <a href={props.data.link.href}>{props.data.link.text}</a>
        <LocationDetalMapButtonsRow>
          <Col xs={12} lg={6}>
            <div>
              <StaticMap
                address={
                  props.data2?.branchName +
                  " " +
                  props.data2?.branchStationAddress1
                }
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <hr />
            <LocationDetailDetailButton>
              <Row>
                <LocationDetailImageText
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                    props.data2?.branchStationAddress1
                  )}+ALAMO%2BALUGUEL%2BDE%2BVEICULOS`}
                >
                  <Col xs={1} sm={1}>
                    <LocationDetailIcons
                      src={mapPinIcon}
                      alt={props.data.image.alt}
                    />
                  </Col>
                  <Col sm={10} xs={11}>
                    <p>{props.data2?.branchStationAddress1}</p>
                  </Col>
                </LocationDetailImageText>
              </Row>
            </LocationDetailDetailButton>
            <hr />
            <LocationDetailDetailButton>
              <Row>
                <LocationDetailImageText>
                  <Col xs={1}>
                    <LocationDetailIcons
                      src={phoneIcon}
                      alt={props.data.image.alt}
                    />
                  </Col>
                  <Col sm={10} xs={11}>
                    <a href={"tel" + props.data2?.branchStationPhone}>
                      {mtel(props.data2?.branchStationPhone)}
                    </a>
                  </Col>
                </LocationDetailImageText>
              </Row>
            </LocationDetailDetailButton>
            <hr />
            <LocationDetailAcordion data={props.data2} />
            <LocationDetailDetailButton>
              <Row>
                <LocationDetailImageText>
                  <Col xs={1}>
                    <LocationDetailIcons
                      src={carIcon}
                      alt={props.data.image.alt}
                    />
                  </Col>
                  <Col sm={10} xs={11}>
                    <p>{props.data.locationDetails.directions}</p>
                  </Col>
                </LocationDetailImageText>
              </Row>
            </LocationDetailDetailButton>
            <hr />
          </Col>
        </LocationDetalMapButtonsRow>
      </Container>
    </LocationDetailBackground>
  );
}
