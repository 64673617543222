import { Col } from "react-bootstrap";
import africaMap from "../../../assets/images/img-africa-middle-east-map.svg";
import asiaMap from "../../../assets/images/img-asia-pacific-map.svg";
import centralAmericaMap from "../../../assets/images/img-central-america-map.svg";
import europeMap from "../../../assets/images/img-europe-map.svg";
import northAmericaMap from "../../../assets/images/img-north-america-map.svg";
import southAmericaMap from "../../../assets/images/img-south-america-map.svg";
import Accordion from "../../Accordion";
import AccordionBody from "../../Accordion/AccordionBody";
import AccordionHeader from "../../Accordion/AccordionHeader";
import AccordionItem from "../../Accordion/AccordionItem";
import { LocationAllCardDropdown } from "../LocationAllCard/LocationAllCardDropdown";
import locationRegions from "./Resources/regions.json";
import { LocationAllCardsImage } from "./styles";

const regionType = {
	EUROPE:"europe",
	NORTH_AMERICA:"north-america",
	CENTRAL_AMERICA:"central-america",
	SOUTH_AMERICA:"south-america",
	AFRICA:"africa",
	ASIA:"asia"
}
export const LocationAllCard = () => {
	const getImage = (region) => {
		switch (region) {
			case regionType.EUROPE:
				return europeMap;
			case regionType.NORTH_AMERICA:
				return northAmericaMap;
			case regionType.CENTRAL_AMERICA:
				return centralAmericaMap;
			case regionType.SOUTH_AMERICA:
				return southAmericaMap;
			case regionType.AFRICA:
				return africaMap;
			case regionType.ASIA:
				return asiaMap;
			default:
				return europeMap;
		}
	}

	return (
		<>
			<Accordion>
				{locationRegions.map((region, index) => {
					return (
						<Col key={index} lg={6} xs={12}>
							<AccordionItem mode={index%2 === 0 ? "left" : "right"}>
								<div>
									<LocationAllCardsImage
										fluid
										src={getImage(region.type)}
										alt={`Ver agências de aluguel na ${region.region}`}
									/>
									<AccordionHeader
										name={`item-${index}`}
										title={region.region}
										subtitle={`${region.countries.length} países`}
									/>
									<AccordionBody name={`item-${index}`} size={window.innerWidth < 993 ? "100%" : "204%"}>
										<LocationAllCardDropdown region={region}/>
									</AccordionBody>
								</div>
							</AccordionItem>
						</Col>
					);
				})}
			</Accordion>
		</>
	);
};

export default LocationAllCard;
