import styled from "styled-components";

export const PostHighlightContainer = styled.div`
  font-family: "Poppins", sans-serif;
  padding-top: 50px;
  color: #213b73;
  img {
    width: 85%;
    margin-left: 4%;
  }
  button {
    background-color: #8c4c9e;
    border: none;
    color: white;
    width: 50%;
    height: 50px;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  hr {
    opacity: 1;
  }
  a {
    color: #8c4c9e !important;
    text-decoration: none;
  }
  .normal-text-color {
    color: #213b73 !important;
  }
  .keep-link-color{
    color
  }
  @media (max-width: 576px) {
    img {
      margin: 0;
    }
  }
`;

export const PostCategory = styled.div`
  max-height: fit-content;
  background-color: white;
  color: #a6adb4;
  font-weight: bold;
  border: 1.8px solid #a6adb4;
  font-size: 12px;
  border-radius: 20px;
  padding: 8px 12px;
  margin-right: 10px;
  @media (max-width: 992px) {
    font-size: 9px;
  }
`;
