import styled from "styled-components";
import { DropdownButton, Row, Col } from "react-bootstrap";

export const VehicleWidgetContainer = styled.div`
  font-family: "Poppins", sans-serif;
  background-color: #213b73;
  color: #fff;
  h1 {
    font-size: 42px;
    font-weight: bold;
  }
  hr {
    opacity: 1;
  }
  #responsive {
    display: none;
  }
  .viewAll {
    color: #feed44;
    text-decoration: none;
  }
  @media (max-width: 991px) {
    h1 {
      text-align: center;
      font-size: 17px;
      padding-bottom: 10px;
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    h4 {
      font-size: 14px;
      font-weight: bold;
      margin: 0px;
    }

    #responsive {
      display: block;
    }
  }
`;

export const VehicleWidgetButton = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const VehicleWidgetTextDropdownButton = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 991px) {
    justify-content: flex-start !important;
  }
`;

export const VehicleWidgetDropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

export const VehicleWidgetDropdownButton = styled(DropdownButton)`
  display: flex;
  justify-content: flex-start;
  button {
    color: #fef100 !important;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: bold;
    &:hover,
    &:focus,
    &:active,
    &::selection {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .dropdown-menu.show {
    overflow-y: auto;
    height: 250px;
    a {
      margin: 5px 0;
      color: #213b73;
      text-decoration: none;
      font-weight: bold !important;
    }
    a:hover,
    a:focus,
    a:active,
    a::selection {
      background-color: transparent !important;
    }
    hr {
      margin: 0;
    }
  }
`;

export const VehicleWidgetFilterCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const CarList = styled(Row)`
  h3 {
    color: #feed44;
  }
  span {
    font-size: 12px;
  }
  @media (max-width: 991px) {
    justify-content: center;
  }
`;
export const OtherVehiclesContainer = styled.div`
  background-color: #005ca9 !important;
`;
