import { LoaderComponent } from "components/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { fetchCategory } from "redux/blog/blogService";
import { BlogStatus } from "redux/blog/constants";
import { getPostByUrl } from "redux/blog/blogService";
import BlogPost from "./Posts";

const BlogEngine = () => {
  const dispatch = useDispatch();
  const blogLoader = useSelector((state) => state?.blog);
  let pageUrl = window.location.pathname;
  pageUrl = pageUrl.replace("/blog", "");

  useEffect(() => {
    dispatch(getPostByUrl(pageUrl));
    dispatch(fetchCategory(BlogStatus.ENABLED));
  }, []);

  return (
    <>
      {blogLoader.status === "loading" && <LoaderComponent />}
      {blogLoader.status === "failed" && <Redirect to="/page-not-found" />}
      <Route exact path={`/blog/${blogLoader.post.url}`}>
        <BlogPost data={{ post: blogLoader.post }} />
      </Route>
    </>
  );
};

export default BlogEngine;
