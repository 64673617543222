import styled from "styled-components";

export const RentalPoliciesListBackground = styled.div`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    font-family: 'Poppins', sans-serif;
    padding: 20px 0;
    p{
        padding-top: 20px;
    }
    a{
        color: #FEF100 !important;
        text-decoration: none;
    }
    .map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    }
    .map-responsive iframe{
    left:0;
    top:0;
    height:80%;
    width:80%;
    position:absolute;
    }
`;

export const RentalPoliciesListTitle = styled.div`
    h4{
        font-weight: bold;
        padding: 25px 0;
    }
`;



export const RentalPoliciesListList = styled.div`
    display: flex;
    flex-direction: column;
    width: 460px;
    @media (max-width: 500px){
        width: 100%;
    }
    button{
        color: ${props => props.color};
        width: 100%;
        height: 56px;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        border: none;
    }
    hr{
        margin: 0;
        color: ${props => props.color};
        height: 1px;
        opacity: 1;
        width: 460px;
        @media (max-width: 500px){
        width: 100%;
        }
    }
`;