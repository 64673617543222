import React from "react";
import { Route, Switch } from "react-router-dom";
import Canvas from "./pages/Canvas";
import SetupHeader from "./pages/SetupHeader";
import SetupFooter from "./pages/SetupFooter";
import PageList from "./pages/PageList";
import PostList from "./pages/PostList";
import CategoryList from "./pages/CategoryList";
import PostPage from "./pages/PostPage";
import FeaturedPage from "./pages/FeaturedPage";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/cms">
        <PageList />
      </Route>
      <Route exact path="/cms/canvas">
        <Canvas />
      </Route>
      <Route exact path="/cms/setup-header">
        <SetupHeader />
      </Route>
      <Route exact path="/cms/setup-footer">
        <SetupFooter />
      </Route>
      <Route exact path="/cms/page-list">
        <PageList />
      </Route>
      <Route exact path="/cms/post-list">
        <PostList />
      </Route>
      <Route exact path="/cms/category-list">
        <CategoryList />
      </Route>
      <Route exact path="/cms/post-page">
        <PostPage />
      </Route>
      <Route exact path="/cms/featured-page">
        <FeaturedPage />
      </Route>
      <Route exact path="*">
        <Redirect to="/page-not-found"/>
      </Route>
    </Switch>
  );
};

export default Routes;
