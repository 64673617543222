import React, { useState, useEffect, useContext } from "react";
import HeaderNav from "@cms-common/HeaderNav";
import Header from "@cms-common/Header";
import { useDispatch, useSelector } from "react-redux";
import { cmsSlice } from "redux/cms/cmsSlice";
import {
  SetupHeaderContainer,
  HeaderNavContainer,
  PencilContainer,
  HeaderNavCover,
  HeaderContainer,
  HeaderCover,
  SubPageContainer,
  SubPageDropZone,
  DraggableItem,
  DraggableItemWrap,
  DraggableSubItem,
  TrashIcon,
  MainContainer,
  SaveChangesbutton,
  Image,
  ImageLabel,
  ImageHeader,
  ImageContainer,
  PageHeader,
  PageSubHeader,
  HeaderComponentContainer,
  DroppableContainer,
} from "./styles";
import { ReactComponent as PencilIcon } from "@assets/images/cms/ic-pencil-white.svg";

import { Droppable, Draggable } from "react-beautiful-dnd";
import { DragNDropContext } from "@cms/components/DragNDrop";

import { fetchHeaders, updateHeader, createHeader } from "redux/cms/cmsService";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import { useHistory } from "react-router-dom";

const headerType = {
  HEADER: 1,
  HEADER_NAV: 2,
};

const SetupHeader = () => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.auth.userLogged);
  if (!loggedUser) {
    history.push("/backoffice/login");
  }
  const {
    uploadImage,
    setHeaderEditing,
    setHeaderType,
    removeHeaderItem,
    updateHeaderItem,
    setCancelPageCreation,
    setInputHomePageState,
  } = cmsSlice.actions;
  const dispatch = useDispatch();
  const { movedItem, setMovedItem } = useContext(DragNDropContext);
  const headerTypeState = useSelector((state) => state?.cms?.headersType);
  const headerPageState = useSelector((state) => state?.cms?.headerPage);
  const [headerData, setHeaderData] = useState([]);
  const [headerNavData, setHeaderNavData] = useState([]);
  const [headerNavHover, setHeaderNavHover] = useState(false);
  const [headerHover, setHeaderHover] = useState(false);
  const headerState = useSelector((state) => state.cms.status);

  useEffect(() => {
    dispatch(setCancelPageCreation());
    dispatch(fetchHeaders());
    dispatch(setHeaderEditing(true));
    return () => {
      dispatch(setHeaderEditing(false));

      dispatch(setHeaderType(headerType.HEADER));
    };
  }, []);
  useEffect(() => {
    let message;
    let type;
    switch (headerState) {
      case "updateHeader-failed":
        message = "Erro ao atualizar Header";
        type = "error";
        break;
      case "updateHeader-success":
        message = "Header atualizado com sucesso";
        type = "success";
        dispatch(fetchHeaders());
        break;
      case "createHeader-failed":
        message = "Erro ao criar Header";
        type = "error";
        break;
      case "createHeader-success":
        message = "Header criado com sucesso";
        type = "success";
        dispatch(fetchHeaders());
        break;

      default:
        return;
    }
    if (message) {
      dispatch(displaySnackbar({ message, type }));
    }
  }, [headerState]);
  useEffect(() => {
    if (movedItem?.source) {
      let items = [];
      if (headerTypeState === headerType.HEADER) {
        items = [...headerData];
        const [reorderedItem] = items.splice(movedItem.source.index, 1);
        items.splice(movedItem.destination.index, 0, reorderedItem);
      } else {
        items = [...headerNavData];
        if (movedItem.type === "item") {
          const [reorderedItem] = items.splice(movedItem.source.index, 1);
          items.splice(movedItem.destination.index, 0, reorderedItem);
        } else {
          const parentId = movedItem?.draggableId.split("-")[1];
          const index = items.indexOf(
            items.find((p) => p.id.toString() === parentId)
          );
          let subitem = [
            ...items.find((p) => p.id.toString() === parentId).subLinks,
          ];
          const [reorderedItem] = subitem.splice(movedItem.source.index, 1);
          subitem.splice(movedItem.destination.index, 0, reorderedItem);
          let newList = [];
          items.reduce((acc, current, i) => {
            if (i === index) {
              newList.push({ ...current, subLinks: subitem });
            } else {
              newList.push(current);
            }
            return acc;
          }, []);
          items = newList;
        }
      }
      setMovedItem({});
      dispatch(updateHeaderItem({ current: items, type: headerTypeState }));
    }
  }, [movedItem?.draggableId]);
  useEffect(() => {
    if (headerPageState?.topMenu) {
      setHeaderData([...headerPageState?.topMenu]);
    } else {
      setHeaderData([]);
    }
  }, [headerPageState?.topMenu]);

  useEffect(() => {
    if (headerPageState?.navMenu) {
      setHeaderNavData([...headerPageState?.navMenu]);
    } else {
      setHeaderNavData([]);
    }
  }, [headerPageState?.navMenu]);

  function previewFile() {
    const preview = document.querySelector("img");
    const file = document.querySelector("input[type=file]").files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {}, false);
    reader.onload = (e) => {
      dispatch(uploadImage(e.target.result));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleSaveHeader = () => {
    const newHeader = {
      ...headerPageState,
      topMenu: headerPageState?.topMenu,
      navMenu: headerPageState?.navMenu,
      image: headerPageState?.image,
    };
    if (headerPageState?.homePageURL) {
      if (headerPageState?.id) {
        //se header nao vier vazio, atualiza o header
        dispatch(updateHeader(newHeader));
      } else {
        //se header vier vazio, cria um novo header
        dispatch(createHeader(newHeader));
      }
    } else {
      dispatch(
        displaySnackbar({
          message: "Selecione uma página inicial",
          type: "error",
        })
      );
      dispatch(setInputHomePageState("error"));
    }
  };

  return (
    <SetupHeaderContainer>
      <PageHeader>Edição de Header</PageHeader>
      <PageSubHeader>Clique no menu que deseja editar.</PageSubHeader>
      <HeaderComponentContainer>
        <HeaderContainer
          theme={{ mode: headerHover || headerTypeState === headerType.HEADER }}
          onMouseOver={() => setHeaderHover(true)}
          onMouseOut={() => setHeaderHover(false)}
          onClick={() => dispatch(setHeaderType(headerType.HEADER))}
        >
          {(headerHover || headerTypeState === headerType.HEADER) && (
            <PencilContainer>
              <PencilIcon style={{ marginTop: "-10px" }} />
            </PencilContainer>
          )}
          {headerData && headerData.length > 0 ? (
            <>
              <Header data={headerData} />
              <HeaderCover
                theme={{
                  mode: headerHover || headerTypeState === headerType.HEADER,
                }}
              />
            </>
          ) : (
            <>Menu superior não criado</>
          )}
        </HeaderContainer>
      </HeaderComponentContainer>
      <HeaderComponentContainer>
        <HeaderNavContainer
          theme={{
            mode: headerNavHover || headerTypeState === headerType.HEADER_NAV,
          }}
          onMouseOver={() => setHeaderNavHover(true)}
          onMouseOut={() => setHeaderNavHover(false)}
          onClick={() => dispatch(setHeaderType(headerType.HEADER_NAV))}
        >
          {(headerNavHover || headerTypeState === headerType.HEADER_NAV) && (
            <PencilContainer>
              <PencilIcon style={{ marginTop: "-10px" }} />
            </PencilContainer>
          )}
          {headerNavData && headerNavData.length > 0 ? (
            <>
              <HeaderNav data={headerNavData} logo={headerPageState?.image} />
              <HeaderNavCover
                theme={{
                  mode:
                    headerNavHover || headerTypeState === headerType.HEADER_NAV,
                }}
              />
            </>
          ) : (
            <>Barra de navegação não criada</>
          )}
        </HeaderNavContainer>
      </HeaderComponentContainer>
      <MainContainer>
        <p>
          Arraste os itens individualmente para os colocar na ordem que
          preferir. Clique no lixo à direita do item para remover do menu, você
          poderá adicioná-los novamente mais tarde.
        </p>
        <SaveChangesbutton onClick={handleSaveHeader}>
          Salvar alterações
        </SaveChangesbutton>
      </MainContainer>
      {headerTypeState === headerType.HEADER && (
        <DroppableContainer>
          <Droppable droppableId="top-menu">
            {(provided) => (
              <ul
                className="top-menu"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {headerData?.map((e, index) => (
                  <Draggable
                    draggableId={`top-menu-${e.id}-${index}`}
                    index={index}
                    key={`top-menu-${e.id}-${index}`}
                  >
                    {(provided) => (
                      <li
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DraggableItemWrap>
                          <DraggableItem>
                            {e.name}
                            <TrashIcon
                              onClick={() =>
                                dispatch(
                                  removeHeaderItem({
                                    current: e,
                                    type: headerType.HEADER,
                                  })
                                )
                              }
                            />
                          </DraggableItem>
                        </DraggableItemWrap>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DroppableContainer>
      )}
      {headerTypeState === headerType.HEADER_NAV && (
        <DroppableContainer>
          <Droppable droppableId="nav-menu" type="item">
            {(provided) => (
              <div
                className="nav-menu"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {headerNavData?.map((e, index) => (
                  <Draggable
                    draggableId={`nav-menu-${e.id}-${index}`}
                    index={index}
                    key={`nav-menu-${e.id}-${index}`}
                  >
                    {(provided) => (
                      <div
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DraggableItemWrap>
                          <DraggableItem>
                            {e.name}
                            <TrashIcon
                              onClick={() =>
                                dispatch(
                                  removeHeaderItem({
                                    current: e,
                                    type: headerType.HEADER_NAV,
                                  })
                                )
                              }
                            />
                          </DraggableItem>
                        </DraggableItemWrap>
                        {e.subLinks && (
                          <SubPageContainer key={`subpage-${e.id}`}>
                            <Droppable
                              droppableId={`subpage-${e.id}`}
                              type="subitem"
                            >
                              {(providedSub) => (
                                <SubPageDropZone
                                  className={`subpage-${e.id}`}
                                  {...providedSub.droppableProps}
                                  ref={providedSub.innerRef}
                                >
                                  {e?.subLinks?.map((i, subIndex) => (
                                    <Draggable
                                      draggableId={`subpage-${e.id}-${i.id}-${subIndex}`}
                                      index={subIndex}
                                      key={`subpage-${e.id}-${i.id}-${subIndex}`}
                                    >
                                      {(providedSub) => (
                                        <div
                                          key={subIndex}
                                          ref={providedSub.innerRef}
                                          {...providedSub.draggableProps}
                                          {...providedSub.dragHandleProps}
                                        >
                                          <DraggableItemWrap>
                                            <DraggableSubItem>
                                              {i.name}
                                              <TrashIcon
                                                onClick={() =>
                                                  dispatch(
                                                    removeHeaderItem({
                                                      parent: e,
                                                      current: i,
                                                      type: headerType.HEADER_NAV,
                                                    })
                                                  )
                                                }
                                              />
                                            </DraggableSubItem>
                                          </DraggableItemWrap>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {providedSub.placeholder}
                                </SubPageDropZone>
                              )}
                            </Droppable>
                          </SubPageContainer>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <ImageContainer>
            <ImageHeader>Logotipo</ImageHeader>
            {headerPageState?.image && (
              <img src={headerPageState.image} alt="logo" />
            )}
            <ImageLabel htmlFor="upload-logo">Alterar imagem</ImageLabel>
            <Image
              type="file"
              id="upload-logo"
              name="logo"
              onChange={previewFile}
            />
          </ImageContainer>
        </DroppableContainer>
      )}
    </SetupHeaderContainer>
  );
};

export default SetupHeader;
