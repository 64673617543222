import styled from "styled-components";

export const SearchLocationBackground = styled.div`
    color: #fff;
    background-color: #213B73;
    padding: 2rem 4rem 0;
    height: 100vh;
    overflow: hidden;
`;

export const SearchLocationHeader = styled.div`
    padding: 0 2%;

    .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        h2 {
            font-family: 'Poppins', sans-serif;
            font-size: 26px;
            font-weight: 700;
        }
    }
    
    .location-count {
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
    }
`;

export const SearchLocationCardList = styled.div`
    overflow-y: scroll;
    height: 100%;
    padding: 2%;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }
`;

export const SearchLocationButtonFilter = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #FEF100;


    img{
        height: 16px;
    }

    p{
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
        font-weight: 600;

        margin-right: 10px;
        margin-bottom: 0px;
    }
`;