import styled from 'styled-components';
import { Alert } from 'react-bootstrap';

import { ReactComponent as CityImage } from "@assets/images/city.svg";
import { ReactComponent as BoatImage } from "@assets/images/boat.svg";
import { ReactComponent as PlaneImage } from "@assets/images/plane.svg";

export const MarkerContainer = styled.div`
    background-color: #000;
`;

export const CustomMarkerTarget = styled.div`
    background-color: #005DAA;
    border-radius: 7px;
    color: #fff;
    height: 53px;
    width: 53px;
    position: relative;

    border-color: #213B73;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0;
    border-left-width: 1px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    .markText {
        font-size: 20px;
        font-weight: bold;
    }

    .markIcon {
        display: none;
    }

    &:hover {
        display: block;
        height: 60px;
        width: 60px;


        &:after {
            left: 20%;
        }

        .markText {
            display: none;
        }

        .markIcon {
            display: flex;
            flex-direction: column;

            h6 {
                padding-top: 5px;
                padding-left: 5px;
            }

            .icone {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 33px;
        left: 28%;
        width: calc(100% - 25px);
        height: calc(100% - 25px);
        background-color: #005DAA;
        transform: rotate(45deg);
        border: 1px solid #213B73;
        z-index: -1;
    }

`;

export const AlertWrapper = styled.div`
    position: absolute;
    padding: 1%;
    z-index: 2;
    width: 50%;
`;

export const AlertMap = styled(Alert)`
    width: 100%;
    position: relative;

    .alert-heading {
        font-size: 17px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        color: #213b73;
        display: flex;

        img {
            margin-right: 10px;
            width: 25px;
            height: 25px;
        }
    }

    .btn-close {
        background-image: url('/ic-close.svg');
        opacity: 1;
    }
`;

export const CityIcon = styled(CityImage)`
    fill: #fef100;
    width: 30px;
    border-radius: 5px;
    margin-top: -13px;
`;

export const BoatIcon = styled(BoatImage)`
    fill: #fef100;
`;

export const PlaneIcon = styled(PlaneImage)`
    fill: #fef100;
    width: 30px;
    border-radius: 5px;
    margin-top: -10px;
`;