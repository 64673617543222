import AgencyFilter from "@components/AgencyFilter";
import { BreadCrumb } from "@components/BreadCrumb";
import { ImageHeader } from "@components/ImageHeader";
import { LoaderComponent } from "@components/Loader";
import { PbkWidget } from "@components/Widget";
import { PbkWidgetExpanded } from "@components/WidgetExpanded";
import { fetchLocations } from "@redux/location/locationService";
import { getRegions } from "@redux/location/locationSlice";
import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function LocationCountryPage(props) {
  const dispatch = useDispatch();
  const { countryId } = useParams();
  const locationState = useSelector((state) => state.location);
  const { setTitleText } = React.useContext(TitleContext);
  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(getRegions());
  }, []);

  const locationCountry = useSelector((state) => state.location?.countries);

  useEffect(() => {
    const country = locationCountry.find((p) => p.code === countryId);
    if (country) setTitleText(`Agências de aluguel - ${country.name}`);
  }, [locationCountry]);

  const components = 
  <>
      {locationState.status === "loading" && <LoaderComponent />}
      <BreadCrumb
        data={props.data.breadCrumbData}
        countryName={
          locationCountry?.find((country) => country.code === countryId)?.name
        }
      />
      <ImageHeader
        data={props.data.imageHeaderData}
        countryName={
          locationCountry?.find((country) => country.code === countryId)?.name
        }
      />
      <AgencyFilter data={props.data.agencyFilterData} />
      <PbkWidget id="pbk-widget" />
      <PbkWidgetExpanded id="pbk-widget-expanded" />
    </>

  return (
    <StaticPageEngine components={components} />
  );
}

export default LocationCountryPage;
