import styled from "styled-components";
import theme from "styled-theming";
import { palettes } from "assets/theme";
import { Col } from "react-bootstrap";

const height = theme("mode", {
  template: "71px",
  default: "270px",
});
const padding = theme("mode", {
  template: "17px",
  default: "40px 5rem",
});
const title = theme("mode", {
  template: "9px",
  default: "26px",
});
const subhead = theme("mode", {
  template: "9px",
  default: "22px",
});
const subtitle = theme("mode", {
  template: "8px",
  default: "17px",
});
const subtitleLineHeight = theme("mode", {
  template: "10px",
  default: "24px",
});
const titleLineHeight = theme("mode", {
  template: "11px",
  default: "36px",
});
const text = theme("mode", {
  template: "7px",
  default: "14px",
});
const textLineHeight = theme("mode", {
  template: "6px",
  default: "21px",
});
const imageHeight = theme("mode", {
  template: "20px",
  default: "auto",
});
const imageWidth = theme("mode", {
  template: "20px",
  default: "220px",
});
const imageMaxSize = theme("mode", {
  template: "30px",
  default: "250px",
});
const sectionIconHeight = theme("mode", {
  template: "25px",
  default: "90px",
});
const iconHeight = theme("mode", {
  template: "8px",
  default: "15px",
});
const featuredImageHeight = theme("mode", {
  template: "45px",
  default: "200px",
});
const featuredImageWidth = theme("mode", {
  template: "150px",
  default: "400px",
});
const height100 = theme("mode", {
  template: "25px",
  default: "100%",
});
const width100 = theme("mode", {
  template: "25px",
  default: "100%",
});
const bgColor = theme("variant", {
  dark: `${palettes.tints_blue_darker}`,
  blue: `${palettes.tints_blue}`,
  light: `${palettes.white}`,
});
const color = theme("variant", {
  dark: `${palettes.white}`,
  blue: `${palettes.white}`,
  light: `${palettes.tints_blue_darker}`,
});
const linkColor = theme("variant", {
  dark: `${palettes.tints_yellow}`,
  blue: `${palettes.tints_purple}`,
  light: `${palettes.tints_purple}`,
});
const buttonWidth = theme("mode", {
  template: "40px",
  default: "177px",
});
const buttonHeight = theme("mode", {
  template: "15px",
  default: "53px",
});
const buttonText = theme("mode", {
  template: "7px",
  default: "17px",
});
const listItemHeight = theme("mode", {
  template: "30px",
  default: "50px",
});
const textWrapperSpacing = theme("mode", {
  template: "5px",
  default: "30px",
});
const buttonMargin = theme("mode", {
  template: "0 2px",
  default: "0 15px",
});
const linkHeigth = theme("mode", {
  template: "6px",
  default: "17px",
});
const cardText = theme("mode", {
  template: "5px",
  default: "14px",
});
const cardHeight = theme("mode", {
  template: "41px",
  default: "270px",
});
const cardPadding = theme("mode", {
  template: "2px",
  default: "16px",
});
const LargebuttonWidth = theme("mode", {
  template: "60px",
  default: "260px",
});
const paddingListItem = theme("mode", {
  template: "4px 8px",
  default: "5px 20px",
});

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${height};
  padding: ${padding};
  background-color: ${bgColor};
  font-family: "Poppins", sans-serif;
  color: ${color};

  @media (max-width: 767px) {
    padding: 40px 12px;
  }

  .maxSectionWidth {
    max-width: 1280px;
  }

  .maxSubSectionWidth {
    max-width: 500px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .maxSubSection3Width {
    max-width: 426px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .flex-column-small {
    flex-flow: row;
    @media (max-width: 767px) {
      flex-flow: column;
    }
  }

  .margin-top-small {
    margin-top: 0px;
    @media (max-width: 767px) {
      margin-top: 12px;
    }
  }

  .margin-top-medium {
    margin-top: 0px;
    @media (max-width: 992px) {
      margin-top: 16px;
    }
  }

  .largeButtonSection4 {
    @media (max-width: 992px) {
      width: 100%;
      margin-top: 12px;
    }
  }

  .hideOnSmall {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .breakWhenSmall {
    @media (max-width: 767px) {
      flex-flow: column;
    }
  }

  .breakWhenMedium {
    @media (max-width: 991px) {
      flex-flow: column;
    }
  }

  .widthOnSmall {
    width: 590px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .subSectionPadding {
    padding: 0;
    @media (max-width: 767px) {
      padding: 0 0;
    }
  }

  .resposive1 {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  }
  .resposive2 {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .button-responsive {
    width: 40%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .subSection5Padding {
    padding: 30px 15px 0 15px;
    @media (max-width: 767px) {
      align-items: center;
      padding: 0 0;
    }
  }

  .active-list-item {
    @media (max-width: 767px) {
      border-bottom: 1px solid ${color};
    }
  }

  .section4DivsContainer {
    display: flex;
    flex-flow: row;
    @media (max-width: 767px) {
      flex-flow: column;
    }
  }

  .section4TextContainer {
    margin-left: 12px;
    @media (max-width: 767px) {
      margin-left: 0px;
    }
  }

  .section4ButtonContainer {
    margin-left: 12px;

    @media (max-width: 767px) {
      margin-left: 0px;
    }
    @media (min-width: 768px) {
      min-width: 177px;
      max-width: 177px;
    }
  }
`;

export const Title = styled.h2`
  font-size: ${title};
  font-weight: 700;
  margin: 0;
  line-height: ${titleLineHeight};
`;

export const SubHead = styled.h3`
  font-size: ${subhead};
  margin: 0;
  line-height: 31px;
`;

export const Subtitle = styled.h4`
  font-size: ${subtitle};
  margin: 0;
  line-height: ${subtitleLineHeight};
`;

export const Text = styled.p`
  font-size: ${text};
  margin: 0;
  line-height: ${textLineHeight};
`;

export const Image = styled.img`
  width: ${imageWidth};
  height: ${imageHeight};
`;

export const SectionIcon = styled.div`
  height: ${sectionIconHeight};
  width: ${sectionIconHeight};
  min-width: ${sectionIconHeight};
  margin-right: 5px;
  display: flex;
  flex-flow: row;
  align-items: start;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Icon = styled.img`
  height: ${iconHeight};
  margin-left: 4px;
`;

export const Link = styled.a`
  font-size: ${text};
  text-decoration: none;
  color: ${linkColor};
  &:hover {
    color: ${linkColor};
  }
`;

export const Hr = styled.hr`
  color: ${color};
  opacity: 1;
  margin: 4px 0;
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${buttonText};
  font-weight: 700;
  color: ${bgColor};
  background-color: ${linkColor};
  border: none;
  width: ${buttonWidth};
  height: ${buttonHeight};
  text-decoration: none;
  text-align: center;
  &:hover {
    color: ${bgColor};
  }
`;

export const LargeButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${title};
  font-weight: 700;
  color: ${bgColor};
  background-color: ${linkColor};
  border: none;
  width: ${buttonWidth};
  height: ${buttonHeight};
  text-decoration: none;
  text-align: center;
  &:hover {
    color: ${bgColor};
  }
  margin: ${buttonMargin};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonAsDiv = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  background-color: ${linkColor};
  padding: 2px 16px;
  border: none;
  width: ${buttonWidth};
  height: ${buttonHeight};
  &:hover {
    transform: scale(1.025);
  }
  a {
    color: ${bgColor};
    font-size: ${subtitle};
    font-weight: 700;
    text-decoration: none;
    text-align: center;
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
  }
`;

export const ListItem = styled.div`
  height: ${listItemHeight};
  display: flex;
  cursor: pointer;
  padding: ${paddingListItem};
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.tileColor};
  border-top: 1px solid ${color};
`;

export const TextArea = styled(Col)`
  padding: 0 10px;
  font-size: ${text};
`;

export const Card = styled.div`
  overflow: auto;
  height: ${cardHeight};
  display: flex;
  flex-direction: column;
  background-color: ${bgColor};
  font-size: ${cardText} !important;
  color: ${color};
  padding: ${cardPadding};
  a {
    color: ${linkColor};
  }
  @media (max-width: 992px) {
    margin-bottom: 15px;
  }
`;

export const FeaturedImage = styled.img`
  max-height: ${featuredImageHeight};
  max-width: ${featuredImageWidth};
  width: 100%;
`;

export const Pbk = styled.div`
  #enterprise-pbk {
    font-family: "Poppins", "sans-serif" !important;
    background-color: ${palettes.tints_blue_darker} !important;
    a {
      color: ${palettes.white} !important;
      text-decoration: none !important;
    }
    .button-special {
      background: #fef100 !important;
      color: ${palettes.tints_blue_darker} !important;
    }
    .label {
      color: ${palettes.tints_blue_darker} !important;
    }
    .widget-search-form-group:first-child .label {
      color: ${palettes.white} !important;
    }
  }
`;

export const MarginWrapper = styled.div`
  padding: 0 0 0 ${textWrapperSpacing};
  @media (max-width: 768px) {
    padding: ${textWrapperSpacing} 0 0 0;
  }
`;
export const CardLink = styled.h4`
  font-size: ${linkHeigth};
  margin-bottom: 8px;
  color: ${linkColor};
`;

export const ImageFluid = styled.img`
  max-height: 250px;
  max-width: 250px;
  height: ${height100};
  width: ${width100};
  @media (max-width: 768px) {
    object-fit: fill;
    max-height: 100vw;
    padding: 12px;
  }
`;

export const ImageFluidWrapper = styled.div`
  @media (max-width: 992px) {
    display: flex;
    justify-content: start;
  }
  @media (max-width: 768px) {
    object-fit: contain;
    justify-content: center;
  }
`;

export const ImageSection4 = styled.img`
  object-fit: scale-down;
  max-width: ${imageMaxSize};
  max-height: ${imageMaxSize};
`;

export const ImageSection5 = styled.img`
  object-fit: scale-down;
  max-width: ${imageMaxSize};
  max-height: ${imageMaxSize};
`;

export const ImageSection4Wrapper = styled.div`
  margin-right: 5px;
  @media (max-width: 768px) {
    max-width: ${imageMaxSize};
    max-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    margin-right: 0px;
  }
`;

export const ImageSection3Wrapper = styled.div`
  padding: 0 12px;
  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const ImageSection5Wrapper = styled.div`
  @media (max-width: 768px) {
    max-width: ${imageMaxSize};
    max-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
`;
