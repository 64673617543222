import React from "react";
import globeAmerica from "../../../../assets/images/img-globe-america.svg";
import { Col, Row, Container } from "react-bootstrap";
import {
  StyledLocationAllCardDropdown,
  LocationAllCardDropdownText,
  LocationAllCardDropdownContent,
  LocationAllCardCol
} from "./styles";

export function LocationAllCardDropdown({ region }) {
  return (
    <StyledLocationAllCardDropdown>
      <Container>
        <Row>
          <Col id="desktop" lg={4} xs={12}>
            <img src={globeAmerica} alt="Agências" height="120px" />
            <LocationAllCardDropdownText id="agencies-number">
              {region.agencies}
            </LocationAllCardDropdownText>
            <LocationAllCardDropdownText id="agencies-text">
              Agências próximas desta região!
            </LocationAllCardDropdownText>
          </Col>
          <LocationAllCardCol lg={8}>
              <LocationAllCardDropdownText>
                Países Atendidos
              </LocationAllCardDropdownText>
              <hr id="desktop" />
              <LocationAllCardDropdownContent>
                {region.countries.map((country, index) => {
                  return (
                    <a
                      href={`/agencias/pais/${country.code}`}
                      alt={country.name}
                      key={index}
                    >
                      <LocationAllCardDropdownText id="link">
                        {`${country.name} >`}
                      </LocationAllCardDropdownText>
                    </a>
                  );
                })}
              </LocationAllCardDropdownContent>
          </LocationAllCardCol>
          <Col id="mobile" lg={4} xs={12}>
            <img src={globeAmerica} alt="Agências" height="120px" />
            <LocationAllCardDropdownText id="agencies-number">
              {region.agencies}
            </LocationAllCardDropdownText>
            <LocationAllCardDropdownText id="agencies-text">
              Agências próximas desta região!
            </LocationAllCardDropdownText>
          </Col>
        </Row>
      </Container>
    </StyledLocationAllCardDropdown>
  );
}
