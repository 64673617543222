import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchUserLogged } from "./authService";

const authAdapter = createEntityAdapter();

const initialState = authAdapter.getInitialState({
  status: "",
  userLogged: null,
  error: null
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogout: (state) => {
      state.userLogged = null;
      state.status = "";
      localStorage.removeItem("token");
      sessionStorage.removeItem("persist:root");
    }
  },
  extraReducers: {
    [fetchUserLogged.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUserLogged.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.userLogged = payload;
      localStorage.setItem("token", payload.token);
    },
    [fetchUserLogged.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    }
  }
});

export const { setLogout } = authSlice.actions;

export default authSlice.reducer;
