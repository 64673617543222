import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const snackbarAdapter = createEntityAdapter();

const initialState = snackbarAdapter.getInitialState({
  type: "default",
  textMessage: null
});

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    displaySnackbar: (state, { payload }) => {
      state.textMessage = payload?.message || null;
      state.type = payload?.type || initialState.type;
    }
  }
});
export const { displaySnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
