import { ReactComponent as PlusIcon } from "@assets/images/cms/ic-plus.svg";
import Footer from "@cms-common/Footer";
import { DragNDropContext } from "@cms/components/DragNDrop";
import { useContext, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createFooter } from "redux/cms/cmsService";
import { fetchFooter, uptadeFooter } from "redux/cms/cmsService";
import { removeFooterItem } from "redux/cms/cmsSlice";
import {
  addSection,
  removeSection,
  renameSection,
  setCancelPageCreation,
  setFooterEditing,
  updateFooterItem,
} from "redux/cms/cmsSlice";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import {
  AddSectionButton,
  Container,
  DraggableItem,
  DraggableItemWrap,
  DraggableSubItem,
  FooterComponentContainer,
  MainContainer,
  PageFooter,
  PencilIcon,
  SaveChangesbutton,
  SaveIcon,
  SubPageContainer,
  SubPageDropZone,
  TrashIcon,
} from "./styles";

const Section = ({ section, index }) => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.auth.userLogged);
  if (!loggedUser) {
    history.push("/backoffice/login");
  }
  const dispatch = useDispatch();
  const [isEditSection, setIsEditSection] = useState(false);
  const [sectionName, setSectionName] = useState(section.name);

  return (
    <Draggable
      draggableId={`nav-menu-${section.name}-${index}`}
      index={index}
      key={`nav-menu-${section.name}-${index}`}
    >
      {(provided) => (
        <div
          key={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DraggableItemWrap>
            <DraggableItem>
              {isEditSection ? (
                <input
                  onChange={(val) => setSectionName(val.target.value)}
                  value={sectionName}
                />
              ) : (
                <>{section.name}</>
              )}
              <div>
                {isEditSection ? (
                  <SaveIcon
                    onClick={() => {
                      dispatch(
                        renameSection({
                          current: section,
                          sectionName,
                        })
                      );
                      setIsEditSection(false);
                    }}
                  />
                ) : (
                  <PencilIcon onClick={() => setIsEditSection(true)} />
                )}
                <TrashIcon
                  onClick={() =>
                    dispatch(
                      removeSection({
                        current: section,
                      })
                    )
                  }
                />
              </div>
            </DraggableItem>
          </DraggableItemWrap>
          {section.link && (
            <SubPageContainer key={`subpage-${section.name}`}>
              <Droppable droppableId={`subpage-${section.name}`} type="subitem">
                {(providedSub) => (
                  <SubPageDropZone
                    className={`subpage-${section.name}`}
                    {...providedSub.droppableProps}
                    ref={providedSub.innerRef}
                  >
                    {section?.link?.map((i, subIndex) => (
                      <Draggable
                        draggableId={`subpage-${section.name}-${i.id}-${subIndex}`}
                        index={subIndex}
                        key={`subpage-${section.name}-${i.id}-${subIndex}`}
                      >
                        {(providedSub) => (
                          <div
                            key={subIndex}
                            ref={providedSub.innerRef}
                            {...providedSub.draggableProps}
                            {...providedSub.dragHandleProps}
                          >
                            <DraggableItemWrap>
                              <DraggableSubItem>
                                {i.name}
                                <TrashIcon
                                  onClick={() =>
                                    dispatch(
                                      removeFooterItem({
                                        parent: section,
                                        current: i,
                                      })
                                    )
                                  }
                                />
                              </DraggableSubItem>
                            </DraggableItemWrap>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedSub.placeholder}
                  </SubPageDropZone>
                )}
              </Droppable>
            </SubPageContainer>
          )}
        </div>
      )}
    </Draggable>
  );
};

const SetupFooter = () => {
  const dispatch = useDispatch();
  const { movedItem, setMovedItem } = useContext(DragNDropContext);
  const footerPageState = useSelector((state) => state?.cms?.footerPage);
  const [footerData, setFooterData] = useState([]);

  const footerState = useSelector((state) => state.cms.status);
  useEffect(() => {
    let message;
    let type;
    switch (footerState) {
      case "uptadeFooter-failed":
        message = "Erro ao atualizar Footer";
        type = "error";

        break;
      case "uptadeFooter-success":
        message = "Footer atualizado com sucesso";
        type = "success";
        dispatch(fetchFooter());
        break;
      case "createFooter-failed":
        message = "Erro ao criar Footer";
        type = "error";
        break;
      case "createFooter-success":
        message = "Footer criado com sucesso";
        type = "success";
        dispatch(fetchFooter());
        break;

      default:
        return;
    }
    if (message) {
      dispatch(displaySnackbar({ message, type }));
    }
  }, [footerState]);

  useEffect(() => {
    dispatch(setCancelPageCreation());
    dispatch(fetchFooter());
    dispatch(setFooterEditing(true));
    return () => {
      dispatch(setFooterEditing(false));
    };
  }, []);

  useEffect(() => {
    if (movedItem?.source) {
      let items = [];

      items = [...footerData];
      if (movedItem.type === "item") {
        const [reorderedItem] = items.splice(movedItem.source.index, 1);
        items.splice(movedItem.destination.index, 0, reorderedItem);
      } else {
        const parentId = movedItem?.draggableId.split("-")[1];
        const index = items.indexOf(items.find((p) => p.name === parentId));

        const source = movedItem.source.droppableId.split("-")[1];
        const source_index = movedItem.source.index;
        const destination = movedItem.destination.droppableId.split("-")[1];
        const destination_index = movedItem.destination.index;

        if (source === destination) {
          const currentArray = items[index].link;
          const newArray = [];
          for (let i = 0; i < currentArray.length; i++) {
            if (destination_index < source_index) {
              if (i === destination_index) {
                newArray.push(currentArray[source_index]);
              }
              if (i !== source_index) {
                newArray.push(currentArray[i]);
              }
            } else {
              if (i !== source_index) {
                newArray.push(currentArray[i]);
              }
              if (i === destination_index) {
                newArray.push(currentArray[source_index]);
              }
            }
          }
          items[index] = { ...items[index], link: newArray };
        } else {
          const parentDestinationIndex = items.indexOf(
            items.find((p) => p.name === destination)
          );
          console.log("items", items);
          console.log("source_index", source_index);
          console.log("destination_index", destination_index);
          console.log("parentDestination", items[parentDestinationIndex]);

          const tempArray = [...items[index].link];
          const [currentLink] = tempArray.splice(source_index, 1);
          items[index] = { ...items[index], link: tempArray };

          const currentArray = items[parentDestinationIndex].link;
          const newArray = [];
          for (let i = 0; i < currentArray.length; i++) {
            if (i === destination_index) {
              newArray.push(currentLink);
            }
            newArray.push(currentArray[i]);
          }
          if (destination_index + 1 > currentArray.length) {
            newArray.push(currentLink);
          }
          items[parentDestinationIndex] = {
            ...items[parentDestinationIndex],
            link: newArray,
          };
        }

        // console.log("movedItem", movedItem);

        // console.log("index", index);

        // console.log(items[index]);
        // let subitem = [...items[index].link];
        // const [reorderedItem] = subitem.splice(movedItem.source.index, 1);
        // subitem.splice(movedItem.destination.index, 0, reorderedItem);
        // let newList = [];
        // items.reduce((acc, current, i) => {
        //   if (i === index) {
        //     newList.push({ ...current, items: subitem });
        //   } else {
        //     newList.push(current);
        //   }
        //   return acc;
        // }, []);
        // items = newList;
      }
      setMovedItem({});
      dispatch(updateFooterItem(items));
    }
  }, [movedItem?.draggableId]);

  useEffect(() => {
    if (footerPageState?.section) {
      setFooterData([...footerPageState?.section]);
    } else {
      setFooterData([]);
    }
  }, [footerPageState?.section]);

  const handleSaveFooter = () => {
    if (footerPageState && footerPageState.id != undefined) {
      dispatch(
        uptadeFooter({ id: footerPageState?.id, section: [...footerData] })
      );
    } else {
      dispatch(createFooter({ id: undefined, section: [...footerData] }));
    }
  };

  return (
    <Container>
      <PageFooter>Edição de Footer</PageFooter>
      <FooterComponentContainer>
        <Footer data={footerData} />
      </FooterComponentContainer>

      <MainContainer>
        <p>
          Para organizar as páginas no footer do site arraste as seções ou itens
          para os colocá-los na ordem que preferir.
        </p>
        <SaveChangesbutton onClick={() => handleSaveFooter()}>
          Salvar alterações
        </SaveChangesbutton>
      </MainContainer>
      <AddSectionButton onClick={() => dispatch(addSection())}>
        <PlusIcon fill="#fff" style={{ marginRight: 8 }} />
        Adicionar nova seção
      </AddSectionButton>
      <Droppable droppableId="nav-menu" type="item">
        {(provided) => (
          <div
            className="nav-menu"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {footerData?.map((e, index) => (
              <Section section={e} index={index} key={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Container>
  );
};

export default SetupFooter;
