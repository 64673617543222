import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React from "react";
import { BreadCrumb } from "../../components/BreadCrumb";
import { ImageHeader } from "../../components/ImageHeader";
import { LocationAll } from "../../components/LocationAll";
import { PbkWidget } from "../../components/Widget";

export function LocationAllPage(props) {

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - Todas as Agências");
    }, []);

    const components =
        <>
            <BreadCrumb data={props.data.breadCrumbData} />
            <ImageHeader data={props.data.imageHeaderData} styles={{ fontSize: '30px' }} />
            <LocationAll />
            <PbkWidget id="pbk-widget" />
        </>

    return (
        <>
            <StaticPageEngine components={components} />
        </>
    )
}

export default LocationAllPage;