import { TitleAndCTAButtonBlockBackground } from './styles';
import { StyledButton } from '../Button/styles';
import { Title2 } from '../Global/Texts/styles'

export function TitleAndCTAButtonBlock(props) {
    return(
        <TitleAndCTAButtonBlockBackground>
            <Title2>{props.data.title}</Title2>
            <StyledButton variant="link" href="/reservas" color={props.data.button.color} colortext={props.data.button.colorText}>{props.data.button.text}</StyledButton>
        </TitleAndCTAButtonBlockBackground>
    );
}