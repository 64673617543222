export const PageTypes = {
  WEB_PAGE: "web-page",
  BLOG: "blog"
};

export const PageStatus = {
  DRAFT: "draft",
  TRASH: "trash",
  PUBLISHED: "published"
};

export const templateImageUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANsSURBVHgB3ZpNWhNBEIa/6hkgoItwAqIHUPQC4kZkZQRcCx5A1ANA4ALgBfjZKo+EjYgLgROAJyA3IC40QTJdVvcQTMj/ZEhmePPkSWd6kvSXqq7uqRpCSBT20ilATRDRuDzHGDTu93AKhDyY8iDOESOvNX5Kx2GiWDqhl9k8QoDQBbyTTv696y6wpjk74GADyBJUdnDy0xa6IJCQwt7sBDlYAmMCoUE5UrypS6Wt4alsDh3SkRDjPuS4qyIgjRuDThVouVMLtS2k+P3VgrhPRkQk0QsIB+yV3rRrnbaEFPdnV+XlHXqOWIcwPfjs80nLM5t1msl8fsfdCXcudAwrqPlWrtZQiI1II+4BA+PoPy3FqEYdYomNiIgwkIbeOP860zDI1BVyvj+7dLORKRDEDq37C28tNUIKe9NzYokMosmoUu4X4/bXO6qE2HVCOUuIMPInPyqOuDURtEoIKXch6Fajl0iEenvdxa6EXHb0Ya0IxKhynMXKA/8topwMYgQzzVVaxQo5O0gnZev9AvFCNs3uXPmNFZIoqnTP9lAhYuZKuW2FKKXiZo0ySXNJYRpWCFNf91LdIDOCrRGUVRRDt7pC0UP7AugU4gz7+0ERolKIN0kThs0cSSHmOM5AUhFhDDHH8zipcEswFgklQdZvlGT9fuEWoJg49hYxqVdFzC1TLREnb/LHytFOvIUQ7PjV75GLHOI84TWbzD7U6FNJ62vE1SosF1i7pmHXEQ3sIpZQbnhq+9C0rJDzodImx9C9iPVhuW2FGPdizV0VWvqADNlbKb+52qIQe2uIEbJsbFaWHK6EmIMicRPxoMoahqpNYzHhvZdQfIaow/zxegGoSogNxSWsINLQ6VDBW645Wu/UP99mVmVbHMWsI7Mu3a9XjqtfVhiyio8RLVgeHxrVFOsK8cNxadqYERFBsvAriefbDSNr0xqiX2YY+CFfcw99REQsD09uZ5qd0/RS15ixMHjxWJo76AcSQVnTfCsR/qltIom8DCksdvKZbpAfOdbi3qHW2ctYVyN3HX6K9WYEyZ6PPcg60doK1R8LgKkzSolu8bK6FY6gSwFy2boW5I6hrgbhZ8L5NSn1JLAoxiEzjoIKKBOae1ym9ydkHj2AyV6SPP3keMVvUE5GnofHR5L0OEkUdTas+7VuDf8AGA1L4OpZGuoAAAAASUVORK5CYII=";