import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  deletePost,
  fetchGetPosts,
  createPost,
  updatePost,
  updateStatusPost,
  updateStatusPosts,
  fetchPostById,
  getLastFivePosts,
} from "../postList/postListService";
import postListData from "./postListData.json";

const postListAdapter = createEntityAdapter();

const initialState = postListAdapter.getInitialState({
  status: "loading",
  postListItem: [],
  postListFiltered: [],
  postHighlight: {},
  selectedCategory: undefined,
  selectedSubcategory: undefined,
  error: null,
  errorCode: null,
});

export const postListSlice = createSlice({
  name: "postList",
  initialState,
  reducers: {
    setSubcategory: (state, { payload }) => {
      if (payload) {
        const filteredList = [...state.postListItem]?.filter((p) =>
          p.subcategory?.some((i) => i === payload)
        );
        state.postHighlight = filteredList?.[0];
        state.postListFiltered = filteredList?.filter(
          (post) => post.id !== state.postHighlight.id
        );
      } else {
        state.postHighlight = state.postListItem[0];
        state.postListFiltered = state.postListItem?.filter(
          (post) => post.id !== state.postHighlight.id
        );
      }
      state.selectedSubcategory = payload;
    },
    setCategory: (state, { payload }) => {
      if (payload) {
        const filteredList = [...state.postListItem]?.filter((p) =>
          p.category.some((i) => i === payload)
        );
        state.postHighlight = filteredList?.[0];
        state.postListFiltered = filteredList?.filter(
          (post) => post.id !== state.postHighlight.id
        );
      } else {
        state.postHighlight = state.postListItem[0];
        state.postListFiltered = state.postListItem?.filter(
          (post) => post.id !== state.postHighlight.id
        );
      }
      state.selectedCategory = payload;
    },
    setPostListItem: (state) => {
      state.postListItem = postListData;
      state.postListFiltered = postListData;
    },
    filterPostListItem: (state, { payload }) => {
      if (payload) {
        state.postListFiltered = state.postListItem.filter((post) =>
          post.title.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.postListFiltered = state.postListItem;
      }
    },
    filterByCategory: (state, { payload }) => {
      const { current, category } = payload;
      if (category) {
        state.postListFiltered = state.postListItem?.filter(
          (post) =>
            post.category?.some((p) => p === category) && post.id !== current
        );
      } else {
        state.postListFiltered = state.postListItem;
      }
    },
    setPostHighlight: (state) => {
      state.postHighlight = state.postListItem[0];
      state.postListFiltered = state.postListItem?.filter(
        (post) => post.id !== state.postHighlight.id
      );
    },
    clearPostData: (state) => {
      state.status = undefined;
      state.postListItem = [];
      state.error = null;
      state.errorCode = null;
    },
  },
  extraReducers: {
    [fetchGetPosts.pending]: (state) => {
      state.status = "loading";
      state.postListItem = [];
      state.postListFiltered = [];
    },
    [fetchGetPosts.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.postListItem = payload.content?.sort(
        (a, b) =>
          new Date(
            b?.auditingInfo?.updatedAt || b?.auditingInfo?.createdAt
          ).getTime() -
          new Date(
            a?.auditingInfo?.updatedAt || a?.auditingInfo?.createdAt
          ).getTime()
      );
      state.postListFiltered = state.postListItem;
    },
    [fetchGetPosts.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [fetchPostById.pending]: (state) => {
      state.status = "updateSubcategory-loading";
    },
    [fetchPostById.fulfilled]: (state, { payload }) => {
      state.status = "updateSubcategory-success";
      state.categoryEditData = payload;
    },
    [fetchPostById.rejected]: (state, action) => {
      state.status = "updateSubcategory-failed";
      state.error = action.error;
    },
    [deletePost.pending]: (state) => {
      state.status = "deletePost-loading";
    },
    [deletePost.fulfilled]: (state, { payload }) => {
      state.status = "deletePost-success";
      state.headers = payload;
    },
    [deletePost.rejected]: (state, action) => {
      state.status = "deletePost-failed";
      state.errorCode = action.payload.status;
      state.error = action.error;
    },
    [updatePost.pending]: (state) => {
      state.status = "updatePost-loading";
    },
    [updatePost.fulfilled]: (state, { payload }) => {
      state.status = "updatePost-success";
    },
    [updatePost.rejected]: (state, action) => {
      state.status = "updatePost-failed";
      state.errorCode = action.payload.status;
      state.error = action.error;
    },
    [createPost.pending]: (state) => {
      state.status = "createPost-loading";
    },
    [createPost.fulfilled]: (state, { payload }) => {
      state.status = "createPost-success";
    },
    [createPost.rejected]: (state, action) => {
      state.status = "createPost-failed";
      state.errorCode = action.payload.status;
      state.error = action.error;
    },
    [updateStatusPosts.pending]: (state) => {
      state.status = "updateStatusPosts-loading";
    },
    [updateStatusPosts.fulfilled]: (state, { payload }) => {
      state.status = "updateStatusPosts-success";
      state.headers = payload;
    },
    [updateStatusPosts.rejected]: (state, action) => {
      state.status = "updateStatusPosts-failed";
      state.error = action.error;
    },
    [updateStatusPost.pending]: (state) => {
      state.status = "updateStatusPost-loading";
    },
    [updateStatusPost.fulfilled]: (state, { payload }) => {
      state.status = "updateStatusPost-success";
    },
    [updateStatusPost.rejected]: (state, action) => {
      state.status = "updateStatusPost-failed";
      state.error = action.error;
    },
    [getLastFivePosts.pending]: (state) => {
      state.status = "getLastFivePosts-loading";
    },
    [getLastFivePosts.fulfilled]: (state, { payload }) => {
      state.status = "getLastFivePosts-success";
      state.postListItem = payload.lastFivePosts;
    },
    [getLastFivePosts.rejected]: (state, action) => {
      state.status = "getLastFivePosts-failed";
      state.error = action.error;
    },
  },
});

export const {
  setSubcategory,
  setCategory,
  setPostListItem,
  filterPostListItem,
  filterByCategory,
  setPostHighlight,
} = postListSlice.actions;
export default postListSlice.reducer;
