import styled from "styled-components";
import { Card, Accordion } from "react-bootstrap";

export const CategoryListContainer = styled.div`
  font-family: "Poppins", sans-serif;
  color: #213b73;
  p {
    margin-bottom: 0; 
  }
  a {
    color: #213b73;
    text-decoration: none;
  }
    ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 180px;
    width: 4px;
    background: #A6ADB4;
  }
`;

export const FilterButton = styled.button`
  background-color: transparent;
  color: #213b73;
  border: none;
`;

export const CategoryDetails = styled.div`
    display: flex;
    margin-top: 20px;
    h6{
      margin-right: 15px;
    }
`;

export const InputWrapper = styled.div`
  color: #213b73;
  background-color: white;
  padding: 3px 10px;
  border: 1px solid #213b73;
  border-radius: 20px;
  margin: 0 10px 20px;
  input {
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #213b73;
      font-weight: bold;
    }
  }
`;

export const CategoryListItemConteiner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  padding: 13px 25px;
  border-radius: 7px;
`;

export const StyledCustomToggle = styled.button`
  background-color: transparent;
  border: none;
`;

export const StyledCardBody = styled(Card.Body)`
  padding: 0;
`;


export const StyledCard = styled(Card)`
  background-color: transparent;
  border: none;
`;

export const StyledCardHeader = styled(Card.Header)`
  background-color: white;
  margin-bottom: 5px;
  display: flex;
  padding: 0;
  border-radius: 7px !important;
  button {
    padding: 0;
    position: relative;
    left: 10px;
    padding-left: 10px;
  }
`;

export const DropdownActionsWrapper = styled.div`
`;