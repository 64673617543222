import styled from "styled-components";
import theme from "styled-theming";
import { palettes } from "assets/theme";

const height = theme("mode", {
    template: "71px",
    default: "270px" 
});

const fontSize = theme("mode", {
    template: "10px",
    default: "42px" 
});

export const StyledImageHeader = styled.div`
    background-color: ${palettes.tints_blue_lighter};
`;

export const ImageHeaderBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: no-repeat left bottom, no-repeat center bottom, no-repeat right bottom;
    background-size: 15%, 90%, 16%;
    height: ${height};
`;

export const ImageHeaderText = styled.h1`
    font-family: "Poppins", sans-serif;
    font-size: ${fontSize};
    line-height: 59px;
    font-weight: 700;
    color: ${palettes.tints_blue_darker};
    text-align: center;
    width:100%;
    padding: 4px;
    .span{
        margin-top: 10px;
    }
    @media(max-width: 425px){
        font-size: 36px;
    }
`;