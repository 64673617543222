import React from "react";
import { templateImageUrl } from "redux/cms/constants";
import { Hr, Image, ImageSection3Wrapper, Section, Subtitle, Text, Title } from "../Secao";

export const Secao3B = ({ properties, mode, variant }) => {
  return (
    <Section
			theme={{ mode: mode, variant: variant }}
			className="align-items-center"
		>
			<div className="maxSectionWidth w-100 justify-content-between flex-column-small">
				<Title
					theme={{ mode: mode }}
					className={mode === "template" ? "pb-1" : "pb-4"}
				>
					{properties[0].value}
				</Title>
				<Hr className={mode === "template" ? "mb-2" : "mb-5"} />
				<div className="d-flex maxSectionWidth w-100 justify-content-between flex-column-small">
					<div className="d-flex maxSubSection3Width margin-top-small">
						<ImageSection3Wrapper>
							<Image
								src={properties[1].properties[0].value === "" ? templateImageUrl: properties[1].properties[0].value}
								height={mode === "template" ? "20px" : "44px"}
								alt="icon"
							/>
						</ImageSection3Wrapper>
						<div className="w-100">
							<Subtitle theme={{ mode: mode }} className="pb-2">
								<b>{properties[1].properties[1].value}</b>
							</Subtitle>
							<Text
								theme={{ mode: mode }}
								dangerouslySetInnerHTML={{
									__html: properties[1].properties[2].value,
								}}
							></Text>
						</div>
					</div>
					<div className="d-flex maxSubSection3Width margin-top-small">
						<ImageSection3Wrapper>
							<Image
								src={properties[2].properties[0].value === "" ? templateImageUrl: properties[2].properties[0].value}
								height={mode === "template" ? "20px" : "44px"}
								alt="icon"
							/>
						</ImageSection3Wrapper>
						<div>
							<Subtitle theme={{ mode: mode }} className="pb-2">
								<b>{properties[2].properties[1].value}</b>
							</Subtitle>
							<Text
								theme={{ mode: mode }}
								dangerouslySetInnerHTML={{
									__html: properties[2].properties[2].value,
								}}
							></Text>
						</div>
					</div>
					<div className="d-flex maxSubSection3Width margin-top-small">
						<ImageSection3Wrapper>
							<Image
								src={properties[3].properties[0].value === "" ? templateImageUrl: properties[3].properties[0].value}
								height={mode === "template" ? "20px" : "44px"}
								alt="icon"
							/>
						</ImageSection3Wrapper>
						<div>
							<Subtitle theme={{ mode: mode }} className="pb-2">
								<b>{properties[3].properties[1].value}</b>
							</Subtitle>
							<Text
								theme={{ mode: mode }}
								dangerouslySetInnerHTML={{
									__html: properties[3].properties[2].value,
								}}
							></Text>
						</div>
					</div>
				</div>
			</div>
		</Section>
  );
};

Secao3B.defaultProperties = {
  mode: "dafault"
};

export default Secao3B;
