import React from "react";

import {WidgetSectionExpanded, Pbk} from "./styles"

export function PbkWidgetExpanded(){
    return(
        <WidgetSectionExpanded>
                <Pbk id="pbk-widget"></Pbk>
        </WidgetSectionExpanded>
    )
}