import { StyledButton } from "../../Button/styles";
import { Note, Subtitle } from "../../Global/Texts/styles";
import { PostCard, PostListItemCategory } from './styles';

export function PostListItem(props) {

    const categories = props.data.category?.map((category, i) => 
        <p key={i}>
            {category?.name}
        </p>
    );

    return (
        <PostCard>
            <PostCard.Img variant="top" src={props.data.image} />
            <PostListItemCategory>
                {categories}
            </PostListItemCategory>
            <PostCard.Body>
                <PostCard.Title>
                    <Subtitle><b>{props.data.title}</b></Subtitle>
                </PostCard.Title>
                <PostCard.Text>
                    <Note>{props.data.body}</Note>
                </PostCard.Text>
            </PostCard.Body>
            <PostCard.Footer>
                <a href={"/blog/publicacao/"+ props.data.url} alt="Leia Mais">
                    <StyledButton color="#8C4C9E" colortext="#ffffff">
                        Leia Mais
                    </StyledButton>
                </a>
            </PostCard.Footer>
        </PostCard>
    );
}