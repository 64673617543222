import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blogSlice } from "redux/blog/blogSlice";
import { BlogPageType, BlogStatus } from "redux/blog/constants";
import { PageTypes } from "redux/cms/constants";
import { cmsSlice } from "redux/cms/cmsSlice";
import { LoaderComponent } from "components/Loader";
import {
  ActionsDiv,
  AuthorDiv,
  CategoryDiv,
  // Checkbox,
  DateDiv,
  GreyRetangle,
  InputWapper,
  InputWrapper,
  PostActionButton,
  PostListContainer,
  PostsDetails,
  PostTitleDiv,
  TagsDiv,
} from "./styles";
import { PostListItem } from "./postListItem";
import {
  fetchGetPosts,
  updateStatusPosts,
} from "redux/postList/postListService";
import { useHistory } from "react-router-dom";
import { fetchCategory } from "redux/blog/blogService";
import { useCallback } from "react";
import { useMemo } from "react";

const FILTER_TYPE = {
  BY_NAME: "name",
  // BY_DISABLED: "DISABLED",
  // BY_TRASH: "TRASH",
  BY_PUBLISHED: "PUBLISHED",
  BY_DRAFT: "DRAFT",
};

const PostList = () => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.auth.userLogged);
  if (!loggedUser) {
    history.push("/backoffice/login");
  }

  const { setBlogPageType } = blogSlice.actions;
  const { setPageType } = cmsSlice.actions;
  const dispatch = useDispatch();

  const [posts, setPosts] = useState([]);
  const [noFilterLength, setNoFilterLength] = useState(0);
  const [byEnabledLength, setByEnabledLength] = useState(0);
  const [byDraftLength, setByDraftLength] = useState(0);

  const [selectedPosts, setSelectedPosts] = useState([]);
  const [selectedAction, setSelectedAction] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [textFilter, setTextFilter] = useState(null);
  // const [byDisabledLength, setByDisabledLength] = useState(0);
  // const [byTrashLength, setByTrashLength] = useState(0);
  // const [masterChecked, setMasterChecked] = useState(false);

  const postLoader = useSelector((state) => {
    return state.postList;
  });

  useEffect(() => {
    dispatch(fetchGetPosts());
    dispatch(fetchCategory());
    dispatch(setBlogPageType(BlogPageType.POST_LIST));
    dispatch(setPageType(PageTypes.BLOG));
  }, []);

  useEffect(() => {
    setPosts(orderPosts());
    filterList(FILTER_TYPE.BY_PUBLISHED);
  }, [postLoader]);

  useEffect(() => {
    filterList(filterStatus);
  }, [filterStatus, textFilter]);

  const debounce = useCallback((func, wait) => {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }, []);

  const onSearchChange = useMemo((e) => {
    return debounce(setTextFilter, 600);
  }, []);

  const filterList = (type) => {
    const posts = orderPosts();

    setFilterStatus(type);

    let noFilterLength = 0;
    let publishedLength = 0;
    let draftLength = 0;
    // let trashLength = 0;
    // let disabledLength = 0;

    posts?.forEach((post) => {
      switch (post.status) {
        // case FILTER_TYPE.BY_DISABLED:
        //   disabledLength++;
        //   break;
        // case FILTER_TYPE.BY_TRASH:
        //   trashLength++;
        //   break;
        case FILTER_TYPE.BY_PUBLISHED:
          publishedLength++;
          break;
        case FILTER_TYPE.BY_DRAFT:
          draftLength++;
          break;
        default:
          break;
      }
      noFilterLength++;
    });
    // setByDisabledLength(disabledLength);
    // setByTrashLength(trashLength);
    setNoFilterLength(noFilterLength);
    setByEnabledLength(publishedLength);
    setByDraftLength(draftLength);

    switch (type) {
      default:
        const noFilter = posts?.filter(
          (post) =>
            post.status !== FILTER_TYPE.BY_TRASH &&
            (!textFilter ||
              post.title?.toLowerCase().includes(textFilter?.toLowerCase()))
        );
        setPosts(noFilter);
        break;
      // case FILTER_TYPE.BY_DISABLED:
      //   const disabled = posts?.filter(
      //     (post) => post.status === FILTER_TYPE.BY_DISABLED
      //   );
      //   setPosts(disabled);
      //   break;
      // case FILTER_TYPE.BY_TRASH:
      //   const trash = posts?.filter(
      //     (post) => post.status === FILTER_TYPE.BY_TRASH
      //   );
      //   setPosts(trash);
      //   break;
      case FILTER_TYPE.BY_PUBLISHED:
        const enabled = posts?.filter(
          (post) =>
            post.status === FILTER_TYPE.BY_PUBLISHED &&
            (!textFilter ||
              post.title?.toLowerCase().includes(textFilter?.toLowerCase()))
        );
        setPosts(enabled);
        break;
      case FILTER_TYPE.BY_DRAFT:
        const draft = posts?.filter(
          (post) =>
            post.status === FILTER_TYPE.BY_DRAFT &&
            (!textFilter ||
              post.title?.toLowerCase().includes(textFilter?.toLowerCase()))
        );
        setPosts(draft);
        break;
    }
  };

  const orderPosts = () => {
    let posts = [];
    postLoader?.postListItem?.forEach((post) => {
      posts.push({ ...post, selected: false });
    });
    return posts.sort((a, b) => a?.name?.localeCompare(b.name));
  };

  const postList = () => {
    return posts.map((post, index) => {
      return (
        <PostListItem
          key={index}
          post={post}
          // onItemCheck={onItemCheck}
          // setFilterStatus={setFilterStatus}
        />
      );
    });
  };

  // const onMasterCheck = (e) => {
  //   let tempList = posts;
  //   tempList.map((post) => (post.selected = e.target.checked));

  //   setPosts(tempList);
  //   setMasterChecked(e.target.checked);
  //   setSelectedPosts(posts.filter((e) => e.selected));
  // };

  // const onItemCheck = (e, item) => {
  //   let tempList = posts;
  //   tempList.map((post) => {
  //     if (post.id === item.id) {
  //       post.selected = e.target.checked;
  //     }
  //     return post;
  //   });

  //   const totalItems = posts.length;
  //   const totalCheckedItems = tempList.filter((e) => e.selected).length;

  //   setMasterChecked(totalItems === totalCheckedItems);
  //   setPosts(tempList);
  //   setSelectedPosts(posts.filter((e) => e.selected));
  // };

  const executeAction = () => {
    const postIds = selectedPosts.map((value) => value.id);
    const bulkUpdate = {
      blogs: postIds,
      status: selectedAction,
    };
    dispatch(updateStatusPosts(bulkUpdate)).then(() => {
      // setMasterChecked(false);
      setSelectedPosts([]);
      dispatch(fetchGetPosts());
    });
  };

  const handleActionChange = (value) => setSelectedAction(value.target.value);

  const bulkActions = () => {
    if (selectedPosts.length > 0)
      return (
        <>
          <div className="d-flex">
            <InputWapper>
              <GreyRetangle />
              <div className="content">
                <span>Ações por seleção</span>

                <select
                  onChange={handleActionChange}
                  id="postActionsId"
                  defaultValue={selectedAction}
                >
                  <option selected></option>
                  <option value={BlogStatus.PUBLISHED}>Publicado</option>
                  <option value={BlogStatus.DRAFT}>Rascunho</option>
                  <option value={BlogStatus.DISABLED}>Desabilitado</option>
                  <option value={BlogStatus.TRASH}>Deletado</option>
                </select>
              </div>
            </InputWapper>
            <PostActionButton
              theme={{ mode: "normal" }}
              onClick={(e) => executeAction(e)}
            >
              Aplicar
            </PostActionButton>
          </div>
        </>
      );
  };

  return (
    <>
      {postLoader.status === "loading" && <LoaderComponent />}
      <PostListContainer>
        <div className="d-flex justify-content-between">
          <PostsDetails>
            <button onClick={() => filterList()}>
              <h6>
                <span
                  style={{
                    fontWeight: filterStatus == null ? "bold" : "normal",
                  }}
                >
                  Todos os artigos ({noFilterLength})
                </span>
              </h6>
            </button>
            <h6 className="mt-2">|</h6>
            <button onClick={() => filterList(FILTER_TYPE.BY_PUBLISHED)}>
              <h6>
                <span
                  style={{
                    fontWeight:
                      filterStatus === FILTER_TYPE.BY_PUBLISHED
                        ? "bold"
                        : "normal",
                  }}
                >
                  Publicados ({byEnabledLength})
                </span>
              </h6>
            </button>
            <h6 className="mt-2">|</h6>
            <button onClick={() => filterList(FILTER_TYPE.BY_DRAFT)}>
              <h6>
                <span
                  style={{
                    fontWeight:
                      filterStatus === FILTER_TYPE.BY_DRAFT ? "bold" : "normal",
                  }}
                >
                  Rascunhos ({byDraftLength})
                </span>
              </h6>
            </button>
            {/* <h6 className="mt-2">|</h6>
            <button onClick={() => filterList(FILTER_TYPE.BY_DISABLED)}>
              <h6>
                <span
                  style={{
                    fontWeight: filterStatus === "DISABLED" ? "bold" : "normal",
                  }}
                >
                  Desabilitados ({byDisabledLength})
                </span>
              </h6>
            </button> */}
            {/* <h6 className="mt-2">|</h6>
            <button onClick={() => filterList(FILTER_TYPE.BY_TRASH)}>
              <h6>
                <span
                  style={{
                    fontWeight: filterStatus === "TRASH" ? "bold" : "normal",
                  }}
                >
                  Lixos ({byTrashLength})
                </span>
              </h6>
            </button> */}
          </PostsDetails>
          <InputWrapper className="mt-3">
            <input
              type="text"
              placeholder="BUSCA"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <img src="/ic-search.svg" alt="icone buscar" className="mb-1" />
          </InputWrapper>
        </div>
        <hr />
        {selectedPosts.length > 0}
        {bulkActions()}
        <div className="d-flex justify-start px-4 mb-3 w-100">
          {/* <Checkbox>
            <input
              type="checkbox"
              checked={masterChecked}
              id="mastercheck"
              onChange={(e) => onMasterCheck(e)}
            />
          </Checkbox> */}
          <PostTitleDiv>Título</PostTitleDiv>
          <AuthorDiv>Autor</AuthorDiv>
          <CategoryDiv>Categorias</CategoryDiv>
          <TagsDiv>Etiquetas</TagsDiv>
          <DateDiv>Data</DateDiv>
          <ActionsDiv>Ações</ActionsDiv>
        </div>
        {postList()}
      </PostListContainer>
    </>
  );
};

export default PostList;
