import styled from "styled-components";

export const ContentBlueHeaderBackground = styled.div`
    background-color: #005DAA;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 40px;
    h1{
        font-size: 52px;
        font-weight: bold;
    }
    p{
        font-size: 22px;
        margin-top: 30px;
        margin-bottom: 0;
    }
    
    @media (max-width: 768px) {
        padding: 16px 23px;
        h1{
            font-size: 22px;
            width: 70%;
        }
        p{
            font-size: 14px;
            margin-top: 15px;
        }
    }
`;