import { BackOfficeHeader } from "components/BackOfficeHeader";
import { LoginField } from "components/LoginField";

export function Login() {
    return(
        <>
            <BackOfficeHeader/>
            <LoginField />
        </>
    );
}