import styled from "styled-components";
import { palettes } from "assets/theme";
export const ProfilePageContainer = styled.div`
    background-color: ${palettes.system_gray};
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${palettes.tints_blue_darker};
    height: calc(100vh - 66px);
    padding: 0 25%;
    @media(max-width: 768px) {
        padding: 0 10%;
    }
    button{
        height: 45px;
        padding: 0;
    }
    select, input{
        border: 1px solid #A6ADB4;
        margin: 5px 0;
        height: 45px;
        padding: 0 10px;
        &::placeholder{
            color: ${palettes.tints_blue_darker};
        }
        &:focus{
            outline: none;
        }
    }
    select{
        option{
            display: block;
        }
    }
    .input-invalid{
        color: ${palettes.tints_red};
        border: 1px solid ${palettes.tints_red};
        margin-bottom:0;
    }
`;