import styled from "styled-components";

export const InputWrapper = styled.div`
  color: #213b73;
  background-color: white;
  padding: 3px 10px;
  border: 1px solid #213b73;
  border-radius: 20px;
  margin: 0 10px 20px;
  input {
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #213b73;
      font-weight: bold;
    }
  }
`;

export const PagesDetails = styled.div`
  display: flex;
  margin-top: 20px;
  h6 {
    margin-right: 15px;
  }
`;

export const PageListContainer = styled.div`
  font-family: "Poppins", sans-serif;
  color: #213b73;
  p {
    margin-bottom: 0;
  }
  a {
    color: #213b73;
    text-decoration: none;
  }
  button {
    background-color: transparent;
    color: #213b73;
    border: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 180px;
    width: 4px;
    background: #a6adb4;
  }
`;

export const StyledPageListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 13px 25px;
  margin-bottom: 10px;
  border-radius: 7px;
`;

export const PageListItemChildren = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  background-color: white;
  padding: 13px 35px;
  margin-bottom: 10px;
  border-radius: 7px;
`;

export const StyledDiv = styled.div`
  min-width: 200px;
`;

export const PageTitleDiv = styled.div`
  min-width: 200px;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AuthorDiv = styled.div`
  min-width: 200px;
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TagsDiv = styled.div`
  min-width: 200px;
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DateDiv = styled.div`
  min-width: 200px;
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ActionsDiv = styled.div`
  min-width: 200px;
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
