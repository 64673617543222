import {
	StyledClosestAgenciesSuggestionCard,
	ClosestAgenciesSuggestionButton,
	ClosestAgenciesSuggestionContent
} from "../ClosestAgenciesSuggestionCard/styles";
import { Col } from "react-bootstrap";
import { StyledButton } from "../Button/styles";

export function ClosestAgenciesSuggestionCard(props) {
	return (
		<StyledClosestAgenciesSuggestionCard>
			<ClosestAgenciesSuggestionContent>
				<Col  lg={3} md={2} xs={2}>
					<img src={props.data.icon} alt="airport" />
				</Col>
				<Col lg={9} md={10} xs={10}>
					<h3>{props.data.name}</h3>
					<p>
						{props.data.location} <br />
						{props.data.register}
					</p>
					<a href="/" alt="Veja os detalhes do local!">
						Detalhes do Local {">"}
					</a>
				</Col>
			</ClosestAgenciesSuggestionContent>
			<ClosestAgenciesSuggestionButton>
				<StyledButton color={props.data.button.color} colortext={props.data.button.colorText}>
					{props.data.button.text}
				</StyledButton>
			</ClosestAgenciesSuggestionButton>
		</StyledClosestAgenciesSuggestionCard>
	);
}
