import { ComponentMapping } from "@cms-common/Resources";
import { DragNDropContext } from "@cms/components/DragNDrop";
import { cmsSlice } from "@redux/cms/cmsSlice";
import { useContext, useEffect } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonContainre,
  ContentDiscription,
  PageConfigButton,
  TemplateContainer,
  TemplateHighlight,
  DeleteButton,
} from "./styles";
import { setPageComponents } from "@redux/cms/cmsSlice";
import { ReactComponent as IcClose } from "@assets/images/cms/ic-close.svg";
import { ConfirmationModal } from "components/Global/ConfirmationModal";

const CustomDraggable = ({ index, template }) => {
  const {
    setTemplateEditionMode,
    cancelTemplateEditionMode,
    setUpdateTemplate,
    setConfirmationModal,
  } = cmsSlice.actions;

  const templatePage = useSelector((state) => state?.cms?.templatePage);
  const canvasPageState = useSelector((state) => state?.cms?.canvasPage);
  const dispatch = useDispatch();
  const handleEdition = (index, current) => {
    dispatch(
      setTemplateEditionMode({
        index: index,
        current: current,
      })
    );
  };
  const handleRemoveComponent = (index) => {
    const newComponents = canvasPageState.components.filter(
      (e, i) => i !== index
    );
    dispatch(setPageComponents(newComponents));
    dispatch(setConfirmationModal(false));
    dispatch(cancelTemplateEditionMode());
  };

  if (!template) {
    return null;
  }
  const Component = ComponentMapping[template.name];
  return (
    <>
      {templatePage?.index === index ? (
        <>
          <TemplateHighlight>
            <ConfirmationModal
              title="Excluir componente"
              body="Tem certeza que deseja excluir este componente?"
              callback={() => handleRemoveComponent(index)}
            />
            <DeleteButton onClick={() => dispatch(setConfirmationModal(true))}>
              <IcClose />
            </DeleteButton>
            <Component
              mode="default"
              variant={template.variant}
              key={template.name}
              {...template}
            />
          </TemplateHighlight>
          <ButtonContainre>
            <PageConfigButton
              theme={{ mode: "inverse" }}
              onClick={() => dispatch(cancelTemplateEditionMode())}
            >
              Cancelar
            </PageConfigButton>
            <PageConfigButton
              theme={{ mode: "normal" }}
              onClick={() => dispatch(setUpdateTemplate())}
            >
              Salvar Alterações
            </PageConfigButton>
          </ButtonContainre>
        </>
      ) : (
        <div onClick={() => handleEdition(index, template)}>
          <Component
            mode="default"
            variant={template.variant}
            isFromCMS
            {...template}
            key={template.name}
          />
        </div>
      )}
    </>
  );
};

const DroppableZone = ({ components }) => (
  <div>
    <Droppable droppableId="canvas-template">
      {(provided) => (
        <div
          className="canvas-template"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {components?.map((e, index) => {
            return (
              <Draggable
                draggableId={`canvas-${e?.name}`}
                index={index}
                key={`canvas-${e?.name}-${index}`}
              >
                {(provided) => (
                  <TemplateContainer
                    key={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <CustomDraggable
                      index={index}
                      template={e}
                      provided={provided}
                    />
                  </TemplateContainer>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
          <ContentDiscription>
            <h2>Escolha um template na barra ao lado, arraste e solte aqui.</h2>
            <h4>Depois você poderá editar textos e imagens.</h4>
          </ContentDiscription>
        </div>
      )}
    </Droppable>
  </div>
);

const PageContent = () => {
  const { setPageComponents } = cmsSlice.actions;
  const dispatch = useDispatch();
  const canvasPageState = useSelector((state) => state?.cms?.canvasPage);
  const { movedItem, setMovedItem } = useContext(DragNDropContext);
  const templates = useSelector((state) => state.cms.templates);
  useEffect(() => {
    const { components } = canvasPageState;
    if (movedItem?.source) {
      let items = [];
      if (components?.length > 0) {
        items = [
          ...components,
        ];
        items.splice(movedItem.destination.index, 0, templates.find((p) => p.name === movedItem.draggableId));
      } else {
        items = [templates.find((p) => p.name === movedItem.draggableId)];
      }
      items.map(e => e === undefined && items.splice(items.indexOf(e), 1));
      const [reorderedItem] = items.splice(movedItem.source.index, 1);
      items.splice(movedItem.destination.index, 0, reorderedItem);
      dispatch(setPageComponents(items));
      setMovedItem({});
    }
  }, [movedItem?.draggableId]);
  return (
    <div>
      <DroppableZone components={canvasPageState?.components} />
    </div>
  );
};

export default PageContent;
