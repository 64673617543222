import { DragNDropContext } from "@cms/components/DragNDrop";
import BlogHighlight from "pages/BlogEngine/components/BlogHighlight";
import { useContext, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { blogSlice } from "redux/blog/blogSlice";
import { BlogPageType } from "redux/blog/constants";

import {
  createHighlight,
  fetchHighlights,
  updateHighlight,
} from "redux/cms/cmsService";
import { cmsSlice } from "redux/cms/cmsSlice";
import { PageTypes } from "redux/cms/constants";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import { SaveChangesbutton, TrashIcon } from "../SetupFooter/styles";
import { Button, Modal, ModalBody } from "react-bootstrap";
import {
  ContentRow,
  DraggableItem,
  DraggableItemWrap,
  GreyRetangle,
  GreyRetangleError,
  InpuDescriptiontWapper,
  InputTitleWapper,
  InputTitleWapperError,
  MainContainer,
  SecondContainer,
  InpuDescriptiontWapperError,
} from "./styles";
import { getHighlights } from "redux/blog/blogService";

const FeaturedPage = () => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.auth.userLogged);
  const blogHighlights = useSelector((state) => state?.blog?.highlights);
  const user = useSelector((state) => state?.auth?.user);
  if (!loggedUser) {
    history.push("/backoffice/login");
  }

  const { setBlogPageType } = blogSlice.actions;
  const { setPageType, updatePostItem, removePostItem } = cmsSlice.actions;
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [postData, setPostData] = useState([]);
  const featuredPageState = useSelector((state) => state?.cms?.featuredPage);
  const highlightState = useSelector((state) => state.cms.status);
  const { movedItem, setMovedItem } = useContext(DragNDropContext);

  const [validation, setIsValidation] = useState(false);
  const [validationDescribe, setIsValidationDescribe] = useState(false);
  const [validationPost, setIsValidationPost] = useState(false);

  useEffect(() => {
    dispatch(setBlogPageType(BlogPageType.FEATURED_PAGE));
    dispatch(setPageType(PageTypes.BLOG));
    dispatch(getHighlights(user));
  }, []);

  useEffect(() => {
    setPostData([...featuredPageState?.posts]);
  }, [featuredPageState?.posts]);

  useEffect(() => {
    setTitle(featuredPageState?.title);
  }, [featuredPageState?.title]);

  useEffect(() => {
    setDescription(featuredPageState?.description);
  }, [featuredPageState?.description]);

  useEffect(() => {
    if (movedItem?.source) {
      let items = [];
      items = [...postData];
      const [reorderedItem] = items.splice(movedItem.source.index, 1);
      items.splice(movedItem.destination.index, 0, reorderedItem);
      setMovedItem({});
      dispatch(updatePostItem({ posts: items }));
    }
  }, [movedItem?.draggableId]);

  useEffect(() => {
    let message;
    let type;
    switch (highlightState) {
      case "updateHighlight-failed":
        message = "Erro ao atualizar os Destaques";
        type = "error";
        break;
      case "updateHighlight-success":
        message = "Destaques atualizados com sucesso";
        type = "success";
        dispatch(fetchHighlights());
        break;
      case "createHighlight-failed":
        message = "Erro ao criar os Destaques";
        type = "error";
        break;
      case "createHighlight-success":
        message = "Destaques criados com sucesso";
        type = "success";
        dispatch(fetchHighlights());
        break;

      default:
        return;
    }
    if (message) {
      dispatch(displaySnackbar({ message, type }));
    }
  }, [highlightState]);

  const handleShow = () => {
    if (title === undefined || title === "") {
      dispatch(displaySnackbar({ message: "Título em branco", type: "error" }));
      setIsValidation(false);
    } else {
      setIsValidation(!false);
    }
    if (description === undefined || description === "") {
      dispatch(
        displaySnackbar({ message: "Descrição em branco", type: "error" })
      );
      setIsValidationDescribe(false);
    } else {
      setIsValidationDescribe(!false);
    }
    if (postData.length === 0) {
      dispatch(
        displaySnackbar({
          message: "Selecione alguma publicação do Blog",
          type: "error",
        })
      );
      setIsValidationPost(false);
    } else {
      setIsValidationPost(!false);
    }
  };

  function validaçao() {
    {
      validation === true &&
        validationDescribe === true &&
        validationPost === true
        ? handleSaveFeatured()
        : handleShow();
    }
  }

  const handleSaveFeatured = () => {
    const postIds = featuredPageState.posts.map((post) => {
      return post.id;
    });
    const newHighlight = {
      description: description,
      title: title,
      blogIds: postIds,
    };
    if (featuredPageState?.id) {
      newHighlight["id"] = featuredPageState?.id;
      dispatch(updateHighlight(newHighlight));
    } else {
      dispatch(createHighlight(newHighlight));
    }
    setIsValidation(false);
    setIsValidationDescribe(false);
    setIsValidationPost(false);
  };

  return (
    <>
      <ContentRow>

        <InputTitleWapper>
          <GreyRetangle />
          <input
            value={title}
            placeholder="   Escreva aqui o Título"
            onChange={(e) => setTitle(e.target.value)}
          />
        </InputTitleWapper>
      </ContentRow>
      <ContentRow>
        <InpuDescriptiontWapper>
          <GreyRetangle />
          <textarea
            rows="5"
            value={description}
            placeholder="   Escreva um texto complementar"
            onChange={(e) => setDescription(e.target.value)}
          />
        </InpuDescriptiontWapper>
      </ContentRow>
      <BlogHighlight properties={blogHighlights} mode={"default"} variant={"blue"} />
      <SecondContainer>
        <MainContainer>
          <p>
            Arraste os itens individualmente para os colocar na prioridade que
            preferir. Você deve escolher cinco publicações, clique no lixo à
            direita do item para remover do destaque, você poderá adicioná-los
            novamente mais tarde.
          </p>

          <SaveChangesbutton onClick={() => validaçao()}>
            Salvar alterações
          </SaveChangesbutton>
        </MainContainer>
        <Droppable droppableId="top-menu">
          {(provided) => (
            <ul
              className="top-menu"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {postData?.map((post, index) => (
                <Draggable
                  draggableId={`top-menu-${post.id}-${index}`}
                  index={index}
                  key={`top-menu-${post.id}-${index}`}
                >
                  {(provided) => (
                    <li
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DraggableItemWrap>
                        <DraggableItem>
                          <div>
                            <span className="index">{`#${index}`}</span>
                            <span>{post.title}</span>
                          </div>
                          <TrashIcon
                            onClick={() =>
                              dispatch(
                                removePostItem({
                                  post: post,
                                })
                              )
                            }
                          />
                        </DraggableItem>
                      </DraggableItemWrap>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </SecondContainer>
    </>
  );
};

export default FeaturedPage;
