export function isUrlValidated(string) {
    const validCharacters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-/";

    //não pode começar com '/'
    if (string[0] === "/") {
        return false;
    }
    for (var i = 0; i < string.length; i++) {
        //deve conter apenas caracteres válidos
        if (!validCharacters.includes(string[i]))
            return false;
        //não pode ter '/' dupla
        if (i !== 0 && string[i] === "/" && string[i - 1] === "/")
            return false;
    }

    return true;
}