import styled from "styled-components";

export const ImageTextCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: flex-start;
    align-items: center;
    padding: 20px;
    img{
        margin-bottom: 20px;
        width: 70%;
    }
    @media(max-width: 992px) {
        width: 100%;
    }
`;