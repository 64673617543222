import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React from "react";
import { HorizontalImageTitleInfoAndButtons } from "../../components/HorizontalImageTitleInfoAndButtons";
import { ImageHeader } from "../../components/ImageHeader";
import { InfoBlock } from "../../components/InfoBlock";
import { ThreeCardsWithTopImage } from "../../components/ThreeCardsWithTopImage";
import { TwoInfoBlocksWithMainTitle } from "../../components/TwoInfoBlocksWithMainTitle";
import { PbkWidget } from "../../components/Widget";
import { InfoBlockContainer } from "./styles";

export function Homepage(props){
    const { setTitleText } = React.useContext(TitleContext);
    React.useEffect(() => {
        setTitleText("Alamo - Página Inicial");
    }, []);
    
    const components = 
        <>
            <ImageHeader data={props.data.imageHeaderData}/>
            <PbkWidget id="pbk-widget"/>
            <InfoBlockContainer><InfoBlock data={props.data.infoBlockData}/></InfoBlockContainer>
            <TwoInfoBlocksWithMainTitle data={props.data.twoInfoBlocksWithMainTitleData}/>
            <ThreeCardsWithTopImage data={props.data.threeCardsWithTopImageData}/>
            <HorizontalImageTitleInfoAndButtons data={props.data.horizontalImageTitleInfoAndButtonsData}/>
        </>

    return(
        <>
            <StaticPageEngine components={components}/>
        </>
    )
}

export default Homepage;