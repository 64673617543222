import JoditEditor from "jodit-react";
import { useEffect, useRef, useState, useMemo } from "react";
import { Container } from "./styles";

const EditorHtml = ({ onChange, value }) => {
  const editor = useRef(null);
  const [content, setContent] = useState(value);
  const config = useMemo(() => {
    return {
      minHeight: 400,
      placeholder: "Escreva aqui seu texto",
      readonly: false,
    };
  }, []);
  // useEffect(() => {
  //   if (value) setContent(value);
  // }, [value]);
  useEffect(() => {
    onChange(content);
  }, [content]);

  return (
    <Container>
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        // tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
      />
    </Container>
  );
};

export default EditorHtml;
