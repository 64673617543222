import styled from "styled-components";
import { Breadcrumb } from "react-bootstrap";

export const BreadCrumbContainer = styled.div`
    color: #6c757d;
    display: flex;
    padding: 15px 30px;
    @media(max-width: 768px) {
        font-size: 12px;
    }
`;

export const BreadCrumbHr = styled.hr`
    opacity: 1;
    color: #213B73;
`;

export const BreadCrumbItem = styled(Breadcrumb.Item)`
    font-size: 15px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    list-style-type: none;
    &:active{
        color: #6c757d
    }
    a{
        text-decoration: none;
        color: #7A498F
    }
    @media(max-width: 768px) {
    }
    `;