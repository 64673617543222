import { BackOfficeHeader } from "components/BackOfficeHeader";
import { UserAdmin } from "components/UserAdmin";

import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "redux/user/userService";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export function UserAdminPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state?.auth?.userLogged);

  //conferir se o usuario esta logado
  if (!userLogged) {
    history.push("/login");
  }
  if(userLogged?.roles[0] !== "ROLE_ADMIN"){
    history.push("/cms");
  }

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <>
      <BackOfficeHeader />
      <UserAdmin />
    </>
  );
}
