import React from "react";
import blueSemicircle from "../../assets/images/bg-blue-semicircle.svg";
import {
	StyledImageHeader,
	ImageHeaderBackground,
	ImageHeaderText,
} from "./styles.js";

export function ImageHeader(props) {
	function handleContent() {
		if (props.countryName) {
			return (
				<ImageHeaderText>
					<p>Agências de aluguel</p>
					<span>{props.countryName}</span>
				</ImageHeaderText>
			);
		}
		return (
			<ImageHeaderText>
				{props.data2 ? props.data2 : props.data.text}
			</ImageHeaderText>
		);
	}

	return (
		<>
			<StyledImageHeader>
				<ImageHeaderBackground
					style={{
						backgroundImage: `url(${props.data.leftImage}), url(${blueSemicircle}) ,url(${props.data.rightImage})`,
					}}
				>
					{handleContent()}
				</ImageHeaderBackground>
			</StyledImageHeader>
		</>
	);
}
