import styled from "styled-components";

export const ThreeInfoBlocksWithMainTitleBackground = styled.div`
    background-color: #213B73;
    padding-top: 40px;
    display: flex;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: bold 400;
    padding-bottom: 30px;
    div{
        padding-left: 1px;
        padding-right: 1px;
    }

    @media(max-width: 600px){
        padding-left: 50px;
        padding-right: 50px;
    }
`;