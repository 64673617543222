import { ThreeInfoBlocksWithMainTitleBackground } from "./styles";
import { InfoBlock } from "../InfoBlock/index";

import { Row, Col, Container } from "react-bootstrap";

export function ThreeInfoBlocksWithMainTitle(props) {
    return (
        <>
            <ThreeInfoBlocksWithMainTitleBackground>
                <Container>
                    <Container fluid>
                        <h4>{props.data.title}</h4>
                        <hr></hr>
                        <Row>
                            <Col lg={6} md={12}>
                                <InfoBlock data={props.data.infoblocks[0]} />
                            </Col>
                            <Col lg={6} md={12}>
                                <InfoBlock data={props.data.infoblocks[1]} />
                            </Col>
                            {props.data.infoblocks[2] &&
                                <Col lg={4} md={12}>
                                    <InfoBlock data={props.data.infoblocks[2]} />
                                </Col>
                            }
                        </Row>
                    </Container>
                </Container>
            </ThreeInfoBlocksWithMainTitleBackground>
        </>
    );
}