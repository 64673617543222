import styled from 'styled-components';
import Col from 'react-bootstrap/Col';

export const StyledLocationAllCardDropdown = styled.div`
    background-color: #fff;
    padding: 20px 0;
    a{
        text-decoration: none;
    }
    img{
        width: 100%;
        height: 140px;
    }
    hr{
        opacity: 1;
    }
    #agencies-number{
        text-align: center;
        font-size: 54px;
    }
    #agencies-text{
        color: #005DAA;
        text-align: center;
        font-weight: 400;
    }
    #link{
        color: #8C4C9E;
        margin-right: 40px;
        min-width: 230px;
    }
    #mobile{
        display: none;
    }
    #desktop{
        display: block;
    }
    @media (max-width: 992px) {
        #mobile{
            display: block;
        }   
        #desktop{
            display: none;
        }   
        #agencies-number{
            font-size: 40px;
        }
        #agencies-text{
            font-size: 16px;
        }
    }
`;

export const LocationAllCardCol = styled(Col)`
    z-index: 3;
`

export const LocationAllCardDropdownText = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: 700;
    padding: 10px 0;
    color:#213B73;
    @media (max-width: 992px) {
        font-size: 12px;
    }
`;

export const LocationAllCardDropdownContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
`;