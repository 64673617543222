import { Container, Nav } from "react-bootstrap";
import blogLogo from "../../assets/images/logo-blog.png";
import {
  Hamburguer,
  HamburguerMenu,
  HeaderLogo,
  InputWrapper,
  MainNavbar,
  MainNavBarNav,
  ResponsiveNavbar,
  StyledHeader,
} from "../Header/styles";

import { useDispatch } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function BlogHeader(props) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);

  const history = useHistory();

  // props.data.mainLink.map((mainMenu, i) => {
  //   if (mainMenu.submenu) {
  //     return (
  //       <div key={`mainMenu${i}`}>
  //         <MainDropdown title={mainMenu.text} align={{ lg: "end" }}>
  //           {mainMenu.submenu.map((submenuItems, i) => (
  //             <>
  //               <MainDropdownItem
  //                 key={`submenuItems${i}`}
  //                 href={submenuItems.href}
  //                 alt={submenuItems.alt}
  //               >
  //                 {submenuItems.text}
  //               </MainDropdownItem>
  //               {i < mainMenu.submenu.length - 1 ? (
  //                 <hr style={{ margin: "0 15px", opacity: 0.25 }} />
  //               ) : null}
  //             </>
  //           ))}
  //         </MainDropdown>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div key={`mainMenu${i}`}>
  //         <MainLink href={mainMenu.href}>{mainMenu.text}</MainLink>
  //       </div>
  //     );
  //   }
  // });

  function submitSearch() {
    history.push("/blog/search?query=" + searchText);
  }
  //a

  return (
    <StyledHeader>
      <MainNavbar className="align">
        <div className="d-flex align-items-center">
          <a href="/blog" alt="Alamo">
            <HeaderLogo>
              <img src={blogLogo} alt={props.data.logo.alt} />
            </HeaderLogo>
          </a>
        </div>
        <MainNavBarNav className="ms-auto">
          <a href="/">
            <b>
              <p>Site Alamo</p>
            </b>
          </a>
          <InputWrapper>
            <input
              type="text"
              placeholder="BUSCA"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  submitSearch();
                }
              }}
            ></input>
            <img src="/ic-search.svg" alt="icone buscar" className="mb-1" />
          </InputWrapper>
        </MainNavBarNav>
      </MainNavbar>

      <ResponsiveNavbar expand="xxl">
        <a href="/" alt="Alamo">
          <HeaderLogo>
            <img src={blogLogo} alt={props.data.logo.alt} />
          </HeaderLogo>
        </a>

        <Hamburguer aria-controls="responsive-navbar-nav" />
        <HamburguerMenu id="responsive-navbar-nav">
          <Nav>
            <Container>
              <a href="/">
                <b>
                  <p>Site Alamo</p>
                </b>
              </a>
              <InputWrapper>
                <input
                  type="text"
                  placeholder="BUSCA"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      submitSearch();
                    }
                  }}
                ></input>
                <img src="/ic-search.svg" alt="icone buscar" className="mb-1" />
              </InputWrapper>
            </Container>
          </Nav>
        </HamburguerMenu>
      </ResponsiveNavbar>
    </StyledHeader>
  );
}
