import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import Mapicon from "../../assets/images/ic-map.svg";
import CustomMarker from "./CustomMarker";
import { AlertMap, AlertWrapper } from "./styles";

const DynamicMap = (props) => {
  const [centerMap, setCenterMap] = useState(false);
  const [show, setShow] = useState(true);

  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);

  useEffect(() => {
    setLat(props.location.lat);
    setLong(props.location.long);

    setCenterMap(true);
  }, [props.location, props.locations]);

  const MapImage = () => {
    return centerMap ? (
      <div style={{ height: "100vh", width: "100%" }}>
        <AlertWrapper>
          <AlertMap
            variant="light"
            onClose={() => setShow(false)}
            dismissible
            style={show ? { display: "block" } : { display: "none" }}
          >
            <AlertMap.Heading>
              <img src={Mapicon} alt="Map" />
              Mova o mapa para explorar as áreas próximas.
            </AlertMap.Heading>
          </AlertMap>
        </AlertWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDpuxF-yeOkXfnMuGChDCawQRYdQVX2EGE" }}
          defaultZoom={10}
          defaultCenter={{
            lat: Number(lat),
            lng: Number(long)
          }}
        >
          {props.locations.map((store, index) => {
            return (
              <CustomMarker
                key={index}
                id={index}
                lat={store.branchStationLatitude}
                lng={store.branchStationLongitude}
                text={index}
                tooltip={store.branchName}
                airportIndicator={store.airportIndicator}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    ) : null;
  };

  return <MapImage />;
};

export default DynamicMap;
