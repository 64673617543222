import { Container, CancelButton, ContinueButton, Option } from "./styles";
import {
  SelectWrap,
  SelectContainer,
  SelectLabel,
  Select
} from "../SetupPage/components/SiteMap/styles";
import { Spacer } from "../SetupPage/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchGetPages } from "redux/page/pageService";
import { useHistory } from "react-router-dom";
import { setDuplicateComponent } from "redux/cms/cmsSlice";
import { createPage, updatePage } from "redux/cms/cmsService";

const PagePosition = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pageOptions = useSelector((state) => state.page.page);
  const duplicatedPage = useSelector((state) => state.cms.canvasPage);
  const selectParentPageState = useSelector(
    (state) => state.cms.selectParentPage
  );
  const [isParentSelected, setIsParentSelected] = useState({
    isSelected: false,
    parentId: null
  });
  const [duplicatedPageState, setDuplicatedPageState] = useState(() => {
    if (selectParentPageState === "duplicate") {
      return {
        ...duplicatedPage,
        id: null,
        name: duplicatedPage.name + "-COPY",
        url: duplicatedPage.url + "-COPY",
        status: "draft",
        parentId: ""
      };
    } else if (selectParentPageState === "moving") {
      return {
        ...duplicatedPage,
        parentId: ""
      };
    }
  });

  useEffect(() => {
    dispatch(fetchGetPages());
  }, []);

  const changeParentId = (parentPageId) => {
    setDuplicatedPageState({
      ...duplicatedPageState,
      parentId: parentPageId
    });
    //selecionou alguem diferente de novo item
    if (parentPageId) {
      setIsParentSelected({
        isSelected: true,
        parentId: parentPageId
      });
    }
    //selecionou novo item
    else {
      setIsParentSelected({
        isSelected: false,
        parentId: parentPageId
      });
    }
  };

  const handleSubmit = async () => {
    if (selectParentPageState === "duplicate") {
      await dispatch(createPage(duplicatedPageState)).then(
        dispatch(setDuplicateComponent(null))
      );
    } else if (selectParentPageState === "moving") {
      await dispatch(updatePage(duplicatedPageState)).then(
        dispatch(setDuplicateComponent(null))
      );
    }
  };

  return (
    <Container>
      <h4>Escolha o local da página</h4>
      <SelectWrap className="mb-2">
        <SelectContainer>
          <SelectLabel>Área do site</SelectLabel>
          <Select
            defaultValue={() => changeParentId("")}
            value={pageOptions?.name}
            onChange={(e) => changeParentId(e.target.value)}
          >
            <Option value={""}>Novo item</Option>
            {pageOptions.map((page) => {
              if (
                !page.parentId &&
                selectParentPageState === "moving" &&
                page.id !== duplicatedPageState.id
              ) {
                return (
                  <Option key={page.id} value={page.id}>
                    {page.name}
                  </Option>
                );
              }
              return null;
            })}
          </Select>
        </SelectContainer>
      </SelectWrap>
      <SelectWrap
        className="mb-2"
        style={{ display: isParentSelected.isSelected ? "block" : "none" }}
      >
        <SelectContainer>
          <SelectLabel>Área do site</SelectLabel>
          <Select
            defaultValue={isParentSelected.parentId}
            value={pageOptions?.name}
            onChange={(e) => {
              setDuplicatedPageState({
                ...duplicatedPageState,
                parentId: e.target.value
              });
            }}
          >
            <Option value={isParentSelected.parentId}>Novo item</Option>
            {pageOptions.map((page) => {
              if (
                page.parentId === isParentSelected.parentId &&
                page.id !== duplicatedPageState.id
              ) {
                return (
                  <Option key={page.id} value={page.id}>
                    {page.name}
                  </Option>
                );
              }
              return null;
            })}
          </Select>
        </SelectContainer>
      </SelectWrap>
      <Spacer className="mb-3 mt-3" />
      <CancelButton
        onClick={() => {
          dispatch(setDuplicateComponent(null));
          history.push("/cms");
        }}
      >
        Cancelar
      </CancelButton>
      <ContinueButton onClick={() => handleSubmit()}>Continuar</ContinueButton>
    </Container>
  );
};

export default PagePosition;
