import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchLocations } from "./locationService";

import regionData from "./Resources/regions.json";
import mainCitiesData from "./Resources/main-cities.json";

const locationAdapter = createEntityAdapter();

const initialState = locationAdapter.getInitialState({
  status: "",
  locations: [],
  mainCities: [],
  regions: [],
  countries: [],
  error: null
});

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    getRegions: (state) => {
      state.regions = regionData;
      state.countries = [].concat
        .apply(
          [],
          regionData.map((x) => x.countries)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    getMainCities: (state) => {
      state.mainCities = mainCitiesData;
    },
    getLocationsById: (state, { payload }) => {
      state.regions = regionData;
      state.countries = [].concat
        .apply(
          [],
          regionData.map((x) => x.countries)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  extraReducers: {
    [fetchLocations.pending]: (state) => {
      state.status = "loading";
    },
    [fetchLocations.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.locations = payload;
    },
    [fetchLocations.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    }
  }
});
export const { getRegions, getLocationsById, getMainCities } =
  locationSlice.actions;
export default locationSlice.reducer;
