import styled from "styled-components";

export const StyledOtherVehicles = styled.div`
  padding: 48px;
  h1,
  p {
    font-family: "Poppins", sans-serif;
  }
  @media (max-width: 768px) {
    padding: 30px 0px;
  }
`;
export const OtherVehiclesTitle = styled.h1`
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 17px;
    padding: 0px 0px 15px 5px;
  }
`;
export const OtherVehiclesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const OtherVehiclesImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-bottom: 12px;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: #213b73;
  margin-right: 12px;
  a {
    color: #8c4c9e !important;
    text-decoration: none;
    padding-bottom: 10px;
  }
  img {
    width: 200px;
  }
  h5 {
    font-weight: bold;
    padding-top: 10px;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
    img {
      width: 200px;
      padding: 5px 5px;
    }
  }
`;
