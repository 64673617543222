import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchPostById } from "redux/postList/postListService";
import {
  fetchCategory,
  createCategory,
  updateCategory,
  fetchSubcategory,
  createSubcategory,
  updateSubcategory,
  bulkUpdateCategory,
  bulkUpdateSubcategory,
  getPostByUrl,
  getHighlights,
} from "./blogService";
import { BlogPageType } from "./constants";

const blogAdapter = createEntityAdapter();

const initialState = blogAdapter.getInitialState({
  status: "",
  selectedSubcategory: undefined,
  filteredCategories: [],
  isFiltering: false,
  blogPageType: BlogPageType.POST_LIST,
  categoryEditData: {},
  postEditData: {},
  error: null,
  categories: [],
  subcategories: [],
  selectedCategoriesAndSubcategories: [],
  filterStatus: null,
  post: {},
  msgError: "",
  highlights: [],
});

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setFiltering: (state, { payload }) => {
      state.isFiltering = payload;
    },
    setBlogPageType: (state, { payload }) => {
      state.blogPageType = payload;
    },
    setCategoryEditData: (state, { payload }) => {
      state.categoryEditData = payload;
    },
    setCancelEdition: (state) => {
      state.status = "";
      state.categoryEditData = {};
      state.postEditData = {};
    },
    setPostEditData: (state, { payload }) => {
      state.categoryEditData = payload;
    },
    setFilteredCategories: (state, { payload }) => {
      if (payload) {
        state.isFiltering = true;
        const filteredCategories = state.categories.filter(category =>
          category.name.toLowerCase().includes(payload.toLowerCase())
        );
        const filteredSubcategories = state.subcategories.filter(subcategory =>
          subcategory.name.toLowerCase().includes(payload.toLowerCase())
        );
        state.filteredCategories = [...filteredCategories, ...filteredSubcategories];
      } else {
        state.isFiltering = false;
        state.filteredCategories = [...state.categories, ...state.subcategories];
      }
    },
    setCategoriesFilteredByStatus: (state, { payload }) => {
      state.filterStatus = payload;
      if (payload) {
        state.isFiltering = true;
        const filteredCategories = state.categories.filter(category => category.status === payload);
        const filteredSubcategories = state.subcategories.filter(subcategory => subcategory.status === payload);
        state.filteredCategories = [...filteredCategories, ...filteredSubcategories];
      } else {
        state.isFiltering = false;
        state.filteredCategories = [...state.categories, ...state.subcategories];
      }
    },
    setSelectedCategoriesAndSubcategories: (state, { payload }) => {
      const found = state.selectedCategoriesAndSubcategories.find(category => category.id === payload.id);
      if(payload === "all"){
        state.selectedCategoriesAndSubcategories = state.filteredCategories;
      } else if (payload === "none") {
        state.selectedCategoriesAndSubcategories = [];
      } else if (found !== undefined) {
        state.selectedCategoriesAndSubcategories = state.selectedCategoriesAndSubcategories.filter(category => category.id !== payload.id);
      } else {
        state.selectedCategoriesAndSubcategories.push(payload);
      }
    }
  },
  extraReducers: {
    [fetchCategory.pending]: (state) => {
      state.status = "loading";
    },
    [fetchCategory.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.categories = payload;
    },
    [fetchCategory.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [createCategory.pending]: (state) => {
      state.status = "createCategory-loading";
    },
    [createCategory.fulfilled]: (state, { payload }) => {
      state.status = "createCategory-success";
    },
    [createCategory.rejected]: (state, action) => {
      state.status = "createCategory-failed";
      state.error = action.error;
      const errors = action?.payload?.errors?.map((err) => {
        return err?.defaultMessage;
      });
      state.msgError = errors ? errors.join(" - ") : action?.payload?.msg;
    },
    [updateCategory.pending]: (state) => {
      state.status = "updateCategory-loading";
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.status = "updateCategory-success";
    },
    [updateCategory.rejected]: (state, action) => {
      state.status = "updateCategory-failed";
      state.error = action.error;
      const errors = action?.payload?.errors?.map((err) => {
        return err?.defaultMessage;
      });
      state.msgError = errors ? errors.join(" - ") : action?.payload?.msg;
    },
    [bulkUpdateCategory.pending]: (state) => {
      state.status = "bulkUpdateCategory-loading";
    },
    [bulkUpdateCategory.fulfilled]: (state, { payload }) => {
      state.status = "bulkUpdateCategory-success";
    },
    [bulkUpdateCategory.rejected]: (state, action) => {
      state.status = "bulkUpdateCategory-failed";
      state.error = action.error;
    },
    [fetchSubcategory.pending]: (state) => {
      state.status = "loading";
    },
    [fetchSubcategory.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.subcategories = payload;
    },
    [fetchSubcategory.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [createSubcategory.pending]: (state) => {
      state.status = "createSubcategory-loading";
    },
    [createSubcategory.fulfilled]: (state, { payload }) => {
      state.status = "createSubcategory-success";
    },
    [createSubcategory.rejected]: (state, action) => {
      state.status = "createSubcategory-failed";
      state.error = action.error;
      const errors = action?.payload?.errors?.map((err) => {
        return err?.defaultMessage;
      });
      state.msgError = errors ? errors.join(" - ") : action?.payload?.msg;
    },
    [updateSubcategory.pending]: (state) => {
      state.status = "updateSubcategory-loading";
    },
    [updateSubcategory.fulfilled]: (state, { payload }) => {
      state.status = "updateSubcategory-success";
    },
    [updateSubcategory.rejected]: (state, action) => {
      state.status = "updateSubcategory-failed";
      state.error = action.error;
      const errors = action?.payload?.errors?.map((err) => {
        return err?.defaultMessage;
      });
      state.msgError = errors ? errors.join(" - ") : action?.payload?.msg;
    },
    [bulkUpdateSubcategory.pending]: (state) => {
      state.status = "bulkUpdateSubcategory-loading";
    },
    [bulkUpdateSubcategory.fulfilled]: (state, { payload }) => {
      state.status = "bulkUpdateSubcategory-success";
    },
    [bulkUpdateSubcategory.rejected]: (state, action) => {
      state.status = "bulkUpdateSubcategory-failed";
      state.error = action.error;
    },
    [fetchPostById.pending]: (state) => {
      state.status = "updateSubcategory-loading";
    },
    [fetchPostById.fulfilled]: (state, { payload }) => {
      state.status = "updateSubcategory-success";
      state.categoryEditData = payload;
    },
    [fetchPostById.rejected]: (state, action) => {
      state.status = "updateSubcategory-failed";
      state.error = action.error;
    },
    [getPostByUrl.pending]: (state) => {
      state.status = "loading";
    },
    [getPostByUrl.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.post = payload;
    },
    [getPostByUrl.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [getHighlights.pending]: (state) => {
      state.status = "loading";
    },
    [getHighlights.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.highlights = payload;
    },
    [getHighlights.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
  }
});
export const { setSubcategory, setFiltering, setFilteredCategories, setCategoryEditData, setCategoriesFilteredByStatus, setSelectedCategoriesAndSubcategories } = blogSlice.actions;
export default blogSlice.reducer;
