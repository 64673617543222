import blueSemicircle from "@assets/images/bg-blue-semicircle.svg";
import leftBackgroundImage from "@assets/images/img-header-generic-left.svg";
import rightBackgroundImage from "@assets/images/img-header-generic-right.svg";
import React from "react";
import {
	ImageHeaderBackground,
	ImageHeaderText, StyledImageHeader
} from "./styles.js";

export const Banner2 = ({ properties, mode }) => {
	return (
		<>
			<StyledImageHeader >
				<ImageHeaderBackground
					theme={{ mode: mode }}
					style={{
						backgroundImage: `url(${leftBackgroundImage}), url(${blueSemicircle}) ,url(${rightBackgroundImage})`,
					}}
				>
					<ImageHeaderText theme={{ mode: mode }}>{properties[0].value}</ImageHeaderText>
				</ImageHeaderBackground>
			</StyledImageHeader>
		</>
	);
}

Banner2.defaultProps = {
	mode: "dafault"
}

export default Banner2;
