import React from "react";
import { ContentLimpezaCompletaContainer } from "./styles";

export function ContentLimpezaCompleta(props) {
    return (
        <ContentLimpezaCompletaContainer>
            <img src={props.data.image.src} alt={props.data.image.alt}/>
            <h1>{props.data.text}</h1>
        </ContentLimpezaCompletaContainer>
    )
}