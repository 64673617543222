import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";

export const StyledHeader = styled.div`
  width: 100%;
  a {
    font-family: "Poppins", "sans-serif";
    text-decoration: none;
    color: #213b73 !important;
  }
  hr {
    color: #213b73;
    opacity: 0.5;
  }
  hr:not([size]) {
    height: 2px;
  }
`;

export const TopNavbar = styled(Navbar)`
  display: flex;
  justify-content: end;
  padding: 0px;
  background-color: #e4e4e4;

  @media (max-width: 876px) {
    display: none;
  }
`;

export const TopLink = styled(Nav.Link)`
  margin-right: 2rem;
  font-weight: 400;
  font-size: 14px;

  @media (max-width: 876px) {
    padding: 12px 16px !important;
  }
`;
