import { BreadCrumbContainer, BreadCrumbHr, BreadCrumbItem } from "./styles";

export function BreadCrumb(props) {
  //separar path em array de strings
  const paths = props.data;

  //função que retorna uma lista com o caminho
  const pathList = paths.map((item, i) => {
    if (
      i === paths.length - 1 &&
      (!props.customPath || props.customPath.length === 0)
    ) {
      return (
        <BreadCrumbItem key={i} href={item.path} active>
          {props.countryName ?? item.pathName}
        </BreadCrumbItem>
      );
    } else {
      return (
        <BreadCrumbItem key={i} href={item.path}>
          {item.pathName}
        </BreadCrumbItem>
      );
    }
  });

  const customPathList = props.customPath?.map((item, i) => {
    if (i === paths.length - 1) {
      return (
        <BreadCrumbItem key={i} href={item.path} active>
          {props.countryName ?? item.pathName}
        </BreadCrumbItem>
      );
    } else {
      return (
        <BreadCrumbItem key={i} href={item.path}>
          {item.pathName}
        </BreadCrumbItem>
      );
    }
  });

  const list = () => {
    if (customPathList) {
      return [...pathList, ...customPathList];
    } else {
      return pathList;
    }
  };

  return (
    <>
      <BreadCrumbHr />
      <BreadCrumbContainer>{list()}</BreadCrumbContainer>
    </>
  );
}
