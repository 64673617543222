import { Col} from "react-bootstrap";
import styled from "styled-components";

export const LocationMainAllBackground = styled.div`
    background-color: #213B73;
    padding: 3rem;
    a{
        text-decoration: none;
    }
    #responsive{
        display: none;
    }
    #title, #subtitle, #card-title {	
        font-weight: 700;
    }
    #title {
        font-size: 26px;
    }
    #subtitle {	
        font-size: 17px;
    }
    #card-title {	
        font-size: 22px;
    }
    @media (max-width: 768px) {
        padding: 1rem;
        #title {	
            font-size: 17px;
            padding: 17px 0;
        }
        #subtitle {	
            font-size: 15px;
        }
        #card-title {	
            font-size: 17px;
        }
    }
    @media (max-width: 992px) {
        #location-card {
            padding: 10px 0px;
        }
        #responsive{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

export const LocationMainAllText = styled.p`
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
    
    @media (max-width: 576px) {
        font-size: 12px;
    }
`;

export const LocationMainAllHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: 576px) {
        flex-direction: column;
        padding: 0;
    }
    `;


export const LocationMainAllCardsTitle = styled.div`
    display: flex;
    background-color: #005DAA;
    padding: 10px;
    p{
        padding: 0px 10px;
    }
    @media (max-width: 576px) {
        padding : 7px 8px;
        margin: 0px;
    }
`;

export const LocationMainAllButtonCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
`;
