import { LinksToFAQBackground } from "./styles"

export function LinksToFAQ(props) {

    const links = props.data.links.map((link, i) =>
        <div key={i}>
            <a href={link.href}>{link.title}</a>
            <br />
        </div>
    )

    return (
        <>
            <LinksToFAQBackground>
                <p>Vá para</p>
                {links}
            </LinksToFAQBackground>
        </>
    )
}