import styled from "styled-components";
import theme from "styled-theming";
import { buttons } from "../../../../assets/theme";

export const PostListContainer = styled.div`
  font-family: "Poppins", sans-serif;
  color: #213b73;
  p {
    margin-bottom: 0;
  }
  a {
    color: #213b73;
    text-decoration: none;
  }
  button {
    background-color: transparent;
    color: #213b73;
    border: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 180px;
    width: 4px;
    background: #a6adb4;
  }
`;

export const PostsDetails = styled.div`
  display: flex;
  margin-top: 20px;
  h6 {
    margin-right: 15px;
  }
`;

export const StyledPostListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 13px 25px;
  margin-bottom: 10px;
  border-radius: 7px;
`;

export const StyledDiv = styled.div`
  min-width: 190px;
`;

const buttonAction = theme("mode", {
  normal: buttons.secondary,
  inverse: buttons.secondary_inverse,
  brandInverse: buttons.brand_inverse,
  brand: buttons.brand,
});

export const PostActionButton = styled.button`
  ${buttonAction};
  width: 200px;
  height: 60px;
  margin: 0 0 0 15px;
  color: white !important;
  background: #8b4c9e !important;
`;

export const InputWapper = styled.div`
  position: relative;
  width: 200px;
  border: 1px solid #a6adb4;
  background: #fff;
  display: flex;
  align-items: center;
  min-height: 50px;
  color: #213b73;
  font-family: "Poppins", sans-serif;
  padding: 5px 10px 5px 0;
  margin-bottom: 10px;
  div.content {
    padding-left: 10px;
    width: 200px;
  }
  p {
    margin: 0;
  }
  input,
  select {
    margin: 0;
    border: none;
    width: 100%;
    &::placeholder {
      color: transparent;
    }
    :focus {
      outline: none;
    }
  }
`;
export const GreyRetangle = styled.span`
  width: 8px;
  height: 100%;
  background-color: #ebeded;
  position: absolute;
`;

export const Checkbox = styled.div`
  min-width: 30px;
`;

export const InputWrapper = styled.div`
  color: #213b73;
  background-color: white;
  padding: 3px 10px;
  border: 1px solid #213b73;
  border-radius: 20px;
  margin: 0 10px 20px;
  input {
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #213b73;
      font-weight: bold;
    }
  }
`;

export const PostTitleDiv = styled.div`
  min-width: 200px;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AuthorDiv = styled.div`
  min-width: 200px;
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CategoryDiv = styled.div`
  min-width: 200px;
  width: 5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TagsDiv = styled.div`
  min-width: 200px;
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DateDiv = styled.div`
  min-width: 200px;
  width: 10%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ActionsDiv = styled.div`
  min-width: 50px;
  width: 5%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
`;
