import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const LocationAllBackground = styled.div`
    font-family: "Poppins", sans-serif;
    background-color: #213B73;
    padding: 3rem;
    hr{
        margin: 0;
        opacity: 1;
    }
    #title, #card-title, #subtitle{	
        font-weight: 700;
    }
    #title{
        font-size: 26px;
        @media (max-width: 576px) {
            text-align: center;
        }
    }
    #card-title{
        font-size: 20px;
    }
    #responsive{
        display: none;
    }

    @media (max-width: 760px) {
        padding: 1rem;
        #title {	
            font-weight: 700;
            font-size: 17px;
            padding: 17px 0;
        }
        #subtitle, #card-title {	
            font-weight: 700;
            font-size: 15px;
        }
    }
    @media (max-width: 992px) {
        #location-card {
            padding: 10px 0px;
        }
        #responsive{
            display: flex;
        }
    }
`;

export const LocationAllText = styled.p`
    font-family: "Poppins", sans-serif;
    color: ${(props) => props.isChanged ? "#213B73" : "#FFFFFF"};
    font-size: 17px;
    margin: 0;

    @media (max-width: 576px) {
        font-size: 12px;
    }
`;

export const LocationAllHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 576px) {
        flex-direction: column;
        padding: 0;
    }
`;

export const LocationAllCardsTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color:  ${(props) => props.isChanged ? "#FFFFFF" : "#005DAA"};
    padding: 20px 30px ;
    @media (max-width: 576px) {
        padding : 7px 8px;
        margin: 0px;
    }
`;

export const LocationAllCardsImage = styled.img`
    width: 100%;
`;

export const LocationAllCardsRow = styled(Row)`
    margin-bottom: 15px;
    @media (max-width: 576px) {
        margin-bottom: 0px;
    }
`;

export const LocationAllButtonCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    @media (max-width: 760px) {
    }
`;

export const LocationAllButton= styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    p{
        font-size: 17px;
        font-weight: 700;
        color: ${(props) => props.isChanged ? "#8C4C9E" : "#FEF100"};
    }
    img{
        transform: ${(props) => props.isChanged ? 'rotate(-90deg)' : 'rotate(90deg)'};
        padding: ${(props) => props.isChanged ? '10px 0 0 0' : '0 0 10px 0'};
        filter: ${(props) => props.isChanged ? 'invert(50%)' : 'none'};
    }

    @media(max-width: 576px){
        p{
            font-size: 12px;
        }
        img{
            height: 20px;
        }
    }

`;