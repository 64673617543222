import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "@api/api";
import getAxios from "api/api";

export const fetchLocations = createAsyncThunk(
  "location/fetchLocations",
  async () => {
    const response = await getAxios().get("/location");
    return response.data;
  }
);
