import { setSelectedLinkType } from "@redux/cms/cmsSlice";
import { Text } from "components/Global/Texts/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageTypes } from "redux/cms/constants";
import Accordion from "../Accordion";
import AccordionBody from "../Accordion/AccordionBody";
import AccordionHeader from "../Accordion/AccordionHeader";
import AccordionItem from "../Accordion/AccordionItem";
import BlogConfig from "./components/BlogConfig";
import PageType from "./components/PageType";
import SiteMap from "./components/SiteMap";
import TemplateEdition, {
  textAreaAutoResize,
} from "./components/TemplateEdition";
import { GreyRetangle, InputWapper } from "./components/TemplateEdition/styles";
import Templates from "./components/Templates";
import {
  Container,
  DisabledSpan,
  PageConfigButton,
  PageConfigContainer,
  RadioButtonSelectMenu,
  Spacer,
} from "./styles";

const SetupPage = () => {
  const history = useHistory();
  const isPageCreation = useSelector((state) => state?.cms?.isPageCreation);
  const templateEditionMode = useSelector((state) => state?.cms?.templatePage);
  const pageType = useSelector((state) => state?.cms?.pageType);
  const headerEditMode = useSelector((state) => state?.cms?.isHeadersEditing);
  const footerEditMode = useSelector((state) => state?.cms?.isFooterEditing);
  const selectedLink = useSelector((state) => state?.cms?.selectedLink);
  const dispatch = useDispatch();
  const handleSelectedLinkType = (value) => {
    dispatch(setSelectedLinkType(value));
  };
  var EditMode = headerEditMode || footerEditMode;
  return (
    <Container>
      {templateEditionMode?.index > -1 ? (
        <TemplateEdition />
      ) : (
        <>
          <PageType />
          {pageType === PageTypes.WEB_PAGE ? (
            <>
              {EditMode && (
                <div>
                  <Spacer />
                  <RadioButtonSelectMenu>
                    <Text>Inserir um link externo</Text>
                    <input
                      type="radio"
                      value="externalLinks"
                      checked={selectedLink.type === "externalLinks"}
                      onChange={() =>
                        handleSelectedLinkType({ type: "externalLinks" })
                      }
                      name="externalLinks"
                    />
                  </RadioButtonSelectMenu>
                  <InputWapper className="mr10">
                    <GreyRetangle />
                    <div className="content">
                      {selectedLink.type !== "externalLinks" ? (
                        <DisabledSpan>Desabilitado</DisabledSpan>
                      ) : (
                        <span>Link</span>
                      )}
                      <textarea
                        value={selectedLink.url || ""}
                        onChange={(e) =>
                          handleSelectedLinkType({ url: e.target.value })
                        }
                        placeholder="Insira o link"
                        onInput={textAreaAutoResize}
                        onFocus={textAreaAutoResize}
                        disabled={selectedLink.type !== "externalLinks"}
                        autoFocus
                      />
                    </div>
                  </InputWapper>
                  <InputWapper className="mr10">
                    <GreyRetangle />
                    <div className="content">
                      {selectedLink.type !== "externalLinks" ? (
                        <DisabledSpan>Desabilitado</DisabledSpan>
                      ) : (
                        <span>Link Text</span>
                      )}
                      <textarea
                        value={selectedLink.name || ""}
                        onChange={(e) =>
                          handleSelectedLinkType({ name: e.target.value })
                        }
                        placeholder="Insira o nome do link"
                        onInput={textAreaAutoResize}
                        onFocus={textAreaAutoResize}
                        disabled={selectedLink.type !== "externalLinks"}
                        autoFocus
                      />
                    </div>
                  </InputWapper>
                  <Spacer />
                </div>
              )}
              {EditMode ? (
                <>
                  <RadioButtonSelectMenu>
                    <Text>Inserir um link interno</Text>
                    <input
                      type="radio"
                      value="internalLinks"
                      checked={selectedLink.type === "internalLinks"}
                      onChange={() =>
                        handleSelectedLinkType({ type: "internalLinks" })
                      }
                      name="internalLinks"
                    />
                  </RadioButtonSelectMenu>
                  <SiteMap />
                </>
              ) : (
                <Accordion
                  keepOtherOpen={true}
                  selected={isPageCreation ? `item-template` : `item-site-map`}
                >
                  <AccordionItem>
                    <AccordionHeader
                      hasTransparentBackground
                      name={`item-site-map`}
                      title={headerEditMode ? "Links internos" : "Mapa do Site"}
                    />
                    <AccordionBody name={`item-site-map`}>
                      <SiteMap />
                    </AccordionBody>
                  </AccordionItem>
                  {isPageCreation && (
                    <>
                      <Spacer />
                      <AccordionItem>
                        <AccordionHeader
                          hasTransparentBackground
                          name={`item-template`}
                          title="Templates"
                        />
                        <AccordionBody name={`item-template`}>
                          <Templates />
                        </AccordionBody>
                      </AccordionItem>
                    </>
                  )}
                </Accordion>
              )}
              <Spacer />
              <PageConfigContainer>
                <PageConfigButton
                  theme={{ mode: "inverse" }}
                  onClick={() => history.push("/cms/setup-header")}
                >
                  Editar Cabeçalho
                </PageConfigButton>
                <PageConfigButton
                  theme={{ mode: "inverse" }}
                  onClick={() => history.push("/cms/setup-footer")}
                >
                  Editar Rodapé
                </PageConfigButton>
              </PageConfigContainer>
            </>
          ) : (
            <BlogConfig />
          )}
        </>
      )}
    </Container>
  );
};

export default SetupPage;
