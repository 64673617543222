import styled from "styled-components";
import theme from "styled-theming";


export const InputWrapper = styled.div`
  color: #213b73;
  background-color: white;
  //width: 100%;
  padding: 3px 10px;
  border: 1px solid #213b73;
  border-radius: 20px;
  margin: 0 10px 20px;
  input {
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #213b73;
      font-weight: bold;
    }
  }
`;

const linkConfig = theme("mode", {

});

export const ContainerList = styled.div`
  font-family: Poppins;
  font-size: 14px;
  color: #213b73;
`;

export const ItemCheckbox = styled.input`
  color: inherit;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  max-height: 21px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemSelect = styled.a`
  ${linkConfig};
  width: 100%;
  color: inherit;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  max-height: 21px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  background: #fff;
  min-height: 100%;
  padding: 3px 0 15px 0;
`;

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  color: #213b73;
`;

export const Description = styled.p`
  font-family: Poppins;
  font-size: 14px;
  color: #213b73;
`;

export const Spacer = styled.hr`
  margin: 0 10px;
  background-color: #213b73;
`;
export const Checkbox = styled.input`
  margin-right: 5px;
  margin-top: 3px;
`;

export const IconContainer = styled.div`
  background-color: #005ca9;
  display: flex;
  border-radius: 3px;
  padding: 8px;
  height: 46px;
  width: 46px;
  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;

export const SelectedIcon = styled.img`
  display: flex;
  fill: white;
  width: 16px;
  height: 16px;
`;
