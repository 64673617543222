import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "@api/api";
import getAxios from "api/api";

export const fetchGetPosts = createAsyncThunk(
  "post/fetchGetPosts",
  async (status) => {
    const response = await getAxios().get(
      "/blog/all" + (status !== undefined ? `?status=${status}` : "")
    );
    return response.data;
  }
);
export const fetchPostById = createAsyncThunk(
  "post/fetchPostById",
  async (id) => {
    const response = await getAxios().get(`/blog/${id}`);
    return response.data;
  }
);

export const deletePost = createAsyncThunk(
  "page/deletePost",
  async (id, thunkAPI) => {
    try {
      const response = await getAxios().delete(`/blog/delete/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        let newResponse = {
          status: error.response.status,
        };
        return thunkAPI.rejectWithValue(newResponse);
      } else {
        throw error;
      }
    }
  }
);

export const createPost = createAsyncThunk(
  "post/createPost",
  async (payload, thunkAPI) => {
    try {
      const response = await getAxios().post("/blog", payload);
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response);
      } else {
        throw error;
      }
    }
  }
);
export const updatePost = createAsyncThunk(
  "post/updatePost",
  async (payload, thunkAPI) => {
    try {
      const response = await getAxios().put(
        `/blog/update/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      if (error.response) {
        let newResponse = {
          status: error.response.status,
        };
        return thunkAPI.rejectWithValue(newResponse);
      } else {
        throw error;
      }
    }
  }
);

export const updateStatusPost = createAsyncThunk(
  "post/updateStatusPost",
  async (payload) => {
    const response = await getAxios().put(
      `/blog/update/${payload.id}`,
      payload
    );
    return response.data;
  }
);

export const updateStatusPosts = createAsyncThunk(
  "post/updateStatusPosts",
  async (bulkUpdate) => {
    const response = await getAxios().patch("/blog", bulkUpdate);
    return response.data;
  }
);

export const getLastFivePosts = createAsyncThunk(
  "blog/getLastFivePosts",
  async () => {
    try {
      const response = await getAxios().get("/blog/last-five-posts");
      return response.data;
    } catch (err) {
      return err;
    }
  }
);
