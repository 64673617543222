import styled from "styled-components";

export const LoginFieldBackground = styled.div`
    background-color: #EBEDED;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #213B73;
    height: calc(100vh - 66px);
    padding: 0 25%;


    @media(max-width: 768px) {
        padding: 0 10%;
    }

    @media(min-width: 2561px){
        padding: 0 32%;
    }

    button{
        background-color: #8C4C9E;
        border: none;
        color: white;
        height: 45px;
        font-weight: bold;
        margin-top: 5px;
        text-align: center;
        padding: 0;
    }

    input{
        border: 1px solid #A6ADB4;
        margin: 5px 0;
        height: 45px;
        padding: 0 10px;
        &::placeholder{
            color: #213B73;
        }
        &:focus{
            outline: none;
        }
    }
`;

export const LoginForm = styled.div`
    width:100%;
    max-width: 1280px;
`;