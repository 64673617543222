import React from "react";

import { CustomMarkerTarget, PlaneIcon, CityIcon } from "./styles";

const CustomMarker = ({ text, tooltip, airportIndicator }) => {
    return (
        <CustomMarkerTarget>
            <span className="markText" title={tooltip}>
                {text}
            </span>
            <span className="markIcon">
                <h6>{text}</h6>
                <div className="icone">
                    {airportIndicator === "Y" ? (
                        <PlaneIcon />
                    ) : (
                        <CityIcon />
                    )}{" "}
                </div>
            </span>
        </CustomMarkerTarget>
    );
};

export default CustomMarker;