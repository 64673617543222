import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StyledButton } from "../Button/styles";
import brasilMap from "../../assets/images/img-brasil-map.svg";
import worldMap from "../../assets/images/img-world-map.svg";
import enterArrowIcon from "../../assets/images/ic-arrow-enter.svg";
import { LocationMainAllBackground, LocationMainAllText, LocationMainAllHeader, LocationMainAllButtonCol, LocationMainAllCardsTitle} from "./styles";


export function LocationMainAll(props) {
    return <LocationMainAllBackground>
        <Container>
            <Row>
                <LocationMainAllHeader>
                    <Col xl={10} lg={9} md={8} sm={6} xs={12}>
                        <LocationMainAllText id="title">Agências de aluguel de carros</LocationMainAllText>
                    </Col>
                    <LocationMainAllButtonCol xl={2} lg={3} md={4} sm={6} xs={12}>
                        <StyledButton onClick={() => window.location.href = "/reservas"} color="#8C4C9E">
                            <LocationMainAllText id="subtitle">Fazer uma reserva</LocationMainAllText>
                        </StyledButton>
                    </LocationMainAllButtonCol>
                </LocationMainAllHeader>
            </Row>
            <Row>
                <Col lg={6} xs={12} id="location-card">
                    <a href="/agencias/pais/BR" alt="Brasil"> 
                        <img fluid src={brasilMap} alt="Ver agências de aluguel no Brasil" />
                        <LocationMainAllCardsTitle>
                            <Col lg={12} xs={11}>
                                <LocationMainAllText id="card-title">Brasil</LocationMainAllText>
                                <LocationMainAllText>156 cidades</LocationMainAllText>
                            </Col>
                            <Col id="responsive" xs={1}>
                                <img src={enterArrowIcon} alt="" />
                            </Col>
                        </LocationMainAllCardsTitle>
                    </a>
                </Col>
                <Col lg={6} xs={12} id="location-card">
                    <a href="/agencias/todas/" alt="Resto do mundo"> 
                        <img fluid src={worldMap} alt="Ver agências de aluguel no Resto do mundo" />
                        <LocationMainAllCardsTitle>
                            <Col lg={12} xs={11}>
                                <LocationMainAllText id="card-title">Resto do mundo</LocationMainAllText>
                                <LocationMainAllText>83 países</LocationMainAllText>
                            </Col>
                            <Col id="responsive" xs={1}>
                                <img src={enterArrowIcon} alt="" />
                            </Col>
                        </LocationMainAllCardsTitle>
                    </a>
                </Col>
            </Row>
        </Container>
    </LocationMainAllBackground>
}