import Axios from "axios";
const token = localStorage.getItem("token");
// export const Api = Axios.create({
//   baseURL: `${process.env.REACT_APP_BASE_URL}`,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   },
// });

export function getAxios() {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export default getAxios;
