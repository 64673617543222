import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { blogSlice } from "redux/blog/blogSlice";
import { BlogPageType, BlogStatus } from "redux/blog/constants";
import { postListSlice } from "redux/postList/postListSlice";
import CategoryEdition from "../CategoryEdition";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import {
  createPost,
  fetchGetPosts,
  updatePost,
} from "redux/postList/postListService";
import {
  ListButton,
  PageConfigButton,
  PageConfigContainer,
  Spacer,
} from "./styles";
import SearchPostList from "../SearchPostList";

const BlogConfig = () => {
  const { setBlogPageType, setCancelEdition } = blogSlice.actions;
  const { clearPostData } = postListSlice.actions;
  const dispatch = useDispatch();
  const history = useHistory();

  const [status, setStatus] = useState();
  const blogPageType = useSelector((state) => state?.blog?.blogPageType);

  const categoryEditData = useSelector(
    (state) => state?.blog?.categoryEditData
  );
  const postState = useSelector((state) => state?.postList?.status);
  const postErrorCode = useSelector((state) => state?.postList?.errorCode);
  useEffect(() => {
    let message;
    let type;
    switch (postState) {
      case "updatePost-failed":
      case "createPost-failed":
        message =
          (status === BlogStatus.DRAFT
            ? "Erro ao salvar o Artigo"
            : "Erro ao publicar o Artigo") +
          (postErrorCode === 409 ? ": URL já utilizada por outro artigo" : "");
        type = "error";
        break;
      case "updatePost-success":
      case "createPost-success":
        message =
          status === BlogStatus.DRAFT
            ? "Artigo salvo com sucesso"
            : "Artigo publicado com sucesso";
        type = "success";
        dispatch(fetchGetPosts());
        break;

      default:
        return;
    }
    if (message) {
      dispatch(displaySnackbar({ message, type }));
    }
  }, [postState]);

  const handlePostSubmit = (status) => {
    setStatus(status);

    const post = { ...categoryEditData, status: status };
    if (!post.category) {
      dispatch(
        displaySnackbar({
          message: "Campo obrigatorio 'Categoria' não informado",
          type: "error",
        })
      );
    } else if (!post.title || post.title.trim().length === 0) {
      dispatch(
        displaySnackbar({
          message: "Campo obrigatorio 'Título do Post' não informado",
          type: "error",
        })
      );
    } else if (!post.url || post.url.trim().length === 0) {
      dispatch(
        displaySnackbar({
          message: "Campo obrigatorio 'URL' não informado",
          type: "error",
        })
      );
    } else if (!post.description || post.description.trim().length === 0) {
      dispatch(
        displaySnackbar({
          message: "Campo obrigatorio 'Descrição' não informado",
          type: "error",
        })
      );
    } else if (!post.image) {
      dispatch(
        displaySnackbar({
          message: "Campo obrigatorio 'Imagem de destaque' não informado",
          type: "error",
        })
      );
    } else if (post.typeImage !== ".jpeg" && post.typeImage !== ".png") {
      dispatch(
        displaySnackbar({
          message:
            "Formato de imagem não compatível, insira no formato .jpeg ou .jpg",
          type: "error",
        })
      );
    } else if (
      !post.imageDescription ||
      post.imageDescription.trim().length === 0
    ) {
      dispatch(
        displaySnackbar({
          message: "Campo obrigatorio 'Nome da página' não informado",
          type: "error",
        })
      );
    } else if (post.content === "" || post.content === "<p><br></p>") {
      dispatch(
        displaySnackbar({
          message: "Campo obrigatorio 'Escreva aqui seu texto' não informado",
          type: "error",
        })
      );
    } else if (post?.id) {
      dispatch(updatePost(post));
    } else {
      dispatch(createPost(post));
    }
  };
  return (
    <>
      {blogPageType === BlogPageType.POST_PAGE && (
        <>
          <PageConfigContainer>
            <PageConfigButton
              theme={{ mode: "inverse" }}
              onClick={() => handlePostSubmit(BlogStatus.DRAFT)}
            >
              Salvar Rascunho
            </PageConfigButton>
            <PageConfigButton
              theme={{ mode: "normal" }}
              onClick={() => handlePostSubmit(BlogStatus.PUBLISHED)}
            >
              Publicar Artigo
            </PageConfigButton>
          </PageConfigContainer>
          <Spacer />
        </>
      )}
      {blogPageType === BlogPageType.POST_LIST && (
        <PageConfigContainer>
          <PageConfigButton
            theme={{ mode: "normal" }}
            onClick={() => {
              dispatch(clearPostData());
              history.push("/cms/post-page");
            }}
          >
            Criar nova publicação
          </PageConfigButton>
        </PageConfigContainer>
      )}
      <Spacer />
      {blogPageType === BlogPageType.POST_LIST && (
        <PageConfigContainer>
          <ListButton
            theme={{
              mode:
                blogPageType === BlogPageType.POST_LIST
                  ? "brand"
                  : "brandInverse",
            }}
            onClick={() => {
              dispatch(setCancelEdition());
              history.push("/cms/post-list");
            }}
          >
            Lista de Artigos
          </ListButton>
          <ListButton
            theme={{
              mode:
                blogPageType === BlogPageType.CATEGORY_LIST
                  ? "brand"
                  : "brandInverse",
            }}
            onClick={() => {
              dispatch(setCancelEdition());
              history.push("/cms/category-list");
            }}
          >
            Lista de Categorias
          </ListButton>
        </PageConfigContainer>
      )}
      {blogPageType === BlogPageType.CATEGORY_LIST && (
        <>
          <PageConfigContainer>
            <PageConfigButton
              theme={{ mode: "normal" }}
              onClick={() =>
                dispatch(setBlogPageType(BlogPageType.ADD_CATEGORY))
              }
            >
              Adicionar categoria
            </PageConfigButton>
            <PageConfigButton
              theme={{ mode: "normal" }}
              onClick={() =>
                dispatch(setBlogPageType(BlogPageType.ADD_SUBCATEGORY))
              }
            >
              Adicionar sub-categoria
            </PageConfigButton>
          </PageConfigContainer>
          <Spacer />
        </>
      )}
      {blogPageType === BlogPageType.POST_LIST && (
        <>
          <PageConfigContainer>
            <PageConfigButton
              theme={{ mode: "inverse" }}
              onClick={() => {
                history.push("/cms/featured-page");
              }}
            >
              Destaque no Blog
            </PageConfigButton>
          </PageConfigContainer>
        </>
      )}
      {blogPageType === BlogPageType.FEATURED_PAGE && (
        <>
          <SearchPostList />
        </>
      )}
      {(blogPageType === BlogPageType.EDIT_CATEGORY ||
        blogPageType === BlogPageType.EDIT_SUBCATEGORY ||
        blogPageType === BlogPageType.ADD_CATEGORY ||
        blogPageType === BlogPageType.ADD_SUBCATEGORY) && <CategoryEdition />}
    </>
  );
};

export default BlogConfig;
