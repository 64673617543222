import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

export const Background = styled.div`
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.colorText};
    width: 100%;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 30px;
`; 

export const ThreeCardsWithTopImageContainer = styled(Container)`
    display: flex;
    justify-content: center;
    width: 65%;
    height: 55%;
    padding-top: 30px;
`;

export const Divisor = styled.hr`
    background-color: ${(props) => props.color};
    color: white;
`;

export const ThreeCards = styled(Row)`
    display: flex;
    justify-content: space-evenly;
`;

export const ThreeCardsWithTopImageText = styled(Row)`
    padding-top: 50px;
    @media(max-width: 992px){
        text-align: center;
    }
`;

export const ThreeCardsWithTopImageTextTextCol = styled(Col)`
    display: flex;
    align-items: initial;
    @media(max-width: 992px){
        justify-content: center;
    }
`;

export const ThreeCardsWithTopImageTextText1 = styled.h3`
    font-weight: bold;
    color: ${(props) => props.color};
`;

export const ThreeCardsWithTopImageTextText2 = styled.a`
    @media(max-width: 992px){
        justify-content: center;
    }
    color: ${(props) => props.color};
    text-decoration: none;
    font-size: 18px;
`;