import React from "react";
import { Background, ThreeCardsWithTopImageContainer, ThreeCards, Divisor, ThreeCardsWithTopImageTextText1, ThreeCardsWithTopImageTextText2, ThreeCardsWithTopImageText, ThreeCardsWithTopImageTextTextCol } from "./styles";
import { Col, Container, Image } from "react-bootstrap";
import { Card } from "../Card/index";

export function ThreeCardsWithTopImage(props) {
    return (
        <Background backgroundColor={props.data.css.backgroundColor} color={props.data.css.color}>
            <Container>
                {props.data.image &&
                    <ThreeCardsWithTopImageContainer>
                        <Image src={props.data.image} fluid/>
                    </ThreeCardsWithTopImageContainer>
                } 
                <div>
                    <ThreeCardsWithTopImageText>
                        <ThreeCardsWithTopImageTextTextCol lg={9}>
                            <ThreeCardsWithTopImageTextText1 color={props.data.css.colorText1}>{props.data.text}</ThreeCardsWithTopImageTextText1>
                        </ThreeCardsWithTopImageTextTextCol>
                        { props.data.link &&
                            <ThreeCardsWithTopImageTextTextCol lg={3}>
                            <ThreeCardsWithTopImageTextText2 href={props.data.link.href} color={props.data.css.colorText2}>{props.data.link.text}</ThreeCardsWithTopImageTextText2>
                        </ThreeCardsWithTopImageTextTextCol>
                        }
                    </ThreeCardsWithTopImageText>
                </div>
                <Divisor color={props.data.css.colorText1}/>
                <div>
                    <ThreeCards>
                        <Col lg={4}>
                            <Card data={props.data.cards[0]}/>
                        </Col>
                        <Col lg={4}>
                            <Card data={props.data.cards[1]}/>
                        </Col>
                        <Col lg={4}>
                            <Card data={props.data.cards[2]}/>
                        </Col>
                    </ThreeCards>
                </div>
            </Container>
        </Background>
    )
}