import styled from "styled-components";

export const WrongTurnWarningContainer = styled.div`
    background-color: #005DAA;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 768px) {
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
`;
export const WrongTurnWarningTextBody = styled.div`
    p{
        color: #fff;
    }
    a{
        color: #FEF100;
    }
`;

export const WrongTurnWarningImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
    img{
        height: 360px;
    }
    @media (max-width: 992px) {
        img{
            height: 200px;
        }
    }
    @media (max-width: 768px) {
        display: none;
    }

`;

