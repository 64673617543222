import { ContactContainer } from '../Contact/styles';
import { Header } from "../../components/Header";
import { Footer} from "../../components/Footer";
import footerData from "@components/Footer/Resources/example.json";
import headerData from "../Contact/Resources/example.json";

export function Subsidiaries(){
    return(
        <>
            <Header data={headerData} />
            <ContactContainer className='text-center'>
                <h1>Subsidiárias</h1>
                <p>Enterprise Leasing Company of STL, LLC</p>
                <p>Enterprise Leasing Company of Georgia, LLC</p>
                <p>Enterprise Leasing Company of Florida, LLC</p>
                <p>Enterprise Leasing Company of KS, LLC</p>
                <p>EAN Holdings, LLC</p>
                <p>Enterprise Leasing Company of Orlando, LLC</p>
                <p>Enterprise Leasing Company of Indianapolis, LLC</p>
                <p>Enterprise Rent-A-Car Company of Boston, LLC</p>
                <p>Enterprise Leasing Company of Denver, LLC</p>
                <p>Enterprise Leasing Company of Chicago, LLC</p>
                <p>Enterprise RAC Company of Maryland, LLC</p>
                <p>Enterprise Leasing Company of Philadelphia, LLC</p>
                <p>Enterprise RAC Company of Baltimore, LLC</p>
                <p>Enterprise Leasing Company of Minnesota, LLC</p>
                <p>Enterprise Leasing Company of Detroit, LLC</p>
                <p>Enterprise Leasing Co of Norfolk/Richmond, LLC</p>
                <p>Enterprise Rent-A-Car Co of San Francisco, LLC</p>
                <p>ELRAC, LLC</p>
                <p>SNORAC, LLC</p>
                <p>Enterprise Rent-A-Car Company of Sacramento, LLC</p>
                <p>Enterprise Rent-A-Car Company of Los Angeles, LLC</p>
                <p>CLERAC, LLC</p>
                <p>Enterprise Rent-A-Car Company of Pittsburgh, LLC</p>
                <p>Enterprise Rent-A-Car Company of Wisconsin, LLC</p>
                <p>Enterprise Rent-A-Car Company of UT, LLC</p>
                <p>CAMRAC, LLC</p>
                <p>Enterprise Leasing Company of Phoenix, LLC</p>
                <p>Enterprise Leasing Company - Southeast, LLC</p>
                <p>Enterprise Leasing Company - West, LLC</p>
                <p>Enterprise Leasing Company - South Central, LLC</p>
                <p>PENRAC, LLC</p>
                <p>Enterprise Rent-A-Car Company - Midwest, LLC</p>
                <p>Enterprise RAC Company of Montana/Wyoming, LLC</p>
                <p>PRERAC, Inc</p>
                <p>Enterprise Rent-A-Car Canada Company</p>
                <p>Enterprise Rent-A-Car UK Limited</p>
                <p>Enterprise Autovermietung Deutschland B.V. & Co. KG</p>
                <p>ERAC Ireland Limited</p>
                <p>Enterprise Holdings France S.A.S.</p>
                <p>Autotransporte Turistico Espanol S.A</p>
            </ContactContainer>
            <Footer data={footerData} />
        </>
    )
}