import React from "react";
import MetaTagEditor from "@cms/components/MetaTagEditor";
import {
  InputWapper,
  GreyRetangle,
  CopyIcon,
  ContentDiscription,
  ContentRow,
  SearchIcon,
  InvalidInputWapper,
  ErrorMessage
} from "./styles";

import { useDispatch, useSelector } from "react-redux";

import { setPageName, setPageTags, setPageUrl } from "@redux/cms/cmsSlice";
import { isUrlValidated } from "./urlValidation";
import { useState } from "react";

const PageSEO = () => {
  const dispatch = useDispatch();

  const canvasPageState = useSelector((state) => state?.cms?.canvasPage);
  const handleNameChange = (value) => dispatch(setPageName(value.target.value));
  const [isUrlInvalid, setIsUrlInvalid] = useState(false);
  const handleUrlChange = (value) => {
    if (!isUrlValidated(value.target.value)) {
      setIsUrlInvalid(true);
    }else{
      setIsUrlInvalid(false);
    }
    dispatch(setPageUrl(value.target.value));
  }
  const handleTagsChange = (value) => dispatch(setPageTags(value));
  return (
    <ContentDiscription>
      <ContentRow>
        <InputWapper className="mr10">
          <GreyRetangle />
          <input
            value={canvasPageState?.name || ""}
            onChange={handleNameChange}
            placeholder="Nome da Pagina"
          />
          <SearchIcon />
        </InputWapper>
        <MetaTagEditor
          tags={canvasPageState?.metaTags || []}
          onTagChange={handleTagsChange}
        />
      </ContentRow>
      <InvalidInputWapper>
        <InputWapper className={isUrlInvalid ? "input-invalid" : ""}>
          <GreyRetangle className={isUrlInvalid ? "retangle-invalid" : ""} />
          <p className="mx-2">URL</p>
          <input
            value={canvasPageState?.url}
            onChange={handleUrlChange}
            placeholder="url/pagina"
          />
          <CopyIcon />
        </InputWapper>
      </InvalidInputWapper>
      {isUrlInvalid && <ErrorMessage>Somente são aceitos os caracteres A-Z a-z, os números 0-9, e os símbolos - _ e /</ErrorMessage>}
    </ContentDiscription>
  );
};

export default PageSEO;
