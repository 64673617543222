import Footer from "@cms-common/Footer";
import Header from "@cms-common/Header";
import HeaderNav from "@cms-common/HeaderNav";
import { ComponentMapping } from "@cms-common/Resources";
import { LoaderComponent } from "components/Loader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { fetchFooter, fetchHeaders } from "redux/cms/cmsService";
import { fetchGetPageByUrl } from "redux/page/pageService";

const CMSEngine = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const pageLoader = useSelector((state) => state?.page);
  const headerPageState = useSelector((state) => state?.cms?.headerPage);
  const footerPageState = useSelector((state) => state?.cms?.footerPage);
  const pageUrl = window.location.pathname;

  useEffect(() => {
    dispatch(fetchGetPageByUrl(pageUrl));
    dispatch(fetchHeaders());
    dispatch(fetchFooter());
  }, []);
  useEffect(() => {
    setPage(pageLoader.cmsPage);
  }, [pageLoader.status]);
  return (
    <>
      {pageLoader.status === "loading" && <LoaderComponent />}
      {pageLoader.status === "failed" && <Redirect to="/page-not-found" />}
      <PageEngine
        data={{
          page,
          topMenu: headerPageState?.topMenu,
          navMenu: headerPageState?.navMenu,
          logo: headerPageState?.image,
          footer: footerPageState?.section,
        }}
      />
    </>
  );
};

const PageEngine = ({ data }) => {
  const { page, topMenu, navMenu, logo, footer } = data;
  return (
    <div>
      <Helmet>
        <title>{`Alamo - ${page?.name}`}</title>
        <meta name="keywords" content={page?.metaTags?.join(",")} />
      </Helmet>
      <Header data={topMenu} />
      <HeaderNav data={navMenu} logo={logo} />
      {page?.components?.map((item, index) => {
        if (item) {
          const Component = ComponentMapping[item.name];
          return (
            <Component
              mode="default"
              variant={item.variant}
              key={`${item.name}_${index}`}
              {...item}
            />
          );
        }
        return null;
      })}
      <Footer data={footer} />
    </div>
  );
};

export default CMSEngine;
