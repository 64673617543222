import styled from "styled-components";
import theme from "styled-theming";
import { buttons, palettes } from "assets/theme";

const buttonType = theme("mode", {
  enabled: buttons.primary,
  disabled: buttons.disabled
});
const buttonConfig = theme("mode", {
  normal: buttons.secondary,
  inverse: buttons.secondary_inverse,
  primaryInverse: buttons.primary_inverse
});

export const Container = styled.div`
  background: ${palettes.white};
  padding: 13px 0px 25px;
`;

export const PageTypeContainer = styled.div``;
export const PageTypeButton = styled.button`
  ${buttonType};
  width: 50%;
  height: 50px;
  cursor: pointer;
`;

export const PageConfigContainer = styled.div`
  margin-top: 20px;
`;
export const PageConfigButton = styled.button`
  ${buttonConfig};
  width: 100%;
  height: 50px;
  margin: 5px 0;
`;
