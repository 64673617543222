import styled from "styled-components";
import { ReactComponent as Copy } from "@assets/images/cms/ic-copy-url.svg";
import { ReactComponent as Search } from "@assets/images/cms/ic-search.svg";
import { palettes } from "assets/theme";

export const UserCreationContainer = styled.div`
  background-color: #dddee7;
  padding: 20px 3rem;
  color: #213b73;
  height: calc(100vh - 66px);
  font-family: Poppins, sans-serif;
  button {
    width: 200px;
    height: 40px;
    font-weight: bold;
    margin-left: 15px;
  }
  #cancel-button {
    background-color: white;
    color: #8c4c9e;
    border: 1px solid #8c4c9e;
  }
  #save-button {
    background-color: #8c4c9e;
    color: white;
    border: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.background};
  margin-bottom: 15px;
  padding: 1px 1px 0 15px ;
  border: 1px solid #a6adb4;
  label {
    font-size: 0.8rem;
  }
  input,
  select {
    border: none;
    outline: 0;
    color: #213b73;
    font-family: Poppins, sans-serif;
    :focus {
      outline: none;
    }
  }
`;
export const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  align-items: start;
  background-color: ${palettes.tints_red};
  border: 1px solid ${palettes.tints_red};
  color: ${palettes.white};
`;
export const LargMax = styled.div`
display: flex;
margin: auto;
width:100%;
max-width:1280px;
flex-direction: column;
`;
