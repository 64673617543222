import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTemplates } from "redux/cms/cmsSlice";
import { ComponentMapping } from "./common/Resources";
import {
  TagFilterContainer,
  TagFilter,
  TemplatesContainer,
  TemplatesArea,
  ComponentDivisor,
} from "./styles";
import { Droppable, Draggable } from "react-beautiful-dnd";

const Templates = () => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.cms.templates);

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  const types = [
    "Todos",
    "Ícone",
    "Subtítulo",
    "Texto",
    "Link",
    "Duas colunas",
    "Três colunas",
    "Widget",
    "Cards",
    "Lista",
    "Call to action",
  ];
  const [active, setActive] = useState(types[0]);
  const [templatesFiltered, setTemplatesFiltered] = useState([]);

  const handleFilter = (type) => {
    if (type === "Todos") {
      setTemplatesFiltered(templates);
    } else {
      const filtereds = templates.filter((template) => {
        return template.categories.find((category) => category.name === type);
      });
      setTemplatesFiltered(filtereds);
    }
    setActive(type);
  };

  useEffect(() => {
    handleFilter(types[0]);
  }, [templates]);

  return (
    <TemplatesContainer>
      <p>
        Encontre o template que precisa e arraste ele para um bloco no modelo de
        página à direita.
      </p>
      <TagFilterContainer>
        {types.map((type) => (
          <TagFilter
            key={type}
            active={active === type}
            style={{
              backgroundColor: active === type ? "#8C4C9E" : "#72ACFF33",
              color: active === type ? "#fff" : "#213B73",
              fontWeight: active === type ? "bold" : "normal",
            }}
            onClick={() => handleFilter(type)}
          >
            {type}
          </TagFilter>
        ))}
      </TagFilterContainer>
      <TemplatesArea>
        <Droppable droppableId="template">
          {(provided) => (
            <div
              className="template"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {templatesFiltered?.map((template, index) => {
                const Component = ComponentMapping[template.name];
                return (
                  <Draggable
                    draggableId={template.name}
                    index={index}
                    key={template.name}
                  >
                    {(provided) => (
                      <div
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div>
                          <p>
                            <b>{template.displayName}</b>
                          </p>
                          <Component
                            mode="template"
                            variant={template.variant}
                            key={template.name}
                            isFromCMS
                            {...template}
                          />
                          <ComponentDivisor />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </TemplatesArea>
    </TemplatesContainer>
  );
};

export default Templates;
