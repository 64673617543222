import { WrongTurnWarning } from "components/WrongTurnWarning";
import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React from "react";
import { BreadCrumb } from "../../components/BreadCrumb";

export function PageNotFound(props) {

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - Página não encontrada");
    }, []);

    const components =
        <>
            <BreadCrumb data={props.data.breadCrumbData} />
            <WrongTurnWarning data={props.data.wrongTurnWarningData} />
        </>

    return (
        <StaticPageEngine components={components} />
    );
}

export default PageNotFound;