import { Marker, StaticGoogleMap } from "react-static-google-map";


const StaticMap = (address) => {

    return(
        <StaticGoogleMap size="600x300" className="img-fluid" apiKey={process.env.REACT_APP_MAPS_API_KEY_DEV} zoom="13" scale="1">
            <Marker location={address.address} />
        </StaticGoogleMap>
    )
}

export default StaticMap;