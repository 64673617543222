import arrow from "@assets/images/cms/ic-elipse-arrow-light.svg";
import { palettes } from "assets/theme";
import React, { useState } from "react";
import { Icon, ListItem, Section, Text, TextArea, Title } from "../Secao";

export const ListaB = ({ properties, mode, variant }) => {
  const [active, setActive] = useState(null);

  const handleColor = (listItem) => {
    if (active === listItem) {
      return "transparent";
    } else {
      return palettes.system_gray;
    }
  };

  const handleClick = (listItem) => {
    if (active === listItem) {
      return setActive(null);
    } else {
      return setActive(listItem);
    }
  };

  const listItems = properties[0].properties.slice(1);
  return (
    <Section
      theme={{ mode: mode, variant: variant }}
      className="flex flex-row justify-content-center"
    >
      <div className="w-100 maxSectionWidth">
        <Title theme={{ mode: mode, variant: variant }}>
          {properties[0].properties[0].value}
        </Title>
        <hr theme={{ mode: mode, variant: variant }} />
        <div className="d-flex justify-content-between">
          <Section
            style={{ borderBottom: "1px solid" }}
            className="button-responsive"
          >
            {listItems?.map((listItem, index) => {
              return (
                <div key={index}>
                  <ListItem
                    onClick={() => handleClick(listItem)}
                    tileColor={handleColor(listItem)}
                    className={active === listItem ? "active-list-item" : ""}
                    theme={{ mode: mode }}
                  >
                    <Text theme={{ mode: mode }}>
                      <b>{listItem.value}</b>
                    </Text>
                    {active !== listItem && (
                      <Icon theme={{ mode: mode }} src={arrow} alt="arrow" />
                    )}
                  </ListItem>
                  {active === listItem && (
                    <TextArea
                      theme={{ mode: mode }}
                      className="resposive1 py-3"
                    >
                      {listItem.properties[0].value}
                    </TextArea>
                  )}
                </div>
              );
            })}
          </Section>
          {active ? (
            <TextArea theme={{ mode: mode }} className="resposive2 px-5">
              <Text theme={{ mode: mode }}>{active.properties[0].value}</Text>
            </TextArea>
          ) : (
            ""
          )}
        </div>
      </div>
    </Section>
  );
};

ListaB.defaultProperties = {
  mode: "dafault",
};

export default ListaB;
