import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  StyledVehicleDetails,
  VehicleDetailsDesktop,
  VehicleDetailsMobile,
  VehicleDetailsTitle,
  VehicleDetailsImage,
  VehicleDetailsSpecs,
  VehicleDetailsSubtitle,
  VehicleDetailsText,
  VehicleDetailsSpecsText,
  VehicleDetailsIcon,
} from "./styles";
import iconCheck from "@assets/images/ic-check-white.svg";
import { ReactComponent as IconPerson } from "@assets/images/ic-person-white.svg";
import { ReactComponent as IconCarDoor } from "@assets/images/ic-car-door.svg";
import { ReactComponent as IconSuitcase } from "@assets/images/ic-suitcase.svg";
import { ReactComponent as IconTransmission } from "@assets/images/ic-transmission.svg";

export function VehicleDetails(props) {
  const {
    exampleModel,
    ehiwebName,
    transmission,
    passengerCapacity,
    luggageCapacity,
    otadoorCount,
    largeImage,
    smallImage,
    vehiclesFeatureList,
  } = props.data?.subCategory;

  const getIcon = (iconType) => {
    switch (iconType) {
      case "transmission":
        return <IconTransmission width="16px" height={16} fill="white" />;
      case "door":
        return <IconCarDoor width="16px" height={16} fill="white" />;
      case "suitcase":
        return <IconSuitcase width="16px" height={16} fill="white" />;
      case "passenger":
        return <IconPerson width="16px" height={16} fill="white" />;
      default:
        return <></>;
    }
  };

  const carSpecsList = (iconType, carSpecs) => (
    <>
      <Row>
        <Col lg={1} xs={1}>
          {getIcon(iconType)}
        </Col>
        <Col lg={11} xs={6}>
          <VehicleDetailsSpecsText>{carSpecs}</VehicleDetailsSpecsText>
        </Col>
      </Row>
    </>
  );
  const carAlsoIncludedList = (carAlsoIncludes) =>
    carAlsoIncludes?.map(({ feature }, index) => (
      <div key={`carAlsoIncludedList${index}`}>
        <Row>
          <Col lg={1} xs={1}>
            <VehicleDetailsIcon src={iconCheck} alt="icon" />
          </Col>
          <Col lg={11} xs={9}>
            <VehicleDetailsText>{feature}</VehicleDetailsText>
          </Col>
        </Row>
      </div>
    ));

  return (
    <StyledVehicleDetails>
      <VehicleDetailsDesktop>
        <Row>
          <VehicleDetailsTitle>{`${ehiwebName} Detalhes`}</VehicleDetailsTitle>
          <VehicleDetailsText>
            {`${exampleModel} ou similar*`}
          </VehicleDetailsText>
        </Row>
        <VehicleDetailsSpecs>
          <Col>
            <VehicleDetailsSubtitle>Especificações</VehicleDetailsSubtitle>
            <hr />
            {carSpecsList("transmission", transmission)}
            {carSpecsList("passenger", `${passengerCapacity} Pessoas`)}
            {carSpecsList("suitcase", `${luggageCapacity} Malas`)}
            {carSpecsList("door", `${otadoorCount} Portas`)}
          </Col>
          <Col>
            <VehicleDetailsSubtitle>Também inclui</VehicleDetailsSubtitle>
            <hr />
            {carAlsoIncludedList(vehiclesFeatureList)}
          </Col>
          <VehicleDetailsImage>
            <img fluid src={largeImage} alt={exampleModel} />
          </VehicleDetailsImage>
        </VehicleDetailsSpecs>
      </VehicleDetailsDesktop>

      <VehicleDetailsMobile>
        <hr style={{ opacity: "1" }} />
        <Row>
          <Col xs={6}>
            <VehicleDetailsTitle>{`${ehiwebName} Detalhes`}</VehicleDetailsTitle>
            <VehicleDetailsText>
              {`${exampleModel} ou similar*`}
            </VehicleDetailsText>
          </Col>
          <VehicleDetailsImage xs={6}>
            <img fluid src={smallImage} alt={exampleModel} />
          </VehicleDetailsImage>
        </Row>
        <VehicleDetailsSpecs>
          <VehicleDetailsSubtitle>Especificações</VehicleDetailsSubtitle>
          <Container>
            <hr style={{ opacity: "1" }} />
          </Container>

          <Col xs={6}> {carSpecsList("transmission", transmission)}</Col>
          <Col xs={6}>
            {carSpecsList("passenger", `${passengerCapacity} Pessoas`)}{" "}
          </Col>
          <Col xs={6}>
            {carSpecsList("suitcase", `${luggageCapacity} Malas`)}
          </Col>
          <Col xs={6}> {carSpecsList("door", `${otadoorCount} Portas`)}</Col>
        </VehicleDetailsSpecs>
        <VehicleDetailsSpecs>
          <VehicleDetailsSubtitle>Também inclui</VehicleDetailsSubtitle>
          <Container>
            <hr style={{ opacity: "1" }} />
          </Container>
          <Col xs={6}>{carAlsoIncludedList(vehiclesFeatureList)}</Col>
        </VehicleDetailsSpecs>
      </VehicleDetailsMobile>
    </StyledVehicleDetails>
  );
}
