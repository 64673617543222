import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { AccordionContext } from "@components/Accordion";
import { AccordionBodyConteiner } from "./styles";

const AccordionBody = ({ name, children, size }) => {
  const { openBody, accordionId } = useContext(AccordionContext);
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    setIsOpen(openBody.some((e) => e === `${accordionId}_${name}`));
  }, [openBody]);

  return (
    <>
      {isOpen && (
        <AccordionBodyConteiner style={{ width: size ? size : "100%" }}>
          {children}
        </AccordionBodyConteiner>
      )}
    </>
  );
};
AccordionBody.propTypes = {
  name: PropTypes.string.isRequired,
};
export default AccordionBody;
