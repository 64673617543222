export const FooterReducer = {
  addSection: (state) => {
    state.footerPage = {
      ...state.footerPage,

      section: state.footerPage?.section
        ? [
            ...state.footerPage?.section,
            {
              name: "Nova Seção",
              index: state.footerPage.section.length + 1,
              link: [],
            },
          ]
        : [
            {
              name: "Nova Seção",
              index: 1,
              link: [],
            },
          ],
    };
  },

  renameSection: (state, { payload }) => {
    const { current, sectionName } = payload;
    let section = state.footerPage?.section?.filter(
      (n) => n.index !== current.index
    );
    let item = state.footerPage?.section?.find(
      (n) => n.index === current.index
    );
    item.name = sectionName;
    state.footerPage = {
      ...state.footerPage,
      section: [...section, item],
    };
  },
  removeSection: (state, { payload }) => {
    const { current } = payload;

    const section = state.footerPage?.section?.filter(
      (n) => n.index !== current.index
    );
    state.footerPage = {
      ...state.footerPage,
      section: [...section],
    };
  },
  addFooterItems: (state, { payload }) => {
    const { selectedMenu, addedItems } = payload;
    if (addedItems?.length > 0) {
      if (
        selectedMenu !== "-1" &&
        state.footerPage?.section?.filter((n) => n.name === selectedMenu)
          .length > 0
      ) {
        let item = state.footerPage?.section?.find(
          (n) => n.name === selectedMenu
        );
        if (item.link) {
          addedItems.map((p) => {
            state.footerPage?.section
              ?.find((n) => n.name === selectedMenu)
              .link.push(p);
            return p;
          });
        } else {
          item.link = [...addedItems];
          const menu = state.footerPage?.section?.filter(
            (n) => n.id?.toString() !== selectedMenu
          );
          state.footerPage = {
            ...state.footerPage,
            section: [...menu, item],
          };
        }
      }
    }
  },
  updateFooterItem: (state, { payload }) => {
    state.footerPage = {
      ...state.footerPage,
      section: [...payload],
    };
  },
  setFooterEditing: (state, { payload }) => {
    state.isFooterEditing = payload;
  },

  removeFooterItem: (state, { payload }) => {
    const { parent, current } = payload;
    if (parent) {
      let item = state.footerPage?.section?.find((n) => n.name === parent.name);
      if (item.link) {
        item.link = item.link.filter((p) => p.id !== current.id);
        const menu = state.footerPage?.section?.filter(
          (n) => n.name !== parent.name
        );
        state.footerPage = {
          ...state.footerPage,
          section: [...menu, item],
        };
      }
    }
  },
};

export default FooterReducer;
