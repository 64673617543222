import styled from "styled-components";

export const BackOfficeHeaderContainer = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #213b73;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  z-index: 9;
  p {
    margin: 0 0 0 10px;
  }
  a {
    color: #213b73;
    text-decoration: none;
    margin: 0 0 0 10px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
  .logo {
    height: 50px;
  }
`;

export const BackOfficeHeaderImageText = styled.div`
  display: flex;
  align-items: center;
  button {
    background: transparent;
    border: none;
  }
`;
