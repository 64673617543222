import styled from "styled-components";
import theme from "styled-theming";
import { buttons, palettes } from "assets/theme";

const buttonType = theme("mode", {
  enabled: buttons.primary,
  disabled: buttons.disabled
});
const buttonConfig = theme("mode", {
  normal: buttons.secondary,
  inverse: buttons.secondary_inverse,
  primaryInverse: buttons.primary_inverse
});

export const Container = styled.div`
  background: ${palettes.white};
  padding: 13px 0px 25px;
`;

export const PageTypeContainer = styled.div``;
export const PageTypeButton = styled.button`
  ${buttonType};
  width: 50%;
  height: 50px;
  cursor: pointer;
`;

export const PageConfigContainer = styled.div`
  margin: 5px 0;
`;
export const PageConfigButton = styled.button`
  ${buttonConfig};
  width: 100%;
  height: 50px;
  margin: 5px 0;
`;

export const Spacer = styled.hr`
  background: ${palettes.tints_blue_darker};
  height: 1px;
  margin: 0;
  opacity: 1;
`;
export const InputWapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #a6adb4;
  background: #fff;
  display: flex;
  align-items: center;
  min-height: 50px;
  color: #213b73;
  font-family: "Poppins", sans-serif;
  padding: 5px 10px 5px 0;
  margin-bottom: 10px;
  div.content {
    padding-left: 10px;
    width: 100%;
  }
  p {
    margin: 0;
  }
  input,
  select {
    margin: 0;
    border: none;
    width: 100%;
    &::placeholder {
      color: transparent;
    }
    :focus {
      outline: none;
    }
  }
`;
export const GreyRetangle = styled.span`
  width: 8px;
  height: 100%;
  background-color: #ebeded;
  position: absolute;
`;
