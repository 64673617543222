import styled from "styled-components";
import theme from "styled-theming";
import { palettes, fonts, buttons } from "assets/theme";
import { ReactComponent as Trash } from "@assets/images/cms/ic-trash.svg";
import { ReactComponent as Pencil } from "@assets/images/cms/ic-pencil.svg";
import { ReactComponent as Save } from "@assets/images/cms/ic-check.svg";

const border = theme("mode", {
  true: `dashed 3px ${palettes.tints_blue}`,
  false: "dashed 3px transparent",
});
export const PageFooter = styled.h2`
  ${fonts.h2_bold}
  color: ${palettes.tints_blue_darker};
`;
export const PageSubFooter = styled.p`
  ${fonts.paragraph}
  color: ${palettes.tints_blue_darker};
`;
export const AddSectionButton = styled.button`
  ${buttons.primary};

  color: #fff;
  width: 474px;
  height: 50px;
  margin: 5px 0;
`;
export const Container = styled.div`
  padding: 10px;
  background: ${palettes.system_gray};
  min-height: 100%;
  overflow-y: auto;
  bottom: 0;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 180px;
    width: 4px;
    background: #a6adb4;
  }
`;
export const FooterComponentContainer = styled.div`
  position: relative;

  background-color: #fff;
`;
export const FooterNavContainer = styled.div`
  border: ${border};
  cursor: pointer;
  max-height: 130px;
  padding-bottom: 10px;
`;
export const SubPageContainer = styled.div`
  margin-left: 40px;
`;
export const SubPageDropZone = styled.div``;
export const DraggableItemWrap = styled.div`
  border: 1px solid ${palettes.system_gray_darker};
  width: 474px;
  height: 50px;
  margin-bottom: 10px;
`;
export const DraggableItem = styled.div`
  ${fonts.h4_bold}
  width: 100%;
  height: 100%;
  color: ${palettes.tints_blue_darker};
  background: ${palettes.white};
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
`;
export const MainContainer = styled.div`
  ${fonts.paragraph}
  width: 100%;
  height: 100%;
  color: ${palettes.tints_blue_darker};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  margin: 40px 0;
  p {
    max-width: 633px;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  img {
    width: 174px;
    height: 78px;
    margin-bottom: 5px;
  }
`;
export const ImageFooter = styled.h3`
  ${fonts.h3_bold}
  color: ${palettes.tints_blue_darker};
`;
export const Image = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
export const ImageLabel = styled.label`
  ${fonts.h4_bold}
  color: ${palettes.tints_blue_darker};
  cursor: pointer;
`;

export const SaveChangesbutton = styled.button`
  ${buttons.secondary};
  width: 212px;
  height: 64px;
`;

export const DraggableSubItem = styled(DraggableItem)`
  ${fonts.h4};
`;
export const TrashIcon = styled(Trash)`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;
export const PencilIcon = styled(Pencil)`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
`;
export const SaveIcon = styled(Save)`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
`;
