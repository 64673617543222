import unionIcon from "@assets/images/cms/ic-union.svg";
import { LoaderComponent } from "components/Loader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageListItem } from "./pageListItem";
import { v4 as uuidv4 } from "uuid";

import {
  ActionsDiv,
  AuthorDiv,
  DateDiv,
  InputWrapper,
  PageListContainer,
  PagesDetails,
  PageTitleDiv,
  TagsDiv,
} from "./styles";
import { Button, Modal } from "react-bootstrap";

const FILTER_TYPE = {
  BY_NAME: "name",
  BY_DRAFT: "draft",
  BY_PUBLISHED: "published",
  BY_INIT: "init",
};

const PageList = () => {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.auth.userLogged);
  if (!loggedUser) {
    history.push("/backoffice/login");
  }

  const [pages, setPages] = useState([]);
  const [noFilterLength, setNoFilterLength] = useState(0);
  const [byDraftLength, setByDraftLength] = useState(0);
  const [byPublishedLength, setByPublishedLength] = useState(0);
  const [filteredPages, setFilteredPages] = useState([]);
  const [filterStatus, setFilterStatus] = useState(FILTER_TYPE.BY_INIT);
  const [textFilter, setTextFilter] = useState(null);
  const pageLoader = useSelector((state) => state.cms.siteMap);
  const pageLoaderStatus = useSelector((state) => state.cms.status);

  useEffect(() => {
    setPages(pageLoader);
    if (filterStatus === FILTER_TYPE.BY_INIT) {
      setFilterStatus(FILTER_TYPE.BY_PUBLISHED);
    }
  }, [pageLoader]);

  useEffect(() => {
    filterList(filterStatus);
  }, [pages, filterStatus, textFilter]);

  const filterList = (type) => {
    let pagesFiltered = [];

    let noFilterLength = 0;
    let publishedLength = 0;
    let draftLength = 0;

    pages.forEach((page) => {
      switch (page.status) {
        case FILTER_TYPE.BY_DRAFT:
          draftLength++;
          break;
        case FILTER_TYPE.BY_PUBLISHED:
          publishedLength++;
          break;
        default:
          break;
      }
      noFilterLength++;
      page.childsPage?.forEach((childPage) => {
        switch (childPage.status) {
          case FILTER_TYPE.BY_DRAFT:
            draftLength++;
            break;
          case FILTER_TYPE.BY_PUBLISHED:
            publishedLength++;
            break;
          default:
            break;
        }
        noFilterLength++;
      });
    });
    setByDraftLength(draftLength);
    setByPublishedLength(publishedLength);
    setNoFilterLength(noFilterLength);

    pages
      .filter((page) => page.parentId == null)
      .forEach((page) => {
        let shouldParentEnter = false;

        page.childsPage?.map((child) => {
          const childsPage = child.childsPage?.filter((grandChild) => {
            return (
              (grandChild.status === type || type === FILTER_TYPE.BY_NAME) &&
              (!textFilter ||
                grandChild.name
                  ?.toLowerCase()
                  .includes(textFilter.toLowerCase()))
            );
          });

          if (childsPage?.length > 0) {
            shouldParentEnter = true;
          }

          return { ...child, childsPage };
        });

        let newPage = { ...page };

        newPage.childsPage = page.childsPage?.filter((child) => {
          return (
            (child.status === type || type === FILTER_TYPE.BY_NAME) &&
            (!textFilter ||
              child.name?.toLowerCase().includes(textFilter.toLowerCase()))
          );
        });

        if (newPage.childsPage?.length > 0) {
          shouldParentEnter = true;
        }

        if (
          (page.status === type || type === FILTER_TYPE.BY_NAME) &&
          (!textFilter ||
            page.name?.toLowerCase().includes(textFilter.toLowerCase()))
        ) {
          pagesFiltered.push(newPage);
        } else if (shouldParentEnter) {
          pagesFiltered.push(newPage);
        }
      });
    setFilteredPages(pagesFiltered);
  };

  const debounce = useCallback((func, wait) => {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }, []);

  const onSearchChange = useMemo(() => {
    return debounce(setTextFilter, 600);
  }, []);

  const createTree = (currentPage, idxParent, index, hierarchy) => {
    const showCurrentPageLine =
      currentPage.status === filterStatus ||
      (filterStatus === FILTER_TYPE.BY_NAME &&
        (!textFilter ||
          currentPage.name?.toLowerCase().includes(textFilter.toLowerCase())));

    return (
      <div key={uuidv4()}>
        <div
          className={"d-flex " + (showCurrentPageLine ? "" : "opacity-50")}
          key={uuidv4()}
        >
          {hierarchy > 0 && (
            <img alt="union" src={unionIcon} className="mx-1" />
          )}
          {hierarchy > 1 && (
            <img alt="union" src={unionIcon} className="mx-1" />
          )}
          <PageListItem page={currentPage} key={uuidv4()} />
        </div>
        {currentPage.childsPage?.map((child, idx) => (
          <React.Fragment key={uuidv4()}>
            <>{createTree(child, idxParent + 1, idx + 1, hierarchy + 1)}</>
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <>
      {pageLoaderStatus === "loading" && <LoaderComponent />}
      <PageListContainer>
        <div className="d-flex justify-content-between">
          <PagesDetails>
            <button onClick={() => setFilterStatus(FILTER_TYPE.BY_NAME)}>
              <h6>
                <span
                  style={{
                    fontWeight:
                      filterStatus == FILTER_TYPE.BY_NAME ? "bold" : "normal",
                  }}
                >
                  Todas as páginas ({noFilterLength})
                </span>
              </h6>
            </button>
            <h6 className="mt-2">|</h6>
            <button onClick={() => setFilterStatus(FILTER_TYPE.BY_PUBLISHED)}>
              <h6>
                <span
                  style={{
                    fontWeight:
                      filterStatus == FILTER_TYPE.BY_PUBLISHED
                        ? "bold"
                        : "normal",
                  }}
                >
                  Publicadas ({byPublishedLength})
                </span>
              </h6>
            </button>
            <h6 className="mt-2">|</h6>
            <button onClick={() => setFilterStatus(FILTER_TYPE.BY_DRAFT)}>
              <h6>
                <span
                  style={{
                    fontWeight:
                      filterStatus == FILTER_TYPE.BY_DRAFT ? "bold" : "normal",
                  }}
                >
                  Rascunhos ({byDraftLength})
                </span>
              </h6>
            </button>
          </PagesDetails>
          <InputWrapper className="mt-3">
            <input
              type="text"
              placeholder="BUSCA"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <img src="/ic-search.svg" alt="icone buscar" className="mb-1" />
          </InputWrapper>
        </div>
        <hr />
        <div className="d-flex px-4 mb-3 w-100">
          <PageTitleDiv>Título</PageTitleDiv>
          <AuthorDiv>Autor</AuthorDiv>
          <TagsDiv>Etiquetas</TagsDiv>
          <DateDiv>Data</DateDiv>
          <ActionsDiv>Ações</ActionsDiv>
        </div>
        {filteredPages?.map((page, index) => createTree(page, 0, index, 0))}
      </PageListContainer>
    </>
  );
};

export default PageList;
