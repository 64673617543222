import styled from "styled-components";
import { Row } from "react-bootstrap";

export const CardBackground = styled.div`
    background-color: white;
    padding: 4%;
    margin-bottom: 20px;
    text-align: left;
    height: 95%;
    a{
        text-decoration: none;
    }
`;

export const CardIconText = styled(Row)`
    color: #5FBEA5;
    font-weight: bold;
`;

export const CardTitle = styled.h4`
    color: #8C4C9E;
    font-weight: bold;
    min-height: 60px;
`;

export const CardText = styled.p`
    color: #213B73;
`;

export const CardIcon = styled.img`
    margin-top: 3px;
    display: block;
    width: 20px;
    height: 20px;
    filter: invert(74%) sepia(16%) saturate(946%) hue-rotate(114deg) brightness(88%) contrast(91%);
`;