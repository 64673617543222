import { ReactComponent as PlusIcon } from "@assets/images/cms/ic-plus-alt-white.svg";
import { blogSlice } from "@redux/blog/blogSlice";
import EditorHtml from "pages/CMS/components/EditorHtml";
import { useDispatch, useSelector } from "react-redux";
import { Container, Image, ImageContainer, InputWapper } from "./styles";
import {displaySnackbar} from "redux/snackbar/snackbarSlice";

const PageContent = () => {
  const { setPostEditData } = blogSlice.actions;
  const dispatch = useDispatch();
  const categoryEditData = useSelector(
    (state) => state?.blog?.categoryEditData
  );

  const onImageChange = (e) => {
    let files = e.target.files;
    if(files.size > 10485760){
      dispatch(displaySnackbar({ message:"Tamanho de imagem muito alto, informe uma imagem de no máximo 10MB", type:"error"}))
    }else
    if (files?.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        const typeImage = `.${e.target.result.split(";")[0].split("/")[1]}`;
        dispatch(
          setPostEditData({
            ...categoryEditData,
            image: e.target.result,
            newHeader: true,
            typeImage
          })
        );
      };
    }
  };
  const handleNameChange = (value) => dispatch(setPostEditData(value));
  return (
    <>
      <Container>
        <ImageContainer
          style={
            categoryEditData?.image
              ? { backgroundImage: `url(${categoryEditData?.image})` }
              : {}
          }
        >
          {/* {data?.value && <img src={data.value} alt="item" />} */}
          {/* <img src="https://i.imgur.com/EEo2Fur.jpeg" alt="item" /> */}
          <label htmlFor={`bannerHeader`}>
            <PlusIcon />
            <span>Adicionar/Alterar imagem de destaque</span>
            
          </label>
          <Image
            type="file"
            accept=".jpeg,.jpg,.png,.webp,.bmp"
            id={`bannerHeader`}
            name={`bannerHeader`}
            onChange={onImageChange}
            
          />
        </ImageContainer>
        <InputWapper className="mr10">
          <input
            value={categoryEditData?.imageDescription || ""}
            onChange={(value) =>
              handleNameChange({
                ...categoryEditData,
                imageDescription: value.target.value
              })
            }
            placeholder="Nome da Pagina"
          />
        </InputWapper>
      </Container>
      <EditorHtml
        onChange={(value) =>
          handleNameChange({
            ...categoryEditData,
            content: value
          })
        }
        value={categoryEditData?.content}
      />
    </>
  );
};

export default PageContent;
