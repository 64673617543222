import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "@api/api";
import getAxios from "api/api";

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  const token = localStorage.getItem("token");
  const response = await getAxios().get("/users", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (user, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await getAxios().put(`/users/${user.id}`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response);
      } else {
        throw error;
      }
    }
  }
);

export const deleteUser = createAsyncThunk("user/deleteUser", async (id) => {
  const token = localStorage.getItem("token");
  const response = await getAxios().delete(`/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response === "Registro deletado com sucesso!") {
    return id;
  } else {
    return null;
  }
});

export const createUser = createAsyncThunk("user/createUser", async (user) => {
  const token = localStorage.getItem("token");
  const response = await getAxios().post("/signup", user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});
