import styled from "styled-components";
import { Accordion } from 'react-bootstrap';
import dropdownIcon from '@assets/images/ic-arrow-yellow.svg';

export const LocationDetailAccordionHeader = styled(Accordion.Header)`
    background-color: #213B73;
    border: none;
    &:after {
        display: none;
    }
    button{
        padding: 0 0 0 7px;
        background-color: transparent !important;
        box-shadow: none !important;
        @media (max-width: 576px) {
            padding: 0;
        }
    }
    img{
        width: 30px;
        height: 30px;
        padding: 0;

    }
    .accordion-button::after {
        background-image: url(${dropdownIcon})!important;
        margin-right: 20px;
    }
`;

export const LocationDetailImageText = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    img{
        margin: 0 15px 0 22px;
    }
    .accordion-button::after {
        background-image: url(${dropdownIcon})!important;
        width: 4rem!important;
    }
    &:after {
        fill: white;
    }
`;

export const LocationDetailAccordionItem = styled(Accordion.Item)`
    background: transparent;
    border: 2px;
    border-style: none none solid none;
`;

export const LocationDetailAccordionBody = styled.div`
    display: flex;
    justify-content: space-between;
`;