import footerData from "@components/Footer/Resources/example.json";
import { PostList } from "components/Blog/PostList";
import { TagFilter } from "components/Blog/TagFilter";
import { BlogHeader } from "components/BlogHeader";
import { Footer } from "components/Footer";
import { HorizontalImageTitleInfoAndButtons } from "components/HorizontalImageTitleInfoAndButtons";
import { PostHighlight } from "components/PostHighlight";
import { TitleContext } from "context/TitleContext";
import React from "react";
import { useSelector } from "react-redux";
import { BreadCrumb } from "../../../components/BreadCrumb";

export function BlogCategory(props) {
  const isFiltering = useSelector((state) => state.blog.isFiltering);
  const { setTitleText } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTitleText("Alamo Blog - Categorias");
  }, []);

  return (
    <>
      <BlogHeader data={props.data.headerData} />
      <BreadCrumb
        data={props.data.breadCrumbData}
        countryName={props.data.tagFilterData.active}
      />
      <TagFilter data={props.data.tagFilterData} />
      {!isFiltering && <PostHighlight data={props.data.postHighlightData} />}
      <TagFilter data={props.data.tagFilter2Data} hasCategory={true} />
      <PostList data={props.data.postListData} />
      <HorizontalImageTitleInfoAndButtons
        data={props.data.horizontalImageTitleInfoAndButtonsData}
      />
      <Footer data={footerData} />
    </>
  );
}

export default BlogCategory;
