import styled from "styled-components";

export const Title = styled.h1`
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: bold;
    color: #213B73;
    @media (max-width: 768px) {
        font-size: 26px;
    }
`;
export const Title2 = styled.h2`
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: bold;
    color: #213B73;
    @media (max-width: 768px) {
        font-size: 17px;
    }
`;
export const Subtitle = styled.h3`
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: normal;
    color: #213B73;
    a{
        font-size: 17px;
        text-decoration: underline;
    }
    @media (max-width: 768px) {
        font-size: 17px;
    }
`;
export const Paragraph = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: normal;
    color: #213B73;
    a{
        font-size: 17px;
        text-decoration: underline;
    }
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
export const Text = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: normal;
    color: #213B73;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
export const Note = styled.p`
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: normal;
    color: #213B73;
`;

// TODO: [AL-303] Add more styles & refactor