import { TextBlockWithLinksBackground } from './styles'
import { Subtitle, Paragraph  } from '../Global/Texts/styles'

export function TextBlockWithLinks(props) {

    const body = props.data.body.map((body, i) => 
        <div key={i}>
            <Paragraph dangerouslySetInnerHTML={{__html: body}} />
            <br />
        </div>
    )

    return (
        <TextBlockWithLinksBackground>
            {props.data.title && <Subtitle>{props.data.title}</Subtitle>}
            {body}
            {props.data.link &&
                <a href={props.data.link.href}>{props.data.link.text}</a>
            }
            <hr/>
        </TextBlockWithLinksBackground>
    );
}