import React, { useEffect } from "react";
import { useContext } from "react";
import { SnackbarContext } from "react-snackbar-alert";
import { useDispatch, useSelector } from "react-redux";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
const Snackbar = () => {
  const dispatch = useDispatch();
  const { createSnackbar } = useContext(SnackbarContext);
  const state = useSelector((state) => state?.snackbar);
  useEffect(() => {
    const { textMessage, type } = state;
    if (textMessage) {
      createSnackbar({
        message: textMessage,
        theme: type
      });
      dispatch(displaySnackbar());
    }
  }, [state?.textMessage]);

  return <></>;
};
export default Snackbar;
