import styled from "styled-components";
import { palettes } from "assets/theme";

export const Container = styled.div`
  background: ${palettes.white};
  height: 100%;
  width: 100%;
  padding: 14px 20px;
  font-family: 'Poppins', sans-serif;
  h4{
      color: #213B73;
      margin: 15px 0;
      font-weight: bold;
  }
`;

export const ContinueButton = styled.button`
  background-color: #8C4C9E;
  color: #fff;
  border: none;
  font-weight: bold;
  width: 100%;
  height: 50px;
  margin: 5px 0;
`;

export const CancelButton = styled.button`
  background-color: #fff;
  color: #8C4C9E;
  border: 1px solid #8C4C9E;
  font-weight: bold;
  width: 100%;
  height: 50px;
  margin: 5px 0;
`;

export const Option = styled.option`
  color: #213B73;
  font-weight: bold;
  background-color: white;
`;