import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PostListItem } from "../PostListItem/index.js";
import {
  PostListContainer,
  PostListItemContainer,
  PostListRow,
  Subtitle,
} from "./styles";

export function PostList() {
  const { category } = useParams();
  const [subcategoryName, setSubcategoryName] = useState(null);
  const blogState = useSelector((state) => state.blog);
  const postListFiltered = useSelector(
    (state) => state?.postList?.postListFiltered
  );

  useEffect(() => {
    postListItems();
  }, [postListFiltered]);

  useEffect(() => {
    setSubcategoryName(blogState.selectedSubcategory);
  }, [blogState]);

  const postListItems = () => {
    let postList = [...postListFiltered];
    if (subcategoryName || category) {
      postList = postList?.filter((post) =>
        post.category.find((item) => item.name === category)
      );
      if (subcategoryName && subcategoryName !== "todas") {
        postList = postList?.filter((post) =>
          post.category.find((item) => item.name === subcategoryName)
        );
      }
    }
    return postList
      ?.sort(
        (a, b) =>
          new Date(
            b?.auditingInfo?.updatedAt || b?.auditingInfo?.createdAt
          ).getTime() -
          new Date(
            a?.auditingInfo?.updatedAt || a?.auditingInfo?.createdAt
          ).getTime()
      )
      ?.map((postList, i) => {
        return (
          <PostListItemContainer
            lg={3}
            md={6}
            xs={12}
            key={i}
            className={"px-0 mx-0"}
          >
            <PostListItem data={postList} />
          </PostListItemContainer>
        );
      });
  };

  return (
    <>
      {postListItems()?.length > 0 ? (
        <Container
          fluid="xxl"
          className="pt-5 px-0"
          style={{ "max-width": "1296px" }}
        >
          <Subtitle className="mx-4">
            <b>Leia também</b>
          </Subtitle>
          <PostListContainer className="mx-0 px-0">
            <PostListRow>{postListItems()}</PostListRow>
          </PostListContainer>
        </Container>
      ) : (
        <div className="mb-5"></div>
      )}
    </>
  );
}
