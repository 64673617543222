import { ReactComponent as ExcludeIcon } from "@assets/images/cms/ic-exclude.svg";
import { ReactComponent as CloseIcon } from "@assets/images/cms/ic-close.svg";
import { ReactComponent as PencilIcon } from "@assets/images/cms/ic-pencil.svg";
import { ReactComponent as DotsIcon } from "@assets/images/cms/ic-three-dots.svg";
import { ReactComponent as TrashIcon } from "@assets/images/cms/ic-trash.svg";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import {
  ActionsDiv,
  AuthorDiv,
  CategoryDiv,
  Checkbox,
  DateDiv,
  Ellipsis,
  PostTitleDiv,
  StyledDiv,
  StyledPostListItem,
  TagsDiv,
} from "./styles";
import React, { useState } from "react";
import {
  ButtonDropdownActions,
  DropdownActions,
  DropdownItemContainer,
  DropdownItemContent,
  Spacer,
} from "../../components/SetupPage/components/SiteMap/styles";
import { BlogStatus } from "../../../../redux/blog/constants";
import { Button, Modal } from "react-bootstrap";
import {
  deletePost,
  fetchGetPosts,
  updateStatusPost,
} from "../../../../redux/postList/postListService";
import dayjs from "dayjs";

export function PostListItem({
  post,
  key: index,
  //   onItemCheck,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const categories = useSelector((state) => state?.blog?.categories);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const [selectedPost, setSelectedPost] = useState();

  const copyTextToClipboard = (text) => {
    navigator.clipboard?.writeText(text);
    dispatch(displaySnackbar({ message: "URL copiada!", type: "info" }));
  };

  const CustomToggle = React.forwardRef((props, ref) => {
    const { onClick, "aria-expanded": isOpenIcon } = props;
    return (
      <ButtonDropdownActions
        style={{ padding: "0", backgroundColor: "#213b73" }}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {isOpenIcon ? <CloseIcon /> : <DotsIcon />}
      </ButtonDropdownActions>
    );
  });

  const handleClose = () => {
    setSelectedPost(null);
    setAction("");
    setShow(false);
  };

  const handleShow = (post, action) => {
    setSelectedPost(post);
    setAction(action);
    setShow(true);
  };

  const handleUpdatePost = (post, status) => {
    let entity = {};
    Object.assign(entity, post);
    entity.status = status;
    entity.newHeader = false;
    dispatch(updateStatusPost(entity)).then(() => {
      handleClose();
      dispatch(fetchGetPosts());
    });
  };

  const handleDeletePost = (post) => {
    dispatch(deletePost(post.id))
      .then(() => {
        dispatch(
          displaySnackbar({
            message: "Post Excluído com sucesso",
            type: "success",
          })
        );
        handleClose();
        dispatch(fetchGetPosts());
      })
      .catch((error) => {
        dispatch(displaySnackbar({ message: error.msg, type: "error" }));
      });
  };

  return (
    <StyledPostListItem key={`PostListItem-${index}`}>
      {/* <Checkbox>
        <input
          type="checkbox"
          checked={post.selected}
          id={`rowcheck-${post.id}`}
          onChange={(e) => onItemCheck(e, post)}
        />
      </Checkbox> */}
      <PostTitleDiv
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push(`/cms/post-page?id=${post.id}`);
        }}
      >
        {post.title}
      </PostTitleDiv>
      <AuthorDiv>{post.auditingInfo?.updatedBy}</AuthorDiv>
      <CategoryDiv>
        {categories.find((p) => p.id === post.category?.[0])?.name}
      </CategoryDiv>
      <TagsDiv>{post.keywords?.join(", ")}</TagsDiv>
      <DateDiv>
        {post.auditingInfo?.createdAt
          ? dayjs(post.auditingInfo?.createdAt).format("DD/MM/YYYY")
          : "-"}
      </DateDiv>
      <ActionsDiv>
        <DropdownActions>
          <DropdownActions.Toggle
            as={CustomToggle}
            id="dropdown-custom-components"
          >
            Custom toggle
          </DropdownActions.Toggle>
          <DropdownActions.Menu>
            <DropdownActions.Item
              onClick={() => {
                history.push(`/cms/post-page?id=${post.id}`);
              }}
            >
              <DropdownItemContainer>
                <DropdownItemContent>
                  <PencilIcon />
                  <span>Editar</span>
                </DropdownItemContent>
              </DropdownItemContainer>
            </DropdownActions.Item>
            <Spacer />
            <DropdownActions.Item
              onClick={() => {
                copyTextToClipboard(
                  `${window.location.origin}/blog/${post.url}`
                );
              }}
            >
              <DropdownItemContainer>
                <DropdownItemContent>
                  <ExcludeIcon />
                  <span>Copiar URL</span>
                </DropdownItemContent>
              </DropdownItemContainer>
            </DropdownActions.Item>
            <Spacer />
            {/* {post?.status !== BlogStatus.PUBLISHED &&
            post?.status !== BlogStatus.DRAFT ? (
              <DropdownActions.Item
                onClick={() => {
                  handleUpdatePost(post, "DRAFT");
                }}
              >
                <DropdownItemContainer>
                  <DropdownItemContent>
                    <VisibleIcon />
                    <span>Habilitar</span>
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
            ) : (
              <DropdownActions.Item
                onClick={() => handleShow(post, "Desabilitar")}
              >
                <DropdownItemContainer>
                  <DropdownItemContent>
                    <VisibleIcon />
                    <span>Desabilitar</span>
                  </DropdownItemContent>
                </DropdownItemContainer>
              </DropdownActions.Item>
            )} */}
            <DropdownActions.Item
              onClick={() =>
                window.open(
                  `${window.location.origin}/blog/${post.url}`,
                  "_newtab"
                )
              }
            >
              <DropdownItemContainer>
                <DropdownItemContent>
                  <FaExternalLinkAlt />
                  <span>Ir para Post</span>
                </DropdownItemContent>
              </DropdownItemContainer>
            </DropdownActions.Item>
            <Spacer />
            <DropdownActions.Item onClick={() => handleShow(post, "Excluir")}>
              <DropdownItemContainer>
                <DropdownItemContent>
                  <TrashIcon />
                  <span>Excluir</span>
                </DropdownItemContent>
              </DropdownItemContainer>
            </DropdownActions.Item>
            <Spacer />
          </DropdownActions.Menu>
        </DropdownActions>
      </ActionsDiv>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`${action} artigo`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você tem ceteza que deseja {action?.toLowerCase()} esse artigo?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={(e) =>
              action === "Excluir"
                ? handleDeletePost(selectedPost)
                : handleUpdatePost(selectedPost, "DISABLED")
            }
          >
            {action}
          </Button>
        </Modal.Footer>
      </Modal>
    </StyledPostListItem>
  );
}
