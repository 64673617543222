import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { MyInfoBlock, InfoBlockImage, InfoBlockText, InfoBlockTitles } from "./styles.js";
import { StyledButton } from "../Button/styles.js";

export function InfoBlock(props) {

    return (
        <MyInfoBlock colortext={props.data.colorText}>
            <Container>
            <Row>
                {props.data.image &&
                    <InfoBlockImage lg={4} xs={12} imagedata={props.data.image}>
                        <img src={props.data.image.src} alt={props.data.image.alt} height={props.data.image.size} />
                    </InfoBlockImage>
                }
                <Col lg={8} xs={12}>
                    <InfoBlockText>
                        <InfoBlockTitles>
                        {
                            props.data.titles[0] &&
                            <p>{props.data.titles[0]}</p>
                        }
                        <h4>{props.data.titles[1]}</h4>
                        </InfoBlockTitles>
                        <p>{props.data.text}</p>
                    </InfoBlockText>
                    {props.data.button &&
                        <a href={props.data.button.href} alt="Saiba mais">
                            <StyledButton color={props.data.button.color} colortext={props.data.button.colorText}>{props.data.button.text}</StyledButton>
                        </a>
                    }
                </Col>
            </Row>
            </Container>
        </MyInfoBlock>
    );
}