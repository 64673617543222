import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

export const AccordionContext = createContext();

const Accordion = ({ keepOtherOpen, id, children }) => {
  const [openBody, setOpenBody] = useState([]);
  const [isKeepOtherOpen] = useState(keepOtherOpen);
  const [accordionId] = useState(id);
  const openCloseAccordion = (name) => {
    if (openBody.some((e) => e === name)) {
      setOpenBody(openBody.filter((i) => i !== name));
    } else {
      setOpenBody([...(isKeepOtherOpen ? openBody : []),name]);
    }
  };
  return (
    <AccordionContext.Provider
      value={{
        openBody,
        isKeepOtherOpen,
        accordionId,
        openCloseAccordion
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

Accordion.propTypes = {
  keepOtherOpen: PropTypes.bool,
  id: PropTypes.string,
};

export default Accordion;
