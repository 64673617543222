import { Form } from "@unform/web";
import { BackOfficeHeader } from "components/BackOfficeHeader";
import { StyledButton } from "components/Button/styles";
import Input from "components/CreatePasswordField/input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import { fetchUsers, updateUser } from "redux/user/userService";
import { ProfilePageContainer } from "./styles";
import { palettes } from "assets/theme";
import { ErrorMessage } from "pages/CMS/pages/Canvas/components/PageSEO/styles";

export function EditProfilePage() {
  const history = useHistory();
  const loggedUser = useSelector((state) => state.auth.userLogged);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const userState = useSelector((state) => state.user);
  const [user, setUser] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState({
    firstName: false,
    lastName: false,
    email: false,
    currentPass: false,
    newPass: false,
    newPassConfirm: false,
  });
  const regexEmail =
    /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const regexPassword = /(?![a-zA-Z0-9~`!@#$%^&*()_\-+={\[}\]|:;"'<,>.?]).+$/;
  const regexNumber = /[0-9]/;
  const regexUppercase = /[A-Z]/;
  useEffect(() => {
    dispatch(fetchUsers());
    setUser(userState?.user?.find((user) => user.id === userId));
    setUserRole(userState?.user?.find((user) => user.id === userId).roles);
  }, []);

  if (!loggedUser) {
    history.push("/backoffice/login");
  }

  const formReset = () => {
    setIsFormInvalid({
      firstName: false,
      lastName: false,
      email: false,
      currentPass: false,
      newPass: false,
      newPassConfirm: false,
    });
  };

  const handleSubmit = (data) => {
    let error = false;
    let isFirstNameInvalid = false;
    let isLastNameInvalid = false;
    let isEmailInvalid = false;
    let isCurrentPassInvalid = false;
    let isNewPassInvalid = false;
    let isNewPassConfirmInvalid = false;

    //valida primeiro nome
    if (data.firstName.length < 3 || data.firstName.length > 26) {
      isFirstNameInvalid = true;
      error = true;
    }

    //valida segundo nome
    if (data.lastName.length < 3 || data.lastName.length > 52) {
      isLastNameInvalid = true;
      error = true;
    }

    //valida email
    if (!regexEmail.test(data.email)) {
      isEmailInvalid = true;
      error = true;
    }

    //valida senha
    if (data.newPass || data.newPassConfirm) {
      if (data.newPass && data.newPassConfirm) {
        //nova senha fora do padrão regex
        if (
          regexPassword.test(data.newPass) ||
          data.newPass.length < 8 ||
          data.newPass.length > 64 ||
          !regexNumber.test(data.newPass) ||
          !regexUppercase.test(data.newPass)
        ) {
          isNewPassInvalid = true;
          error = true;
        }
        //confirmação diferente da senha inserida
        if (data.newPass !== data.newPassConfirm) {
          isNewPassConfirmInvalid = true;
          error = true;
        }
      } else {
        isCurrentPassInvalid = !data.currentPass;
        isNewPassInvalid = !data.newPass;
        isNewPassConfirmInvalid = !data.newPassConfirm;
        error = true;
      }
    }

    if (error) {
      setIsFormInvalid({
        firstName: isFirstNameInvalid,
        lastName: isLastNameInvalid,
        email: isEmailInvalid,
        currentPass: isCurrentPassInvalid,
        newPass: isNewPassInvalid,
        newPassConfirm: isNewPassConfirmInvalid,
      });
      return;
    }

    //reseta estado se corrigido
    formReset();

    const body = {
      id: user.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      pass: data.newPass,
      oldPass: data.currentPass,
      roles: userRole,
    };
    // console.log("body:", body);
    dispatch(updateUser(body));
  };

  const handleErrorMessage = () => {
    switch (userState.errorCode) {
      case 400:
        return "Um ou mais dados informados são inválidos.";
      case 401 || 403:
        return "Falha de autenticação. Experimente sair e entrar novamente no CMS.";
      case 500:
        return "Falha de processamento da atualização no servidor. Tente novamente mais tarde.";
      default:
        break;
    }
  };

  useEffect(() => {
    let message;
    let type;
    switch (userState.status) {
      case "updateUser-failed":
        message = handleErrorMessage();
        type = "error"; //usar errorCode pra definir outras messages
        break;
      case "updateUser-success":
        message = "Usuário atualizado com sucesso."; //add: Você será redirecionado para a Administração de usuários.
        type = "success";
        setTimeout(() => {
          history.push("/backoffice/administracao-de-usuario");
        }, 3000);
        break;
      default:
        return;
    }
    if (message) {
      dispatch(displaySnackbar({ message, type }));
    }
  }, [userState.status]);

  return (
    <>
      <BackOfficeHeader />
      <ProfilePageContainer>
        <div className="mb-5">
          <h2>
            <b>Editar perfil</b>
          </h2>
          <h4>{user?.email}</h4>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="d-flex flex-column">
            <label>
              <b>Primeiro Nome</b>
            </label>
            <Input
              className={isFormInvalid.firstName ? "input-invalid" : ""}
              name="firstName"
              type="text"
              defaultValue={user?.firstName}
            />
            {isFormInvalid.firstName && (
              <ErrorMessage>Deve conter entre 3 e 26 caracteres.</ErrorMessage>
            )}
            <label>
              <b>Sobrenome</b>
            </label>
            <Input
              className={isFormInvalid.lastName ? "input-invalid" : ""}
              name="lastName"
              type="text"
              defaultValue={user?.lastName}
            />
            {isFormInvalid.lastName && (
              <ErrorMessage>Deve conter entre 3 e 52 caracteres.</ErrorMessage>
            )}
            <label>
              <b>Email</b>
            </label>
            <Input
              className={isFormInvalid.email ? "input-invalid" : ""}
              name="email"
              type="email"
              defaultValue={user?.email}
            />
            {isFormInvalid.email && (
              <ErrorMessage>Formato de email inválido.</ErrorMessage>
            )}
            <label>
              <b>Alterar senha</b>
            </label>
            {loggedUser?.roles?.includes("ROLE_USER") ? (
              <>
                <Input
                  className={isFormInvalid.currentPass ? "input-invalid" : ""}
                  name="currentPass"
                  type="password"
                  placeholder="Senha atual"
                />

                {isFormInvalid.currentPass && (
                  <ErrorMessage>Insira sua senha atual.</ErrorMessage>
                )}
              </>
            ) : (
              <></>
            )}
            <Input
              className={isFormInvalid.newPass ? "input-invalid" : ""}
              name="newPass"
              type="password"
              placeholder="Nova senha"
            />
            {isFormInvalid.newPass && (
              <ErrorMessage>
                A senha deve conter entre 8 e 64 caracteres e conter ao menos um
                número e uma letra maiúscula.
              </ErrorMessage>
            )}
            <Input
              className={isFormInvalid.newPassConfirm ? "input-invalid" : ""}
              name="newPassConfirm"
              type="password"
              placeholder="Confirmar nova senha"
            />
            {isFormInvalid.newPassConfirm && (
              <ErrorMessage>As senhas devem coincidir.</ErrorMessage>
            )}
            <label>
              <b>Perfil</b>
            </label>
            <select
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
              name="roles"
            >
              <option value="ROLE_ADMIN">Administrador</option>
              <option value="ROLE_USER">Usuário</option>
            </select>

            <div className="d-flex justify-content-end my-2">
              <StyledButton
                color={palettes.white}
                colortext={palettes.tints_purple}
                style={{ border: "1px solid #8C4C9E" }}
                className="mx-2"
                onClick={() =>
                  history.push("/backoffice/administracao-de-usuario")
                }
              >
                Cancelar
              </StyledButton>
              <StyledButton
                color={palettes.tints_purple}
                colortext={palettes.white}
                type="submit"
              >
                Salvar
              </StyledButton>
            </div>
          </div>
        </Form>
      </ProfilePageContainer>
    </>
  );
}
