import { BackOfficeHeader } from "components/BackOfficeHeader";
import { LoginFieldBackground } from "components/LoginField/styles";
import { Form } from "@unform/web";
import Input from "components/CreatePasswordField/input";
import { StyledButton } from "components/Button/styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export function ChangePassword() {
    const history = useHistory();
    const loggedUser = useSelector(state => state.auth.userLogged);
    if (!loggedUser) {
        history.push("/backoffice/login");
    }

    const handleSubmit = (data) => {
        if (data.newPassword === data.newPasswordConfirm) {
            //chamada de api
        }
    }

    const userEmail = localStorage.getItem("userLoggedEmail");

    return (
        <>
            <BackOfficeHeader />
            <LoginFieldBackground>
                <div className="mb-5">
                    <h2>
                        <b>Alterar Senha</b>
                    </h2>
                    <h4>{userEmail}</h4>
                </div>
                <Form onSubmit={handleSubmit}>
                    <div className="d-flex flex-column">
                        <label><b>Senha Atual</b></label>
                        <Input name="currentPassword" type="password" />
                        <label><b>Nova Senha</b></label>
                        <Input name="newPassword" type="password" />
                        <label><b>Confirmar Nova Senha</b></label>
                        <Input name="newPasswordConfirm" type="password" />
                        <div className="d-flex justify-content-end">
                            <StyledButton color="#8C4C9E" className="mx-2" onClick={() => history.push("/backoffice/pagina-de-perfil")}>Cancelar</StyledButton>
                            <StyledButton color="#FEF100 !important" colortext="#8C4C9E !important" type="submit">Alterar senha</StyledButton>
                        </div>
                    </div>
                </Form>
            </LoginFieldBackground>
        </>
    )
}