import { Row } from "react-bootstrap";
import { StyledButton } from "../Button/styles.js";
import { ImageTitleTextBlockAndButtonBackground, ImageTitleTextBlockAndButtonImage, ImageTitleTextBlockAndButtonMainImage, ImageTitleTextBlockAndButtonButton, ImageTitleTextBlockAndButtonText } from "./styles"

export function ImageTitleTextBlockAndButton(props) {
    return (
        <>
            <ImageTitleTextBlockAndButtonBackground textColor={props.data.textColor} backgroundColor={props.data.backgroundColor}>
                <Row>
                    <ImageTitleTextBlockAndButtonImage xs={12} lg={2}>
                        <ImageTitleTextBlockAndButtonMainImage src={props.data.image.src} alt={props.data.image.alt}/>
                    </ImageTitleTextBlockAndButtonImage>
                    <ImageTitleTextBlockAndButtonText xs={12} lg={7}>
                        <h4>{props.data.title}</h4>
                        <p>{props.data.text}</p>
                    </ImageTitleTextBlockAndButtonText>
                    <ImageTitleTextBlockAndButtonButton xs={12} lg={3}>
                        <a href={props.data.button.href}>
                            <StyledButton color={props.data.button.color} colortext={props.data.button.colorText}>{props.data.button.text}</StyledButton>
                        </a>
                    </ImageTitleTextBlockAndButtonButton>
                </Row>
                <hr />
            </ImageTitleTextBlockAndButtonBackground>
        </>
    );
}