import styled from "styled-components";
import theme from "styled-theming";
import { buttons, palettes } from "assets/theme";

const buttonConfig = theme("mode", {
  normal: buttons.secondary,
  inverse: buttons.secondary_inverse,
  primaryInverse: buttons.primary_inverse
});

export const Container = styled.div`
  background: ${palettes.white};
  width: 100%;
  padding: 14px 20px;
  min-height: 100%;
  overflow-y: auto;
  bottom: 0;
`;
export const Spacer = styled.hr`
  background: ${palettes.tints_blue_darker};
  height: 1px;
  margin: 0;
  opacity: 1;
`;
export const PageConfigContainer = styled.div`
  margin-top: 20px;
`;
export const PageConfigButton = styled.button`
  ${buttonConfig};
  width: 100%;
  height: 50px;
  margin: 5px 0;
`;

export const RadioButtonSelectMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${palettes.system_gray};
  p{
    margin: 0;
  }
`;

export const DisabledSpan = styled.span`
  color: ${palettes.system_gray_darker}
`;

