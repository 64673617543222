import { Checkbox } from "../../Checkbox"
import { SearchLocationFilterBackground } from "./styles"

export function SearchLocationFilter(props) {

    const fields = props.data.checkboxes.map((item, i) => 
        <div key={i} className="d-flex w-50">
            <Checkbox />
            <p>{item}</p>
        </div>
    )

    return(
        <SearchLocationFilterBackground>
            <hr/>
            <p>Tipos de local</p>
            <div className="SearchLocationFilterOptions">
                {fields}
            </div>
        </SearchLocationFilterBackground>
    );
}