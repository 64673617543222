import { Col, Row } from "react-bootstrap";
import IconPhone from "../../assets/images/ic-contact-phone.svg";
import IconTopics from "../../assets/images/ic-help-topics.svg";
import {
  CustomerSupportButtonLink,
  CustomerSupportContent,
  CustomerSupportIcon,
  CustomerSupportInformationCard,
  CustomerSupportListItens,
  CustomerSupportSubtitle,
  CustomerSupportTitle
} from "./styles";

export function CustomerSupportBlocks(props) {
  const data = props.data.cards;
  return (
    <CustomerSupportContent>
      <Row className="g-0 mx-0">
        <Col lg={6} md={12}>
          <CustomerSupportInformationCard>
            <Row>
              <Col lg={2} md={12} className="g-0">
                <CustomerSupportIcon icon={IconTopics} alt="Icon Topics" />
              </Col>
              <Col lg={10} md={12} className="g-0">
                <CustomerSupportTitle>{data[0].title}</CustomerSupportTitle>
                <CustomerSupportSubtitle>
                  {data[0].subtitle}
                </CustomerSupportSubtitle>
                <CustomerSupportListItens type="topics">
                  {data[0].links.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.url}>{item.title}</a>
                      </li>
                    );
                  })}
                </CustomerSupportListItens>
                <CustomerSupportButtonLink
                  variant="link"
                  href="/suporte-ao-cliente/faq"
                >
                  {data[0].textButton}
                </CustomerSupportButtonLink>
              </Col>
            </Row>
          </CustomerSupportInformationCard>
        </Col>
        <Col lg={6} md={12}>
          <CustomerSupportInformationCard>
            <Row>
              <Col lg={2} md={12} className="g-0">
                <CustomerSupportIcon icon={IconPhone} alt="Icon Phone" />
              </Col>
              <Col lg={10} md={12} className="g-0">
                <CustomerSupportTitle>{data[1].title}</CustomerSupportTitle>
                <CustomerSupportSubtitle>
                  {data[1].subtitle}
                </CustomerSupportSubtitle>
                <CustomerSupportListItens type="phone">
                  {data[1].itemsContent.map((item, index) => {
                    return (
                      <>
                        <li
                          key={index}
                          className="w-100 d-flex justify-content-betwen mr-5"
                        >
                          <div className="w-50">
                            <span>{item.textContentPrimary}</span>
                          </div>
                          <div className="d-flex justify-content-evenly w-50 mr-3">
                            <a
                              href={`tel:${item.textContentSecondary}`}
                              className="d-flex text-right"
                            >
                              {item.textContentSecondary}
                            </a>
                          </div>
                        </li>
                        <br />
                      </>
                    );
                  })}
                </CustomerSupportListItens>
              </Col>
            </Row>
          </CustomerSupportInformationCard>
        </Col>
      </Row>
    </CustomerSupportContent>
  );
}
