export const PostReducer = {
    addPostItem: (state, {payload}) => {
        const {post} = payload;
        state.featuredPage.posts.push(post);
    },
    updatePostItem: (state, { payload }) => {
        const { posts } = payload;
        state.featuredPage.posts = posts;
    },
    removePostItem: (state, {payload}) => {
        const {post} = payload;
        state.featuredPage.removedPost = post;
        state.featuredPage.posts = state.featuredPage.posts.filter(
            (p) => p.id !== post.id
        );
    }
};

export default PostReducer;
