import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  createSubcategory,
  fetchCategory,
  fetchSubcategory,
  updateCategory,
  updateSubcategory,
} from "redux/blog/blogService";
import { blogSlice } from "redux/blog/blogSlice";
import { BlogPageType, BlogStatus } from "redux/blog/constants";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import {
  Container,
  GreyRetangle,
  InputWapper,
  PageConfigButton,
  PageConfigContainer,
} from "./styles";
const CategoryEdition = () => {
  const { setCancelEdition, setBlogPageType } = blogSlice.actions;
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const categoryEditData = useSelector(
    (state) => state?.blog?.categoryEditData
  );
  const categoryStatus = useSelector((state) => state?.blog?.status);
  const errorMsg = useSelector((state) => state?.blog?.msgError);

  const blogPageType = useSelector((state) => state?.blog?.blogPageType);
  const categories = useSelector((state) => state?.blog?.categories);
  useEffect(() => {
    dispatch(fetchCategory());
    if (
      blogPageType === BlogPageType.ADD_CATEGORY ||
      blogPageType === BlogPageType.ADD_SUBCATEGORY
    ) {
      setData({
        ...data,
        nome: undefined,
        id: undefined,
      });
    }
  }, []);
  useEffect(() => {
    let message;
    let type;
    switch (categoryStatus) {
      case "updateCategory-failed":
        message = errorMsg ?? "Erro ao atualizar Categoria";
        type = "error";
        break;
      case "updateCategory-success":
        message = "Categoria atualizado com sucesso";
        type = "success";
        dispatch(setBlogPageType(BlogPageType.CATEGORY_LIST));
        dispatch(fetchCategory());
        break;
      case "createCategory-failed":
        message = errorMsg ?? "Erro ao criar Categoria";
        type = "error";
        break;
      case "createCategory-success":
        message = "Categoria criada com sucesso";
        type = "success";
        dispatch(setBlogPageType(BlogPageType.CATEGORY_LIST));
        dispatch(fetchCategory());
        break;
      case "updateSubcategory-failed":
        message = errorMsg ?? "Erro ao atualizar Sub-categoria";
        type = "error";
        break;
      case "updateSubcategory-success":
        message = "Sub-categoria atualizado com sucesso";
        type = "success";
        dispatch(setBlogPageType(BlogPageType.CATEGORY_LIST));
        dispatch(fetchSubcategory());
        break;
      case "createSubcategory-failed":
        message = errorMsg ?? "Erro ao criar Sub-categoria";
        type = "error";
        break;
      case "createSubcategory-success":
        message = "Sub-categoria criada com sucesso";
        type = "success";
        dispatch(setBlogPageType(BlogPageType.CATEGORY_LIST));
        dispatch(fetchSubcategory());
        break;

      default:
        return;
    }
    if (message) {
      dispatch(displaySnackbar({ message, type }));
    }
  }, [categoryStatus]);

  useEffect(() => {
    if (categoryEditData?.id) setData(categoryEditData);
  }, [categoryEditData]);

  const handleSubmit = (formData) => {
    formData.preventDefault();
    if (
      blogPageType === BlogPageType.EDIT_CATEGORY ||
      blogPageType === BlogPageType.ADD_CATEGORY
    ) {
      if (data?.id) {
        dispatch(
          updateCategory({
            ...data,
            name: formData.target.name.value?.trim(),
            status: BlogStatus.ENABLED,
          })
        );
      } else {
        dispatch(
          createCategory({
            name: formData.target.name.value?.trim(),
            status: BlogStatus.ENABLED,
          })
        );
      }
    } else {
      if (data?.id) {
        dispatch(
          updateSubcategory({
            ...data,
            name: formData.target.name.value?.trim(),
            categoryId: formData.target.categoryId.value,
            status: BlogStatus.ENABLED,
          })
        );
      } else {
        dispatch(
          createSubcategory({
            name: formData.target.name.value?.trim(),
            categoryId: formData.target.categoryId.value,
            status: BlogStatus.ENABLED,
          })
        );
      }
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit} id={`CategoryEdition`}>
        <InputWapper className="mr10">
          <GreyRetangle />
          <div className="content">
            <span>Nome da Categoria</span>
            <input
              defaultValue={data?.name || ""}
              name="name"
              placeholder="Nome da Categoria"
              required
            />
          </div>
        </InputWapper>
        {(blogPageType === BlogPageType.EDIT_SUBCATEGORY ||
          blogPageType === BlogPageType.ADD_SUBCATEGORY) && (
          <InputWapper className="mr10">
            <GreyRetangle />
            <div className="content">
              <span>Categoria Superior</span>

              <select
                name="categoryId"
                id="categoryId"
                form={`CategoryEdition`}
                value={data?.categoryId}
                onChange={(event) => {
                  setData({ ...data, categoryId: event.target.value });
                }}
                required
              >
                <option></option>
                {categories?.map((item, idx) => (
                  <option key={idx} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </InputWapper>
        )}
        <PageConfigContainer>
          <PageConfigButton
            theme={{ mode: "inverse" }}
            onClick={() => {
              dispatch(setBlogPageType(BlogPageType.CATEGORY_LIST));
              dispatch(setCancelEdition());
            }}
          >
            Cancelar
          </PageConfigButton>
          <PageConfigButton type="submit" theme={{ mode: "normal" }}>
            Salvar
          </PageConfigButton>
        </PageConfigContainer>
      </form>
    </Container>
  );
};

export default CategoryEdition;
