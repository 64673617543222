export const PageReducer = {
  setPageType: (state, { payload }) => {
    state.pageType = payload;
  },
  setPageCreation: (state, { payload }) => {
    state.isPageCreation = true;
    if (state.isPageCreation && payload) {
      state.currentPage = payload.currentPage;
      state.parentPage = payload.parentPage;
    } else {
      state.currentPage = {};
      state.parentPage = {};
    }
  },
  setCancelPageCreation: (state) => {
    state.isFooterEditing = false;
    state.isHeadersEditing = false;
    state.isPageCreation = false;
    state.currentPage = {};
    state.parentPage = {};
    state.canvasPage = {};
  },
  setParentId: (state, { payload }) => {
    state.isPageCreation = true;
    state.canvasPage = {
      parentId: payload
    };
  },
  setPageName: (state, { payload }) => {
    state.canvasPage = {
      ...state.canvasPage,
      name: payload
    };
  },
  setPageUrl: (state, { payload }) => {
    state.canvasPage = {
      ...state.canvasPage,
      url: payload
    };
  },
  setPageTags: (state, { payload }) => {
    state.canvasPage = {
      ...state.canvasPage,
      metaTags: payload
    };
  },
  setPageComponents: (state, { payload }) => {
    state.canvasPage = {
      ...state.canvasPage,
      components: payload?.filter((p) => p !== null && p !== undefined)
    };
  },
  setPageChange: (state, { payload }) => {
    state.canvasPage = {
      ...state.canvasPage,
      status: payload
    };
  },
  setDuplicateComponent: (state, { payload }) => {
    state.selectParentPage = payload;
  }
};

export default PageReducer;
