import React from "react";
import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import { GreyRetangle, MetaTagEditorWapper, StyledTagInput } from "./styles";

const MetaTagEditor = ({ tags, onTagChange }) => {
  return (
    <MetaTagEditorWapper>
      <GreyRetangle />
      <p className="mx-2">
        <b>#</b>
      </p>
      <StyledTagInput value={tags} onChange={onTagChange} addOnBlur={true} />
    </MetaTagEditorWapper>
  );
};

export default MetaTagEditor;
