import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import categoriesData from "./Resources/categories.json";
import featureData from "./Resources/features.json";
import { fetchVehicles } from "./vehiclesService";

const vehiclesAdapter = createEntityAdapter();
const filterInitial = {
  country: {
    code: "BR",
    name: "Brasil"
  },
  categories: []
};
const initialState = vehiclesAdapter.getInitialState({
  status: "",
  vehicles: [],
  filteredVehicles: [],
  features: [],
  categories: [],
  filter: filterInitial,
  error: null
});

export const vehiclesSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    getFilters: (state) => {
      state.features = featureData;
    },
    getFilteredVehicles: (state) => {
      filterVehicles(state);
    },
    setFilterCategory: (state, { payload }) => {
      if (state.filter.categories?.some((e) => e === payload)) {
        state.filter.categories = state.filter.categories.filter(
          (i) => i !== payload
        );
      } else {
        state.filter.categories = [...state.filter.categories, payload];
      }
    },
    setFilterCategoryQuery: (state, { payload }) => {
      state.filter.categories = [payload];
    },
    setFilterCountry: (state, { payload }) => {
      state.filter = { ...state.filter, country: payload };
      getCategories(state);
    },
    setFilterPassengers: (state, { payload }) => {
      state.filter = { ...state.filter, passengers: payload };
    },
    setFilterTransmition: (state, { payload }) => {
      state.filter = { ...state.filter, transmition: payload };
    }
  },
  extraReducers: {
    [fetchVehicles.pending]: (state) => {
      state.status = "loading";
    },
    [fetchVehicles.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.vehicles = payload;
      state.filter = filterInitial;
      getCategories(state);
      filterVehicles(state);
    },
    [fetchVehicles.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    }
  }
});

const getCategories = (state) => {
  if (state.vehicles && state.vehicles.length > 0) {
    const allCategories = state.vehicles.reduce((acc, current) => {
      if (
        current.country === state.filter.country.code &&
        current.otavehCategory &&
        acc.indexOf(current.otavehCategory) === -1
      ) {
        acc.push(current.otavehCategory);
      }
      return acc;
    }, []);

    const filteredCategories = allCategories.map((r) =>
      categoriesData.find((x) => x.id === r)
    );
    state.categories = filteredCategories;
  } else {
    state.categories = categoriesData;
  }
};
const filterVehicles = (state) => {
  let vehicles = [...state.vehicles];
  const filter = { ...state.filter };
  vehicles = [...vehicles.filter((x) => x.country === filter.country.code)];
  if (filter.categories.length > 0) {
    vehicles = [].concat.apply(
      [],
      filter.categories.map((r) =>
        vehicles.filter((x) => x.otavehCategory === r)
      )
    );
  }
  if (filter.passengers) {
    vehicles = vehicles.filter(
      (x) => x.passengerCapacity === filter.passengers
    );
  }
  if (filter.transmition) {
    vehicles = vehicles.filter((x) => x.transmission === filter.transmition);
  }
  state.filteredVehicles = vehicles;
};

export const {
  getFilters,
  getFilteredVehicles,
  setFilterCategory,
  setFilterCategoryQuery,
  setFilterCountry,
  setFilterPassengers,
  setFilterTransmition
} = vehiclesSlice.actions;
export default vehiclesSlice.reducer;
