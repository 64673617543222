import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import theme from "styled-theming";
import { palettes, fonts, buttons } from "assets/theme";

const sizeWidthLeafParentBefore = theme("mode", {
  show: "10px",
  hide: "0"
});
const sizeWidth = theme("mode", {
  show: "1px",
  hide: "0"
});
export const Container = styled.div`
  background: #fff;
  min-height: 100%;
  padding: 3px 0 15px 0;
`;

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  color: #213b73;
`;

export const Description = styled.p`
  font-family: Poppins;
  font-size: 14px;
  color: #213b73;
`;

export const ContainerTree = styled.div`
  font-family: Poppins;
  font-size: 14px;
  color: #213b73;
  position: relative;
  margin-left: -16px;
  .icon-home {
    margin-left: 18px;
  }
`;
export const ContainerLeaf = styled.div`
  flex-direction: column;
  margin-left: 25px;
  position: relative;
`;

export const ContainerLeafParent = styled.div`
  display: flex;
  padding: 8px 0 8px 8px;
  .leaf {
    display: flex;
    cursor: pointer;
    max-height: 21px;
    margin-right: 10px;
  }
  .icon-container {
    margin-right: 10px;
    margin-top: 0px;
  }

  position: relative;
  :before {
    position: absolute;
    top: 19px;
    bottom: 16px;
    left: -6px;
    width: ${sizeWidthLeafParentBefore};
    content: " ";
    background: #213b73;
    display: block;
  }
  :after {
    position: absolute;
    top: -8px;
    bottom: 18px;
    left: -6px;
    width: ${sizeWidth};
    content: " ";
    background: #213b73;
    display: block;
  }
`;
export const ContainerLeafChild = styled.div`
  flex-direction: column;
  margin-left: 12px;
  position: relative;
  &:after {
    position: absolute;
    top: -6px;
    bottom: 8px;
    left: -18px;
    width: ${sizeWidth};
    content: " ";
    background: #213b73;
    display: block;
  }
`;
export const DropdownActions = styled(Dropdown)``;
export const ButtonDropdownActions = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  background: #213b73;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ButtonAddPage = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  background: transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 25px;
  padding-left: 16px;
  :after {
    position: absolute;
    top: -8px;
    bottom: 7px;
    left: -6px;
    width: 1px;
    content: " ";
    background: #213b73;
    display: block;
  }
  &:before {
    position: absolute;
    top: 11px;
    bottom: 7px;
    left: -6px;
    width: 10px;
    content: " ";
    background: #213b73;
    display: block;
  }
`;
export const DropdownItemContainer = styled.div`
  flex-direction: column;
  font-family: Poppins;
  font-size: 14px;
  color: #213b73;
`;
export const DropdownItemContent = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 11px;
  }
`;
export const Spacer = styled.hr`
  margin: 0 10px;
  background-color: #213b73;
`;
export const Checkbox = styled.input`
  margin-right: 5px;
  margin-top: 3px;
`;
export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 8px solid ${palettes.system_gray};
  padding: 8px;
`;
export const SelectWrap = styled.div`
  border: 1px solid ${palettes.system_gray_darker};
`;
export const SelectLabel = styled.span`
  ${fonts.paragraph_bold}
  color:${palettes.tints_blue_darker};
  margin-left: 3px;
`;
export const Select = styled.select`
  border: none;
  ${fonts.paragraph}
  color:${palettes.tints_blue_darker};
  outline: none;
`;
export const Separetor = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  background-color: #213b73;
`;
export const AddButton = styled.button`
  ${buttons.secondary};
  width: 100%;
  height: 50px;
  margin-top: 20px;
`;
