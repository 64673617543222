import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "@api/api";
import getAxios from "api/api";

export const fetchCategory = createAsyncThunk(
  "location/fetchCategory",
  async (status) => {
    const response = await getAxios().get(
      `/blog/category${status !== undefined ? `?status=${status}` : ""}`
    );
    return response.data;
  }
);

export const createCategory = createAsyncThunk(
  "blog/createCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAxios().post("/blog/category", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateCategory = createAsyncThunk(
  "blog/updateCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAxios().put(
        `/blog/category/update/${payload.id}`,
        payload
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const bulkUpdateCategory = createAsyncThunk(
  "blog/bulkUpdateCategory",
  async (payload) => {
    const response = await getAxios().patch(`/blog/category`, payload);
    return response.data;
  }
);
export const fetchSubcategory = createAsyncThunk(
  "location/fetchSubcategory",
  async (status) => {
    const response = await getAxios().get(
      `/blog/category/subcategory${
        status !== undefined ? `?status=${status}` : ""
      }`
    );
    return response.data;
  }
);
export const createSubcategory = createAsyncThunk(
  "blog/createSubcategory",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAxios().post(
        "/blog/category/subcategory",
        payload
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateSubcategory = createAsyncThunk(
  "blog/updateSubcategory",
  async (payload) => {
    const response = await getAxios().put(
      `/blog/category/subcategory/update/${payload.id}`,
      payload
    );
    return response.data;
  }
);
export const bulkUpdateSubcategory = createAsyncThunk(
  "blog/bulkUpdateSubcategory",
  async (payload) => {
    const response = await getAxios().patch(
      `/blog/category/subcategory`,
      payload
    );
    return response.data;
  }
);

export const getPostByUrl = createAsyncThunk(
  "blog/getPostByUrl",
  async (url) => {
    const response = await getAxios().get("/blog/url" + url);
    return response.data;
  }
);

export const getHighlights = createAsyncThunk(
  "blog/getHighlights",
  async (user) => {
    const response = await getAxios().get("/highlight/all", user);
    return response.data;
  }
);
