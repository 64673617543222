import styled from "styled-components";
import theme from "styled-theming";
const side = theme("mode", {
  left: "25%",
  rigth: "70%"
});

const sideUltraWide = theme("mode", {
  left: "22%",
  rigth: "75%"
});

const margin = theme("mode", {
  left: "0",
  rigth: "0 20px 0 0"
});

export const Container = styled.div`
  background: #e4e4e4;
  position: absolute;
  height: 100%;
  width: 100%;
`;
export const HeaderContainer = styled.div`
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
`;

export const BodyContainer = styled.div`
  position: fixed;
  margin-top: 116px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: auto;
  align-content: flex-start;
  z-index: 1;
`;
export const BodyItemContainer = styled.div`
  width: ${side};
  margin: ${margin};
  @media (min-width: 1440px) {
    width: ${sideUltraWide};
  }
  height: calc(100vh - 140px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 180px;
    width: 4px;
    background: #a6adb4;
  }
`;
export const BackButtonLink = styled.a`
  position: absolute;
  color: #213b73;
  text-decoration: none;
  top: 78px;
  left: 14px;
  svg {
    margin-top: -2px;
  }
  span {
    margin-left: 10px;
  }
`;
