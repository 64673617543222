import styled from "styled-components";

export const VehicleCardBackground = styled.div`
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #213B73;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    height: 350px;
    h5{
        text-align: center;
        font-weight: bold;
    }
    a{
        color: #8C4C9E !important;
    }
    img{
        height: 18px;
        filter: invert(15%) sepia(73%) saturate(1652%) hue-rotate(203deg) brightness(93%) contrast(85%);
    }
    #carName{
        font-weight: 400;
    }
`;

export const VehicleCardVehicleImage = styled.img`
    height: 130px !important;
    @media(max-width: 1400px){
        width: 170 !important;
        height: 150 !important;
    }
    filter: none !important;
`;