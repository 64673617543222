import { PrivacyPoliciesContainer } from "./styles";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import footerData from "@components/Footer/Resources/example.json";
import headerData from "../Contact/Resources/example.json";

export function PrivacyPolicies() {
  return (
    <>
      <Header data={headerData} />
      <PrivacyPoliciesContainer>
        <h1>POLÍTICA DE PRIVACIDADE GLOBAL</h1>
        <span>Esta política foi atualizada pela última vez em 2022.02.21.</span>
        <p>
          Na Enterprise Holdings, Inc., por meio de nossas{" "}
          <a href="/subsidiarias">subsidiárias </a>regionais independentes
          (“subsidiárias”) que operam as marcas Enterprise Rent-A-Car, National
          Car Rental, Alamo Rent A Car, e Discount Car and Truck Rental, bem
          como a Truck Rental, Car Sales, CarShare e Car Club, e Subscribe with
          Enterprise (coletivamente, “Enterprise Holdings”, “nós”, “nosso” ou
          “nos”), respeitamos a sua privacidade.  Implementamos esta política de
          privacidade (&quot;Política de Privacidade&quot;) e a política de
          cookies (&quot;<a href="/politica-de-cookies">Política de Cookies</a>
          &quot;) para explicar nossas práticas de coleta, uso, divulgação e
          transferência de dados, bem como os direitos de acesso individuais que
          você pode ter ao usar nossos sites e serviços.  Você pode saber mais
          nas seções abaixo.
        </p>
        <h3>Quais informações coletamos</h3>
        <h3>Para todas as transações:</h3>
        <p>Coletamos:</p>
        <p>
          <b>INFORMAÇÕES DE CONTATO</b>, incluindo:
        </p>
        <ul>
          <li>Nome</li>
          <li>Endereço</li>
          <li>Número de telefone</li>
          <li>Endereço de e-mail</li>
        </ul>
        <h3>Ao alugar</h3>
        <p>
          Ao alugar um veículo, o que inclui ser adicionado como motorista
          adicional autorizado, inscrever-se na Enterprise ou ingressar na
          CarShare ou no Car Club, podemos coletar:
        </p>
        <p>
          <b>Qualificações do motorista e verificação de identificação</b>,
          incluindo:
        </p>
        <ul>
          <li>
            Dados da carteira de motorista, incluindo data de nascimento e
            fotografias que foram enviadas por você.
          </li>
          <li>Informações de seguro</li>
          <li>Passaporte</li>
          <li>Comprovante de residência</li>
          <li>Referências pessoais</li>
        </ul>
        <p>
          <b>Informações de fidelidade e afiliação,</b> incluindo:
        </p>
        <ul>
          <li>
            Informações sobre prêmios e fidelidade da Enterprise, Alamo ou
            National
          </li>
          <li>Detalhes da conta de usuário on-line</li>
          <li>Números de ID da conta corporativa</li>
          <li>Números da associação</li>
          <li>Afiliações de passageiro frequente ou parceiro de viagem</li>
        </ul>
        <p>
          <b>Informações de sinistros</b>
        </p>
        <ul>
          <li>
            Informações relacionadas a detalhes de acidentes envolvendo nossos
            veículos, incluindo os de terceiros, como indivíduos envolvidos,
            testemunhas e boletins de ocorrência
          </li>
        </ul>
        <p>
          <b>Informações de saúde</b>, incluindo
        </p>
        <ul>
          <li>
            Informações sobre suas condições de saúde relevantes que nos ajudam
            a fornecer dispositivos de condução adaptáveis; ou relacionadas a
            sinistros envolvendo ferimentos
          </li>
        </ul>
        <p>
          <b>Informações opcionais</b>, incluindo
        </p>
        <ul>
          <li>Informações de contato de emergência</li>
          <li>
            Quaisquer preferências ou requisitos especiais que você possa ter
          </li>
        </ul>
        <h3>Ao comprar um veículo</h3>
        <p>
          Além{" "}
          <b>
            das informações de contato, verificação de identidade e informações
            de qualificação do{" "}
          </b>
          listadas acima, quando você compra um veículo por meio da Enterprise
          Car Sales e solicita financiamento, também coletaremos:
        </p>
        <p>
          <b>Informações de histórico pessoal e financeiro</b>, incluindo:
        </p>
        <ul>
          <li>Renda</li>
          <li>Histórico de crédito</li>
          <li>Histórico profissional</li>
          <li>
            Outras informações pessoais necessárias para concluir a aplicação de
            crédito
          </li>
        </ul>
        <p>
          <b>Informações sobre a transação de venda de carro</b>, incluindo:
        </p>
        <ul>
          <li>Veículo comprado</li>
          <li>Preço de compra</li>
          <li>Veículo oferecido como parte do pagamento</li>
          <li>Produtos adicionais adquiridos</li>
        </ul>
        <h3>
          Ao entrar em contato conosco para obter os serviços de Assistência de
          Gerenciamento de Acidentes
        </h3>
        <p>
          Além{" "}
          <b>
            das informações de contato, qualificação de condução, informações
            transacionais e de saúde{" "}
          </b>
          listadas informações de contato, qualificação de condução, informações
          transacionais e de saúde listadas
        </p>
        <p>
          <b>Informações de sinistros:</b>, incluindo
        </p>
        <ul>
          <li>
            Informações relacionadas a detalhes de acidentes, incluindo
            quaisquer terceiros, como indivíduos envolvidos, testemunhas e
            boletins de ocorrência
          </li>
          <li>Detalhes do seguro</li>
          <li>
            Informações sobre o incidente para determinar a parte causadora
          </li>
          <li>Detalhes do veículo</li>
          <li>Detalhes de reparo de crédito</li>
          <li>
            detalhes sobre as necessidades de reparo do seu veículo e suas
            necessidades de mobilidade
          </li>
        </ul>
        <h3>Ao usar nossos sites e aplicativos</h3>
        <p>Coletamos:</p>
        <p>
          <b>Informações do navegador,</b> incluindo:
        </p>
        <ul>
          <li>Logs da Web</li>
          <li>Geolocalização</li>
        </ul>
        <p>
          <b>Informações do dispositivo,</b> incluindo:
        </p>
        <ul>
          <li>Identificadores exclusivos de dispositivos</li>
          <li>Endereço IP</li>
          <li>Sistema operacional</li>
          <li>Informações de geolocalização precisa</li>
        </ul>
        <p>
          Com seu consentimento, acessaremos informações baseadas em
          localização, geolocalização precisa, CEP, código postal, cidade, área
          metropolitana ou região geográfica a partir do seu dispositivo móvel.
          Usaremos essas informações para lhe fornecer notificações relacionadas
          a nossas agências, informações de devolução, ofertas locais ou outras
          informações que possam ser úteis no fornecimento de melhores serviços.
          Só podemos identificar você usando as informações baseadas em
          localização se permitir que nossos aplicativos acessem os serviços de
          localização do seu dispositivo. As informações baseadas em localização
          ficam guardadas por sete anos. Esses dados são hospedados por nossos
          provedores de análise.
        </p>
        <p>
          Com seu consentimento, nosso aplicativo utilizará a câmera de seu
          dispositivo.
        </p>
        <p>
          Se você nos concedeu a permissão para a utilização das suas
          informações de localização ou da câmera de seu dispositivo e deseja
          revogar as permissões, altere as configurações no seu dispositivo.
        </p>
        <p>
          <b>Cookies e outras tecnologias de rastreamento</b>
        </p>
        <p>
          Nós (e nossos prestadores de serviços) usamos cookies e web beacons em
          nossos site para coletar informações que lembrem suas preferências,
          melhorem a experiência do usuário e personalizem os anúncios que você
          vê para exibir aqueles que são mais relevantes para você.
        </p>
        <p>
          Para obter mais informações sobre a coleta de informações on-line e
          sobre como cancelar seu consentimento, consulte nossa 
          <a href="/politica-de-cookies">Política de Cookies</a>
        </p>
        <h3>Ao participar de pesquisas ou promoções</h3>
        <p>Coletamos:</p>
        <ul>
          <li>Preferências de marketing</li>
          <li>Opiniões sobre os serviços fornecidos</li>
        </ul>
        <h3>Informações que coletamos de terceiros</h3>
        <p>
          Terceiros nos fornecem informações adicionais sobre você ou sua
          família, incluindo:
        </p>
        <ul>
          <li>
            Fontes de referência, como sites de reservas on-line de terceiros
            (que nos fornecem informações gerais de contato e um número de
            confirmação de reserva para facilitar o aluguel), seu empregador
            (informações gerais de contato), cooperativas de crédito e seu
            provedor de seguros (informações gerais sobre contato e apólices);
          </li>
          <li>
            Quando for o caso, nossos programas &quot;Indique um Amigo&quot; ou
            similares;
          </li>
          <li>
            Hotéis, companhias aéreas e clubes de associação, que nos fornecem
            suas informações gerais de contato e informações sobre reservas;
          </li>
          <li>
            Terceiros que, para fins de prevenção de fraudes, nos fornecem
            verificação relacionada à identidade e autenticação de documentos;
          </li>
          <li>
            Parceiros de crédito e financiamento, incluindo agências de
            informação de crédito, que facilitam a conclusão de aplicações de
            crédito ao financiar uma compra de veículo;
          </li>
          <li>
            Franqueados, que nos fornecem informações sobre suas transações de
            aluguel;
          </li>
          <li>
            Prestadores de serviços, tais como atendimento ao cliente
            (informações relacionadas com o serviço ao cliente e solicitações
            feitas por você), serviços de segurança (filmagens do usuário a
            partir do nosso sistema CFTV) e provedores de pesquisas de
            satisfação (sua resposta às nossas pesquisas de avaliação de
            serviço);
          </li>
          <li>
            Provedores de marketing que fornecem informações para nos ajudar a
            comunicar melhor nossos produtos e serviços, incluindo serviços de
            aquisição de e-mails, serviços de higiene de dados, corretores de
            dados, e parceiros e plataformas de pesquisa de mercado.
          </li>
          <li>Conteúdo de mídias sociais que você disponibilizou ao público</li>
        </ul>
        <h3>Nossos Veículos</h3>
        <p>
          <b>Carros Conectados e Dados de Telemática</b>
        </p>
        <p>
          Nossos veículos podem estar equipados com dispositivos telemáticos,
          como gravadores de dados de eventos pré-instalados, dispositivos de
          posicionamento global, OnStar® e outros sistemas de comunicação que
          possam estar conectados à Internet ou a serviços de celular. Nós (e,
          às vezes, o fabricante do veículo e outros terceiros autorizados)
          podemos coletar informações desses dispositivos telemáticos e de
          outros serviços e tecnologias, e usá-las e compartilhá-las conforme
          descrito abaixo ou conforme estabelecido nesta Política de
          Privacidade. Essas informações são associadas apenas ao veículo e
          mantidas como dados desidentificados ou anônimos e serão vinculadas
          apenas para os fins e as situações descritas a seguir.  Todas as
          informações vinculadas a você serão tratadas como informações pessoais
          de acordo com esta Política de Privacidade.
        </p>
        <p>
          Você também pode optar por aceitar ou comprar dispositivos opcionais
          que permitem coletar determinados dados do veículo.  Consulte os
          termos e condições do fabricante e a política de privacidade.
        </p>
        <p>
          A utilização de dispositivos telemáticos e outros serviços e
          tecnologias pré-instalados está sujeita aos nossos termos e condições
          de aluguel e, em alguns casos, aos termos e condições e à política de
          privacidade do fabricante do veículo e/ou do provedor de tecnologia. 
          Para o OnStar, as informações estão disponíveis em 
          <a href="https://www.onstar.com.">www.onstar.com.</a>  Para outros
          fabricantes de veículos, acesse {" "}
          <a href="/fabricantes-de-veiculos">aqui </a>.  Alguns ou todos esses
          recursos podem ser ativados o tempo todo, mesmo quando outros serviços
          ou outras mídias no veículo estão desligados.  Se não tiver certeza se
          o seu veículo de aluguel contém dispositivos ou tecnologias
          telemáticas ou sistemas, fale com um membro da nossa equipe na agência
          de aluguel.
        </p>
        <p>Coletamos e usamos os dados do veículo da seguinte forma:</p>
        <ul>
          <li>
            Informações sobre combustível: utilizamos essas informações para
            facilitar o registro e a cobrança de taxas de combustível.
          </li>
          <li>
            Informações do odômetro: utilizamos essas informações para facilitar
            a cobrança de taxas de quilometragem (quando aplicável) e para nos
            ajudar a gerenciar nossa frota.
          </li>
          <li>
            Informações de diagnóstico e desempenho do veículo: utilizamos essas
            informações para nos ajudar a identificar as necessidades de
            manutenção do veículo e gerenciar a frota.
          </li>
          <li>
            Informações para nos ajudar a emitir determinados comandos para o
            veículo a fim de facilitar a sua transação no CarShare ou no Car
            Club: por exemplo, coletamos informações que nos permitem destrancar
            ou trancar remotamente as portas do veículo.
          </li>
          <li>
            Informações de localização: coletamos e usamos as informações de
            localização do veículo para:
            <ul>
              <li>
                Permitir que nossos fornecedores de assistência nas estradas
                prestem esse serviço a você.
              </li>
              <li>
                Notificar os fornecedores de serviços de emergência para
                enviarem esses serviços a você.
              </li>
              <li>
                Ajudar-nos a recuperar um veículo que temos motivo para achar
                que esteja perdido, tenha sido roubado ou abandonado.
              </li>
              <li>
                Facilitar o horário de início e de término para fins de cobrança
                do CarShare e do Car Club.
              </li>
            </ul>
          </li>
          <li>
            Gravadores de dados de eventos: em determinadas situações de
            sinistros, podemos usar essas informações em conexão com
            investigações sobre o acidente e esforços relacionados a sinistros.
          </li>
          <li>
            Divulgação obrigatória por lei e circunstâncias de emergência:
            podemos compartilhar informações sobre veículos, incluindo
            informações de localização, em circunstâncias de emergência ou
            obrigatórias por lei.  Além disso, no Reino Unido e na União
            Europeia, nossos veículos devem estar equipados com a tecnologia
            eCall, que é ativada caso o veículo se envolva em um acidente. O
            eCall telefonará automaticamente e fornecerá informações sobre o
            acidente aos serviços de emergência. Também usaremos, divulgaremos e
            teremos acesso a essas informações.
          </li>
        </ul>
        <p>
          Mantemos informações sobre veículos com base em períodos de retenção,
          de acordo com nossas necessidades comerciais e requisitos
          legais.  Podemos usar e armazenar esses dados após o término de nosso
          contrato com você.
        </p>
        <p>
          <b>
            Sistemas de Infoentretenimento e Aplicativos do Fabricante do
            Veículo
          </b>
        </p>
        <p>
          Se você emparelhar um dispositivo móvel com os sistemas de navegação
          ou infoentretenimento de nossos veículos e optar por usar o OnStar,
          Apple CarPlay, Android Auto ou outros softwares ou serviços de
          terceiros semelhantes no veículo, as informações pessoais e outros
          dados poderão ser transferidos do dispositivo móvel e armazenados
          nesses sistemas. Não podemos garantir a privacidade ou
          confidencialidade dessas informações. Você deve excluir todas essas
          informações pessoais e outros dados desses sistemas antes da devolução
          do veículo para evitar que os ocupantes subsequentes do veículo
          acessem essas informações.
        </p>
        <p>
          Se você baixar um aplicativo disponibilizado pelo fabricante do
          veículo ou por terceiros e registrar o veículo nesse aplicativo, seu
          uso do aplicativo poderá resultar no compartilhamento de dados
          telemáticos e outras informações (inclusive informações pessoais e de
          localização) com o fabricante do veículo ou com terceiros. O uso
          desses aplicativos é estritamente regido pelos termos e condições e
          pela política de privacidade dos aplicativos móveis de terceiros.
          Antes de devolver o veículo, você deve remover o aplicativo do seu
          dispositivo móvel ou excluir o veículo do aplicativo.
        </p>
        <p>
          <b>Programas de fidelidade</b>
        </p>
        <p>
          Você tem a opção de se inscrever em um de nossos programas de
          fidelidade.  Esses programas oferecem benefícios relacionados às suas
          transações conosco.
        </p>
        <p>
          Não há obrigação de participar de um desses programas, no entanto, seu
          empregador pode optar por usar nosso programa de fidelidade para
          facilitar o plano de viagens da empresa.  Se você ou sua empresa optar
          por se inscrever em um desses programas, você poderá cancelar sua
          associação a qualquer momento acessando o 
          <a
            rel="noreferrer"
            href="https://privacyportal.onetrust.com/webform/b7f28d27-6bb9-46d7-a7f4-c89ba7faf08f/afd4312f-7273-4b9c-b12d-828f04f67fbe"
            target="_blank"
          >
            Portal de Privacidade Global
          </a>
           em nossa página  <a href="/contato">Fale conosco</a>
        </p>
        <p>
          <b>Privacidade de crianças e adolescentes</b>
        </p>
        <p>
          Nossos sites e marketing on-line não são direcionados a pessoas com
          menos de 18 anos, ou que não tenham a idade de maioridade de seu país,
          e a Enterprise Holdings não coleta informações pessoais de forma
          deliberada deles. Se, mesmo assim, você acha que seu filho nos
          forneceu suas informações pessoais e deseja nos informar para que elas
          sejam excluídas, entre em contato conosco usando o link no topo desta
          página.
        </p>
        <h3>Como usamos as informações sobre você</h3>
        <p>
          Usamos as informações que coletamos de você ou sobre você para
          fornecer nossos serviços e com as seguintes finalidades:
        </p>
        <h3>
          Onde for necessário para a execução de um contrato que rege os
          serviços que fornecemos a você, incluindo
        </h3>
        <ul>
          <li>
            Para ajudar nas transações que você (ou alguém em seu nome)
            solicitar, como reserva, aluguel, compra, locação de veículos
            motorizados ou prestação de serviços de gerenciamento de acidentes;
          </li>
          <li>
            A menos que o consentimento possa ser exigido pela lei aplicável,
            para comunicar informações importantes sobre sua reserva, aluguel,
            locação ou compra de um veículo. Essas comunicações são de natureza
            transacional e serão enviadas a você via e-mail, mensagens de texto
            ou ligações telefônicas.
          </li>
          <li>
            Para fornecer atendimento ao cliente relacionado a suas interações
            conosco ou para responder a suas solicitações;
          </li>
          <li>
            Para inscrever você em nossos programas de fidelidade e enviar
            informações relacionadas à sua associação contínua a esses
            programas.
          </li>
          <li>
            Para inscrever você em nossas empresas CarShare e Car Club e enviar
            informações relacionadas à sua associação contínua a essas empresas.
          </li>
        </ul>
        <h3>
          Quando for necessário para nossos interesses legítimos, conforme
          listado abaixo, e quando nossos interesses não forem sobrepostos por
          seus direitos de proteção de dados, incluindo
        </h3>
        <ul>
          <li>
            Para comunicar informações importantes sobre seu aluguel e fornecer
            um excelente serviço. Essas comunicações, que são de natureza
            transacional, serão enviadas a você via e-mail, mensagens de texto
            ou ligações telefônicas;
          </li>
          <li>
            Para nos auxiliar a fornecer melhores produtos e serviços: por
            exemplo, avaliar o desempenho de nossa equipe, avaliar a qualidade
            dos produtos e serviços recebidos e nos ajudar a aprimorar nossos
            sites, instalações e serviços.  A menos que o consentimento possa
            ser exigido pela lei aplicável, sua opinião pode ser solicitada por
            e-mail, mensagens de texto ou ligação telefônica.
          </li>
          <li>
            Para coletar estatísticas e análises sobre o uso de nossos sites,
            produtos e serviços por nossos clientes. Essas estatísticas são
            usadas apenas para nos permitir oferecer um melhor serviço de
            atendimento ao cliente, produtos, recursos e funcionalidades para
            você e outros clientes no futuro;
          </li>
          <li>
            Quando permitido, para desenvolver um perfil sobre você e suas
            interações conosco para que possamos oferecer ofertas, oportunidades
            e serviços sob medida que possam ser de seu interesse;
          </li>
          <li>
            Para proteger nossos direitos legais e gerenciar a segurança de
            nossas redes e propriedades, incluindo para proteger nossos sites
            contra spam e abuso. Por exemplo, usaremos CFTV para garantir a
            segurança de nossas instalações;
          </li>
          <li>
            Para a prevenção de perdas, mantemos um registro de indivíduos cujas
            interações passadas conosco indicam que podem apresentar um risco de
            não pagamento ou segurança;
          </li>
          <li>
            Para receber, usar e compartilhar suas informações com terceiros a
            fim de evitar fraudes e proteger nossos interesses e direitos
            comerciais, de privacidade, segurança e propriedade, ou os dos
            indivíduos e do público. Faremos isso para atender à solicitação de
            cumprimento da lei, cobrar contas não pagas, para evitar a
            responsabilidade pelas penalidades incorridas por você (por exemplo,
            multas de trânsito) e para fins de processamento de sinistros;
          </li>
          <li>
            Para fins de prevenção contra fraudes, verificar a identidade e
            autenticar a documentação relacionada à identidade, bem como pontos
            de contato adicionais para comunicações relacionadas ao aluguel por
            meio de referências pessoais.
          </li>
          <li>
            Para usar e compartilhar suas informações com terceiros, a fim de
            permitir-nos buscar soluções disponíveis ou limitar os danos que
            possamos sofrer e fazer cumprir os termos de qualquer contrato de
            aluguel ou termos e condições de nossos sites;
          </li>
          <li>
            Para nos permitir usar os sistemas de dados do veículo para os fins
            listados na seção Dados do veículo acima;
          </li>
          <li>
            A menos que o consentimento possa ser exigido pela lei aplicável
            para enviar determinadas comunicações promocionais ou de marketing
            direcionado, oportunidades e serviços que possam ser do seu
            interesse. Isso incluirá convites para participar de nossos
            programas de fidelidade, anúncios de upgrade, comunicações
            relacionadas a um formulário incompleto de associação on-line ou
            reserva, e ofertas relacionadas a outros serviços e produtos que
            oferecemos. Algumas dessas ofertas serão recebidas como publicidade
            em sites que não sejam da Enterprise, incluindo em mídias sociais;
          </li>
          <li>
            Para receber, usar e compartilhar suas informações com terceiros a
            fim de evitar, detectar, denunciar fraudes e proteger nossos
            interesses e direitos comerciais, de privacidade, segurança e
            propriedade, ou os dos indivíduos e do público. Faremos isso para
            atender à solicitação de cumprimento da lei, cobrar contas não
            pagas, para evitar a responsabilidade pelas penalidades incorridas
            por você (por exemplo, multas de trânsito) e para fins de
            processamento de sinistros;
          </li>
          <li>
            Para desidentificar ou agregar informações pessoais coletadas sobre
            você e usá-las para qualquer finalidade legal; e
          </li>
          <li>
            Para transferir seus dados conforme descrito na seção
            &quot;Transferências e processamento de dados globais&quot;.
          </li>
        </ul>
        <h3>
          Quando você tiver dado consentimento, conforme exigido pela lei
          aplicável do seu país, incluindo
        </h3>
        <ul>
          <li>
            Para nos ajudar a fornecer a você os veículos mais adequados, como
            aqueles com dispositivos de condução adaptáveis. Em alguns casos,
            isso exigirá que infiramos ou coletemos explicitamente informações
            sobre a sua saúde ou condição física;
          </li>
          <li>
            Para enviar tokens de segurança de uso único via mensagem de texto
          </li>
          <li>
            Quando aplicável, para solicitar seu feedback por e-mail, mensagem
            de texto ou ligação telefônica, para nos auxiliar a fornecer
            melhores produtos e serviços: por exemplo, avaliar o desempenho de
            nossa equipe, avaliar a qualidade dos produtos e serviços recebidos
            e nos ajudar a aprimorar nossos sites, instalações e serviços.
          </li>
          <li>
            Quando aplicável, para perguntar se você deseja receber, por e-mail
            ou mensagem de texto, comunicações promocionais ou de marketing
            direcionado, oportunidades e serviços que possam ser do seu
            interesse. Isso incluirá convites para participar de nossos
            programas de fidelidade, anúncios de upgrade, ofertas e descontos,
            comunicações on-line relacionadas a um formulário incompleto de
            associação on-line ou reserva, e ofertas relacionadas a outros
            serviços e produtos que oferecemos. Algumas dessas ofertas serão
            recebidas como publicidade em sites que não sejam da Enterprise,
            incluindo em mídias sociais;
          </li>
          <li>
            Quando aplicável, use as informações do veículo para auxiliar e
            gerenciar transações com as quais você concordou e cumprir as
            obrigações estabelecidas no Contrato de Aluguel.
          </li>
          <li>
            Você pode optar por revogar seu consentimento a qualquer momento; no
            entanto, tal revogação não afeta a legalidade do processamento
            anterior a mesma.
          </li>
        </ul>
        <h3>Retenção de suas informações pessoais</h3>
        <p>
          Mantemos suas informações pessoais pelo tempo que for necessário para
          atingir nossos objetivos comerciais, conforme descrito abaixo:
        </p>
        <p>
          <b>Transações de aluguel</b>
        </p>
        <p>
          Mantemos os dados associados ao seu contrato de aluguel por até sete
          anos a partir da data do aluguel ou de acordo com nossas necessidades
          comerciais, sujeitas a outros requisitos legais locais. Mantemos
          registros de pagamentos efetuados por dois anos a partir da data da
          transação (dependendo da agência/local do aluguel).
        </p>
        <p>
          <b>Vendas de carros</b>
        </p>
        <p>
          Mantemos os dados associados à venda de um veículo por até dez anos a
          partir da data de venda, sujeitos a outros requisitos legais locais.
        </p>
        <p>
          <b>Sinistros</b>
        </p>
        <p>
          Mantemos os dados associados a sinistros envolvendo nossos locatários
          ou outros indivíduos durante a vigência do sinistro e até dez anos
          após a conclusão de nossa obrigação, sujeitos a outros requisitos
          legais
        </p>
        <p>
          <b>Consultas relacionadas ao atendimento ao cliente</b>
        </p>
        <p>
          Mantemos as consultas gerais por três anos, a menos que você faça uma
          reclamação. Nesses casos, mantemos os dados das reclamações por sete
          anos.
        </p>
        <p>
          <b>Fins de marketing e análise</b>
        </p>
        <p>
          Mantemos os dados vinculados a cookies e outros identificadores
          on-line por até três anos. Mantemos outras informações de marketing,
          como assinatura de e-mail e dados de registro de fidelidade por até
          sete anos após a sua última interação conosco.
        </p>
        <p>
          <b>Litígios e cumprimento da lei</b>
        </p>
        <p>
          Se estivermos envolvidos em litígios ou em uma investigação
          governamental ou regulatória, manteremos os dados durante o período do
          litígio ou da investigação e por cinco anos após isso. Se uma
          resolução de litígio indicar que devemos manter os dados por mais
          tempo, então, manteremos os dados pelo período necessário para
          administrar a resolução. Se fornecermos dados a agências por imposição
          da lei, manteremos um registro destes por um ano após o final da
          investigação.
        </p>
        <h3>Com quem compartilhamos informações</h3>
        <p>
          Como um fornecedor global de soluções de transporte, compartilhamos
          suas informações conforme indicado a seguir para fornecer uma
          experiência transacional perfeita.
        </p>
        <h3>Subsidiárias</h3>
        <p>
          Compartilhamos seu
          <b>
             contato, qualificação do motorista, informações transacionais, de
            fidelidade e afiliação, de saúde e opcionais, informações de
            sinistros, informações financeiras e pessoais de vendas de
            carros, e informações transacionais de venda de carros 
          </b>
          com nossas subsidiárias para fornecer as transações solicitadas e (com
          seu consentimento e quando aplicável) para fins de marketing. Consulte
          a página Subsidiárias para obter uma lista. A lista está sujeita a
          alterações de tempos em tempos, e você deverá verificá-la
          periodicamente para ver se houve alterações. Os funcionários de nossas
          subsidiárias em nossas divisões de TI, marketing, operações e
          atendimento ao cliente usarão suas informações, conforme descrito
          nesta Política de Privacidade, incluindo, se exigido pela legislação
          aplicável, para enviar a você informações sobre produtos e serviços ou
          responder às suas perguntas.
        </p>
        <h3>Franqueados</h3>
        <p>
          A natureza dos negócios e das operações exige que compartilhemos suas
          <b>
            {" "}
            informações de contato e, quando aplicável, as
            informações transacionais, de qualificação do motorista, de
            fidelidade e afiliação, e (se aplicável) de saúde, 
          </b>
          com nossos franqueados (&quot;Franqueados&quot;). Consulte a página
          Franqueados para obter uma lista. A lista está sujeita a alterações de
          tempos em tempos, e você deverá verificá-la periodicamente em busca de
          alterações.  Para essas transações, o Franqueado é um controlador de
          dados independente ou equivalente de acordo com a legislação aplicável
          e manterá sua própria política de privacidade em relação aos dados do
          aluguel fornecidos por você ao Franqueado. Recomendamos que você
          verifique a política de privacidade do Franqueado que seja aplicável à
          sua transação.
        </p>
        <p>
          Para identificar quem é o controlador de dados ou equivalente de
          acordo com a lei aplicável em relação às suas informações pessoais,
          consulte as informações em Fatos principais, fornecidas em sua reserva
          de aluguel, os termos e condições do aluguel ou visite a página{" "}
          <a href="/contato">Fale conosco .</a>
        </p>
        <h3>Prestadores de serviços</h3>
        <p>
          <b>Transações de aluguel</b>
        </p>
        <p>
          Poderemos usar um prestador de serviços para realizar sua transação de
          aluguel de carro. Por exemplo, no caso de você reservar um carro e
          motorista em países que exigem um motorista, enviaremos suas
          informações <b>Transacionais e de Contato</b> para o prestador de
          serviço desses países. Quando os serviços de carro e motorista são
          disponibilizados para você reservar, os mesmos são prestados pela
          empresa informada no momento da reserva.  Embora disponibilizadas nos
          sites das nossas marcas, essas empresas são operadoras independentes.
        </p>
        <p>
          <b>Transações de venda de carros</b>
        </p>
        <p>
          Para fornecer um valor de avaliação de um veículo de troca, o setor de
          Vendas de Carros usa seu Número de Identificação do Veículo (VIN) para
          executar um relatório de histórico e valor do veículo.  Após a compra
          de um veículo em uma de nossas agências de Vendas de Carros, e,
          mediante sua solicitação, para facilitar opções adicionais de veículos
          (assentos de couro, vidros escurecidos, etc.), compartilharemos suas
          informações de contato e (quando aplicável) informações transacionais
          de venda de carro com as empresas que fornecem esses produtos ou
          serviços. Para essas transações, o prestador de serviços é um
          controlador de dados independente, ou equivalente, de acordo com a
          legislação aplicável, e manterá sua própria política de privacidade
          com relação às informações fornecidas por você ao prestador.
          Recomendamos que você verifique a política de privacidade do prestador
          aplicável à sua transação.
        </p>
        <h3>Nossos provedores de serviços e parceiros de negócios</h3>
        <p>Compartilharemos:</p>
        <p>
          <b>
            Informações de contato, qualificação do motorista, informações
            transacionais, de sinistros, informações financeiras e pessoais de
            vendas de carros e informações transacionais de vendas de carros
          </b>
        </p>
        <ul>
          <li>Provedores de suporte técnico, administrativo e de pagamento</li>
          <li>
            Nossa reposição do seguro, aluguel corporativo ou outros clientes ou
            parceiros para facilitar reparos de veículos, transações de aluguel
            e programas de recompensas com membros do grupo Enterprise Holdings
          </li>
          <li>Agentes de recuperação de veículo</li>
          <li>Provedores de cobrança de dívidas</li>
          <li>Agências de certificação de crédito</li>
          <li>Provedores de verificação de identidade e endereço</li>
          <li>Processadores de sinistros</li>
          <li>
            Fabricantes de veículos e seus fornecedores de reparo de veículos
            e/ou prestadores de assistência nas estradas
          </li>
          <li>Provedores de frota</li>
          <li>
            Outros provedores para fins de reparo de veículos e avaliação de
            danos
          </li>
        </ul>
        <p>
          <b>Informações de saúde</b>
        </p>
        <ul>
          <li>Processadores de sinistros</li>
          <li>Provedores de dispositivos adaptáveis</li>
        </ul>
        <p>
          <b>Informações transacionais e de contato</b>
        </p>
        <ul>
          <li>Processadores de pedágios e multas</li>
        </ul>
        <p>
          <b>Informações transacionais, de contato, fidelidade e afiliação:</b>
        </p>
        <ul>
          <li>
            Para reposição do seguro, aluguel corporativo ou outros clientes ou
            parceiros para facilitar a transação de aluguel e programas de
            recompensas com membros do grupo Enterprise Holdings;
          </li>
          <li>
            Empresas que realizam pesquisas de clientes ou pesquisas de
            satisfação em nosso nome;
          </li>
          <li>
            Os fornecedores de comunicações que enviam e-mails, mensagens de
            texto e outras comunicações relacionadas a marketing e serviços em
            nosso nome; na União Europeia, nosso fornecedor de comunicações,
            Twilio, pode coletar e processar determinadas informações fornecidas
            por você como um controlador para prestar estes serviços. Visite o
            aviso de privacidade da Twilio  aqui  para saber mais sobre suas
            práticas de dados.
          </li>
          <li>
            Provedores de suporte ao cliente, como o provedor de nosso recurso
            de bate-papo ao vivo, para fornecer o atendimento ao cliente;
          </li>
          <li>
            Parceiros de contas comerciais gerenciam a conta deles conosco,
            inclusive o pagamento de valores que podem ser devidos à Enterprise
            de acordo com o contrato de aluguel ou outro contrato. Consulte a
            política de privacidade deles para obter detalhes de suas práticas
            de privacidade.
          </li>
        </ul>
        <p>
          <b>Informações móveis e da Web</b>
        </p>
        <ul>
          <li>
            Provedores de análise e tecnologia, para serviços baseados na
            agência, e para analisar, aprimorar e gerenciar nossos dados a fim
            de fornecer, manter e melhorar nossos serviços;
          </li>
          <li>
            Os parceiros de reprodução de sessão de aplicativos e da Web nos
            permitem rever sessões de usuários para melhorar a experiência de
            navegação e solucionar erros; e
          </li>
        </ul>
        <p>
          <b>Informações anônimas e desidentificadas</b>
        </p>
        <ul>
          <li>
            Nossos parceiros de publicidade e marketing e propriedades de mídia
            de terceiros, incluindo sites de mídia social, com os quais
            compartilhamos informações agregadas ou desidentificadas sobre
            nossos clientes, para permitir que eles enviem mensagens de
            publicidade direcionadas em nosso nome (sujeito a acordos de
            confidencialidade, conforme apropriado).
          </li>
        </ul>
        <h3>Autoridades do governo</h3>
        <p>
          Suas informações pessoais também podem ser compartilhadas com
          autoridades governamentais, conforme descrito acima.
        </p>
        <h3>Venda, fusão ou mudança de controle</h3>
        <p>
          Suas informações pessoais também podem ser repassadas para outra
          empresa em caso de transferência, mudança de proprietário,
          reorganização ou atribuição, total ou parcial, dos ativos ou negócios
          da Enterprise Holdings. Isso ocorrerá se as partes entrarem em um
          acordo sob o qual a coleta, o uso e a divulgação das informações
          estejam limitados às finalidades da transação comercial, incluindo
          determinar se a transação comercial deverá ou não ser levada adiante.
          Você será notificado via e-mail, ou aviso proeminente em nossos sites
          em trinta (30) dias, sobre qualquer alteração de propriedade ou
          controle de suas informações pessoais, ou conforme exigido ou
          permitido por lei.
        </p>
        <h3>Testemunhos</h3>
        <p>
          Podemos publicar testemunhos de clientes em nossos sites que podem
          conter informações de contato. Usamos um provedor de terceiros para
          gerenciar esse processo. Esse provedor é responsável por obter o seu
          consentimento conforme necessário, removendo o conteúdo mediante sua
          solicitação e respondendo a quaisquer perguntas que você possa ter.
        </p>
        <h3>Widgets de mídia social</h3>
        <p>
          Nossos sites podem incluir recursos de mídias sociais, como o botão do
          Facebook. Esses recursos podem coletar seu endereço IP, informações
          sobre as páginas de nosso site que você está visitando, e podem
          definir um cookie para permitir que o recurso funcione corretamente.
          Os recursos de mídia social e widgets ficam hospedados em sites de
          terceiros ou diretamente em nosso site. Suas interações com esses
          recursos são regidas pela política de privacidade da empresa que os
          fornece.
        </p>
        <p>
          Se você usa um desses sites de mídia social e não quer que eles
          coletem dados por meio de nossa página e os vinculem aos seus dados de
          membro, deverá fazer logout de sua conta de usuário antes de visitar
          nosso site e excluir os cookies relevantes. Você também pode bloquear
          plugins sociais através de extensões do navegador. Consulte o site da
          mídia social para obter as instruções sobre plugins ou consulte nossa
          Política de Cookies para ver mais informações.
        </p>
        <h3>Venda das suas informações</h3>
        <p>
          A Enterprise não vende suas informações pessoais a terceiros.  Embora
          não vendamos seus dados, residentes sob as leis de Nevada podem ainda
          enviar uma solicitação para que seus dados não sejam vendidos.  Se
          você for um residente de Nevada, pode exercer esse direito acessando
          o link <a href="/contato">Fale conosco .</a>
        </p>
        <p>
          Embora não vendamos seus dados, como é comum em empresas com presença
          on-line, alguns de nossos anúncios usam informações coletadas por
          empresas de nossas propriedades digitais, incluindo endereços IP,
          identificadores digitais, informações sobre sua navegação na Web e
          suas interações com nossas propriedades e anúncios, a fim de fornecer
          anúncios relevantes na Internet e para outros fins de análise. Essas
          empresas podem vender essas informações a outras empresas para
          publicidade e outros fins.
        </p>
        <p>
          De acordo com a Lei de Proteção ao Consumidor da Califórnia,
          residentes da Califórnia têm o direito de recusar a venda de suas
          informações pessoais.  Os residentes da Califórnia podem exercer esse
          direito visitando www.privacyrights.info para desativar as vendas
          desse tipo de informação pessoal por empresas que participem da
          ferramenta de desativação.
        </p>
        <h3>Transferências e processamento de dados globais</h3>
        <p>
          Para que possamos operar e fornecer serviços a você globalmente, as
          informações que você nos fornece ou que coletamos podem ser
          transferidas ou acessadas por entidades em todo o mundo, incluindo:
        </p>
        <h3>Franqueados/Agências de propriedade da empresa</h3>
        <p>
          Se você fizer uma reserva a ser cumprida por um franqueado ou agência
          de propriedade da Enterprise em outro país (por exemplo, nos Estados
          Unidos, Canadá, Alemanha, Espanha, França, Irlanda e Reino Unido),
          transferiremos suas informações pessoais para aquele país para
          facilitar sua transação solicitada.
        </p>
        <h3>
          Sistemas de informações globais da Enterprise localizados nos Estados
          Unidos
        </h3>
        <p>
          A Enterprise mantém seus sistemas de informações globais nos Estados
          Unidos.  Se você nos fornecer ou se coletarmos informações pessoais
          suas fora dos Estados Unidos, transferiremos e manteremos suas
          informações pessoais nos Estados Unidos.
        </p>
        <p>
          Quando transferimos suas informações pessoais e as processamos nos
          Estados Unidos, fazemos isso de acordo com as cláusulas contratuais
          padrão aprovadas pela Comissão Europeia (ou outro padrão semelhante,
          conforme aplicável ao seu país) e mantemos nosso status de
          certificação com a Estrutura do Escudo de Proteção da Privacidade dos
          EUA (descrito abaixo) .
        </p>
        <h3>Prestadores de serviços de terceiros</h3>
        <p>
          Utilizamos provedores de serviços de terceiros localizados fora dos
          países em que possuímos agências de propriedade da empresa, como
          suporte de central de atendimento, processadores de sinistros e
          provedores de TI. Quando transferimos suas informações pessoais para
          nossos provedores de serviços de terceiros que estão localizados fora
          do Espaço Econômico Europeu (EEE), usamos as cláusulas contratuais
          padrão aprovadas pela Comissão Europeia.
        </p>
        <p>
          Visite a página <a href="/contato">Fale conosco</a> para obter
          informações a respeito do controlador de dados aplicável à sua
          transação.
        </p>
        <h3>Escudo de Proteção da Privacidade dos EUA</h3>
        <p>
          A Enterprise Holdings, Inc. está em conformidade com a estrutura do
          Privacy Shield UE-EUA e a Estrutura do Privacy Shield Suíça-EUA
          conforme estabelecido pelo Departamento de Comércio dos EUA com
          relação à coleta, uso e retenção de informações pessoais transferidas
          da União Europeia e da Suíça para os Estados Unidos.  A Enterprise
          Holdings, Inc. certifica sua conformidade com o Departamento de
          Comércio dos EUA, que adere aos Princípios do Privacy Shield.  Se
          houver algum conflito entre os termos desta política de privacidade e
          os Princípios do Privacy Shield, os Princípios do Privacy Shield
          deverão prevalecer.  Para saber mais sobre o programa de Privacy
          Shield e visualizar nossa certificação, acesse{" "}
          <a href="https://www.privacyshield.gov/list">
            https://www.privacyshield.gov/list
          </a>
        </p>
        <p>
          Em caso de dúvidas a respeito de privacidade e uso de dados que não
          foram abordadas de forma satisfatória, entre em contato com o nosso
          provedor de resolução de litígios de terceiros com sede nos EUA
          (gratuitamente) em{" "}
          <a href="https://www.jamsadr.com/file-an-eu-us-privacy-shield-claim">
            https://www.jamsadr.com/file-an-eu-us-privacy-shield-claim.
          </a>
        </p>
        <p>
          Sob certas condições, descritas mais detalhadamente no site do Privacy
          Shield [
          <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">
            https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint
          </a>
          ], você pode solicitar a arbitragem obrigatória quando não houver mais
          outros procedimentos de resolução de litígios.
        </p>
        <h3>Seus direitos</h3>
        <p>
          <b>
            Regulamento Geral sobre a Proteção de Dados da UE (GDPR, EU General
            Data Protection Regulation), Proteção de Dados do Reino Unido (UK
            Data Protection), e Lei Geral de Proteção de Dados Pessoais do
            Brasil (LGPD)
          </b>
        </p>
        <p>
          Se o GDPR,  a Proteção de Dados do Reino Unido, e/ou LGPD  for
          aplicável à sua transação no Espaço Econômico Europeu, Reino Unido ou
          Suíça, você terá o direito de:
        </p>
        <ul>
          <li>
            Recusar a coleta ou o uso de suas informações pessoais, incluindo o
            uso de cookies e tecnologias semelhantes e o uso de suas informações
            pessoais para fins de marketing, e o anonimato de suas informações
            pessoais para análises de dados;
          </li>
          <li>
            Acessar suas informações pessoais, retificá-las, restringi-las ou
            opor-se ao seu processamento, ou, sujeito a exceções de acordo com a
            lei, solicitar sua exclusão;
          </li>
          <li>
            Receber as informações pessoais que você nos forneceu para
            transmiti-las a outra empresa;
          </li>
          <li>Revogar qualquer consentimento fornecido; e</li>
          <li>
            Quando aplicável, apresentar uma reclamação à autoridade de
            supervisão.
          </li>
          <li>
            Entre em contato com o nosso Agente de Proteção de Dados
            (DPO@ehi.com) ou envie um formulário de reclamação sobre privacidade
            através do nosso
            <a
              href="https://privacyportal.onetrust.com/webform/b7f28d27-6bb9-46d7-a7f4-c89ba7faf08f/afd4312f-7273-4b9c-b12d-828f04f67fbe"
              target="_blank"
              rel="noreferrer"
            >
              Portal de Privacidade Global.
            </a>
          </li>
        </ul>
        <p>
          <b>Califórnia</b>
        </p>
        <p>
          De acordo com a Lei de Proteção ao Consumidor da Califórnia (CCPA), os
          residentes da Califórnia têm certos direitos com relação às
          informações pessoais deles que foram coletadas por nós.  Se você
          residir na Califórnia, poderá exercer os seguintes direitos sobre suas
          informações pessoais, sujeitos a determinadas exceções e limitações:
        </p>
        <ul>
          <li>
            Direito de acessar as informações pessoais que coletamos sobre você
            e saber como coletamos essas informações, nosso propósito em
            coletá-las e os tipos de partes para as quais podemos divulgá-las;
          </li>
          <li>
            Direito de excluir as informações pessoais que coletamos de você; e
          </li>
          <li>
            Direito de recusar que vendamos suas informações pessoais, se
            aplicável.
          </li>
          <li>
            O direito de não receber tratamento discriminatório para o exercício
            dos direitos de privacidade [relevantes] conferidos pelo CCPA
          </li>
        </ul>
        <p>
          <b>Canadá</b>
        </p>
        <p>Se você residir no Canadá, terá o direito de:</p>
        <ul>
          <li>Acessar suas informações pessoais e retificá-las;</li>
          <li>Revogar qualquer consentimento fornecido;</li>
          <li>
            Quando aplicável, apresentar uma reclamação à autoridade de
            supervisão.
          </li>
        </ul>
        <p>
          <b>Exercer seus direitos</b>
        </p>
        <p>
          Você pode exercer quaisquer direitos aplicáveis descritos acima
          acessando nosso 
          <a
            href="https://privacyportal.onetrust.com/webform/b7f28d27-6bb9-46d7-a7f4-c89ba7faf08f/afd4312f-7273-4b9c-b12d-828f04f67fbe"
            target="_blank"
            rel="noreferrer"
          >
            Portal de Privacidade Global
          </a>
           na nossa página <a href="/contato">Fale conosco.</a>  Se você deseja
          apresentar uma reclamação a uma autoridade de supervisão, na Europa,
          você pode entrar em contato com a autoridade de supervisão necessária.
          No Canadá, você pode entrar em contato com o Privacy Commissioner
          (Encarregado de Privacidade) do país ou pelo site 
          <a href="https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/pipeda-compliance-help/">
            https://www.priv.gc.ca/cu-cn/index_e.asp
          </a>
           ou visitando o escritório do Privacy Commissioner do Canadá no
          seguinte endereço: 30 Victoria Street, Gatineau, Quebec, K1A 1H3.
        </p>
        <p>
          Poderemos solicitar informações adicionais a você para verificar sua
          identidade e residência antes de processarmos sua solicitação.  Para
          os residentes da Califórnia que desejam exercer seus direitos sob os
          termos do CCPA, é preciso fornecer os dados da carteira de motorista
          ou de um documento oficial de identificação do estado para comprovar a
          residência.  Certifique-se de ocultar sua fotografia e todas as
          informações de identificação que não sejam seu nome, endereço e número
          da carteira de motorista.  Também reservamo-nos o direito de
          restringir o acesso às suas informações ou de limitar seus direitos
          (por exemplo, se tal divulgação for proibida por lei ou se os direitos
          de outra pessoa puderem ser violados).  Em alguns casos, isso pode
          significar que poderemos manter suas informações pessoais mesmo que
          você revogue seu consentimento.
        </p>
        <p>
          Os residentes da Califórnia podem designar um agente autorizado para
          fazer uma solicitação CCPA em seu nome, preenchendo e assinando o
          formulário disponível {" "}
          <a href="https://privacy.ehi.com/content/dam/ehi-gpc/pdfs/CCPA%20Authorized%20Agent%20Form.pdf">
            aqui{" "}
          </a>
          .
        </p>
        <p>
          Se você mora na Colúmbia Britânica, Alberta ou Quebec, consulte os
          seguintes recursos. Na Colúmbia Britânica, você poderá encontrar
          informações sobre como exercer os seus direitos no 
          <a href="https://www.oipc.bc.ca/for-the-public/">site</a> do
          escritório de informações e do Privacy Commissioner (Encarregado de
          Privacidade). Em Alberta, você poderá encontrar informações sobre
          solicitações de acesso {" "}
          <a href="https://www.oipc.ab.ca/action-items/how-to-make-an-access-request.aspx">
            aqui
          </a>{" "}
           e fazer reclamações {" "}
          <a href="https://www.oipc.ab.ca/action-items/request-a-review-file-a-complaint.aspx">
            aqui
          </a>{" "}
          . Em Quebec, você poderá encontrar informações sobre seus direitos de
          privacidade <a href="https://www.cai.gouv.qc.ca/citoyens/"> aqui</a>{" "}
           no site da Commission d’accès à l’information Quebec
        </p>
        <p>
          <b>Preferências de marketing</b>
        </p>
        <p>
          Você poderá optar por não receber comunicações de marketing da
          Enterprise Holdings e de nossas subsidiárias, afiliadas e Franqueadas,
          seguindo as instruções em cada comunicação recebida.  Você também pode
          entrar em contato diretamente conosco usando o link no topo desta
          página.
        </p>
        <p>
          Você pode optar por não ver os anúncios exibidos em sites que não
          sejam da Enterprise clicando no ícone de opções de propaganda no
          próprio anúncio ou por meio das configurações de privacidade do site.
        </p>
        <h3>Alterações nestas políticas</h3>
        <p>
          Conforme necessário, atualizaremos nossa Política de Privacidade e
          Política de Cookies para refletir as alterações em nossas práticas de
          privacidade. Se fizermos quaisquer alterações materiais, notificaremos
          você por e-mail (enviado ao endereço de e-mail especificado em sua
          conta), por meio de um aviso neste site, ou conforme exigido ou
          permitido por lei antes da alteração entrar em vigor. Recomendamos que
          você revise periodicamente este site para obter as informações mais
          recentes sobre nossas práticas de privacidade.
        </p>
        <h3>Fale conosco</h3>
        <p>
          Para dúvidas ou questões relacionadas à proteção de privacidade e
          dados, visite nossa página  <a href="/contato">Fale conosco</a>  e
          envie sua solicitação pelo 
          <a
            href="https://privacyportal.onetrust.com/webform/b7f28d27-6bb9-46d7-a7f4-c89ba7faf08f/afd4312f-7273-4b9c-b12d-828f04f67fbe"
            target="_blank"
            rel="noreferrer"
          >
            Portal de Privacidade Global.
          </a>
        </p>
      </PrivacyPoliciesContainer>
      <Footer data={footerData} />
    </>
  );
}
