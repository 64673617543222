import TitleProvider from "@context/TitleContext";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./redux/store";
import { SnackbarProvider } from "react-snackbar-alert";
import Snackbar from "components/Snackbar";

require("dotenv");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <TitleProvider>
            <SnackbarProvider
              position="top-right"
              pauseOnHover={true}
              dismissable={true}
              timeout={3000}
            >
              <Snackbar />
              <App />
            </SnackbarProvider>
          </TitleProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
