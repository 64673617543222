import { ContactContainer } from "./styles";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import footerData from "@components/Footer/Resources/example.json";
import headerData from "./Resources/example.json";

export function Contact() {
  return (
    <>
      <Header data={headerData} />
      <ContactContainer>
        <hr />
        <h1>Entrando em contato conosco</h1>
        <div>
          <span>
            Para dúvidas ou questões relacionadas à proteção de privacidade e
            dados, envie sua solicitação pelo{" "}
            <a href="/politicas-de-privacidade">
              Portal de Privacidade Global.
            </a>
          </span>
        </div>
        <h4>
          Se você estiver fazendo um aluguel, ou visitando um site da Web ou
          site para dispositivos móveis, nos países a seguir, o controlador de
          dados estará listado abaixo.
        </h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">País</th>
                <th scope="col">Controlador de dados</th>
                <th scope="col">Informações de contato</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Estados Unidos da América</td>
                <td>Enterprise Holdings, Inc.</td>
                <td>
                  Perguntas sobre privacidade da Enterprise 600 Corporate Park
                  Drive St. Louis, MO 63105
                </td>
              </tr>
              <tr>
                <td>Canadá</td>
                <td>Enterprise Rent-A-Car Canada Company</td>
                <td>
                  Perguntas sobre privacidade da Enterprise 709 Milner Drive
                  Scarborough, Ontario M1B 6B6
                  <br /> Os clientes canadenses também podem buscar orientações
                  no escritório do Privacy Commissioner of Canada (em 1-800-282-
                  1376) ou em seu respectivo encarregado regional
                </td>
              </tr>
              <tr>
                <td>Reino Unido</td>
                <td>Enterprise Rent-A-Car UK Limited</td>
                <td>
                  Perguntas sobre privacidade da Enterprise Enterprise House
                  Melburne Park,Vicarage Road Egham Surrey TW20 9FB
                </td>
              </tr>
              <tr>
                <td>Irlanda</td>
                <td>ERAC Ireland Limited</td>
                <td>
                  Perguntas sobre privacidade da Enterprise Unit 4 Lyncon Court
                  Snugborough Road Blanchardstown Business Park, Dublin 15
                </td>
              </tr>
              <tr>
                <td>Alemanha</td>
                <td>Enterprise Autovermietung Deutschland B.V. &amp; Co. KG</td>
                <td>
                  Perguntas sobre privacidade da Enterprise Mergenthalerallee
                  42, 65760, Eschborn
                </td>
              </tr>
              <tr>
                <td>França</td>
                <td>Enterprise Holdings France S.A.S.</td>
                <td>
                  Perguntas sobre privacidade da Enterprise 37 rue du Colonel
                  Pierre Avia 75015, Paris
                </td>
              </tr>
              <tr>
                <td>Espanha</td>
                <td>Autotransporte Turistico Espanol, S.A.</td>
                <td>
                  Perguntas sobre privacidade da Enterprise Avenida del Ensanche
                  de Vallecas 37, 28051 Madrid
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>
          Se você estiver visitando nosso site da Web, aplicativo móvel ou site
          para dispositivos móveis nos países listados abaixo (isto é, com um
          sufixo de país relevante para esses países), o controlador desse site
          estará listado abaixo. Se você efetuar um aluguel em um desses países,
          o controlador do aluguel será o franqueado identificado no contrato de
          aluguel.
        </h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">País</th>
                <th scope="col">Controlador de dados</th>
                <th scope="col">Informações de contato</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Albânia, Áustria, Bélgica, Bósnia. Bulgária, Croácia, Chipre,
                  República Tcheca, Dinamarca, Estônia, Finlândia, Grécia,
                  Hungria, Islândia, Itália, Letônia, Lituânia, Luxemburgo,
                  Macedônia, Malta,Montenegro, Moldávia, Países Baixos, Noruega,
                  Polônia, Portugal, Romênia, Sérvia, Eslováquia, Eslovênia,
                  Suécia, Suíça, Turquia
                </td>
                <td>EAN Data Services UK Limited</td>
                <td>
                  Perguntas sobre privacidade da Enterprise Enterprise House
                  Melburne Park,Vicarage Road Egham Surrey TW20 9FB
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>
          Se você está localizado no Espaço Econômico Europeu e usa a função
          &quot;pagar antecipadamente&quot;, o controlador das informações de
          pagamento está listado abaixo.
        </h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">País</th>
                <th scope="col">Controlador de dados</th>
                <th scope="col">Informações de contato</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Espaço Econômico Europeu, Suíça</td>
                <td>EAN Data Services UK Limited</td>
                <td>
                  Perguntas sobre privacidade da Enterprise Enterprise House
                  Melburne Park,Vicarage Road Egham Surrey TW20 9FB
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>
          Se você é um membro do Emerald Club localizado no Espaço Econômico
          Europeu ou na Suíça, o controlador de suas informações está listado
          abaixo.
        </h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">País</th>
                <th scope="col">Controlador de dados</th>
                <th scope="col">Informações de contato</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Espaço Econômico Europeu, Suíça</td>
                <td>EAN Data Services UK Limited</td>
                <td>
                  Perguntas sobre privacidade da Enterprise Enterprise House
                  Melburne Park,Vicarage Road Egham Surrey TW20 9FB
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>
          Para todos os outros locais globais em que temos um site da Web, um
          site para dispositivos móveis ou aplicativo móvel, o controlador dos
          dados fornecido por esses meios ou por você está listado abaixo. Se
          você efetuar um aluguel em um desses países, o controlador do aluguel
          será o franqueado identificado, e suas informações de contato serão os
          detalhes fornecidos no contrato de aluguel.
        </h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">País</th>
                <th scope="col">Controlador de dados</th>
                <th scope="col">Informações de contato</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Todos os outros locais globais</td>
                <td>Enterprise Holdings, Inc.</td>
                <td>
                  Perguntas sobre privacidade da Enterprise 600 Corporate Park
                  Drive St. Louis, MO 63105
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>
          Para quaisquer consultas ou reclamações específicas da UE e Brazil,
          nosso responsável pela proteção de dados pode ser contatado pelo
          e-mail dpo@ehi.com
        </h4>
        <hr />
      </ContactContainer>
      <Footer data={footerData} />
    </>
  );
}
