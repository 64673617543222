import React from "react";
import { Header } from "../../../components/Header";
import { BreadCrumb } from "../../../components/BreadCrumb";
import { TextBlockWithLinks } from "../../../components/TextBlockWithLinks";
import { TitleAndCTAButtonBlock } from "../../../components/TitleAndCTAButtonBlock";
import { Footer } from "../../../components/Footer";
import { Title } from "../../../components/Global/Texts/styles";
import { FAQContentPageBody} from "../styles";
import footerData from "@components/Footer/Resources/example.json";

export function EarlyReturn(props){
    return(
        <>
            <Header data={props.data.headerData}/>
            <BreadCrumb data={props.data.breadCrumbData}/>
            <FAQContentPageBody>
                <Title className="mb-4">{props.data.titleAndSubtitleData}</Title>
                <TextBlockWithLinks data={props.data.textBlockWithLinksData}/>
                <TitleAndCTAButtonBlock data={props.data.titleAndCTAButtonBlockData}/>
            </FAQContentPageBody>
            <Footer data={footerData}/>
        </>
    )
}