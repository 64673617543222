import {
  CityIcon,
  NearestRentalLocationsBody,
  NearestRentalLocationsCard,
  NearestRentalLocationsCardBody,
  IconContainer,
  NearestRentalLocationsCardFooter,
  NearestRentalLocationsCardHeader,
  NearestRentalLocationsHeader,
  NearestRentalLocationsWrapper,
  PlaneIcon
} from "./styles";

export const NearestRentalLocations = ({ data, agencyId }) => {
  return (
    data.length > 0 && (
      <NearestRentalLocationsWrapper>
        <NearestRentalLocationsHeader>
          <h4>Locais de aluguel mais próximos</h4>
          <a href={`/aluguel-de-carro/achar-loja` + agencyId}>Ver mapa</a>
        </NearestRentalLocationsHeader>
        <NearestRentalLocationsBody>
          {data.map((location, index) => (
            <NearestRentalLocationsCard key={index}>
              <NearestRentalLocationsCardHeader>
                <IconContainer>
                  {location.airportIndicator === "Y" ? (
                    <PlaneIcon />
                  ) : (
                    <CityIcon />
                  )}
                </IconContainer>
                <h4>{location.branchName}</h4>
              </NearestRentalLocationsCardHeader>
              <NearestRentalLocationsCardBody>
                <p>{location.branchStationAddress1}</p>
                <a href={`/agencias/detalhes/${location.id}`}>
                  Detalhes da localização
                </a>
              </NearestRentalLocationsCardBody>
              <NearestRentalLocationsCardFooter className="mt-5">
                <a href={`/agencias/detalhes/${location.id}`}>Agende agora</a>
              </NearestRentalLocationsCardFooter>
            </NearestRentalLocationsCard>
          ))}
        </NearestRentalLocationsBody>
      </NearestRentalLocationsWrapper>
    )
  );
};
