import { BreadCrumb } from "@components/BreadCrumb";
import { OtherVehicles } from "@components/OtherVehicles";
import { VehicleCategoryTitleAndSubtitle } from "@components/VehicleCategoryTitleAndSubtitle";
import { VehicleDetails } from "@components/VehicleDetails";
import { PbkWidgetExpanded } from "@components/WidgetExpanded";
import { fetchVehicles } from "@redux/vehicle/vehiclesService";
import { getFilters } from "@redux/vehicle/vehiclesSlice";
import { StaticPageEngine } from "pages/StaticPageEngine";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { OtherVehiclesContainer } from "./styles";

export function VehicleSubcategoryDetails(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [subCategory, setSubCategory] = useState();
  const [otherCategories, setOtherCategories] = useState();
  const { id } = useParams();
  const vehicleState = useSelector((state) => state.vehicle);

  const filterSubcategory = () => {
    if (vehicleState.vehicles && vehicleState.vehicles.length > 0) {
      let current;
      if (id) {
        current = vehicleState.vehicles.find((x) => x.id.toString() === id);
      }
      if (current) {
        setOtherCategories(
          vehicleState.categories
            .map((x) => {
              const v = vehicleState.vehicles.find(
                (y) =>
                  y?.otavehCategory === x?.id && y?.country === current?.country
              );
              if (v) return { ...v, categoryName: x.name };
              else return null;
            })
            .filter((x) => x !== null)
        );
        setSubCategory(current);
      } else {
        history.push("/page-not-found");
      }
    }
  };

  useEffect(() => {
    if (!vehicleState.vehicles || vehicleState.vehicles.length === 0) {
      dispatch(fetchVehicles());
      dispatch(getFilters());
    } else {
      filterSubcategory();
    }
  }, []);

  useEffect(() => {
    filterSubcategory();
  }, [vehicleState]);

  const components =
    <>
      <BreadCrumb data={props.data.breadCrumbData} />
      {subCategory && (
        <>
          <VehicleCategoryTitleAndSubtitle
            data={{
              ...props.data.vehicleCategoryTitleAndSubtitleData,
              subCategory: subCategory
            }}
          />
          <VehicleDetails
            data={{
              ...props.data.vehicleDetailsData,
              subCategory: subCategory
            }}
          />
          <OtherVehiclesContainer>
            <OtherVehicles
              data={{
                ...props.data.otherVehiclesData,
                otherCategories: otherCategories
              }}
            />
          </OtherVehiclesContainer>
        </>
      )}
      <PbkWidgetExpanded />
    </>

  return (
    <StaticPageEngine components={components} />
  );
}
