import { FranchisesContainer } from './styles';
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import footerData from "@components/Footer/Resources/example.json";
import headerData from "../Contact/Resources/example.json";

export function Franchises() {
    return (
        <>
            <Header data={headerData} />
            <FranchisesContainer>
                <hr />
                <h1>Franqueados</h1>
                <h4>EMEA - Europa, Oriente Médio e África</h4>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Território</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Albania</td>
                                <td>Enterprise</td>
                                <td>Albania Car Rentals</td>
                            </tr>
                            <tr>
                                <td>Armenia</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>J.M.S., LLC</td>
                            </tr>
                            <tr>
                                <td>Austria</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>AJAR Car Rental GmbH</td>
                            </tr>
                            <tr>
                                <td>Belgium</td>
                                <td>Enterprise</td>
                                <td>General Lease NV</td>
                            </tr>
                            <tr>
                                <td>Bosnia &amp; Herzegovina</td>
                                <td>Enterprise</td>
                                <td>City Car Rental d.o.o.</td>
                            </tr>
                            <tr>
                                <td>Bulgaria</td>
                                <td>Enterprise</td>
                                <td>Mirkat</td>
                            </tr>
                            <tr>
                                <td>Corsica</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>A.T.F. SARL</td>
                            </tr>
                            <tr>
                                <td>Croatia</td>
                                <td>Enterprise</td>
                                <td>Autobenussi d.o.o.</td>
                            </tr>
                            <tr>
                                <td>Cyprus</td>
                                <td>Enterprise</td>
                                <td>Lakis Rent a Car Ltd.</td>
                            </tr>
                            <tr>
                                <td>Czech Republic</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Czechocar CZ spol s.r.o.</td>
                            </tr>
                            <tr>
                                <td>Denmark</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Shared Mobility A/S</td>
                            </tr>
                            <tr>
                                <td>Egypt</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Premier Auto Rentals</td>
                            </tr>
                            <tr>
                                <td>Estonia</td>
                                <td>Enterprise</td>
                                <td>OU A-Rental</td>
                            </tr>
                            <tr>
                                <td>Finland</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>LansiAuto Rent Oy</td>
                            </tr>
                            <tr>
                                <td>Georgia</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Universal Rent LLC</td>
                            </tr>
                            <tr>
                                <td>Greece</td>
                                <td>Enterprise</td>
                                <td>Sfakianakis S.A.</td>
                            </tr>
                            <tr>
                                <td>Hungary</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Grandsud kft</td>
                            </tr>
                            <tr>
                                <td>Iceland</td>
                                <td>Enterprise</td>
                                <td>Bilaleiga Kynnisferda ehf</td>
                            </tr>
                            <tr>
                                <td>Israel</td>
                                <td>Enterprise</td>
                                <td>Eldan Transportation Ltd.</td>
                            </tr>
                            <tr>
                                <td>Italy</td>
                                <td>Enterprise</td>
                                <td>Locauto Rent s.p.a.</td>
                            </tr>
                            <tr>
                                <td>Jordan</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Al Amal Tourist Cars Rental Co. Ltd.</td>
                            </tr>
                            <tr>
                                <td>Kosovo</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>AUTO BENUSSI D.O.O.</td>
                            </tr>
                            <tr>
                                <td>Kuwait</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Island Integrated Car Rental</td>
                            </tr>
                            <tr>
                                <td>Latvia</td>
                                <td>Enterprise</td>
                                <td>Sia Car Rental Service</td>
                            </tr>
                            <tr>
                                <td>Liechtenstein</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Helvetic Mobility AG</td>
                            </tr>
                            <tr>
                                <td>Lithuania</td>
                                <td>Enterprise</td>
                                <td>UAB Admita</td>
                            </tr>
                            <tr>
                                <td>Luxembourg</td>
                                <td>Enterprise</td>
                                <td>RENT Me</td>
                            </tr>
                            <tr>
                                <td>Macedonia</td>
                                <td>Enterprise</td>
                                <td>ABC Logisticka DOOEL</td>
                            </tr>
                            <tr>
                                <td>Malta</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>BC Auto Rentals Ltd.</td>
                            </tr>
                            <tr>
                                <td>Moldova</td>
                                <td>Enterprise</td>
                                <td>Touring Rent Srl</td>
                            </tr>
                            <tr>
                                <td>Montenegro</td>
                                <td>Enterprise</td>
                                <td>Perla RC d.o.o</td>
                            </tr>
                            <tr>
                                <td>Netherlands</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>SharingMatters B.V.</td>
                            </tr>
                            <tr>
                                <td>Norway</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Shared Mobility A/S</td>
                            </tr>
                            <tr>
                                <td>Oman</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Oman United Agencies Traavel LLC</td>
                            </tr>
                            <tr>
                                <td>Poland</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Car Hire Poland sp z.o.o.</td>
                            </tr>
                            <tr>
                                <td>Portugal</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Guerin Car Rental Solutions Lda</td>
                            </tr>
                            <tr>
                                <td>Qatar</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Almana Car Retnal Enterprises-National</td>
                            </tr>
                            <tr>
                                <td>Romania</td>
                                <td>Enterprise</td>
                                <td>Touring Rent Srl</td>
                            </tr>
                            <tr>
                                <td>Saudi Arabia</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Al Jomiah Auto Rental</td>
                            </tr>
                            <tr>
                                <td>Serbia</td>
                                <td>Enterprise</td>
                                <td>Autobenussi d.o.o.</td>
                            </tr>
                            <tr>
                                <td>Slovakia</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Czechocar CZ spol s.r.o.</td>
                            </tr>
                            <tr>
                                <td>Slovenia</td>
                                <td>Enterprise</td>
                                <td>Autobenussi d.o.o.</td>
                            </tr>
                            <tr>
                                <td>Sweden</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Shared Mobility A/S</td>
                            </tr>
                            <tr>
                                <td>Switzerland</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Helvetic Mobility AG</td>
                            </tr>
                            <tr>
                                <td>Turkey</td>
                                <td>Enterprise</td>
                                <td>Yes Oto</td>
                            </tr>
                            <tr>
                                <td>United Arab Emirates</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Sanam Rent-A-Car LLC</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>LAC - América Latina</h4>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Território</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Argentina</td>
                                <td>National/Alamo</td>
                                <td>Flotas Comerciales S.R.L.</td>
                            </tr>
                            <tr>
                                <td>Aruba</td>
                                <td>National/Alamo</td>
                                <td>Trecca Car Rental N.V.</td>
                            </tr>
                            <tr>
                                <td>Barbados</td>
                                <td>Enterprise/Alamo</td>
                                <td>Go Car Investments</td>
                            </tr>
                            <tr>
                                <td>Belize</td>
                                <td>National/Alamo</td>
                                <td>Belize Estate Company Ltd.</td>
                            </tr>
                            <tr>
                                <td>Bonaire</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Imoto Bonaire B.V</td>
                            </tr>
                            <tr>
                                <td>Brazil</td>
                                <td>National/Alamo</td>
                                <td>Unidas S.A.</td>
                            </tr>
                            <tr>
                                <td>Cayman Islands</td>
                                <td>National/Alamo</td>
                                <td>Eurocar Rentals Ltd.</td>
                            </tr>
                            <tr>
                                <td>Chile</td>
                                <td>National/Alamo</td>
                                <td>Franquicias Internacionales Limitada</td>
                            </tr>
                            <tr>
                                <td>Colombia</td>
                                <td>Alamo</td>
                                <td>Massy Motors Rentals, S.A.S.</td>
                            </tr>
                            <tr>
                                <td>Costa Rica</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>ANC Car S.A.</td>
                            </tr>
                            <tr>
                                <td>Curacao</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Caribe Car Rental N.V.</td>
                            </tr>
                            <tr>
                                <td>Dominican Republic</td>
                                <td>National/Alamo</td>
                                <td>Motor Plan S.A.</td>
                            </tr>
                            <tr>
                                <td>Ecuador</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Automotores y Anexos S.A.</td>
                            </tr>
                            <tr>
                                <td>El Salvador</td>
                                <td>National/Alamo</td>
                                <td>UNO Rent-A-Car S.A. de CV</td>
                            </tr>
                            <tr>
                                <td>Grenada</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>True Rentals, Ltd.</td>
                            </tr>
                            <tr>
                                <td>Guatemala</td>
                                <td>National/Alamo</td>
                                <td>Autos de Alquier S.A.</td>
                            </tr>
                            <tr>
                                <td>Guyana</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Massy</td>
                            </tr>
                            <tr>
                                <td>Honduras</td>
                                <td>Alamo</td>
                                <td>Inversiones y Dessarrollos Comerciales S.A.</td>
                            </tr>
                            <tr>
                                <td>Jamaica</td>
                                <td>Alamo</td>
                                <td>ATL Automotive Limited</td>
                            </tr>
                            <tr>
                                <td>Martinique</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Location Auto Martinique</td>
                            </tr>
                            <tr>
                                <td>Mexico</td>
                                <td>Alamo</td>
                                <td>Trade MX S.A. de CV</td>
                            </tr>
                            <tr>
                                <td>Mexico (North)</td>
                                <td>Enterprise/National</td>
                                <td>Corporativo Gaviotas, S.A. de CV</td>
                            </tr>
                            <tr>
                                <td>Mexico (South)</td>
                                <td>Enterprise/National</td>
                                <td>Rent-A-Matic Itza, S.A. de CV</td>
                            </tr>
                            <tr>
                                <td>Nicaragua</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Autos de Alquier S.A.</td>
                            </tr>
                            <tr>
                                <td>Panama</td>
                                <td>National/Alamo</td>
                                <td>Servicios Turisticos Panamenos</td>
                            </tr>
                            <tr>
                                <td>Paraguay (Asuncion Only)</td>
                                <td>National/Alamo</td>
                                <td>Amigos S.R.L.</td>
                            </tr><tr>
                                <td>Peru</td>
                                <td>National/Alamo</td>
                                <td>ANC Peru S.A.</td>
                            </tr>
                            <tr>
                                <td>St. Maarten</td>
                                <td>National/Alamo</td>
                                <td>Olzina N.V.</td>
                            </tr>
                            <tr>
                                <td>St. Thomas</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Marant International, LLC</td>
                            </tr>
                            <tr>
                                <td>Suriname</td>
                                <td>National/Alamo</td>
                                <td>Perfect Rentals</td>
                            </tr>
                            <tr>
                                <td>Tortola</td>
                                <td>National</td>
                                <td>Tropical Rentals Limited</td>
                            </tr>
                            <tr>
                                <td>Trinidad &amp; Tobago</td>
                                <td>National/Alamo</td>
                                <td>Massy Motors, Ltd.</td>
                            </tr>
                            <tr>
                                <td>Uruguay</td>
                                <td>National/Alamo</td>
                                <td>Olecram S.A.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>AP - Ásia Pacífico</h4>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Território</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Australia</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Redspot Franchising Pty. Ltd.</td>
                            </tr>
                            <tr>
                                <td>Guam</td>
                                <td>National/Alamo</td>
                                <td>Emerald Pacific Group Corp.</td>
                            </tr>
                            <tr>
                                <td>Japan</td>
                                <td>National</td>
                                <td>Nippon Rent-A-Car International, Inc.</td>
                            </tr>
                            <tr>
                                <td>New Zealand</td>
                                <td>Enterprise/National/Alamo</td>
                                <td>Redspot Franchising Pty. Ltd.</td>
                            </tr>
                            <tr>
                                <td>Palau</td>
                                <td>National/Alamo</td>
                                <td>Emerald Pacific Group Corp.</td>
                            </tr>
                            <tr>
                                <td>Philippines (Cebu)</td>
                                <td>National</td>
                                <td>Drive Services Asia Pacific, Inc.</td>
                            </tr>
                            <tr>
                                <td>Philippines (Manila)</td>
                                <td>National</td>
                                <td>International Car Rentals, Inc.</td>
                            </tr>
                            <tr>
                                <td>Saipan</td>
                                <td>National/Alamo</td>
                                <td>Emerald Pacific Group Corp.</td>
                            </tr>
                            <tr>
                                <td>South Korea</td>
                                <td>National</td>
                                <td>Sam Bo Rent A Car Co., Ltd.</td>
                            </tr>
                            <tr>
                                <td>Thailand</td>
                                <td>National</td>
                                <td>S.M.T. Rent-A-Car Co., Ltd</td>
                            </tr>
                            <tr>
                                <td>Vietnam</td>
                                <td>Enterprise</td>
                                <td>MP Executive Corp.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>Canadá</h4>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Província</th>
                                <th scope="col">Agência/local</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>AB</td>
                                <td>HIGH LEVEL</td>
                                <td>National</td>
                                <td>1903004 Alberta Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>JASPER</td>
                                <td>National</td>
                                <td>Alpine Auto Rentals</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>LAKE LOUISE</td>
                                <td>National</td>
                                <td>Lake Louise Investments, Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>PEACE RIVER</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>PEACE RIVER</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>GRANDE PRAIRIE</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>GRANDE PRAIRIE</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>WHITECOURT</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>YELLOWKNIFE</td>
                                <td>National</td>
                                <td>Ted&#39;s U-Drive</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>LETHBRIDGE</td>
                                <td>National</td>
                                <td>Wild Roads Rentals, Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>LETHBRIDGE</td>
                                <td>National</td>
                                <td>Wild Roads Rentals, Ltd.</td>
                            </tr>
                            <tr>
                                <td>AB</td>
                                <td>DRUMHELLER</td>
                                <td>National</td>
                                <td>Western Chevrolet Buick GMC Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>CAMPBELL RIVER</td>
                                <td>National</td>
                                <td>486728 British Columbia Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>NORTH VANCOUVER</td>
                                <td>National</td>
                                <td>0771764 B.C. Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>NANAIMO</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>CASSIDY</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>COURTENAY</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>COMOX</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>VICTORIA</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>VICTORIA</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>SIDNEY</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>VICTORIA</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>VICTORIA</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>PORT HARDY</td>
                                <td>National</td>
                                <td>H.A.L.T. Holdings Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>DAWSON CREEK</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>POUCE COUPE</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>FORT ST JOHN</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>FORT ST JOHN</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>FORT NELSON</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>BC</td>
                                <td>FORT NELSON</td>
                                <td>National</td>
                                <td>Mel&#39;s U-Drive (1978) Ltd.</td>
                            </tr>
                            <tr>
                                <td>NS</td>
                                <td>TRURO</td>
                                <td>National</td>
                                <td>Rainbow Motors Ltd.</td>
                            </tr>
                            <tr>
                                <td>NS</td>
                                <td>PORT HAWKESBURY</td>
                                <td>National</td>
                                <td>Robert A. Fox Sales &amp; Rentals Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>TRENTON</td>
                                <td>National</td>
                                <td>100336 Ontario, Inc.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>COBOURG</td>
                                <td>National</td>
                                <td>100336 Ontario, Inc.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>LINDSAY</td>
                                <td>National</td>
                                <td>Economy Leasing, Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>CORNWALL</td>
                                <td>National</td>
                                <td>516941 Ontario Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>WOODSTOCK</td>
                                <td>National</td>
                                <td>Connell Chrysler Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>OWEN SOUND</td>
                                <td>National</td>
                                <td>Grey Motors Pontiac Buick GMC Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>GERALDTON</td>
                                <td>National</td>
                                <td>Spadoni Leasing Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>DRYDEN</td>
                                <td>National</td>
                                <td>Spadoni Leasing Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>DRYDEN</td>
                                <td>National</td>
                                <td>Spadoni Leasing Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>THUNDER BAY</td>
                                <td>National</td>
                                <td>Spadoni Leasing Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>THUNDER BAY</td>
                                <td>National</td>
                                <td>Spadoni Leasing Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>MARATHON</td>
                                <td>National</td>
                                <td>Spadoni Leasing Ltd.</td>
                            </tr>
                            <tr>
                                <td>ON</td>
                                <td>TERRACE BAY</td>
                                <td>National</td>
                                <td>Spadoni Leasing Ltd.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>JONQUIÈRE</td>
                                <td>National</td>
                                <td>3150-3143 Quebec Inc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>CHICOUTIMI</td>
                                <td>National</td>
                                <td>3150-3143 Quebec Inc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>LA BAIE</td>
                                <td>National</td>
                                <td>3150-3143 Quebec Inc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>ROBERVAL</td>
                                <td>National</td>
                                <td>3150-3143 Quebec Inc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>ALMA</td>
                                <td>National</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>SALABERRY-DE-VALLEYFIELD</td>
                                <td>National</td>
                                <td>90456 Canada Ltee</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>CHIBOUGAMAU</td>
                                <td>National</td>
                                <td>Chibougamau Automobile Inc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>LA BAIE</td>
                                <td>National</td>
                                <td>3150-3143 Quebec Inc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>VAL-D&#39;OR</td>
                                <td>National</td>
                                <td>Location Aube Ltee.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>VAL-D&#39;OR</td>
                                <td>National</td>
                                <td>Location Aube Ltee.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>ROUYN- NORANDA</td>
                                <td>National</td>
                                <td>Location Groupe Dion Inc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>ROUYN- NORANDA</td>
                                <td>National</td>
                                <td>Location Groupe Dion Iyc.</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>LA SARRE</td>
                                <td>National</td>
                                <td>Location Elite In.c</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>AMOS</td>
                                <td>National</td>
                                <td>Location Elite In.c</td>
                            </tr>
                            <tr>
                                <td>QC</td>
                                <td>SOREL-TRACY</td>
                                <td>National</td>
                                <td>Paul Gamelin &amp; Fils Ltd.</td>
                            </tr>
                            <tr>
                                <td>SK</td>
                                <td>REGINA</td>
                                <td>National</td>
                                <td>Platinum Motors Corp.</td>
                            </tr>
                            <tr>
                                <td>SK</td>
                                <td>REGINA</td>
                                <td>National</td>
                                <td>Platinum Motors Corp.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>Estados Unidos</h4>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Estado</th>
                                <th scope="col">Agência/local</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>WI</td>
                                <td>Appleton</td>
                                <td>National/Alamo</td>
                                <td>Midwest Car Corporation</td>
                            </tr>
                            <tr>
                                <td>WI</td>
                                <td>Green Bay</td>
                                <td>National/Alamo</td>
                                <td>Midwest Car Corporation</td>
                            </tr>
                            <tr>
                                <td>WI</td>
                                <td>Madison</td>
                                <td>National/Alamo</td>
                                <td>Midwest Car Corporation</td>
                            </tr>
                            <tr>
                                <td>WI</td>
                                <td>Milwaukee</td>
                                <td>National/Alamo</td>
                                <td>Midwest Car Corporation</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
            </FranchisesContainer>
            <Footer data={footerData} />
        </>
    )
}