import { ListOfCategoriesAndArticlesBackground } from "./styles"

export function ListOfCategoriesAndArticles(props) {

    const links = (menu) => menu.map((link, i) =>
        <div key={i}>
            <a href={link.href}>{link.text}</a>
            <br />
        </div>
    )

    const list = props.data.menus.map((menu, index) =>
        <div key={index} id={index}>
            <p><b>{menu.title}</b></p>
            {links(menu.links)}
        </div>
    )

    return (
        <>
            <ListOfCategoriesAndArticlesBackground>
                {list}
            </ListOfCategoriesAndArticlesBackground>
        </>
    )
}