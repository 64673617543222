import styled from "styled-components";
import theme from "styled-theming";
import { buttons, palettes } from "assets/theme";

const buttonType = theme("mode", {
  enabled: buttons.primary,
  disabled: buttons.disabled
});
const buttonConfig = theme("mode", {
  normal: buttons.secondary,
  inverse: buttons.secondary_inverse,
  brandInverse: buttons.brand_inverse,
  brand: buttons.brand
});

export const Container = styled.div`
  background: ${palettes.white};
  padding: 13px 0px 25px;
`;

export const PageTypeContainer = styled.div``;
export const PageTypeButton = styled.button`
  ${buttonType};
  width: 50%;
  height: 50px;
  cursor: pointer;
`;

export const PageConfigContainer = styled.div`
  margin: 5px 0;
`;
export const PageConfigButton = styled.button`
  ${buttonConfig};
  width: 100%;
  height: 50px;
  margin: 5px 0;
`;
export const ListButton = styled.button`
  ${buttonConfig};
  width: 100%;
  height: 50px;
  margin: 5px 0;
  border-radius: 25px;
`;

export const Spacer = styled.hr`
  background: ${palettes.tints_blue_darker};
  height: 1px;
  margin: 0;
  opacity: 1;
`;

export const SelectWrapper = styled.div`
  color: ${palettes.tints_blue_darker};
  display: flex;
  height: 65px;
  border: 1px solid #A6ADB4;
  select{
    width: 95%;
    border: none;
    outline: none;
    color: ${palettes.tints_blue_darker};
    ::after{
      margin-left: 50px;
    }

  }
`;

export const GreyRetangle = styled.div`
  background: #EBEDED;
  height: 100%;
  width: 15px;
  margin-right: 10px;
`;
