import { TitleTextSubtitleWithTextAndCTAButtonBackground } from "./styles";
import { Row, Col } from "react-bootstrap";
import { StyledButton } from "../Button/styles";

export function TitleTextSubtitleWithTextAndCTAButton(props) {
	return (
		<>
			<TitleTextSubtitleWithTextAndCTAButtonBackground>
				<Row>
					<Col xs={12} lg={5}>
						<h2>
							<b>{props.data.title}</b>
						</h2>
					</Col>
					<Col xs={0} lg={7}></Col>
					<Col xs={12} lg={6}>
						<p>{props.data.text}</p>
					</Col>
					<Col xs={0} lg={6}></Col>
					<p>
						<b>{props.data.subtitle}</b>
					</p>
				</Row>
				<Row>
					<Col lg={6} sm={12}>
						<p>{props.data.subtitleText}</p>
					</Col>
					<Col xs={0} lg={1}></Col>
					<Col lg={3} sm={12}>
						<StyledButton
							variant="link"
							href="/sobre/covid"
							target="_blank"
							color={props.data.button.color}
							colortext={props.data.button.colorText}
						>
							{props.data.button.text}
						</StyledButton>
					</Col>
				</Row>
			</TitleTextSubtitleWithTextAndCTAButtonBackground>
		</>
	);
}
