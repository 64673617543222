import footerData from "@components/Footer/Resources/example.json";
import { BreadCrumb } from "../../components/BreadCrumb";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { SiteMapText } from "./styles";

export function SiteMapPage(props) {
  return (
    <>
      <Header data={props.data.headerData} />
      <BreadCrumb data={props.data.breadCrumbData} />
      <SiteMapText>
        <h3>Site Map</h3>
        <p>
          Navigate your way around Alamo® website and find our worldwide car
          hire offers online today.
        </p>
      </SiteMapText>
      <Footer data={footerData} />
    </>
  );
}

export default SiteMapPage;
