import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from "redux-persist";
import expireTransform from "redux-persist-expire-transform";
import storageSession from "redux-persist/lib/storage/session";
import blogReducer from "./blog/blogSlice";
import cmsReducer from "./cms/cmsSlice";
import locationReducer from "./location/locationSlice";
import pageReducer from "./page/pageSlice";
import userReducer from "./user/userSlice";
import vehicleReducer from "./vehicle/vehiclesSlice";
import authReducer from "./login/auth/authSlice";
import postListReducer from "./postList/postListSlice";
import snackbarReducer from "./snackbar/snackbarSlice";

const expireInMinutes = 1;
const reducerWithExpiration = ["location", "vehicle"];

const persistConfig = {
  key: "root",
  storage: storageSession,
  transforms: [expireTransform(expireInMinutes, reducerWithExpiration)],
  whitelist: ["location", "vehicle", "auth"]
};
const rootReducer = combineReducers({
  location: locationReducer,
  vehicle: vehicleReducer,
  blog: blogReducer,
  cms: cmsReducer,
  user: userReducer,
  page: pageReducer,
  auth: authReducer,
  postList: postListReducer,
  snackbar: snackbarReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});
export const persistor = persistStore(store);
