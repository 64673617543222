import React from "react";
import { Header } from "../../components/Header";
import { BreadCrumb } from "../../components/BreadCrumb";
import { ContentLimpezaCompleta } from "../../components/ContentLimpezaCompleta";
import { ContentBlock } from "../../components/ContentBlock";
import { Footer } from "../../components/Footer";
import footerData from "@components/Footer/Resources/example.json";
import { TitleContext } from "context/TitleContext";

import { Row, Col } from "react-bootstrap";


export function CovidInfoPAge(props) {

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - Informações COVID-19");
    }, []);

    return (
        <>
            <Header data={props.data.headerData} />
            <BreadCrumb data={props.data.breadCrumbData} />
            <ContentLimpezaCompleta data={props.data.contentLimpezaCertaData} />
            <Row className="m-0">
                <Col lg={2} sm={0}>
                </Col>
                <Col lg={8} sm={12}>
                    <ContentBlock data={props.data.contentBlockData[0]} />
                    <ContentBlock data={props.data.contentBlockData[1]} />
                    <ContentBlock data={props.data.contentBlockData[2]} />
                    <ContentBlock data={props.data.contentBlockData[3]} />
                    <ContentBlock data={props.data.contentBlockData[4]} />
                    <ContentBlock data={props.data.contentBlockData[5]} />
                </Col>
                <Col lg={1} sm={0}>
                </Col>
            </Row>
            <Footer data={footerData} />
        </>
    );
}

export default CovidInfoPAge;