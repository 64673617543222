import { fonts } from "./fonts";
import { palettes } from "./palettes";

export const buttons = {
  primary: {
    ...fonts.h4_bold,
    background: palettes.tints_blue_darker,
    padding: "10px",
    border: "none",
    color: palettes.tints_yellow
  },
  primary_inverse: {
    ...fonts.h4_bold,
    background: palettes.white,
    padding: "10px",
    border: `solid 1px ${palettes.tints_blue_darker}`,
    color: palettes.tints_blue_darker
  },
  secondary: {
    ...fonts.h4_bold,
    background: palettes.tints_purple,
    padding: "10px",
    border: "none",
    color: palettes.white
  },
  secondary_inverse: {
    ...fonts.h4_bold,
    background: palettes.white,
    padding: "10px",
    border: `solid 1px ${palettes.tints_purple}`,
    color: palettes.tints_purple
  },
  brand: {
    ...fonts.h4_bold,
    background: palettes.tints_blue,
    padding: "10px",
    border: "none",
    color: palettes.tints_yellow
  },
  brand_inverse: {
    ...fonts.h4_bold,
    background: palettes.white,
    padding: "10px",
    border: `solid 1px ${palettes.tints_blue}`,
    color: palettes.tints_blue
  },
  disabled: {
    ...fonts.h4_bold,
    background: palettes.system_gray,
    padding: "10px",
    border: "none",
    color: palettes.system_gray_darker
  }
};
