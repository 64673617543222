import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Card,
  Hr,
  FeaturedImage,
  CardLink,
  Section,
  Text,
  Title,
} from "../Secao";

export const Destaque = ({ properties, mode, variant }) => {
  return (
    <Section theme={{ mode: mode, variant: variant }}>
      <Container fluid="lg" className="maxSectionWidth px-0">
        <div className="d-flex justify-content-center p-2">
          <FeaturedImage
            theme={{ mode: mode }}
            src="https://i.imgur.com/3cvoLXH.png"
            alt="image"
          />
        </div>
        <Title theme={{ mode: mode }}>{properties[1].value}</Title>
        <Hr style={{ opacity: 0.25 }} />
        <Row className="mt-2">
          <Col lg={4}>
            <Card theme={{ mode: mode, variant: "light" }}>
              <CardLink theme={{ mode: mode, variant: "light" }}>
                <b>{properties[2].properties[0].value}</b>
              </CardLink>
              <div className="pt-1">
                <p
                  theme={{ mode: mode }}
                  dangerouslySetInnerHTML={{
                    __html: properties[2].properties[1].value,
                  }}
                ></p>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card theme={{ mode: mode, variant: "light" }} id="center-card">
              <CardLink theme={{ mode: mode, variant: "light" }}>
                <b>{properties[3].properties[0].value}</b>
              </CardLink>
              <div className="pt-1">
                <p
                  theme={{ mode: mode }}
                  dangerouslySetInnerHTML={{
                    __html: properties[3].properties[1].value,
                  }}
                ></p>
              </div>
            </Card>
          </Col>
          <Col lg={4}>
            <Card theme={{ mode: mode, variant: "light" }}>
              <CardLink theme={{ mode: mode, variant: "light" }}>
                <b>{properties[4].properties[0].value}</b>
              </CardLink>
              <div className="pt-1">
                <p
                  theme={{ mode: mode }}
                  dangerouslySetInnerHTML={{
                    __html: properties[4].properties[1].value,
                  }}
                ></p>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

Destaque.defaultProperties = {
  mode: "dafault",
};

export default Destaque;
