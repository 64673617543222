import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { cmsSlice } from "redux/cms/cmsSlice";

export function ConfirmationModal(props) {
    const dispatch = useDispatch();
    const confirmationModalState = useSelector((state) => state?.cms?.confirmationModal);
    const { setConfirmationModal } = cmsSlice.actions;

    const handleClose = () => dispatch(setConfirmationModal(false));
    
    return (
        <>
            <Modal show={confirmationModalState} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={props.callback}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}