import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {blogSlice} from "redux/blog/blogSlice";
import {BlogPageType} from "redux/blog/constants";
import {cmsSlice} from "redux/cms/cmsSlice";
import {CategoryDetails, CategoryListContainer, FilterButton, InputWrapper} from "./styles";
import {CategoryListItem} from "./categoryListItem";
import {bulkUpdateCategory, bulkUpdateSubcategory, fetchCategory, fetchSubcategory} from "redux/blog/blogService";
import {PageTypes} from "redux/cms/constants";
import {GreyRetangle, InputWapper, PostActionButton} from "../PostList/styles";

const CategoryList = () => {
  const { setBlogPageType } = blogSlice.actions;
  const { setPageType } = cmsSlice.actions;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBlogPageType(BlogPageType.CATEGORY_LIST));
    dispatch(setPageType(PageTypes.BLOG));
    dispatch(fetchCategory());
    dispatch(fetchSubcategory());
  }, []);
  const [categoriesLenght, setCategoriesLenght] = useState(0);
  const [disabledCategoriesLength, setDisabledCategoriesLength] = useState(0);
  const [trashCategoriesLength, setTrashCategoriesLength] = useState(0);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const categoriesList = useSelector((state) => state.blog.categories);
  const subcategoriesList = useSelector((state) => state.blog.subcategories);
  const selectedCategoriesAndSubcategories = useSelector((state) => state.blog.selectedCategoriesAndSubcategories);
  const [categoryStatus, setCategoryStatus] = useState('ENABLED');
  const [filterStatus, setFilterStatus] = useState();
  const [firstCall, setFirstCall] = useState(true);
  const [options, setOptions] = useState([
      {value: "ENABLED", name: "Habilitada"},
      {value: "DISABLED", name: "Desabilitada"},
      {value: "TRASH", name: "Lixo"}
  ]);

  const orderCategoriesByParent = categoriesList.map((parent) => {
    const child = subcategoriesList?.filter((child) => child.categoryId === parent.id);
    return {
      parent: parent,
      children: child
    }
  });

  useEffect(() => {
    setCategoriesLenght(categoriesList.length + subcategoriesList.length);
    setDisabledCategoriesLength(categoriesList.filter((category) => category.status === "DISABLED").length + subcategoriesList.filter((subcategory) => subcategory.status === "DISABLED").length);
    setTrashCategoriesLength(categoriesList.filter((category) => category.status === "TRASH").length + subcategoriesList.filter((subcategory) => subcategory.status === "TRASH").length);
  }, [orderCategoriesByParent]);

  const handleFilter = (e) => {
    dispatch(blogSlice.actions.setFilteredCategories(e.target.value));
  }

  const handleFilterByStatus = (e) => {
    setIsAllSelected(false);
    dispatch(blogSlice.actions.setSelectedCategoriesAndSubcategories("none"));
    setFilterStatus(e);
    dispatch(blogSlice.actions.setCategoriesFilteredByStatus(e));
    let options = [];
    switch (e){
      case 'DISABLED':
        options = [
          {value: "ENABLED", name: "Habilitada"},
          {value: "TRASH", name: "Lixo"}
        ];
        setCategoryStatus('ENABLED');
        break;
      case 'TRASH':
        options = [
          {value: "ENABLED", name: "Habilitada"},
          {value: "DISABLED", name: "Desabilitada"}
        ];
        setCategoryStatus('ENABLED');
        break;
      default:
        options = [
          {value: "ENABLED", name: "Habilitada"},
          {value: "DISABLED", name: "Desabilitada"},
          {value: "TRASH", name: "Lixo"}
        ];
        setCategoryStatus('ENABLED');
        break;
    }
    setOptions(options);
  }

  const handleSelectAll = () => {
    if(firstCall){
      dispatch(blogSlice.actions.setFilteredCategories(null));
      setFirstCall(false);
    }
    setIsAllSelected(!isAllSelected);
  }

  const handleActionChange = (value) => {
    setCategoryStatus(value.target.value);
  };

  const handleEditStatus = () => {
    const categories = selectedCategoriesAndSubcategories.map((category) => {
      if(!category?.categoryId){
        return category.id;
      }
    });
    const subcategories = selectedCategoriesAndSubcategories.map((category) => {
      if(category?.categoryId){
        return category.id;
      }
    });
    dispatch(bulkUpdateCategory({
      categories: categories,
      status: categoryStatus
    })).then(() => {
      setIsAllSelected(false);
      dispatch(blogSlice.actions.setSelectedCategoriesAndSubcategories("none"));
      dispatch(fetchCategory());
    });
    dispatch(bulkUpdateSubcategory({
      subCategories: subcategories,
      status: categoryStatus
    })).then(() => {
      dispatch(fetchSubcategory());
    });
  }

  const bulkActions = () => {
    if (selectedCategoriesAndSubcategories.length > 0)
      return (
          <>
            <div className="d-flex">
              <InputWapper>
                <GreyRetangle />
                <div className="content">
                  <span>Ações por seleção</span>

                  <select
                      onChange={handleActionChange}
                      id="postActionsId"
                      defaultValue={categoryStatus}
                  >
                    {options.map((e, key) =>{
                        return <option value={e.value}>{e.name}</option>;
                    })}
                  </select>
                </div>
              </InputWapper>
              <PostActionButton
                  theme={{ mode: "normal" }}
                  onClick={(e) => handleEditStatus(e)}
              >
                Aplicar
              </PostActionButton>
            </div>
          </>
      );
  };

  useEffect(() => {
    isAllSelected ?
    dispatch(blogSlice.actions.setSelectedCategoriesAndSubcategories("all"))
    :
    dispatch(blogSlice.actions.setSelectedCategoriesAndSubcategories("none"));
  }, [isAllSelected]);

  useEffect(() => {
    
  }, [selectedCategoriesAndSubcategories]);

  return (
    <>
      <CategoryListContainer>
        <div className="d-flex justify-content-between">
          <CategoryDetails>
            <FilterButton onClick={() => {handleFilterByStatus()}}>
              <h6>
                <span  style={{
                  fontWeight: filterStatus == null ? "bold" : "normal",
                }}>Todos as categorias ({categoriesLenght})</span>
              </h6>
            </FilterButton>
            <h6 className="mt-2">|</h6>
            <FilterButton onClick={() => {handleFilterByStatus("DISABLED")}}>
              <h6>
                <span  style={{
                  fontWeight: filterStatus === 'DISABLED' ? "bold" : "normal",
                }}>Desabilitadas ({disabledCategoriesLength})</span>
              </h6>
            </FilterButton>
            <h6 className="mt-2">|</h6>
            <FilterButton onClick={() => {handleFilterByStatus("TRASH")}}>
              <h6>
                <span  style={{
                  fontWeight: filterStatus === 'TRASH' ? "bold" : "normal",
                }}>Lixo ({trashCategoriesLength})</span>
              </h6>
            </FilterButton>
          </CategoryDetails>
          <InputWrapper className="mt-3">
            <input
              type="text"
              placeholder="BUSCA"
              onChange={(e) => {handleFilter(e)}}
            />
            <img src="/ic-search.svg" alt="icone buscar" className="mb-1" />
          </InputWrapper>
        </div>
        <hr />
        {selectedCategoriesAndSubcategories.length > 0}
        {bulkActions()}
        <div className="d-flex justify-content-between px-5 mb-3">
          <div>
            <input type="checkbox" id="all" name="all" value="all" checked={isAllSelected} className="mx-2" onChange={handleSelectAll}/>
            <span>Nome</span>
          </div>
          <span style={{position: "relative", left: "80px"}}>Destaque</span>
          <span style={{position: "relative", left: "30px"}}>Categoria Superior</span>
          <div>
            <span>Ações</span>
          </div>
        </div>
        {orderCategoriesByParent.map((parent) =>
          <CategoryListItem data={parent} />
        )}
      </CategoryListContainer>
    </>
  );
};

export default CategoryList;
