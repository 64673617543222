import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BlogStatus } from "redux/blog/constants";
import { fetchGetPosts } from "redux/postList/postListService";
import { cmsSlice } from "../../../../../../redux/cms/cmsSlice";
import Accordion from "../../../Accordion";
import AccordionBody from "../../../Accordion/AccordionBody";
import AccordionHeader from "../../../Accordion/AccordionHeader";
import AccordionItem from "../../../Accordion/AccordionItem";
import {
  PageConfigButton,
  PageConfigContainer,
  Spacer,
} from "../BlogConfig/styles";
import {
  Container,
  ContainerList,
  Description,
  InputWrapper,
  ItemCheckbox,
  ItemSelect,
} from "./styles";
import { fetchHighlights } from "../../../../../../redux/cms/cmsService";

const SearchPostList = () => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const postLoader = useSelector((state) => {
    return state.postList;
  });
  const [selectedPost, setSelectedPost] = useState();
  const history = useHistory();
  const { addPostItem } = cmsSlice.actions;
  const featuredPageState = useSelector((state) => state?.cms?.featuredPage);
  const highlightState = useSelector((state) => state.cms.status);

  useEffect(() => {
    dispatch(fetchGetPosts(BlogStatus.PUBLISHED));
    dispatch(fetchHighlights());
  }, []);

  useEffect(() => {
    setPosts(orderPosts());
  }, [postLoader]);

  useEffect(() => {
    if (featuredPageState?.removedPost) {
      const updatedPosts = [...posts];
      updatedPosts.push(featuredPageState?.removedPost);
      setPosts(updatedPosts.sort((a, b) => a.title.localeCompare(b.title)));
    }
  }, [featuredPageState?.removedPost]);

  useEffect(() => {
    if (highlightState === "fetchHighlights-success") {
      const insertedIds = featuredPageState?.posts.map((p) => p.id);
      let difference = posts
        .filter((x) => {
          return !insertedIds.includes(x.id);
        })
        .sort((a, b) => a.title.localeCompare(b.title));
      setPosts([...difference]);
    }
  }, [highlightState]);

  const orderPosts = () => {
    let posts = [];
    postLoader?.postListItem?.forEach((post) => {
      posts.push({ ...post, selected: false });
    });
    return posts.sort((a, b) => a.title?.localeCompare(b.title));
  };

  const postList = () => {
    return posts.map((post, index) => {
      return (
        <div className="d-flex" key={index}>
          <ItemCheckbox
            type="checkbox"
            checked={selectedPost?.id === post.id}
            onChange={() => {
              setSelectedPost(post);
            }}
          />
          <ItemSelect
            theme={{ mode: "normal" }}
            onClick={() => {
              setSelectedPost(post);
            }}
          >
            {post.title}
          </ItemSelect>
        </div>
      );
    });
  };

  const handleFilter = (e) => {
    if (e.target.value) {
      const filteredPosts = posts
        ?.filter((post) =>
          post.title?.toLowerCase().includes(e.target.value.toLowerCase())
        )
        .sort((a, b) => a.title.localeCompare(b.title));
      setPosts(filteredPosts);
    } else {
      setPosts(orderPosts());
    }
  };

  const handleAddClick = (post) => {
    if (post) {
      dispatch(addPostItem({ post }));
      const updatePosts = posts
        .filter((p) => p.id !== post.id)
        .sort((a, b) => a.title.localeCompare(b.title));
      setPosts(updatePosts);
      setSelectedPost(null);
    }
  };

  return (
    <>
      <Accordion keepOtherOpen={true} selected={`item-blog-featured`}>
        <AccordionItem>
          <AccordionHeader
            hasTransparentBackground
            name={`item-blog-featured`}
            title="Publicaçoes do Blog"
          />
          <AccordionBody name={`item-blog-featured`}>
            <Container>
              <Description>
                Adicione publicações no esquema abaixo. Selecione a publicação
                que deseja adicionar como destaque no Blog.
              </Description>
              <InputWrapper className="mt-3">
                <input
                  type="text"
                  placeholder="BUSCAR PUBLICAÇÃO"
                  onChange={(e) => handleFilter(e)}
                />
                <img src="/ic-search.svg" alt="icone buscar" className="mb-1" />
              </InputWrapper>
              <ContainerList>{postList()}</ContainerList>
            </Container>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <PageConfigContainer>
        <PageConfigButton
          theme={{ mode: "normal" }}
          onClick={() => handleAddClick(selectedPost)}
        >
          Adicionar ao Destaque
        </PageConfigButton>
      </PageConfigContainer>
      <Spacer />
      <PageConfigContainer>
        <PageConfigButton
          theme={{ mode: "inverse" }}
          onClick={() => history.push("/cms/post-list")}
        >
          Voltar
        </PageConfigButton>
      </PageConfigContainer>
    </>
  );
};

export default SearchPostList;
