import React from "react";
import { Header } from '../../components/Header';
import { BreadCrumb } from '../../components/BreadCrumb';
import { TitleTextSubtitleWithTextAndCTAButton } from '../../components/TitleTextSubtitleWithTextAndCTAButton';
import { LinksToFAQ } from '../../components/LinksToFAQ';
import { ListOfCategoriesAndArticles } from '../../components/ListOfCategoriesAndArticles';
import { Footer } from '../../components/Footer';
import footerData from "@components/Footer/Resources/example.json";
import { Row, Col } from 'react-bootstrap';
import { TitleContext } from "context/TitleContext";

export function FAQPage(props) {

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - FAQ");
    }, []);

    return (
        <>
            <Header data={props.data.headerData} />
            <BreadCrumb data={props.data.breadCrumbData} />
            <TitleTextSubtitleWithTextAndCTAButton data={props.data.titleTextSubtitleWithTextAndCTAButtonData} />
            <Row className="p-0">
                <Col sm={0} md={1} >
                </Col>
                <Col sm={12} lg={4} className="py-2 mt-2">
                    <LinksToFAQ data={props.data.linksToFAQData} />
                </Col>
                <Col sm={12} lg={7}>
                    <ListOfCategoriesAndArticles data={props.data.listOfCategoriesAndArticlesData} />
                </Col>
            </Row>
            <Footer data={footerData} />
        </>
    );
}

export default FAQPage;