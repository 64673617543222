import styled from "styled-components";

import { Col } from "react-bootstrap";

export const MyInfoBlock = styled.div`
    color: ${(props) => props.colorText};
    font-family: 'Poppins', sans-serif;
`;

export const InfoBlockImage = styled(Col)`
    display: flex;
    justify-content: ${(props) => props.imagedata.position};  
    text-align: left;
    padding-top: 5%;
    
    @media(max-width: 576px){
        img{
            height: ${(props) => props.imagedata.mobileSize};
        }
    }

`;

export const InfoBlockText = styled.div`
    font-size: 22px;
    padding: 5% 7% 2% 0;
    
    @media(max-width: 576px){
        p{
            font-size: 14px;
        }
    }
`;

export const InfoBlockTitles = styled.div`
    font-weight: 700;
    h4{
        font-size: 36px;
        font-weight: 700;
    }
    
    @media(max-width: 576px){
        h4{
            font-size: 26px;
        }
    }
`;