import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React from "react";
import { BreadCrumb } from "../../components/BreadCrumb";
import { HorizontalImageTitleInfoAndButtons } from "../../components/HorizontalImageTitleInfoAndButtons";
import { ImageHeader } from "../../components/ImageHeader";
import { ThreeImageTexts } from "../../components/ThreeImageTexts";

export function AlamoApp(props) {

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - Aplicativo");
    }, []);

    const components =
        <>
            <BreadCrumb data={props.data.breadCrumbData} />
            <ImageHeader data={props.data.imageHeaderData} />
            <HorizontalImageTitleInfoAndButtons data={props.data.horizontalImageTitleInfoAndButtonsData} />
            <ThreeImageTexts data={props.data.threeImageTextsData} />
        </>

    return (
        <>
            <StaticPageEngine components={components} />
        </>
    )
}

export default AlamoApp;