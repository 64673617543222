import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "@api/api";
import getAxios from "api/api";

export const fetchUserLogged = createAsyncThunk(
  "auth/fetchUserLogged",
  async (user) => {
    const response = await getAxios().post("/auth/signin", user);
    return response.data;
  }
);
