import styled from "styled-components";

export const TitleAndCTAButtonBlockBackground = styled.div`
    width: 100%;
    margin-bottom: 80px;
    h2{
        margin-bottom: 43px;
    }
    a{
        width: 40%;
        text-decoration: none;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px;
        h2{
            margin-bottom: 17px;
        }
        a{
            width: 100%;
        }
    }
`;