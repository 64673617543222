import styled from "styled-components";

export const TitleTextSubtitleWithTextAndCTAButtonBackground = styled.div`
    background-color: #005DAA;
    color: white;
    font-family: 'Poppins', sans-serif;
    padding: 2rem 3rem;
    a{
        text-decoration: none;
    }
`;