import React from "react";

import {WidgetSection, Pbk, WidgetText} from "./styles"
import {Container} from "react-bootstrap";

export function PbkWidget(){
    return(
        <WidgetSection>
            <Container>
                <WidgetText>Faça sua reserva!</WidgetText>
                <Pbk id="pbk-widget"></Pbk>
            </Container>
        </WidgetSection>
    )
}