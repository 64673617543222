import styled from "styled-components";

import { palettes, fonts, buttons } from "assets/theme";
import theme from "styled-theming";

const buttonConfig = theme("mode", {
  normal: buttons.secondary,
  inverse: buttons.secondary_inverse,
  primaryInverse: buttons.primary_inverse
});

export const ContentDiscription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${palettes.white};
  padding: 40px 15px;
  border: dashed 3px ${palettes.tints_blue_dark};
  margin: 40px 0;
  color: ${palettes.tints_blue};
  h4 {
    ${fonts.h4_bold}
  }
  h2 {
    ${fonts.h2_bold}
  }
`;
export const TemplateContainer = styled.div`
  position: relative;
`;
export const TemplateHighlight = styled.div`
  background-color: transparent;
  border: dashed 3px ${palettes.tints_blue_dark};
  color: ${palettes.tints_blue};
`;
export const PageConfigButton = styled.button`
  ${buttonConfig};
  width: 212px;
  height: 64px;
  margin: 0px 5px;
`;
export const ButtonContainre = styled.div`
  position: absolute;
  z-index: 999;
  right: 0;
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 5px;
  top: 8px;
  padding: 0px 5px 2px;
  font-weight: bold;
  svg {
    width: 28px;
    height: 28px;
    path{
      fill: #d50000 !important;
    }
    &:hover{
      scale: 1.3;
    }
  }
`;