import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setPostListItem } from "redux/postList/postListSlice.js";
import { PostListItem } from "../PostListItem/index.js";
import {
  PostListContainer,
  PostListItemContainer,
  PostListRow
} from "./styles";

export function PostList(props) {
  const dispatch = useDispatch();

  const postListFiltered = useSelector(state => state.postList.postListFiltered);

  useEffect(() => {
    postListItems();
  }, [postListFiltered]);

  useEffect(() => {
    dispatch(setPostListItem())
  }, []);

  const [subcategoryName, setSubcategoryName] = useState(null);
  const blogState = useSelector((state) => state.blog);
  const { category } = useParams();

  useEffect(() => {
    setSubcategoryName(blogState.selectedSubcategory);
  }, [blogState]);

  const postListItems = () => {
    let postList = postListFiltered;
    if (subcategoryName || category) {
      postList = postList?.filter((post) =>
        post.category.find((item) => item.name === category)
      );
      if (subcategoryName && subcategoryName !== "todas") {
        postList = postList?.filter((post) =>
          post.category.find((item) => item.name === subcategoryName)
        );
      }
    }
    return postList?.map((postList, i) => {
      return (
        <PostListItemContainer lg={3} md={6} xs={12} key={i}>
          <PostListItem data={postList} />
        </PostListItemContainer>
      );
    });
  };

  return (
    <Container fluid="xxl">
      <PostListContainer>
        <PostListRow>{postListItems()}</PostListRow>
      </PostListContainer>
    </Container>
  );
}
