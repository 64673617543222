import "bootstrap/dist/css/bootstrap.css";
import insuranceProtectionPoliciesData from "pages/ContentPages/InsuranceProtectionPolicies/Resources/example.json";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import aboutAlamoData from "./pages/AboutAlamo/Resources/example.json";
import alamoAppData from "./pages/AlamoApp/Resources/example.json";
import blogCategoryData from "./pages/Blog/Category/Resources/example.json";
import cmsPageData from "./pages/CMS/Resources/cmsPage.json";
import { AccountingForRentalRates } from "./pages/ContentPages/AccountingForRentalRates";
import accountingForRentalRatesData from "./pages/ContentPages/AccountingForRentalRates/Resources/example.json";
import additionalDriverPoliciesData from "./pages/ContentPages/AdditionalDriverPolicies/Resources/example.json";
import { AdditionalLeaseAccessories } from "./pages/ContentPages/AdditionalLeaseAccessories";
import additionalLeaseAccessoriesData from "./pages/ContentPages/AdditionalLeaseAccessories/Resources/example.json";
import ageToRentPoliciesData from "./pages/ContentPages/AgeToRentPolicies/Resources/example.json";
import cancelModifyPoliciesData from "./pages/ContentPages/CancelModifyPolicies/Resources/example.json";
import carSeatPoliciesData from "./pages/ContentPages/CarSeatPolicies/Resources/example.json";
import complementsPoliciesData from "./pages/ContentPages/ComplementsPolicies/Resources/example.json";
import costumersWithDesabilitiesData from "./pages/ContentPages/CostumersWithDesabilities/Resources/example.json";
import crossBorderPoliciesData from "./pages/ContentPages/CrossBorderPolicies/Resources/example.json";
import driversLicensePoliciesData from "./pages/ContentPages/DriversLicensePolicies/Resources/example.json";
import { EarlyReturn } from "./pages/ContentPages/EarlyReturn";
import earlyReturnData from "./pages/ContentPages/EarlyReturn/Resources/example.json";
import { ExtensionOfTheRentalPeriod } from "./pages/ContentPages/ExtensionOfTheRentalPeriod";
import extensionOfTheRentalPeriodData from "./pages/ContentPages/ExtensionOfTheRentalPeriod/Resources/example.json";
import flightDelayPoliciesData from "./pages/ContentPages/FlightDelayPolicies/Resources/example.json";
import { FuelService } from "./pages/ContentPages/FuelService";
import fuelServiceData from "./pages/ContentPages/FuelService/Resources/example.json";
import fuelServicePoliciesData from "./pages/ContentPages/FuelServicePolicies/Resources/example.json";
import giftCardsPoliciesData from "./pages/ContentPages/GiftCardsPolicies/Resources/example.json";
import gracePeriodsData from "./pages/ContentPages/GracePeriodsPolicies/Resources/example.json";
import { HowToChangeOrCancelReservation } from "./pages/ContentPages/HowToChangeOrCancelReservation";
import howToChangeOrCancelReservationData from "./pages/ContentPages/HowToChangeOrCancelReservation/Resources/example.json";
import { LateReturn } from "./pages/ContentPages/LateReturn";
import lateReturnData from "./pages/ContentPages/LateReturn/Resources/example.json";
import lostAndFoundPoliciesData from "./pages/ContentPages/LostAndFoundPolicies/Resources/example.json";
import { MaximumRentalPeriod } from "./pages/ContentPages/MaximumRentalPeriod";
import maximumRentalPeriodData from "./pages/ContentPages/MaximumRentalPeriod/Resources/example.json";
import { MechanicalProblemsWithMyAlamoVehicle } from "./pages/ContentPages/MechanicalProblemsWithMyAlamoVehicle";
import mechanicalProblemsWithMyAlamoVehicleData from "./pages/ContentPages/MechanicalProblemsWithMyAlamoVehicle/Resources/example.json";
import militarPoliciesData from "./pages/ContentPages/MilitarPolicies/Resources/example.json";
import { MyAlamoOutsideBrazil } from "./pages/ContentPages/MyAlamoOutsideBrazil";
import myAlamoOutsideBrazilData from "./pages/ContentPages/MyAlamoOutsideBrazil/Resources/example.json";
import oneWayPoliciesData from "./pages/ContentPages/OneWayPolicies/Resources/example.json";
import paymentPoliciesData from "./pages/ContentPages/PaymentPolicies/Resources/example.json";
import petsPoliciesdata from "./pages/ContentPages/PetsPolicies/Resources/example.json";
import prePayPoliciesData from "./pages/ContentPages/PrePayPolicies/Resources/example.json";
import proofOfPaymentPoliciesData from "./pages/ContentPages/ProofOfPaymentPolicies/Resources/example.json";
import refundInformationPoliciesData from "./pages/ContentPages/RefundInformationPolicies/Resources/example.json";
import { RentalCarInsuranceAndProtectionProducts } from "./pages/ContentPages/RentalCarInsuranceAndProtectionProducts";
import rentalCarInsuranceAndProtectionProductsData from "./pages/ContentPages/RentalCarInsuranceAndProtectionProducts/Resources/example.json";
import rentingCashPoliciesData from "./pages/ContentPages/RentingCashPolicies/Resources/example.json";
import requirementsPoliciesData from "./pages/ContentPages/RequirementsPolicies/Resources/example.json";
import rewardsMilesPoliciesData from "./pages/ContentPages/RewardsMilesPolicies/Resources/example.json";
import safetyRecallPoliciesData from "./pages/ContentPages/SafetyRecallPolicies/Resources/example.json";
import siriusXMPoliciesData from "./pages/ContentPages/SiriusXMPolicies/Resources/example.json";
import snowChainPoliciesData from "./pages/ContentPages/SnowChainPolicies/Resources/example.json";
import taxesPoliciesdata from "./pages/ContentPages/TaxesPolicies/Resources/example.json";
import tollCitationChargesPoliciesData from "./pages/ContentPages/TollCitationChargesPolicies/Resources/example.json";
import tollFeesPoliciesData from "./pages/ContentPages/TollFeesPolicies/Resources/example.json";
import tollReceiptPoliciesData from "./pages/ContentPages/TollReceiptPolicies/Resources/example.json";
import towingPoliciesData from "./pages/ContentPages/TowingPolicies/Resources/example.json";
import { TrafficAccident } from "./pages/ContentPages/TrafficAccident";
import trafficAccidentData from "./pages/ContentPages/TrafficAccident/Resources/example.json";
import { TrafficTicket } from "./pages/ContentPages/TrafficTicket";
import trafficTicketData from "./pages/ContentPages/TrafficTicket/Resources/example.json";
import travelTabPoliciesData from "./pages/ContentPages/TravelTabPolicies/Resources/example.json";
import CovidInfoData from "./pages/CovidInfo/Resources/example.json";
import customerSupportData from "./pages/CustomerSupport/Resources/example.json";
import FAQData from "./pages/FAQ/Resources/example.json";
import homePageData from "./pages/Homepage/Resources/homepage.json";
import locationAllData from "./pages/LocationAll/Resources/example.json";
import locationAndOffersData from "./pages/LocationAndOffers/Resources/example.json";
import LocationCountryData from "./pages/LocationCountry/Resources/example.json";
import LocationDetailData from "./pages/LocationDetail/Resources/example.json";
import locationsData from "./pages/Locations/Resources/example.json";
import locationWithMapData from "./pages/LocationWithMap/Resources/example.json";
import pageNotFoundData from "./pages/PageNotFound/Resources/example.json";
import reservationsData from "./pages/Reservations/Resources/example.json";
import siteMapData from "./pages/SiteMap/Resources/example.json";
import TermsOfUseData from "./pages/TermsOfUse/Resources/example.json";
import vehiclesData from "./pages/Vehicles/Resources/example.json";
import vehicleSubcategoryDetailsData from "./pages/VehicleSubcategoryDetails/Resources/example.json";

import { LoaderComponent } from "./components/Loader/index";

import BlogEngine from "pages/BlogEngine";
import BlogHomepage from "pages/BlogEngine/Home";
import { Login } from "pages/Login";
import { UserAdminPage } from "pages/UserAdmin";
import { AboutAlamo } from "./pages/AboutAlamo";
import { AlamoApp } from "./pages/AlamoApp";
import { BlogCategory } from "./pages/Blog/Category";
import { ChangePassword } from "./pages/ChangePassword";
import CmsPage from "./pages/CMS";
import { UserCreationPage } from "./pages/CMS/UserCreation";
import CMSEngine from "./pages/CMSEngine";
import { Contact } from "./pages/Contact";
import { AdditionalDriverPolicies } from "./pages/ContentPages/AdditionalDriverPolicies";
import { AgeToRentPolicies } from "./pages/ContentPages/AgeToRentPolicies";
import { CancelModifyPolicies } from "./pages/ContentPages/CancelModifyPolicies";
import { CarSeatPolicies } from "./pages/ContentPages/CarSeatPolicies";
import { ComplementsPolicies } from "./pages/ContentPages/ComplementsPolicies";
import { CustomersWithDesabilities } from "./pages/ContentPages/CostumersWithDesabilities";
import { CrossBorderPolicies } from "./pages/ContentPages/CrossBorderPolicies";
import { DriversLicensePolicies } from "./pages/ContentPages/DriversLicensePolicies";
import { FlightDelayPolicies } from "./pages/ContentPages/FlightDelayPolicies";
import { FuelServicePolicies } from "./pages/ContentPages/FuelServicePolicies";
import { GiftCardsPolicies } from "./pages/ContentPages/GiftCardsPolicies";
import { GracePeriods } from "./pages/ContentPages/GracePeriodsPolicies";
import { InsuranceProtectionPolicies } from "./pages/ContentPages/InsuranceProtectionPolicies";
import { LostAndFoundPolicies } from "./pages/ContentPages/LostAndFoundPolicies";
import { MilitarPolicies } from "./pages/ContentPages/MilitarPolicies";
import { OneWayPolicies } from "./pages/ContentPages/OneWayPolicies";
import { PaymentPolicies } from "./pages/ContentPages/PaymentPolicies";
import { PetsPolicies } from "./pages/ContentPages/PetsPolicies";
import { PrePayPolicies } from "./pages/ContentPages/PrePayPolicies";
import { ProofOfPaymentPolicies } from "./pages/ContentPages/ProofOfPaymentPolicies";
import { RefundInformationPolicies } from "./pages/ContentPages/RefundInformationPolicies";
import { RentingCashPolicies } from "./pages/ContentPages/RentingCashPolicies";
import { RequirementsPolicies } from "./pages/ContentPages/RequirementsPolicies";
import { RewardsMilesPolicies } from "./pages/ContentPages/RewardsMilesPolicies";
import { SafetyRecallPolicies } from "./pages/ContentPages/SafetyRecallPolicies";
import { SiriusXMPolicies } from "./pages/ContentPages/SiriusXMPolicies";
import { SnowChainPolicies } from "./pages/ContentPages/SnowChainPolicies";
import { TaxesPolicies } from "./pages/ContentPages/TaxesPolicies";
import { TollCitationChargesPolicies } from "./pages/ContentPages/TollCitationChargesPolicies";
import { TollFeesPolicies } from "./pages/ContentPages/TollFeesPolicies";
import { TollReceiptPolicies } from "./pages/ContentPages/TollReceiptPolicies";
import { TowingPolicies } from "./pages/ContentPages/TowingPolicies";
import { TravelTabPolicies } from "./pages/ContentPages/TravelTabPolicies";
import { CookiesPolicies } from "./pages/CookiesPolicies";
import { CovidInfoPAge } from "./pages/CovidInfo";
import { CustomerSupport } from "./pages/CustomerSupport";
import { FAQPage } from "./pages/FAQ";
import { Franchises } from "./pages/Franchises";
import { Homepage } from "./pages/Homepage";
import { LocationAllPage } from "./pages/LocationAll";
import { LocationAndOffers } from "./pages/LocationAndOffers";
import { LocationCountryPage } from "./pages/LocationCountry";
import { LocationDetailPage } from "./pages/LocationDetail";
import { Locations } from "./pages/Locations";
import { LocationWithMap } from "./pages/LocationWithMap";
import { PageNotFound } from "./pages/PageNotFound";
import { PrivacyPolicies } from "./pages/PrivacyPolicies";
import { ProfilePage } from "./pages/ProfilePage";
import { Reservations } from "./pages/Reservations";
import { SiteMapPage } from "./pages/SiteMap";
import { Subsidiaries } from "./pages/Subsidiaries";
import { TermsOfUse } from "./pages/TermsOfUse";
import { VehicleManufacturers } from "./pages/VehicleManufacturers";
import { Vehicles } from "./pages/Vehicles";
import { VehicleSubcategoryDetails } from "./pages/VehicleSubcategoryDetails";
import { EditProfilePage } from "pages/EditProfilePage";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/page-not-found">
          <PageNotFound data={pageNotFoundData} />
        </Route>
        <Route exact path="/contato">
          <Contact />
        </Route>
        <Route exact path="/politicas-de-privacidade">
          <PrivacyPolicies />
        </Route>
        <Route exact path="/fabricantes-de-veiculos">
          <VehicleManufacturers />
        </Route>
        <Route exact path="/franquias">
          <Franchises />
        </Route>
        <Route exact path="/subsidiarias">
          <Subsidiaries />
        </Route>
        <Route exact path="/politica-de-cookies">
          <CookiesPolicies />
        </Route>
        <Route exact path="/backoffice/pagina-de-perfil">
          <ProfilePage />
        </Route>
        <Route exact path="/backoffice/editar-perfil/:userId">
          <EditProfilePage />
        </Route>
        <Route exact path="/backoffice/mudar-senha">
          <ChangePassword />
        </Route>
        <Route exact path="/backoffice/administracao-de-usuario">
          <UserAdminPage />
        </Route>
        <Route exact path="/backoffice/criacao-de-usuario">
          <UserCreationPage />
        </Route>
        <Route exact path="/backoffice/login">
          <Login />
        </Route>
        <Route path="/blog/categoria/:category">
          <BlogCategory data={blogCategoryData} />
        </Route>
        <Route exact path="/blog">
          <BlogHomepage />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/complements-policies">
          <ComplementsPolicies data={complementsPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/costumers-with-desabilities">
          <CustomersWithDesabilities data={costumersWithDesabilitiesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/cross-border-policies">
          <CrossBorderPolicies data={crossBorderPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/traveltab-policies">
          <TravelTabPolicies data={travelTabPoliciesData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/insurance-protection-policies"
        >
          <InsuranceProtectionPolicies data={insuranceProtectionPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/safety-recall-policies">
          <SafetyRecallPolicies data={safetyRecallPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/payment-policies">
          <PaymentPolicies data={paymentPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/toll-receipt-policies">
          <TollReceiptPolicies data={tollReceiptPoliciesData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/toll-citation-charges-policies"
        >
          <TollCitationChargesPolicies data={tollCitationChargesPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/toll-fees-policies">
          <TollFeesPolicies data={tollFeesPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/requirements-policies">
          <RequirementsPolicies data={requirementsPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/rewards-miles-policies">
          <RewardsMilesPolicies data={rewardsMilesPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/militar-policies">
          <MilitarPolicies data={militarPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/age-to-rent-policies">
          <AgeToRentPolicies data={ageToRentPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/gift-cards-policies">
          <GiftCardsPolicies data={giftCardsPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/one-way-policies">
          <OneWayPolicies data={oneWayPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/towing-policies">
          <TowingPolicies data={towingPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/car-seat-policies">
          <CarSeatPolicies data={carSeatPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/sirius-xm-policies">
          <SiriusXMPolicies data={siriusXMPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/snow-chain-policies">
          <SnowChainPolicies data={snowChainPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/proof-of-payment-policies">
          <ProofOfPaymentPolicies data={proofOfPaymentPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/refund-information-policies">
          <RefundInformationPolicies data={refundInformationPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/grace-periods">
          <GracePeriods data={gracePeriodsData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/lost-and-found-policies">
          <LostAndFoundPolicies data={lostAndFoundPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/flight-delay-policies">
          <FlightDelayPolicies data={flightDelayPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/fuel-service-policies">
          <FuelServicePolicies data={fuelServicePoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/additional-driver-policies">
          <AdditionalDriverPolicies data={additionalDriverPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/drivers-license-policies">
          <DriversLicensePolicies data={driversLicensePoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/renting-cash-policies">
          <RentingCashPolicies data={rentingCashPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/cancel-modify-policies">
          <CancelModifyPolicies data={cancelModifyPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/prepay-policies">
          <PrePayPolicies data={prePayPoliciesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/pets-policies">
          <PetsPolicies data={petsPoliciesdata} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/taxes-policies">
          <TaxesPolicies data={taxesPoliciesdata} />
        </Route>
        <Route path="/suporte-ao-cliente/mapa-do-site">
          <SiteMapPage data={siteMapData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq">
          <FAQPage data={FAQData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/acessorios-adicionais-para-locacao"
        >
          <AdditionalLeaseAccessories data={additionalLeaseAccessoriesData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/seguro-para-carro-alugado-e-produtos-de-protecao"
        >
          <RentalCarInsuranceAndProtectionProducts
            data={rentalCarInsuranceAndProtectionProductsData}
          />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/meu-alamo-fora-do-brasil">
          <MyAlamoOutsideBrazil data={myAlamoOutsideBrazilData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/como-alterar-ou-cancelar-uma-reserva"
        >
          <HowToChangeOrCancelReservation
            data={howToChangeOrCancelReservationData}
          />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/periodo-maximo-de-alguel">
          <MaximumRentalPeriod data={maximumRentalPeriodData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/contabilizacao-das-diarias-de-alguel"
        >
          <AccountingForRentalRates data={accountingForRentalRatesData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/multas-de-transito">
          <TrafficTicket data={trafficTicketData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/acidentes-de-transito">
          <TrafficAccident data={trafficAccidentData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/problemas-mecanicos-com-meu-veiculo-alamo"
        >
          <MechanicalProblemsWithMyAlamoVehicle
            data={mechanicalProblemsWithMyAlamoVehicleData}
          />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/servico-de-combustivel">
          <FuelService data={fuelServiceData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/devolucao-antecipada">
          <EarlyReturn data={earlyReturnData} />
        </Route>
        <Route exact path="/suporte-ao-cliente/faq/devolucao-atrasada">
          <LateReturn data={lateReturnData} />
        </Route>
        <Route
          exact
          path="/suporte-ao-cliente/faq/extensao-do-periodo-de-aluguel"
        >
          <ExtensionOfTheRentalPeriod data={extensionOfTheRentalPeriodData} />
        </Route>
        <Route path="/aluguel-de-carro/achar-loja:agencyName">
          <LocationWithMap data={locationWithMapData} />
        </Route>
        <Route exact path="/suporte-ao-cliente">
          <CustomerSupport data={customerSupportData} />
        </Route>
        <Route path="/sobre/baixar-app">
          <AlamoApp data={alamoAppData} />
        </Route>
        <Route exact path="/agencias">
          <Locations data={locationsData} />
        </Route>
        <Route exact path="/termos-de-uso">
          <TermsOfUse data={TermsOfUseData} />
        </Route>
        <Route exact path="/agencias/todas/">
          <LocationAllPage data={locationAllData} />
        </Route>
        <Route path="/agencias/detalhes/:agencyName">
          <LocationDetailPage data={LocationDetailData} />
        </Route>
        <Route path="/agencias/detalhes-da-cidade/:cityId">
          <LocationDetailPage data={LocationDetailData} />
        </Route>
        <Route path="/agencias/ofertas/">
          <LocationAndOffers data={locationAndOffersData} />
        </Route>
        <Route path="/agencias/pais/:countryId">
          <LocationCountryPage data={LocationCountryData} />
        </Route>
        <Route exact path="/veiculos">
          <Vehicles data={vehiclesData} />
        </Route>
        <Route path="/veiculos/detalhes-de-subcategoria/:id">
          <VehicleSubcategoryDetails data={vehicleSubcategoryDetailsData} />
        </Route>
        <Route path="/reservas">
          <Reservations data={reservationsData} />
        </Route>
        <Route path="/sobre/covid">
          <CovidInfoPAge data={CovidInfoData} />
        </Route>
        <Route exact path="/sobre">
          <AboutAlamo data={aboutAlamoData} />
        </Route>
        <Route exact path="/">
          <CMSEngine />
        </Route>
        <Route exact path="/cms/:path?">
          <CmsPage data={cmsPageData} />
        </Route>
        <Suspense fallback={<LoaderComponent />}>
          <Route exact path="/blog/:path?">
            <BlogEngine />
          </Route>
          <Route exact path="/:path?">
            <CMSEngine />
          </Route>
        </Suspense>
      </Switch>
    </div>
  );
}

export default App;
