import { Container } from "react-bootstrap";
import {
  FooterBackground,
  FooterCopyright,
  FooterMenu,
  FooterMenuCol,
  FooterMenuName,
  FooterMenusDesktop,
  FooterMobile,
} from "./styles";

const Footer = ({ data }) => {
  //funçao que retorna uma lista de menus com seus links
  const footerMenusDesktop = (section, index) => (
    <div key={index}>
      <FooterMenuName>{section.name}</FooterMenuName>
      <FooterMenu>
        {section?.link?.map((item, i) => (
          <li key={i}>
            <a href={item.url}>{item.name}</a>
          </li>
        ))}
      </FooterMenu>
    </div>
  );

  return (
    <>
      <FooterBackground>
        <Container>
          <div>
            <FooterMenusDesktop>
              <hr />
              {data?.map((sec, index) => (
                <FooterMenuCol key={`section-${index}`} sm={4} xm={4}>
                  {footerMenusDesktop(sec, index)}
                </FooterMenuCol>
              ))}
            </FooterMenusDesktop>
          </div>
          <FooterMobile>
            <hr />
            {data?.map((sec, index) => footerMenusDesktop(sec, index))}
          </FooterMobile>
          <FooterCopyright>
            Copyright Ⓒ2021 Alamo. Todos os direitos reservados.
          </FooterCopyright>
        </Container>
      </FooterBackground>
    </>
  );
};
export default Footer;
