import excludeIcon from "@assets/images/cms/ic-trash.svg";
import pencilIcon from "@assets/images/ic-pencil.svg";
import { StyledButton } from "components/Button/styles";
import { LoaderComponent } from "components/Loader";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import { deleteUser, fetchUsers, updateUser } from "redux/user/userService";

import {
	EditUserContainer,
	InputWrapper,
	ProfilesDetails,
	UserAdminContainer,
	UserDetailsContainer
} from "./styles";

export function UserAdmin() {
	const history = useHistory();
	const dispatch = useDispatch();
	const [updateUserDisplay, setUpdateUserDisplay] = useState();
	const [users, setUsers] = useState([]);
	const [userToDelete, setUserToDelete] = useState("");
	const [show, setShow] = useState(false);
	const [userRolesSize, setUserRolesSize] = useState();
	const [userTypeSelected, setUserTypeSelected] = useState("all");

	const userState = useSelector((state) => state.user);

	useEffect(() => {
		let message;
		let type;
		switch (userState.status) {
			case "updateUser-failed":
				message = "Erro ao atualizar usuário";
				type = "error";
				break;
			case "updateUser-success":
				message = "Usuário atualizado com sucesso";
				type = "success";
				dispatch(fetchUsers());
				break;
			case "deleteUser-failed":
				message = "Erro ao excluir usuário";
				type = "error";
				break;
			case "deleteUser-success":
				message = "Usuário excluído com sucesso";
				type = "success";
				dispatch(fetchUsers());
				break;

			default:
				return;
		}
		if (message) {
			dispatch(displaySnackbar({ message, type }));
		}
	}, [userState.status]);

	useEffect(() => {
		setUsers(userState.user);
	}, [userState]);

	useEffect(() => {
		if (users) {
			setUserRolesSize({
				adminSize: userState?.user?.filter(
					(user) => user?.roles?.[0] === "ROLE_ADMIN"
				).length,
				userSize: userState?.user?.filter(
					(user) => user?.roles?.[0] === "ROLE_USER"
				).length,
			});
		}
	}, [users]);

	const handleFilter = (e) => {
		if (e.target.value) {
			const filteredUsers = userState.user.filter(
				(user) =>
					user?.email?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
					user?.firstName?.toLowerCase()?.includes(e.target.value.toLowerCase())
			);
			setUsers(filteredUsers);
		} else {
			setUsers(userState.user);
		}
	};

	//estado para atualizar o usuario

	const showUpdateUser = (id) => {
		setUpdateUserDisplay(id);
	};

	//funçao para atualizar o usuario
	const handleSubmit = async (newUserData) => {
		newUserData.preventDefault();
		const user = userState.user.find((p) => p.id === updateUserDisplay);
		dispatch(
			updateUser({
				...user,
				firstName: newUserData.target.firstName.value,
				lastName: newUserData.target.lastName.value,
				roles: [newUserData.target.role.value],
			})
		);
		showUpdateUser();
	};

	//estado do modal de exclusão

	const handleClose = () => {
		setUserToDelete(null);
		setShow(false);
	};

	const handleShow = (id) => {
		setUserToDelete(id);
		setShow(true);
	};

	const handleDeleteUser = () => {
		dispatch(deleteUser(userToDelete));
		handleClose();
	};

	const handleFilterUsersByRole = (option) => {
		switch (option) {
			case "all":
				setUsers(userState.user);
				setUserTypeSelected("all");

				break;
			case "admin":
				setUsers(
					userState?.user?.filter((user) => user?.roles?.[0] === "ROLE_ADMIN")
				);
				setUserTypeSelected("admin");
				break;
			case "user":
				setUsers(
					userState?.user?.filter((user) => user?.roles?.[0] === "ROLE_USER")
				);
				setUserTypeSelected("user");
				break;
			default:
				break;
		}
	};

	return (
		<>
			{userState.status === "loading" && <LoaderComponent />}
			{userState.status === "success" && (
				<UserAdminContainer>
					<h3>
						<b>Administração de Usuários</b>
					</h3>
					<div className="d-flex justify-content-between align-items-center">
						<ProfilesDetails>
							<button onClick={() => handleFilterUsersByRole("all")}>
								{userTypeSelected === "all" ? (
									<h6>
										<b>Todos ({userState.user.length})</b>
									</h6>
								) : (
									<h6>Todos ({userState.user.length})</h6>
								)}
							</button>
							<h6>|</h6>
							<button onClick={() => handleFilterUsersByRole("admin")}>
								{userTypeSelected === "admin" ? (
									<h6>
										<b>Administrador ({userRolesSize?.adminSize})</b>
									</h6>
								) : (
									<h6>Administrador ({userRolesSize?.adminSize})</h6>
								)}
							</button>
							<h6>|</h6>
							<button onClick={() => handleFilterUsersByRole("user")}>
								{userTypeSelected === "user" ? (
									<h6>
										<b>Usuário ({userRolesSize?.userSize})</b>
									</h6>
								) : (
									<h6>Usuário ({userRolesSize?.userSize})</h6>
								)}
							</button>
						</ProfilesDetails>
						<InputWrapper className="mt-3">
							<input
								type="text"
								placeholder="BUSCA"
								onChange={(e) => handleFilter(e)}
							/>
							<img src="/ic-search.svg" alt="icone buscar" className="mb-1" />
						</InputWrapper>
					</div>
					<hr />
					<div className="d-flex justify-content-between px-5 mb-3">
						<span>Nome</span>
						<span style={{ position: "relative", left: "20px" }}>Email</span>
						<span style={{ position: "relative", left: "50px" }}>Perfil</span>
						<span>Ações</span>
					</div>
					{users?.map((user, index) => (
						<div key={index}>
							<UserDetailsContainer>
								<p>{user.firstName + " " + user.lastName}</p>
								<p>{user.email}</p>
								<p style={{ position: "relative", left: "60px" }}>
									{user?.roles?.[0] === "ROLE_USER"
										? "Usuário"
										: "Administrador"}
								</p>
								<div className="d-flex justify-content-between">
									<button
										onClick={() => {
											history.push("/backoffice/editar-perfil/" + user.id);
										}}
									>
										<img src={pencilIcon} alt="icone de lapis" />
									</button>
									<button onClick={() => handleShow(user.id)}>
										<img src={excludeIcon} alt="icone de excluir" />
									</button>
								</div>
							</UserDetailsContainer>

							{updateUserDisplay === user.id && (
								<form onSubmit={handleSubmit} id={`ediUserForm${index}`}>
									<EditUserContainer>
										<input defaultValue={user.firstName} name="firstName" />
										<input defaultValue={user.lastName} name="lastName" />
										<input defaultValue={user.email} name="email" />
										<select
											name="role"
											id="role"
											form={`ediUserForm${index}`}
											defaultValue={user?.roles?.[0]?.name}
										>
											<option value="ROLE_ADMIN">Administrador</option>
											<option selected value="ROLE_USER">
												Usuário
											</option>
										</select>
										<div className="buttons">
											<button
												id="cancel-button"
												onClick={() => showUpdateUser()}
											>
												Cancelar
											</button>
											<button
												type="submit"
												id="save-button"
												form={`ediUserForm${index}`}
											>
												Salvar
											</button>
										</div>
									</EditUserContainer>
								</form>
							)}
						</div>
					))}
					<div className="d-flex justify-content-end mt-3">
						<StyledButton
							color="#8C4C9E"
							colortext="white"
							onClick={() => {
								history.push("/backoffice/criacao-de-usuario");
							}}
						>
							Criar novo perfil
						</StyledButton>
					</div>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Excluir página</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Você tem ceteza que deseja excluir esse usuário?
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Cancelar
							</Button>
							<Button variant="danger" onClick={handleDeleteUser}>
								Excluir
							</Button>
						</Modal.Footer>
					</Modal>
				</UserAdminContainer>
			)}
		</>
	);
}
