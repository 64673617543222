export const fonts = {
  banner: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "normal",
    "font-size": "52px",
    "line-height": "72px"
  },
  banner_bold: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "52px",
    "line-height": "72px"
  },
  h3: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "normal",
    "font-size": "17px",
    "line-height": "24px"
  },
  h3_bold: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "17px",
    "line-height": "24px"
  },
  h4: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "normal",
    "font-size": "17px",
    "line-height": "24px"
  },
  h4_bold: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "17px",
    "line-height": "24px"
  },
  paragraph: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "normal",
    "font-size": "12px",
    "line-height": "17px"
  },
  paragraph_bold: {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "bold",
    "font-size": "12px",
    "line-height": "17px"
  }
};
