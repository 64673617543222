import { Button } from "react-bootstrap";
import styled from "styled-components";

export const CustomerSupportContent = styled.div`
  margin-top: 30px;
  padding: 0px 1rem;
`;

export const CustomerSupportInformationCard = styled.div`
  padding: 15px;
`;

export const CustomerSupportIconBase = (props) => (
  <img src={props.icon} alt={props.alt} {...props} />
);

export const CustomerSupportIcon = styled(CustomerSupportIconBase)`
  width: 51px;
  margin-left: 20px;

  @media (max-width: 992px) {
    margin-left: 0px;
  }
`;

export const CustomerSupportTitle = styled.h1`
  font-size: 26px;
  font-weight: bold;
  font-family: "Poppins", "sans-serif";
  color: #213b73;
  text-align: left;
  width: 70%;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const CustomerSupportSubtitle = styled.p`
  font-size: 17px;
  color: #213b73;
  text-align: left;
  font-family: "Poppins", "sans-serif";
`;

export const CustomerSupportContentText = styled.p`
  font-size: 16px;
  font-weight: normal;
  color: #213b73;
  text-align: left;
  font-family: "Poppins", "sans-serif";
`;

export const CustomerSupportListItens = styled.ul`
  font-family: "Poppins", "sans-serif";
  font-size: 17px;

  ${({ type }) =>
    type === "topics" &&
    `
        li:before {
            content: "";
            display: none;
            width: 5px;
            height: 5px;
            background-color: #000;
            border-radius: 50%;
            margin-right: 10px;
        }

        li a {
            text-decoration: none;
            color: #7A498F!important;
        }

        @media (max-width: 992px) {
            font-size: 14px;
        }
    `}

  ${({ type }) =>
    type === "phone" &&
    `
        li {
            display: flex;
            justify-content: space-between;
        }

        li span {
            color: #213B73!important;
            font-weight: bold;
        }

        li a {
            color: #7A498F!important;
        }

        @media (max-width: 992px) {
            li {
                font-size: 14px;
            }
        }
    `}
`;

export const CustomerSupportButtonLink = styled(Button)`
  background-color: #8c4c9e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  width: 60%;
  margin-top: 30px;
  text-decoration: none;
  border-radius: 2px !important;

  &:hover {
    background-color: #8c4c9e;
    color: #fff;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;
