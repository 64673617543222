import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchGetPages, deletePage, fetchGetPageByUrl } from "./pageService";

const pageAdapter = createEntityAdapter();

const initialState = pageAdapter.getInitialState({
  status: "loading",
  page: [],
  cmsPage: {},
  error: null
});

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    getPage: (state) => {
      state.status = "loading";
    },
    getPageSuccess: (state, { payload }) => {
      state.status = "success";
      state.page = payload;
    },
    getPageFailed: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    }
  },
  extraReducers: {
    [fetchGetPages.pending]: (state) => {
      state.status = "loading";
    },
    [fetchGetPages.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.page = payload;
    },
    [fetchGetPages.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [fetchGetPageByUrl.pending]: (state) => {
      state.status = "loading";
    },
    [fetchGetPageByUrl.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.cmsPage = payload;
    },
    [fetchGetPageByUrl.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [deletePage.pending]: (state) => {
      state.status = "deletePage-loading";
    },
    [deletePage.fulfilled]: (state, { payload }) => {
      state.status = "deletePage-success";
      state.headers = payload;
    },
    [deletePage.rejected]: (state, action) => {
      state.status = "deletePage-failed";
      state.error = action.error;
    }
  }
});

export const { getPage, getPageSuccess, getPageFailed } = pageSlice.actions;

export default pageSlice.reducer;
