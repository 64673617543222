import { Container, Row, Col } from "react-bootstrap";
import {
  RentalPoliciesListBackground,
  RentalPoliciesListTitle,
  RentalPoliciesListList
} from "./styles";
import { useState } from "react";
import dropDownImageBefore from "../../assets/images/ic-arrow-yellow.svg";
import dropDownImageBefore2 from "../../assets/images/ic-dropdown-arrow-compress.svg";

export function RentalPoliciesList(props) {
  const [rentalPolicieText, setRentalPolicieText] = useState("");

  const [rentalPolicieTextIsActive, setRentalPolicieTextIsActive] =
    useState("none");

  const [buttonIsActive] = useState([]);

  const [wichButton, setWichButton] = useState();

  const handleRentalPoliciesListClick = (text, i) => {
    if (rentalPolicieText === text) {
      setRentalPolicieTextIsActive("none");
      setRentalPolicieText("");
      setWichButton(null);
      buttonIsActive[i] = "#213b73";
    } else {
      setRentalPolicieTextIsActive("block");
      setRentalPolicieText(text);
      buttonIsActive[i] = "transparent";
      setWichButton(i);
      buttonIsActive[wichButton] = "#213b73";
    }
  };

  const list = props.data.rentalPoliciesList.map((item, i) => {
    buttonIsActive.push("#213b73");
    return (
      <div key={i}>
        <button
          onClick={() => handleRentalPoliciesListClick(item.text, i)}
          style={{ background: buttonIsActive[i] }}
        >
          <Container className="d-flex justify-content-between">
            <p>{item.title}</p>
            <img
              src={
                props.data.css.page === "2"
                  ? dropDownImageBefore
                  : dropDownImageBefore2
              }
              alt="imagem"
            />
          </Container>
        </button>
        <hr />
      </div>
    );
  });

  return (
    <RentalPoliciesListBackground
      color={props.data.css.color}
      backgroundColor={props.data.css.backgroundColor}
    >
      <Container>
        <Row>
          {props.data.title && (
            <RentalPoliciesListTitle>
              <h4>{props.data.title}</h4>
            </RentalPoliciesListTitle>
          )}
          <Col xs={12} lg={6}>
            <RentalPoliciesListList
              color={props.data.css.color}
              image={props.data.img}
              page={props.data.css.page}
            >
              <hr />
              {list}
            </RentalPoliciesListList>
          </Col>
          <Col xs={12} lg={6}>
            <p
              style={{ display: rentalPolicieTextIsActive }}
              dangerouslySetInnerHTML={{ __html: rentalPolicieText }}
            />
          </Col>
          {props.data.span && (
            <>
              <p>{props.data.span}</p>
              <a href={props.data.link.url}>{props.data.link.text}</a>
            </>
          )}
        </Row>
      </Container>
    </RentalPoliciesListBackground>
  );
}
