import React from "react";
import { Header } from "../../components/Header";
import { BreadCrumb } from "../../components/BreadCrumb";
import { ContentBlueHeader } from "../../components/ContentBlueHeader";
import { ContentBlock } from "../../components/ContentBlock";
import { Footer } from "../../components/Footer";
import { Row, Col } from "react-bootstrap";
import footerData from "@components/Footer/Resources/example.json";
import { TitleContext } from "context/TitleContext";

export function TermsOfUse(props) {

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - Termos de Uso");
    }, []);


    return (
        <>
            <Header data={props.data.headerData} />
            <BreadCrumb data={props.data.breadCrumbData} />
            <ContentBlueHeader data={props.data.contentBlueHeaderData} />
            <Row>
                <Col lg={2} sm={0} />
                <Col lg={9} sm={12}>
                    <ContentBlock data={props.data.contentBlockData[0]} />
                    <ContentBlock data={props.data.contentBlockData[1]} />
                </Col>
                <Col lg={1} sm={0} />
            </Row>
            <Footer data={footerData} />
        </>
    )
}

export default TermsOfUse;