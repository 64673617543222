import { Accordion } from 'react-bootstrap';
import { LocationDetailAccordionHeader, LocationDetailAccordionItem, LocationDetailAccordionBody, LocationDetailImageText } from './styles';
import clockIcon from "@assets/images/ic-clock-alt.svg";

export function LocationDetailAcordion(props) {
    return (
        <Accordion>
            <LocationDetailAccordionItem eventKey="1">
                <LocationDetailAccordionHeader>
                        <LocationDetailImageText >
                            <img src={clockIcon} alt="Ativar o check-in rápido" />
                            <p>Horas de hoje • {props.data?.todayOpen} AM para {props.data?.todayClose} PM</p>
                        </LocationDetailImageText>
                </LocationDetailAccordionHeader>
                <Accordion.Body>
                    {props.data?.branchCloseMon &&
                        <LocationDetailAccordionBody>
                            <p>Sunday</p>
                            <p>{props.data?.branchOpenSun} para {props.data?.branchCloseSun}</p>
                        </LocationDetailAccordionBody>
                    }
                    {props.data?.branchCloseMon &&
                        <LocationDetailAccordionBody>
                            <p>Monday</p>
                            <p>{props.data?.branchOpenMon} para {props.data?.branchCloseMon}</p>
                        </LocationDetailAccordionBody>
                    }
                    {props.data?.branchCloseMon &&
                        <LocationDetailAccordionBody>
                            <p>Tuesday</p>
                            <p>{props.data?.branchOpenTue} para {props.data?.branchCloseTue}</p>
                        </LocationDetailAccordionBody>
                    }
                    {props.data?.branchCloseMon &&
                        <LocationDetailAccordionBody>
                            <p>Wednesday</p>
                            <p>{props.data?.branchOpenWed} para {props.data?.branchCloseWed}</p>
                        </LocationDetailAccordionBody>
                    }
                    {props.data?.branchCloseMon &&
                        <LocationDetailAccordionBody>
                            <p>Thursday</p>
                            <p>{props.data?.branchOpenThu} para {props.data?.branchCloseThu}</p>
                        </LocationDetailAccordionBody>
                    }
                    {props.data?.branchCloseMon &&
                        <LocationDetailAccordionBody>
                            <p>Friday</p>
                            <p>{props.data?.branchOpenMon} para {props.data?.branchCloseMon}</p>
                        </LocationDetailAccordionBody>
                    }
                    {props.data?.branchCloseMon &&
                        <LocationDetailAccordionBody>
                            <p>Saturday</p>
                            <p>{props.data?.branchOpenSat} para {props.data?.branchCloseSat}</p>
                        </LocationDetailAccordionBody>
                    }
                </Accordion.Body>
            </LocationDetailAccordionItem>
        </Accordion>
    );
}
