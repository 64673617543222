import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PostListItemCategory } from "../PostListItem/styles";
import { PostContentImageContainer, Title } from "./styles";

export function PostContent({
  data: { image, imageDescription, category, content, title }
}) {
  const categories = useSelector((state) => state?.blog?.categories);
  return (
    <>
      <PostContentImageContainer imageUrl={image}>
        <div>
          <p>{imageDescription}</p>
        </div>
      </PostContentImageContainer>
      <Container>
        <PostListItemCategory className="py-5">
          {category?.map((category, index) => (
            <p key={index}>
              {categories?.find((p) => p.id === category)?.name}
            </p>
          ))}
        </PostListItemCategory>
        <Title>{title}</Title>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </>
  );
}
