import styled from "styled-components";
import { Col, Container, Modal, Button } from "react-bootstrap";
import iconCarFilter from "../../assets/images/ic-car-filter.svg";


export const VehicleFilterBackground = styled.div`
    padding: 20px;
    background-color: #1c2c54;
    color: white;
    width: 70%;
    @media (max-width: 991px) {
        display: none;
    }
`;

export const VehicleFilterResponsive = styled(Col)`
    display: none;
    @media (max-width: 991px) {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
`;

export const VehicleFilterLabel = styled.div`
    label{
        display: flex;
        align-items: center;
        p{
            margin: 0;
        }
    }
    span{
        background-color: transparent;
        border:none;
        padding: 0;
    };
    input[type = checkbox]{
        margin: 10px;
    }
`;

export const VehicleFilterDropdown = styled(Container)`
    padding: 10px;
    background-color: #213B73;
    margin-bottom: 10px;
    border-bottom: 1px inset #A6ADB4;
    border-left: 2px solid #A6ADB4;
    button{
        width: 100%;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        &:hover, &:focus, &:active, &:visited {
			background-color: transparent !important;
            box-shadow: none !important;
		}
        &::after{
            margin-top: 7px;
            color: #FEF100;
        }
    };
    .dropdown-menu.show {
        padding: 0;
        width: 100%;
        a{
            margin: 5px 0;
            color: #213B73;
            text-decoration: none;
            font-weight: bold; 
        }
        a:hover, a:focus, a:active, a::selection {
			background-color: transparent !important;
		}
        hr{
            margin: 0;
        }
    }
    p{
        margin: 0;
    }
`;

export const VehicleFilterButton = styled(Button)`
    color: #000000 !important;
    filter: invert(100%) sepia(90%) saturate(7494%) hue-rotate(349deg) brightness(96%) contrast(109%);
    background: url(${iconCarFilter}) no-repeat left;
    background-color: #FEF100 !important;
    background-color: transparent !important;
    background-size: 24px;
    padding-left: 24px;
    border: none;
    font-weight: bold;
    &:focus{
        box-shadow: none !important;
    }
`;

export const VehicleFilterModal = styled(Modal)`
    background-color: #1c2c54;
    .modal-content{
        background-color: #1c2c54;
        border: none;
        color: white;
    }
    .btn-close{
        filter: invert(100%);
    }
`;