import styled from "styled-components";


export const StyledImageHeader = styled.div`
    background-color: #5ab7e8;
`;

export const ImageHeaderBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: no-repeat left bottom, no-repeat center bottom, no-repeat right bottom;
    background-size: 15%, 90%, 16%;
    height: 270px;

    @media(max-width: 576px){
        height: 91px;
    }

    @media(max-width: 768px) and (min-width: 576px){
        height: 150.8px;
    }
`;

export const ImageHeaderText = styled.h1`
    font-family: "Poppins", sans-serif;
    font-size: 52px;
    font-weight: 700;
    color: #213B73;
    text-align: center;
    width:55%;
    @media(max-width: 992px){
        font-size: 36px;
        width:75%;
    }
    
    @media(max-width: 768px){
        font-size: 22px;
        width:75%;
    }

    @media(max-width: 576px){
        p {
            margin-bottom: 0;
        }
    }
`;