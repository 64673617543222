import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchUsers, updateUser, deleteUser, createUser } from "./userService";

const userAdapter = createEntityAdapter();

const initialState = userAdapter.getInitialState({
  status: "",
  user: [],
  error: null,
  errorCode: undefined
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.user = payload;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [updateUser.pending]: (state) => {
      state.status = "updateUser-loading";
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.status = "updateUser-success";
      state.errorCode = undefined;
    },
    [updateUser.rejected]: (state, action) => {
      state.status = "updateUser-failed";
      state.error = action.error;
      state.errorCode = action.payload.status;
    },
    [deleteUser.pending]: (state) => {
      state.status = "deleteUser-loading";
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.status = "deleteUser-success";
      state.user = state.user.filter(user => user.id !== payload);
    },
    [deleteUser.rejected]: (state, action) => {
      state.status = "deleteUser-failed";
      state.error = action.error;
    },
    [createUser.pending]: (state) => {
      state.status = "createUser-loading";
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.status = "createUser-success";
      state.user = [payload];
    },
    [createUser.rejected]: (state, action) => {
      state.status = "createUser-failed";
      state.error = action.error;
    }
  }
});

export default userSlice.reducer;
