import { Row } from "react-bootstrap";
import styled from "styled-components";
import dropDownImageBefore from "../../assets/images/ic-arrow-yellow.svg";
import externalIcon from "../../assets/images/ic-external.svg";

export const LocationDetailBackground = styled.div`
  padding: 20px 0 30px;
  background-color: #213b73;
  color: white;
  font-family: "Poppins", sans-serif;
  a {
    color: #fef100;
    text-decoration: none;
    margin: 10px 0;
  }
  button {
    border: none;
    font-weight: bold;
    width: 100%;
    height: 67px;
    font-size: 14px;
    text-align: start;
  }
  h3,
  h5 {
    font-weight: bold;
    padding: 10px 0;
  }
  hr {
    margin: 0;
    opacity: 1;
    height: 2px;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
    @media (max-width: 768px) {
      font-size: 12px;
      margin-left: 5px;
    }
  }
`;

export const LocationDetailButtonUnavailable = styled.button`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #004f9a;
  color: #a6adb4 !important;
  p {
    font-size: 13px;
  }
  width: 24.5% !important;
  @media (max-width: 992px) {
    span {
      font-size: 10px;
    }
    margin-bottom: 15px;
  }
  @media (max-width: 600px) {
    width: 100% !important;
  }
  img {
    height: 20px;
    margin-right: 10px;
  }
`;

export const LocationDetailButtonAvailable = styled.button`
  background-color: #005daa;
  margin: 10px 0;
`;

export const LocationDetailDetailButton = styled.button`
  width: 100%;
  text-align: left;
  font-size: 17px;
  background: url(${externalIcon}) no-repeat 97%;
  background-size: 16px;
`;

export const LocationDetalMapButtonsRow = styled(Row)`
  padding: 20px 0;
  button {
    color: white;
  }
`;

export const LocationDetailImageText = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const LocationDetailButtonAvailableIcon = styled.div`
  width: 100%;
  background: url(${dropDownImageBefore}) no-repeat 97%;
  p {
    margin-left: 13px;
    font-size: 13px;
  }
`;

export const LocationDetailIcons = styled.img`
  height: 30px;
  margin-right: 10px;
  width: auto;
`;
export const AccordionButton = styled.div`
  width: 24.5%;
  @media (max-width: 992px) {
    span {
      font-size: 10px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const AccordionButtonList = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
