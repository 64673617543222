import styled from "styled-components";

export const PrivacyPoliciesContainer = styled.div`
    color: #213b73;
    font-family: "Poppins", sans-serif;
    padding: 30px 5rem;
    th,td{
        color: #213b73;
    }
    h3{
        margin-top: 80px;
    }
`;