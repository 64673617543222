import styled from "styled-components";
import { ReactComponent as Copy } from "@assets/images/cms/ic-copy-url.svg";
import { ReactComponent as Search } from "@assets/images/cms/ic-search.svg";
import { palettes } from "assets/theme";

export const ContentDiscription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;
export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
  .mr10 {
    margin-right: 10px;
  }
`;
export const GreyRetangle = styled.div`
  width: 8px;
  height: 100%;
  background-color: ${palettes.system_gray};
`;

export const InputWapper = styled.div`
  width: 100%;
  border: 1px solid ${palettes.system_gray_darker};
  background: ${palettes.white};
  display: flex;
  align-items: center;
  height: 50px;
  color: ${palettes.tints_blue_darker};
  font-family: "Poppins", sans-serif;
  padding-right: 10px;
  p {
    margin: 0;
  }
  input {
    margin: 0;
    border: none;
    width: 100%;
    :focus {
      outline: none;
    }
  }
`;

export const CopyIcon = styled(Copy)`
  height: 16px;
  cursor: pointer;
`;
export const SearchIcon = styled(Search)`
  height: 16px;
`;

export const InvalidInputWapper = styled.div`
  width: 100%;
  .input-invalid{
    border: 1px solid ${palettes.tints_red};
  }
  .retangle-invalid{
    background-color: ${palettes.tints_red};
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  align-items: start;
  background-color: ${palettes.tints_red};
  border: 1px solid ${palettes.tints_red};
  color: ${palettes.white};
`;