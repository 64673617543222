import styled from "styled-components";

export const CheckboxContainer = styled.div`
    height: 27px;
    width: 29px;
    margin: 0 10px 10px 0;
    button{
        background-color: transparent;
        height: 100%;
        width: 100%;
        border: 2.5px solid #fff;
        img{
            position: relative;
            bottom: 4.5px;
            right:9px;
        }
    }
`;