import styled from "styled-components";
import { ReactComponent as Copy } from "@assets/images/cms/ic-copy-url.svg";
import { ReactComponent as Search } from "@assets/images/cms/ic-search.svg";

export const ContentDiscription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;
export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
  .mr10 {
    margin-right: 10px;
  }
`;
export const GreyRetangle = styled.div`
  width: 8px;
  height: 100%;
  background-color: #ebeded;
`;

export const InputWapper = styled.div`
  width: 100%;
  border: 1px solid #a6adb4;
  background: #fff;
  display: flex;
  align-items: center;
  height: 50px;
  color: #213b73;
  font-family: "Poppins", sans-serif;
  padding-right: 10px;
  p {
    margin: 0;
  }

  input,
  select,
  textarea {
    margin: 0;
    border: none;
    width: 100%;

    :focus {
      outline: none;
    }
  }
`;
export const WithTitle = styled.div`
  width: 100%;
  position: relative;

  span {
    position: absolute;
    top: -23px;
    left: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
  }
  input,
  select {
    position: absolute;
    top: -7px;
    left: 5px;
    height: 30px;
  }
`;

export const CopyIcon = styled(Copy)`
  height: 16px;
  cursor: pointer;
`;
export const SearchIcon = styled(Search)`
  height: 16px;
`;
