import { Header } from "../../components/Header";
import { Footer} from "../../components/Footer";
import headerData from "../Contact/Resources/example.json";
import footerData from "@components/Footer/Resources/example.json";
import { CookiesPoliciesContainer } from './styles';

export function CookiesPolicies() {
    return (
        <>
        <Header data={headerData}/>
        <CookiesPoliciesContainer>
            <hr/>
            <h1>POLÍTICA DE COOKIES</h1>
            <span>Esta política foi atualizada pela última vez em 2020-07-14.</span>
            <p>Os cookies permitem que um site se lembre de suas preferências, melhore a experiência do
                usuário e personalize os anúncios que você vê para exibir aqueles que são mais relevantes para
                você. Os cookies são enviados de volta ao site de origem em cada visita subsequente ou para um
                outro site que reconhece o cookie.</p>
            <p>Utilizamos também outras formas de tecnologia que funcionam de forma similar aos cookies e
                que nos permitem monitorar e melhorar nossos sites e apps. Usamos essas tecnologias
                juntamente com os cookies para ajudar a operar nossos sites e e-mails, e coletar informações
                sobre atividades on-line. Quando falamos sobre cookies nesta Política, o termo irá se referir a
                essas tecnologias semelhantes também.</p>
            <h4>Por que usamos cookies?</h4>
            <p>Os cookies têm cinco funções diferentes e os usamos para os fins listados abaixo:</p>
            <ul>
                <li>1. Cookies estritamente necessários são usados para permitir que você navegue neste site
                    ou aplicativo e use seus recursos, como acessar áreas seguras do site ou fazer uma
                    reserva.  Esses cookies expiram no final de sua sessão.</li>
                <li>2. Os cookies de desempenho coletam informações sobre como você interage com este site
                    ou aplicativo, e são usados para melhorar sua experiência.  Eles não contêm informações
                    pessoais que possam ser usadas para identificá-lo.  Usamos esses cookies para medir o
                    desempenho deste site ou aplicativo, como coletar informações sobre o número de
                    visitantes, testar designs e garantir uma aparência consistente.  Todas as informações
                    coletadas por esses cookies são agregadas e, portanto, anônimas.  Esses cookies expiram
                    em 7 dias.</li>
                <li>3. Os cookies funcionais permitem que este site ou aplicativo se lembre das opções que
                    você seleciona e forneça recursos aprimorados e mais pessoais, como armazenar sua
                    conta de login e idioma ou preferências geográficas. As informações coletadas por esses
                    cookies podem ser anônimas e não monitoram sua atividade de navegação em outros
                    sites.  A duração desses cookies varia, mas todos expiram no prazo de 2 anos.</li>
                <li>4.  Cookies de publicidade são usados para fornecer anúncios mais relevantes para você.
                    Terceiros que anunciam em nosso nome usam cookies para ajudar a selecionar os
                    anúncios que são exibidos a você e garantem que você não veja o mesmo anúncio
                    repetidas vezes. Os cookies também medem a eficácia das campanhas publicitárias. Esses
                    cookies expiram em 2 anos.</li>
                <li>5. Cookies de redes sociais são usados quando você compartilha informações usando um
                    botão de compartilhamento ou o botão &quot;curtir&quot; em nossos sites ou apps, ou quando você
                    se engaja com nosso conteúdo através de uma rede social, como Facebook ou
                    Twitter. Esses cookies expiram em 1 ano.</li>
            </ul>
            <p>Para mais informações sobre publicidade direcionada, incluindo quais terceiros colocam esses
                cookies e para saber como recusá-los, visite a nossa <a href="https://preferences-mgr.truste.com/?pid=ehi01&amp;aid=ehi_holdings01">Ferramenta de Consentimento de Cookies.</a></p>
            <p>Alguns navegadores da Web mais recentes incorporam recursos &quot;Não rastrear&quot;. Nossos sites
                podem não estar respondendo atualmente a solicitações de &quot;Não rastrear&quot; ou cabeçalhos desses
                navegadores. Para saber mais sobre suas opções de não ter essas informações usadas por
                determinados provedores de serviços, acesse  <a href="https://preferences-mgr.truste.com/?pid=ehi01&amp;aid=ehi_holdings01">Consentimento de Cookies </a>. A opção de não
                participar dessa prática não isenta você de continuar recebendo anúncios — você continuará
                recebendo anúncios genéricos.</p>
            <p>Para obter mais informações sobre como processamos seus dados pessoais e para exercer seus
                direitos em relação aos seus dados pessoais, consulte nossa Política de Privacidade.</p>
            <h4>Como você pode gerenciar cookies?</h4>
            <p>Os cookies de sessão são usados para sua sessão atual do navegador e expiram após você fechar
                o navegador. Os cookies persistentes são usados para melhorar a sua experiência entre visitas no
                site e permanecerão no seu computador até que você os exclua ou até que você estabeleça uma
                data de expiração. Você tem sempre a possibilidade de excluir os cookies manualmente.</p>
            <p>Caso esteja visualizando nosso conteúdo através de um navegador da Web, você poderá alterar
                suas preferências de cookies e retirar seu consentimento a qualquer momento usando
                nossa <a href="https://preferences-mgr.truste.com/?pid=ehi01&amp;aid=ehi_holdings01">Ferramenta de Consentimento de Cookies</a>. Caso esteja usando nossos aplicativos, você
                pode alterar suas preferências nas configurações de análise no app.</p>
            <p>Você também pode configurar seu navegador para bloquear todos os cookies ou para indicar
                quando um cookie está sendo definido, embora nossos serviços possam não funcionar
                adequadamente se os cookies estiverem desativados. Para descobrir como controlar ou
                desabilitar os cookies na maioria dos navegadores, consulte a seção &quot;Ajuda&quot; do seu navegador ou
                dispositivo móvel ou acesse <a href="www.allaboutcookies.org">www.allaboutcookies.org.</a></p>
                <hr/>
        </CookiesPoliciesContainer>
        <Footer data={footerData} />
        </>
    )
}