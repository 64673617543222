import { StaticPageEngine } from 'pages/StaticPageEngine';
import { Col, Row } from 'react-bootstrap';
import { VehicleManufacturersContainer } from './styles';

export function VehicleManufacturers() {
    const components =
        <>
            <VehicleManufacturersContainer>
                <hr />
                <h1>Fabricantes de veículos</h1>
                <div className='px-5'>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Alfa</Col>
                        <Col lg={3} md={6}>Aston-Martin</Col>
                        <Col lg={3} md={6}>Audi</Col>
                        <Col lg={3} md={6}>Bentley</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>BMW</Col>
                        <Col lg={3} md={6}>Chrysler Motors</Col>
                        <Col lg={3} md={6}>Citroen</Col>
                        <Col lg={3} md={6}>Dacia</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Ferrari</Col>
                        <Col lg={3} md={6}>Ford Motor Company</Col>
                        <Col lg={3} md={6}>Freightliner Corp</Col>
                        <Col lg={3} md={6}>General Motors</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Hino Diesel Trucks Canada</Col>
                        <Col lg={3} md={6}>Honda Motor Co</Col>
                        <Col lg={3} md={6}>Hyundai Motor Co</Col>
                        <Col lg={3} md={6}>Infiniti</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Isuzu</Col>
                        <Col lg={3} md={6}>Iveco</Col>
                        <Col lg={3} md={6}>Jaguar</Col>
                        <Col lg={3} md={6}>Kia Motors</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Lamborghini</Col>
                        <Col lg={3} md={6}>Land Rover</Col>
                        <Col lg={3} md={6}>Lexus</Col>
                        <Col lg={3} md={6}>Maserati North America</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Mazda Motor Corporation</Col>
                        <Col lg={3} md={6}>Mercedes-Benz</Col>
                        <Col lg={3} md={6}>Mitsubishi Motors</Col>
                        <Col lg={3} md={6}>Navistar International</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Nissan</Col>
                        <Col lg={3} md={6}>Opel</Col>
                        <Col lg={3} md={6}>Peugeot</Col>
                        <Col lg={3} md={6}>Porsche</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Renault</Col>
                        <Col lg={3} md={6}>Rolls-Royce</Col>
                        <Col lg={3} md={6}>Seat</Col>
                        <Col lg={3} md={6}>Skoda</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Ssang Yong</Col>
                        <Col lg={3} md={6}>Subaru</Col>
                        <Col lg={3} md={6}>Suzuki Motor Corporation</Col>
                        <Col lg={3} md={6}>Tesla</Col>
                    </Row>
                    <Row className='my-3'>
                        <Col lg={3} md={6}>Toyota</Col>
                        <Col lg={3} md={6}>Vauxhall</Col>
                        <Col lg={3} md={6}>Volkswagen</Col>
                        <Col lg={3} md={6}>Volvo</Col>
                    </Row>
                </div>
                <hr />
            </VehicleManufacturersContainer>
        </>
    return (
        <StaticPageEngine components={components} />
    )
}