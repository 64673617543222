import Banner1 from "../Banner1";
import Banner2 from "../Banner2";
import Banner3 from "../Banner3";
import Banner4 from "../Banner4";
import Secao1A from "../Secao1A";
import Secao1B from "../Secao1B";
import Secao2A from "../Secao2A";
import Secao2B from "../Secao2B";
import Secao3A from "../Secao3A";
import Secao3B from "../Secao3B";
import Secao4A from "../Secao4A";
import Secao4B from "../Secao4B";
import Secao5A from "../Secao5A";
import Secao5B from "../Secao5B";
import Secao6A from "../Secao6A";
import Secao6B from "../Secao6B";
import Secao7A from "../Secao7A";
import Secao7B from "../Secao7B";
import ListaA from "../ListaA";
import ListaB from "../ListaB";
import Destaque from "../Destaque";
import Pesquisa from "../Pesquisa";
import { Pesquisa as Widget } from "../Pesquisa";

export const ComponentMapping = {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Secao1A,
  Secao1B,
  Secao2A,
  Secao2B,
  Secao3A,
  Secao3B,
  Secao4A,
  Secao4B,
  Secao5A,
  Secao5B,
  Secao6A,
  Secao6B,
  Secao7A,
  Secao7B,
  ListaA,
  ListaB,
  Destaque,
  Pesquisa,
  Widget,
};
export default ComponentMapping;
