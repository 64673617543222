import React from "react";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { BreadCrumb } from "../../components/BreadCrumb";
import { ImageHeader } from "../../components/ImageHeader";
import { CustomerSupportBlocks } from "../../components/CustomerSupportBlocks";
import footerData from "@components/Footer/Resources/example.json";
import { TitleContext } from "context/TitleContext";

export function CustomerSupport(props){

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - Suporte ao Cliente");
    }, []);

    return(
        <>
            <Header data={props.data.headerData} />
            <BreadCrumb data={props.data.breadCrumbData} />
            <ImageHeader data={props.data.imageHeaderData } />
            <CustomerSupportBlocks data={props.data.CustomerSupportData} />
            <Footer data={footerData} />
        </>
    )
}

export default CustomerSupport;