import styled from "styled-components";
import { palettes } from "assets/theme";

export const TemplatesContainer = styled.div`
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: ${palettes.tints_blue_darker};
`;

export const TagFilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;

export const TagFilter = styled.button`
    display: flex;
    align-items: center;
    min-width: fit-content;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    border: none;
    text-decoration: none;
    background-color: ${(props) => props.backgrondColor};
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fontWeight};
`;

export const TemplatesArea = styled.div`
    max-height: 300px;
    overflow-y: scroll;
    background-color: ${palettes.system_gray};
    padding: 20px;
    ::-webkit-scrollbar{
        width: 4px;
    }
    ::-webkit-scrollbar-track {
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        height: 180px;
        width: 4px;
        background: ${palettes.system_gray_darker};
    }
`;

export const ComponentDivisor = styled.hr`
    opacity: 1;
    margin: 10px 0;
`;