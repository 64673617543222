import siteMapData from "../Resources/siteMap.json";
export const SiteMapReducer = {
  getSiteMap: (state) => {
    state.siteMap = siteMapData;
  },
  setSelectedLinkType: (state, { payload }) => {
    if(payload.type){
      state.selectedLink.type = payload.type
    }else if(payload.url || payload.url===""){
      state.selectedLink.url = payload.url
    }else if(payload.name || payload.name===""){
      state.selectedLink.name = payload.name
    }
  }
};
export default SiteMapReducer;
