import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "@api/api";
import getAxios from "api/api";

export const fetchGetPages = createAsyncThunk(
  "page/fetchGetPages",
  async (status) => {
    const response = await getAxios().get(
      "/page/all" + (status !== undefined ? `?status=${status}` : "")
    );
    return response.data;
  }
);

export const fetchGetPageByUrl = createAsyncThunk(
  "page/fetchGetPageByUrl",
  async (url) => {
    if (!url || url === "/") {
      url = "/%09";
    }
    const response = await getAxios().get("/page/url" + url);
    return response.data;
  }
);

export const deletePage = createAsyncThunk("page/deletePage", async (id) => {
  const response = await getAxios().delete(`/page/delete/${id}`);
  return response.data;
});
