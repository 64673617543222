import styled from "styled-components";
import theme from "styled-theming";
import { ReactComponent as DownImage } from "@assets/images/chevron-alt-down.svg";
import { ReactComponent as UpImage } from "@assets/images/chevron-alt-up.svg";

const backgroundColor = theme("mode", {
  open: "#ffffff",
  close: "#005daa",
  transparent: "transparent",
});
const fontColor = theme("mode", {
  open: "#213B73",
  close: "#ffffff",
  transparent: "#8C4C9E",
});
const sellAllColor = theme("mode", {
  open: "#8C4C9E",
  close: "#FEF100",
  transparent: "#8C4C9E",
});
const fontSize = theme("reduced", {
  open: "22px",
  close: "22px",
  reduced: "14px",
});
export const AccordionHeaderButton = styled.button`
  background-color: ${backgroundColor};
  font-family: "Poppins", sans-serif;
  color: ${fontColor};
  font-size: ${fontSize};
  border: none;
  height: 90px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  direction: ltr;
  @media (max-width: 768px) {
    height: 50px;
    font-size: 15px;
  }
`;
export const IconContainer = styled.div`
  padding-left: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 768px) {
    padding-left: 5px;
  }
`;
export const TextContainer = styled.div`
  height: 100%;
  width: 75%;
  margin-left: 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 768px) {
    margin-left: 15px;
  }
`;
export const SubtitleText = styled.div`
  font-weight: normal;
  font-size: 17px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
export const SeeAllContainer = styled.div`
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 5px;
  }
`;
export const SeeAllText = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: bold;
  color: ${sellAllColor};
  margin-right: 8px;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const UpIcon = styled(UpImage)`
  width: 18px;
  fill: ${sellAllColor};
`;
export const DownIcon = styled(DownImage)`
  width: 18px;
  fill: ${sellAllColor};
`;

export const ImageContainer = styled.div`
  padding-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 768px) {
    padding-left: 5px;
  }
`;