import styled from "styled-components";

export const LocationCardAccordionBodyBackground = styled.div`
    font-size: 17px;
    padding: 0 0.5rem;
    p{
        color: #213B73 !important;
        margin: 0;
    }
    a{
        color: #8C4C9E;
        text-decoration: none;
    }
    img{
        height: 30px;
        background-color: #213B73;
        border-radius: 50%;
        padding: 0.5rem;
    }
    .img-external-link{
        height: 15px;
        background-color: transparent;
        border-radius: 0%;
        padding: 0;
        margin-left: 0.5rem;
    }
    hr{
        opacity: 1;
    }
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;