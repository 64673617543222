import { BreadCrumb } from "@components/BreadCrumb";
import { LoaderComponent } from "@components/Loader";
import { VehicleWidget } from "@components/VehicleWidget";
import { getRegions } from "@redux/location/locationSlice";
import { fetchVehicles } from "@redux/vehicle/vehiclesService";
import { getFilters } from "@redux/vehicle/vehiclesSlice";
import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export function Vehicles(props) {
  const dispatch = useDispatch();
  const vehicleState = useSelector((state) => state.vehicle);

  useEffect(() => {
    dispatch(fetchVehicles());
    dispatch(getFilters());
    dispatch(getRegions());
  }, []);

  const { setTitleText } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTitleText("Alamo - Veículos");
  }, []);

  const components =
    <>
      {vehicleState.status === "loading" && <LoaderComponent />}
      <BreadCrumb data={props.data.breadCrumbData} />
      <VehicleWidget data={props.data.vehicleWidgetData} />
    </>

  return (
    <StaticPageEngine components={components} />
  );
}

export default Vehicles;