import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Section,
  Title,
  Text,
} from "../../../CMS/components/SetupPage/components/Templates/common/Secao";
import { MainPost, ChildPost, TextContainer, Separator } from "./styles";
const BlogHighlight = ({ properties, mode }) => {
  return (
    <Section theme={{ mode: mode }} style={{ background: "#213B73" }}>
      <Container fluid="lg" className="maxSectionWidth p-3">
        <Row>
          <Col lg={6} className="pe-5">
            <TextContainer>
              <Title theme={{ mode: mode }}>{properties[0]?.title}</Title>
              <Text className="py-3" theme={{ mode: mode }}>
                {properties[0]?.description}
              </Text>
              <button className="mb-3">Ver Mais</button>
            </TextContainer>
          </Col>
          <Col
            lg={6}
            className={
              "d-flex flex-column justify-content-center align-items-end"
            }
          >
            <MainPost>
              <Link to={"blog/" + properties[0]?.highlights[0]?.url}>
                <img
                  src={properties[0]?.highlights[0]?.image}
                  alt={properties[0]?.highlights[0]?.imageDescription}
                />
              </Link>
              <div
                value={properties[0]?.highlights[0]?.description}
                disabled={true}
              >
                <Link
                  href={"blog/" + properties[0]?.highlights[0]?.url}
                  rel="noreferrer"
                >
                  <p className="w-100">{properties[0]?.highlights[0]?.title}</p>
                </Link>
              </div>
            </MainPost>
          </Col>
        </Row>
        {properties[0]?.highlights[1] && <Separator />}
        <Row>
          {properties[0]?.highlights.slice(1).map((highlight, index) => {
            return (
              <Col lg={3} md={6} sm={12} className="mb-3" key={index}>
                <ChildPost>
                  <Link to={"blog/" + highlight.url}>
                    <img
                      src={highlight.image}
                      alt={highlight.imageDescription}
                    />
                  </Link>
                  <div className={"w-100"} disabled={true}>
                    <Link
                      className="w-100"
                      to={"blog/" + highlight.url}
                      rel="noreferrer"
                    >
                      <p className="w-100">{highlight.title}</p>
                    </Link>
                  </div>
                </ChildPost>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};

export default BlogHighlight;
