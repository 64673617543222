import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React from "react";
import { BreadCrumb } from "../../components/BreadCrumb";
import { HorizontalImageTitleInfoAndButtons } from "../../components/HorizontalImageTitleInfoAndButtons";
import { ImageHeader } from "../../components/ImageHeader";
import { ThreeInfoBlocksWithMainTitle } from "../../components/ThreeInfoBlocksWithMainTitle";

export function AboutAlamo(props) {
  const { setTitleText } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTitleText("Alamo - Sobre a Alamo");
  }, []);

  const components =
    <>
      <BreadCrumb data={props.data.breadCrumbData} />
      <ImageHeader data={props.data.imageHeaderData} />
      <ThreeInfoBlocksWithMainTitle
        data={props.data.threeInfoBlocksWithMainTitleData}
      />
      <HorizontalImageTitleInfoAndButtons
        data={props.data.horizontalImageTitleInfoAndButtonsData}
      />
      <hr style={{ opacity: 0.5, color: "#213B73" }} />
    </>

  return (
    <>
      <StaticPageEngine components={components} />
    </>
  )

}

export default AboutAlamo;
