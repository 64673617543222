import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import icon from "../../assets/images/ic-arrow-enter.svg";
import DynamicMap from "../DynamicMap";
import { LocationCard } from "../LocationCard";
import { SearchLocationFilter } from "./SearchLocationFilter";
import SearchLocationFilterData from "./SearchLocationFilter/Resources/example.json";
import {
  SearchLocationBackground,
  SearchLocationButtonFilter,
  SearchLocationCardList,
  SearchLocationHeader
} from "./styles";

export function SearchLocation(props) {
  const locations = props.locations;

  const [filterData, setFilterData] = useState([]);
  const [filter, setFilter] = useState("none");

  useEffect(() => {
    setFilterData(locations);
  }, [locations]);

  const changeFilter = () => {
    filter === "none" ? setFilter("flex") : setFilter("none");
  };

  const card = filterData.map((location, index) => (
    <LocationCard
      key={index}
      dataFtp={location}
      dataContent={props.data}
      interador={index + 1}
    />
  ));

  return (
    <Row className="m-0 g-0">
      <Col lg={6} xs={12} className="p-0">
        <SearchLocationBackground>
          <SearchLocationHeader>
            <div className="navigation">
              <h2>{props.data.title}</h2>
              <SearchLocationButtonFilter
                className="m-0"
                onClick={changeFilter}
              >
                <p>Filtros</p>
                <img src={icon} alt="icon" />
              </SearchLocationButtonFilter>
            </div>
            <div style={{ display: filter }}>
              <SearchLocationFilter data={SearchLocationFilterData} />
            </div>
            <p className="m-0 location-count">
              {props.locations.length} Locations
            </p>
          </SearchLocationHeader>
          <SearchLocationCardList>{card}</SearchLocationCardList>
        </SearchLocationBackground>
      </Col>
      <Col lg={6} xs={12}>
        <DynamicMap
          location={{
            lat: props.location?.branchStationLatitude,
            long: props.location?.branchStationLongitude
          }}
          locations={props.locations}
        />
      </Col>
    </Row>
  );
}
