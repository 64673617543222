import styled from "styled-components";

export const WidgetSectionExpanded = styled.div`
    background-color: #213B73;
    padding: 50px;
`;

export const Pbk = styled.div`
    #enterprise-pbk{
        font-family: 'Poppins', 'sans-serif' !important;
        background-color:#213B73 !important;
        a{
            color:#FFFFFF !important;
            text-decoration: none !important;
        }
        input{
            color: #213B73 !important;
        }
        .label{
            color:#FFFFFF !important;
        }
        .button-special{
            background: #FEF100 !important;
            color: #213B73 !important;
        }
    }
   
`;
