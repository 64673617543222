import styled from "styled-components";

export const LoaderContainer= styled.div`
    height: 100vh;
    width: 100vw;   
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
`;