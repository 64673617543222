import React from "react";
import { Section, Title, Pbk } from "../Secao";
import WidgetImage from "@assets/images/widget.png";
import { useEffect } from "react";
export const Pesquisa = ({ properties, mode, variant, isFromCMS }) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "./pbk-script.js";
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
    <Section
      theme={{ mode: mode, variant: variant }}
      className="flex-row justify-content-center"
    >
      <div className="maxSectionWidth w-100">
        <Title theme={{ mode: mode }} className="mb-4">
          {properties[0].value}
        </Title>
        {isFromCMS ? (
          <div className="w-100">
            <img
              className="w-100"
              src={WidgetImage}
              alt="Imagem exemplo widget"
            />
          </div>
        ) : (
          <>
            <Pbk id="pbk-widget"></Pbk>
          </>
        )}
      </div>
    </Section>
  );
};

Pesquisa.defaultProperties = {
  mode: "dafault",
  isFromCMS: false,
};

export default Pesquisa;
