import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxios } from "@api/api";

export const createPage = createAsyncThunk(
  "page/pageHeader",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAxios().post("/page", payload);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response);
      } else {
        throw error;
      }
    }
  }
);
export const fetchPageById = createAsyncThunk(
  "page/fetchPageById",
  async (id) => {
    const response = await getAxios().get(`/page/${id}`);
    return response.data;
  }
);

export const updatePage = createAsyncThunk(
  "page/updatePage",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAxios().put(
        `/page/update/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        let newResponse = {
          status: error.response.status,
        };
        return rejectWithValue(newResponse);
      } else {
        throw error;
      }
    }
  }
);

export const fetchFooter = createAsyncThunk("footer/fetchFooter", async () => {
  const response = await getAxios().get("/footer/all");
  return response.data;
});

export const fetchHeaders = createAsyncThunk(
  "header/fetchHeaders",
  async () => {
    const response = await getAxios().get("/header/all");
    return response.data;
  }
);

export const createFooter = createAsyncThunk(
  "footer/createFooter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAxios().post(`/footer`, payload);
      return response.data;
    } catch (error) {
      if (error.response) {
        let newResponse = {
          status: error.response.status,
        };
        return rejectWithValue(newResponse);
      } else {
        throw error;
      }
    }
  }
);

export const uptadeFooter = createAsyncThunk(
  "footer/uptadeFooter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAxios().put(
        `/footer/update/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        let newResponse = {
          status: error.response.status,
        };
        return rejectWithValue(newResponse);
      } else {
        throw error;
      }
    }
  }
);

export const updateHeader = createAsyncThunk(
  "header/updateHeader",
  async (payload) => {
    const response = await getAxios().put(
      `/header/update/${payload.id}`,
      payload
    );
    return response.data;
  }
);

export const createHeader = createAsyncThunk(
  "header/createHeader",
  async (payload) => {
    const response = await getAxios().post("/header", payload);
    return response.data;
  }
);

export const fetchSiteMap = createAsyncThunk(
  "page/fetchSiteMap",
  async (payload) => {
    const response = await getAxios().get("/page/sitemap/all", payload);
    return response.data;
  }
);

export const fetchHighlights = createAsyncThunk(
  "highlight/fetchHighlights",
  async () => {
    const response = await getAxios().get("/highlight/all");
    return response.data;
  }
);

export const createHighlight = createAsyncThunk(
  "highlight/createHighlight",
  async (payload) => {
    const response = await getAxios().post("/highlight", payload);
    return response.data;
  }
);

export const updateHighlight = createAsyncThunk(
  "highlight/updateHighlight",
  async (payload) => {
    const response = await getAxios().put(
      `/highlight/update/${payload.id}`,
      payload
    );
    return response.data;
  }
);
