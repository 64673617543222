import React from "react";
import {
  StyledHeader,
  TopNavbar,
  MainNavbar,
  TopLink,
  MainLink,
  TopDropdown,
  MainDropdown,
  TopDropdownItem,
  MainDropdownItem,
  HeaderLogo,
  ResponsiveNavbar,
  Hamburguer,
  HamburguerMenu
} from "./styles";
import { Container, Nav } from "react-bootstrap";

export function Header(props) {
  const topMenu = props.data.topLink?.map((topMenu, i) => {
    if (topMenu.submenu) {
      return (
        <TopDropdown
          title={topMenu.text}
          key={`header${i}`}
          align={{ lg: "end" }}
        >
          {topMenu.submenu.map((submenuItems, i) => (
            <div key={i}>
              <TopDropdownItem href={submenuItems.href} alt={submenuItems.alt}>
                {submenuItems.text}
              </TopDropdownItem>
              {i < topMenu.submenu.length - 1 ? (
                <hr style={{ margin: "0 15px", opacity: 0.25 }} />
              ) : null}
            </div>
          ))}
        </TopDropdown>
      );
    } else {
      return (
        <TopLink href={topMenu.href} key={`header${i}`}>
          {topMenu.text}
        </TopLink>
      );
    }
  });

  const mainMenu = props.data.mainLink.map((mainMenu, i) => {
    if (mainMenu.submenu) {
      return (
        <div key={`mainMenu${i}`}>
          <MainDropdown title={mainMenu.text} align={{ lg: "end" }}>
            {mainMenu.submenu.map((submenuItems, i) => (
              <div key={i}>
                <MainDropdownItem
                  href={submenuItems.href}
                  alt={submenuItems.alt}
                >
                  {submenuItems.text}
                </MainDropdownItem>
                {i < mainMenu.submenu.length - 1 ? (
                  <hr style={{ margin: "0 15px", opacity: 0.25 }} />
                ) : null}
              </div>
            ))}
          </MainDropdown>
        </div>
      );
    } else {
      return (
        <div key={`mainMenu${i}`}>
          <MainLink href={mainMenu.href}>{mainMenu.text}</MainLink>
        </div>
      );
    }
  });

  return (
    <StyledHeader>
      {topMenu && (
        <TopNavbar>
          <Nav>{topMenu}</Nav>
        </TopNavbar>
      )}
      <MainNavbar>
        <a href="/" alt="Alamo">
          <HeaderLogo>
            <img src={props.data.logo.src} alt={props.data.logo.alt} />
          </HeaderLogo>
        </a>
        <Nav className="ms-auto">{mainMenu}</Nav>
      </MainNavbar>

      <ResponsiveNavbar expand="xxl">
        <a href="/" alt="Alamo">
          <HeaderLogo>
            <img src={props.data.logo.src} alt={props.data.logo.alt} />
          </HeaderLogo>
        </a>

        <Hamburguer aria-controls="responsive-navbar-nav" />
        <HamburguerMenu id="responsive-navbar-nav">
          <Nav>
            <Container>
              {mainMenu}
              <hr style={{ margin: "0px" }} />
              {topMenu}
              <hr style={{ margin: "15px" }} />
            </Container>
          </Nav>
        </HamburguerMenu>
      </ResponsiveNavbar>
    </StyledHeader>
  );
}
