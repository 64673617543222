import styled from "styled-components";

export const SearchLocationFilterBackground = styled.div`
    width: 100%;

    .SearchLocationFilterOptions {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;