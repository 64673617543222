import styled from "styled-components";

import { ReactComponent as CityImage } from "@assets/images/city.svg";
import { ReactComponent as BoatImage } from "@assets/images/boat.svg";
import { ReactComponent as PlaneImage } from "@assets/images/plane.svg";

export const NearestRentalLocationsWrapper = styled.section`
  margin-top: 30px;
`;

export const NearestRentalLocationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 30px;

  h4 {
    font-weight: 700;
    font-size: 1.4rem;
    color: #213b73;
  }

  a {
    font-size: 1.1rem;
    font-weight: 700;
    color: #8c4c9e;
    text-decoration: none;
  }
`;

export const NearestRentalLocationsBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
`;

export const NearestRentalLocationsCard = styled.div`
  width: 350px;
  margin: 0 40px 20px;
  padding: 10px;
  background-color: #fff;
`;

export const NearestRentalLocationsCardHeader = styled.div`
  display: flex;

  h4 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #213b73;
    text-wrap: wrap;
  }
`;

export const CityIcon = styled(CityImage)`
  fill: #fef100;
`;

export const BoatIcon = styled(BoatImage)`
  fill: #fef100;
`;

export const PlaneIcon = styled(PlaneImage)`
  fill: #fef100;
`;

export const NearestRentalLocationsCardBody = styled.div`
  margin-left: 45px;

  p {
    color: #213b73;
  }

  a {
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: 700;
    color: #8c4c9e;
  }
`;

export const NearestRentalLocationsCardFooter = styled.div`
  margin-top: 3rem;

  a {
    background-color: #8c4c9e;
    display: inline-block;
    width: 100%;
    font-weight: 700;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    font-size: 1.2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  a:hover {
    opacity: 0.8;
  }
`;
export const IconContainer = styled.div`
  background-color: #005ca9;
  display: flex;
  border-radius: 3px;
  padding: 8px;
  height: 46px;
  width: 46px;
  margin-right: 10px;
  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;
