import TagsInput from "react-tagsinput";
import styled from "styled-components";

export const MetaTagEditorWapper = styled.div`
  border: 1px solid #a6adb4;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  color: #213b73;
  font-family: "Poppins", sans-serif;
  background: #fff;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  p {
    margin: 0;
  }
  span {
    .react-tagsinput-tag {
      height: 25px;
      font-family: "Poppins", sans-serif;
      margin: 0 10px 0 0;
      padding: 2px 10px 5px;
      border: none;
      background: #72acff33;
      color: #213b73;
      font-weight: bold;
      a {
        text-decoration: none;
      }
      a::before {
        color: #213b73;
      }
    }
  }
  input {
    margin: 0;
    &::placeholder {
      color: transparent;
    }
    :focus {
      outline: none;
    }
  }
`;

export const GreyRetangle = styled.div`
  width: 8px;
  height: 100%;
  background-color: #ebeded;
`;

export const StyledTagInput = styled(TagsInput)`
  border: none;
  width: 100%;
  &:focus {
    border: none;
  }
`;
