import styled from "styled-components";
import theme from "styled-theming";

export const direction = theme("mode", {
  left: "ltr",
  right: "rtl",
  default: "ltr"
});

export const AccordionItemConteiner = styled.div`
  flex-direction: row;
  direction: ${direction};
`;
