import React, { createContext, useState } from "react";
import { Helmet } from "react-helmet";

export const TitleContext = createContext();

const TitleProvider = ({ children }) => {
  const [titleText, setTitleText] = useState("Alamo alugueis de carros");

  return (
    <TitleContext.Provider
      value={{
        setTitleText
      }}
    >
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;
