import React, { useEffect } from "react";
import PageContent from "./components/PageContent";
import PageSEO from "./components/PageSEO";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { LoaderComponent } from "components/Loader";
import { fetchPageById } from "@redux/cms/cmsService";
import { setPageCreation } from "@redux/cms/cmsSlice";

import { useDispatch, useSelector } from "react-redux";
const Canvas = () => {
  const history = useHistory();
  const loggedUser = useSelector(state => state.auth.userLogged);
  if(!loggedUser){
    history.push("/backoffice/login");
  }
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const pageId = query.get("id");
  const pageLoader = useSelector((state) => state?.cms);
  useEffect(() => {
    dispatch(setPageCreation());
  }, []);
  useEffect(() => {
    if (pageId) dispatch(fetchPageById(pageId));
  }, [pageId]);
  return (
    <Container>
      {pageLoader?.status === "loading" && <LoaderComponent />}
      <PageSEO />

      <PageContent />
    </Container>
  );
};

export default Canvas;
