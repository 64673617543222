import styled from "styled-components";
import { Row , Col, Container } from "react-bootstrap"

export const TwoInfoBlocksWithMainTitleBackground = styled.div`
    background-color: #213B73;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: left;
`; 

export const TwoInfoBlocksWithMainTitleMain = styled(Container)`
    padding: 30px 60px;

    @media(max-width:767px){
        padding: 15px 23px;
    }
`;

export const TwoInfoBlocksWithMainTitleTitle = styled.h2`
    color: white;
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;

    @media(max-width:767px){
        font-size: 23px;
    }
`;

export const TwoInfoBlocksWithMainTitleTwoInfoBlocks = styled(Row)`
    display: flex;
`;

export const TwoInfoBlocksWithMainTitleInfoBlock = styled(Col)`
    padding: 0.75rem 0.75rem 0 0.75rem;
`;

export const TwoInfoBlocksWithMainTitleInfoBlockImage = styled(Col)`
    img{
        height: auto;
        width: 90px;    
    }
    padding: 20px 0;
`;

export const TwoInfoBlocksWithMainTitleInfoBlockTitle = styled.h4`
    font-size: 17px;
    line-height: 24px;
    font-weight:700;

    @media(max-width:767px){
       font-size: 17px;
    }
`;

export const TwoInfoBlocksWithMainTitleInfoBlockText = styled.p`
    font-size: 14px;
    line-height: 21px 
    @media(max-width:767px){
       font-size: 14px;
    }
`;

export const TwoInfoBlocksWithMainTitleInfoBlockLink = styled.a`
    color: ${(props) => props.color};
    text-decoration: none;
    display: block;
    
    margin-left: 115px;
    @media(max-width: 1400px){
        margin-left: 100px;
    }
    @media(max-width: 1200px){
        margin-left: 85px;
    }
    @media(max-width: 991px){
        display: none;
    }
`;

export const TwoInfoBlocksWithMainTitleInfoBlockButton = styled.div`
    display: none;

    @media(max-width: 991px){
        display: block;
    }
`;