import styled from "styled-components";

export const TextBlockWithLinksBackground = styled.div`
    h2{
        margin-bottom: 48px 
    }
    hr{
        height: 1px;
        opacity: 0.75;
        margin: 43px 0;
    }
    a{
        font-size: 22px;
        color: #8C4C9E;
        text-decoration: none;
    }
    @media(max-width: 768px){
        h2{
            margin-bottom: 12px !important;
        }
        hr{
            height: 1px;
            opacity: 0.75;
            margin: 22px 0;
        }
        a{
            font-size: 14px;
            text-decoration: underline;
        }   
    }
`;