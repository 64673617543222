import styled from "styled-components";

export const LocationCardBackground = styled.div`
    font-size: 17px;
    background-color: #fff;
    margin-bottom: 20px;
    border-style: solid none none;  
    border-color: #E4E4E4;
    border-width: 8px;
    font-family: 'Poppins', sans-serif;
    color: #213B73;
    padding: 1rem;
    img{
        height: 40px;
        margin-right: 30px;
    }
    button{
        height: 64px;
        width: 150px;
        margin-bottom: 20px;
    }
    @media(max-width: 768px){
        h4{
            font-size: 12px;
        }
        img{
            margin-right: 10px;
        }
        font-size: 11px;
    }
`;