import { CardBackground, CardIconText, CardTitle, CardText, CardIcon } from "./styles";
import { Col } from "react-bootstrap";
import icon from "../../assets/images/icon-tag.png";

export function Card(props) {

    return (
        <CardBackground>
            <a href={props.data.href}>
                {props.data.tagText &&
                    <CardIconText>
                        <Col lg={1} xs={1}>
                            <CardIcon src={icon} />
                        </Col>
                        <Col lg={11} xs={11}>
                            <p>{props.data.tagText}</p>
                        </Col>
                    </CardIconText>
                }
                <CardTitle>{props.data.tittle}</CardTitle>
                <CardText>{props.data.text}</CardText>
            </a>
        </CardBackground>
    )
}