import React from "react";
import MetaTagEditor from "@cms/components/MetaTagEditor";
import {
  InputWapper,
  GreyRetangle,
  CopyIcon,
  ContentDiscription,
  ContentRow,
  WithTitle
} from "./styles";

import { useDispatch, useSelector } from "react-redux";

import { blogSlice } from "@redux/blog/blogSlice";

const PostSEO = () => {
  const dispatch = useDispatch();
  const { setPostEditData } = blogSlice.actions;
  const categoryEditData = useSelector(
    (state) => state?.blog?.categoryEditData
  );
  const categories = useSelector((state) => state?.blog?.categories);
  const subcategories = useSelector((state) => state?.blog?.subcategories);
  const handleNameChange = (value) => dispatch(setPostEditData(value));
  return (
    <ContentDiscription>
      <ContentRow>
        <InputWapper className="mr10">
          <GreyRetangle />
          <WithTitle>
            <span> Categoria</span>
            <select
              value={categoryEditData?.category?.[0] || ""}
              onChange={(value) =>
                handleNameChange({
                  ...categoryEditData,
                  category: [value.target.value]
                })
              }
              placeholder="Nome da Pagina"
            >
              <option>Nenhuma Categoria</option>
              {categories?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </WithTitle>
        </InputWapper>
        <InputWapper className="mr10">
          <GreyRetangle />
          <WithTitle>
            <span> Subcategoria</span>
            <select
              value={categoryEditData?.subcategory?.[0] || ""}
              disabled={!!!categoryEditData?.category?.[0]}
              onChange={(value) =>
                handleNameChange({
                  ...categoryEditData,
                  subcategory: [value.target.value]
                })
              }
              placeholder="Nome da Pagina"
            >
              <option>Nenhuma Subcategoria</option>
              {subcategories
                ?.filter(
                  (p) => p.categoryId === categoryEditData?.category?.[0]
                )
                ?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </WithTitle>
        </InputWapper>
        <MetaTagEditor
          tags={categoryEditData?.keywords || []}
          onTagChange={(value) =>
            handleNameChange({
              ...categoryEditData,
              keywords: value
            })
          }
        />
      </ContentRow>
      <ContentRow>
        <InputWapper className="mr10">
          <GreyRetangle />
          <WithTitle>
            <span> Titulo do post</span>
            <input
              value={categoryEditData?.title}
              onChange={(value) =>
                handleNameChange({
                  ...categoryEditData,
                  title: value.target.value
                })
              }
              placeholder=""
            />
          </WithTitle>
        </InputWapper>
        <InputWapper>
          <GreyRetangle />
          <p className="mx-2">URL</p>
          <input
            value={categoryEditData?.url}
            onChange={(value) =>
              handleNameChange({
                ...categoryEditData,
                url: value.target.value
              })
            }
            placeholder="url/Post"
          />
          <CopyIcon />
        </InputWapper>
      </ContentRow>
      <ContentRow>
        <InputWapper style={{ height: 130 }}>
          <GreyRetangle />
          <WithTitle>
            <span> Descricao</span>
            <textarea
              value={categoryEditData?.description}
              rows="3"
              onChange={(value) =>
                handleNameChange({
                  ...categoryEditData,
                  description: value.target.value
                })
              }
              placeholder=""
            />
          </WithTitle>
        </InputWapper>
      </ContentRow>
    </ContentDiscription>
  );
};

export default PostSEO;
