import { ReactComponent as PlaneImage } from "@assets/images/city2.svg";
import { ReactComponent as MapImage } from "@assets/images/map.svg";
import Accordion from "@components/Accordion";
import AccordionBody from "@components/Accordion/AccordionBody";
import AccordionHeader from "@components/Accordion/AccordionHeader";
import AccordionItem from "@components/Accordion/AccordionItem";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AgencyFilterBackground,
  CityIcon,
  CityText,
  ContentContainer,
  HeaderContent,
  IconContainer,
  ItemContainer,
  PlaneIcon,
  TextContainer,
  PopularCityContainer
} from "./styles";
import { getMainCities } from "redux/location/locationSlice";

export const DataType = {
  AIRPORT: 1,
  PORT_OF_CALL: 2,
  CITY: 2
};

const renderItem = (item, index) => {
  return (
    <ItemContainer key={`${index}`}>
      <IconContainer>
        {item.airportIndicator === "Y" ? <PlaneIcon /> : <CityIcon />}
      </IconContainer>
      <TextContainer>
        <a href={`/agencias/detalhes/${item.id}`}>{`${item.branchName} >`}</a>
      </TextContainer>
    </ItemContainer>
  );
};

const AgencyFilter = () => {
  const dispatch = useDispatch();
  const { countryId } = useParams();
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);

  const locationState = useSelector((state) => state.location?.locations);
  const mainCitiesState = useSelector((state) => state.location?.mainCities);

  useEffect(() => {
    if (locationState && locationState.length > 0) {
      let location = [
        ...locationState.filter((x) => x.branchStationCountry === countryId)
      ];
      let list = [];
      location.map((i) => {
        if (!list.some((e) => e === i.branchStationCity)) {
          list = [...list, i.branchStationCity];
        }
        return i;
      });
      list.sort((a, b) => a.localeCompare(b));
      setCities(list);
      location.sort((a, b) => a.branchName.localeCompare(b.branchName));
      setLocations(location);
    }
    dispatch(getMainCities());
  }, [locationState]);

  return (
    <AgencyFilterBackground>
      <Accordion keepOtherOpen={true}>
        <AccordionItem>
          <AccordionHeader
            name={`item-${1}`}
            title={`Localizações de aluguel (${locations.length})`}
            icon={MapImage}
          />
          <AccordionBody name={`item-${1}`}>
            <ContentContainer>
              {locations.map((i, idx) => renderItem(i, idx))}
            </ContentContainer>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader
            name={`item-${2}`}
            title={`Por cidades (${cities.length})`}
            icon={PlaneImage}
          />
          <AccordionBody name={`item-${2}`}>
            <ContentContainer>
              {mainCitiesState?.length > 0 && countryId === "BR" && (
                <>
                  <HeaderContent>Destinos populares</HeaderContent>
                  {mainCitiesState.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <PopularCityContainer>
                          <a
                            href={`/agencias/detalhes-da-cidade/${e.id}`}
                          >{`${e.branchName} >`}</a>
                        </PopularCityContainer>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
              <HeaderContent>Mais cidades atendidas</HeaderContent>
              {cities.map((i, idx) => (
                <CityText key={idx}>{i}</CityText>
              ))}
            </ContentContainer>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </AgencyFilterBackground>
  );
};

export default AgencyFilter;
