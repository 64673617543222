import { BreadCrumb } from "@components/BreadCrumb";
import { ImageHeader } from "@components/ImageHeader";
import { ImageTitleTextBlockAndButton } from "@components/ImageTitleTextBlockAndButton";
import { LoaderComponent } from "@components/Loader";
import { LocationDetail } from "@components/LocationDetail";
import { NearestRentalLocations } from "@components/NearestRentalLocations";
import { RentalPoliciesList } from "@components/RentalPoliciesListWithTextBlock";
import { PbkWidget } from "@components/Widget";
import { fetchLocations } from "@redux/location/locationService";
import { getMainCities, getRegions } from "@redux/location/locationSlice";
import { InfoBlock } from "components/InfoBlock";
import { StaticPageEngine } from "pages/StaticPageEngine";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import infoBlockData from "./Resources/cityTexts.json";

export function LocationDetailPage(props) {
  const { agencyName, cityId } = useParams();
  const history = useHistory();

  const [location, setLocation] = useState();
  const [locations, setLocations] = useState();
  const [locationDetail, setLocationDetail] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(getRegions());
    dispatch(getMainCities());
  }, []);

  const locationId = agencyName;

  const locationLoader = useSelector((state) => state.location);
  const locationState = useSelector((state) => state.location?.locations);
  const locationCountry = useSelector((state) => state.location?.countries);

  const mainCitiesState = useSelector((state) => state.location?.mainCities);

  // https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371;
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const setDayOfWeek = () => {
    const diaSemana = new Date();
    if (location) {
      switch (diaSemana.getDay()) {
        case 0:
          setLocationDetail({
            ...location,
            ...{
              todayOpen: location.branchOpenSun ?? "",
              todayClose: location.branchCloseSun ?? ""
            }
          });

          break;
        case 1:
          setLocationDetail({
            ...location,
            ...{
              todayOpen: location.branchOpenMon ?? "",
              todayClose: location.branchCloseMon ?? ""
            }
          });

          break;
        case 2:
          setLocationDetail({
            ...location,
            ...{
              todayOpen: location.branchOpenTue ?? "",
              todayClose: location.branchCloseTue ?? ""
            }
          });

          break;
        case 3:
          setLocationDetail({
            ...location,
            ...{
              todayOpen: location.branchOpenWed ?? "",
              todayClose: location.branchCloseWed ?? ""
            }
          });

          break;
        case 4:
          setLocationDetail({
            ...location,
            ...{
              todayOpen: location.branchOpenThu ?? "",
              todayClose: location.branchCloseThu ?? ""
            }
          });

          break;
        case 5:
          setLocationDetail({
            ...location,
            ...{
              todayOpen: location.branchOpenFri ?? "",
              todayClose: location.branchCloseFri ?? ""
            }
          });

          break;
        case 6:
          setLocationDetail({
            ...location,
            ...{
              todayOpen: location.branchOpenSat ?? "",
              todayClose: location.branchCloseSat ?? ""
            }
          });
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (
      locationState &&
      locationState.length > 0 &&
      mainCitiesState &&
      mainCitiesState.length > 0
    ) {
      let current;
      if (locationId)
        current = locationState.find(
          (location) => location.id.toString() === locationId
        );
      if (cityId)
        current = mainCitiesState.find(
          (location) => location.id.toString() === cityId
        );
      if (current) {
        setLocation(current);

        setLocations(
          locationState
            .filter((el) => el?.branchName !== current?.branchName)
            .filter(
              (el) =>
                getDistanceFromLatLonInKm(
                  el.branchStationLatitude,
                  el.branchStationLongitude,
                  current.branchStationLatitude,
                  current.branchStationLongitude
                ) < 50
            )
        );
      } else {
        history.push("/pagina-nao-encontrada");
      }
    }
  }, [locationState, mainCitiesState]);

  useEffect(() => {
    if (location) setDayOfWeek();
  }, [location]);

  const components = <>
  {locationLoader.status === "loading" && <LoaderComponent />}
  {location && locationDetail && (
    <>
      <BreadCrumb
        data={props.data.breadCrumbData}
        customPath={[
          {
            path: `/locations/country/${location.branchStationCountry}`,
            pathName: locationCountry.find(
              (country) => country.code === location.branchStationCountry
            )?.name
          },
          {
            path: "/agencias/detalhes/:name",
            pathName: location?.branchName
          }
        ]}
      />
      <ImageHeader
        data={props.data.imageHeaderData}
        data2={location?.branchName}
      />
    </>
  )}
  <PbkWidget />
  {location && locationDetail && (
    <>
      {locationId && (
        <LocationDetail
          data={props.data.locationDetailData}
          data2={locationDetail}
        />
      )}
      {infoBlockData[location?.branchName] && (
        <InfoBlock data={infoBlockData[location?.branchName]} />
        )}
      <RentalPoliciesList data={props.data.rentalPoliciesListData} />
      <NearestRentalLocations data={locations} agencyId={locationId} />
      <ImageTitleTextBlockAndButton
        data={props.data.imageTitleTextBlockAndButtonData}
      />
    </>
  )}
</>

  return (
    <StaticPageEngine components={components} />
  );
}

export default LocationDetailPage;
