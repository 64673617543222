import { LoaderComponent } from "@components/Loader";
import { fetchLocations } from "@redux/location/locationService";
import { StaticPageEngine } from "pages/StaticPageEngine";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BreadCrumb } from "../../components/BreadCrumb";
import { SearchLocation } from "../../components/SearchLocation";

export function LocationWithMap(props) {
  const { agencyName } = useParams();
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const locationId = agencyName;

  const locationLoader = useSelector((state) => state.location);

  const locationState = useSelector((state) => state.location?.locations);

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371;
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  useEffect(() => {
    const location = locationState.find(
      (location) => location.id.toString() === locationId
    );
    if (location) {
      setLocation(location);
      const locations = locationState
        .filter((el) => el.branchName !== location?.branchName)
        .filter(
          (el) =>
            getDistanceFromLatLonInKm(
              el.branchStationLatitude,
              el.branchStationLongitude,
              location?.branchStationLatitude,
              location?.branchStationLongitude
            ) < 50
        );
      setLocations(locations);
    }
  }, [locationState]);

  const components =
    <>
      {locationLoader.status === "loading" || !location || !locations ? (
        <LoaderComponent />
      ) : (
        <>
          <BreadCrumb data={props.data.breadCrumbData} />
          <SearchLocation
            data={props.data.searchLocationData}
            location={location}
            locations={locations}
          />
        </>
      )}
    </>

  return (
    <StaticPageEngine components={components} />
  );
}

export default LocationWithMap;
