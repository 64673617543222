import { fonts, palettes } from "assets/theme";
import styled from "styled-components";
export const Component = styled.div`
  padding: 0 10px 0 18px;
`;
export const Spacer = styled.hr`
  border: 0;
  border-top: 1px solid ${palettes.tints_blue_darker};
`;
export const ComponentWapper = styled.div`
  border: 1px solid ${palettes.system_gray_darker};
  position: relative;
  button {
    padding-left: 18px;
  }
`;
export const ComponentParentWapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 16px;
`;
export const GreyRetangle = styled.span`
  width: 8px;
  height: 100%;
  background-color: #ebeded;
  position: absolute;
`;

export const Title = styled.h3`
  ${fonts.h3_bold};
  color: ${palettes.tints_blue_darker};
`;
export const Paragraph = styled.p`
  ${fonts.paragraph};
  color: ${palettes.tints_blue_darker};
`;

export const InputWapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #a6adb4;
  background: #fff;
  display: flex;
  align-items: center;
  min-height: 50px;
  color: #213b73;
  font-family: "Poppins", sans-serif;
  padding-right: 10px;
  margin-bottom: 10px;
  div.content {
    padding-left: 10px;
    width: 100%;
  }
  p {
    margin: 0;
  }
  textarea {
    overflow: hidden;
    resize: none;
    margin: 0;
    border: none;
    width: 100%;
    &::placeholder {
      color: transparent;
    }
    :focus {
      outline: none;
    }
  }
  .jodit-workplace+.jodit-status-bar:not(:empty) {
    display: none;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 10px 0 0 16px;
  img {
    width: 100%;
    margin-bottom: 5px;
  }
`;
export const ImageLabel = styled.label`
  ${fonts.h4_bold}
  color: ${palettes.tints_blue_darker};
  cursor: pointer;
`;
export const Image = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const AddSectionButton = styled.button`
  ${fonts.h4_bold}
  color: ${palettes.tints_blue_darker};
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  `;