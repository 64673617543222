import styled from "styled-components";

export const VehicleCategoryTitleAndSubtitleContainer = styled.div`
    background-color: #005DAA;
    color: white;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    padding: 40px 50px 20px 50px;
    h1 {
        margin-bottom: 30px;
        font-weight: bold;
    }
    h4 {
        font-weight: bold;
    }
    span {
        font-weight: normal;
        font-size: 12px;
    }
`;