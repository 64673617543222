import { StyledButton } from "components/Button/styles";
import { Note } from "components/Global/Texts/styles";
import { Subtitle } from "components/Global/Texts/styles";
import { useSelector } from "react-redux";
import { PostCard, PostListItemCategory } from "./styles";

export function PostListItem({
  data: { image, description, category, url, title },
}) {
  const categories = useSelector((state) => state?.blog?.categories);
  return (
    <PostCard className={"px-0 mx-0"}>
      <PostCard.Img variant="top" src={image} />
      <PostListItemCategory>
        {category?.map((category, index) => (
          <span key={index}>
            {categories?.find((p) => p.name === category)?.name}
          </span>
        ))}
      </PostListItemCategory>
      <PostCard.Body>
        <PostCard.Title>
          <a
            href={"/blog/" + url}
            alt="Leia Mais"
            style={{ textDecoration: "none" }}
          >
            <Subtitle>
              <b>{title}</b>
            </Subtitle>
          </a>
        </PostCard.Title>
        <p>{description}</p>
      </PostCard.Body>
      <PostCard.Footer>
        <a href={"/blog/" + url} alt="Leia Mais">
          <StyledButton color="#8C4C9E" colortext="#ffffff">
            Leia Mais
          </StyledButton>
        </a>
      </PostCard.Footer>
    </PostCard>
  );
}
