import excludeIcon from "@assets/images/cms/ic-exclude.svg";
import trashIcon from "@assets/images/cms/ic-trash.svg";
import { FaExternalLinkAlt } from "react-icons/fa";
import pencilIcon from "@assets/images/ic-pencil.svg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import {
  ActionsDiv,
  AuthorDiv,
  DateDiv,
  PageTitleDiv,
  StyledDiv,
  StyledPageListItem,
  TagsDiv,
} from "./styles";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { deletePage } from "redux/page/pageService";
import { useEffect } from "react";
import { fetchGetPages } from "redux/page/pageService";
import { fetchSiteMap } from "redux/cms/cmsService";

export function PageListItem(props, index) {
  const dispatch = useDispatch();
  const history = useHistory();
  const copyTextToClipboard = (text) => {
    navigator.clipboard?.writeText(text);
    dispatch(displaySnackbar({ message: "URL copiada!", type: "info" }));
  };
  const [show, setShow] = useState(false);
  const statusPage = useSelector((state) => state?.page?.status);
  const statusCMS = useSelector((state) => state?.cms?.status);
  useEffect(() => {
    if (
      statusPage === "deletePage-success" ||
      statusCMS === "updatePage-success" ||
      statusCMS === "createPage-success"
    ) {
      dispatch(fetchGetPages());
      dispatch(fetchSiteMap());
    }
  }, [statusPage, statusCMS]);

  const handleDeletePage = () => {
    dispatch(deletePage(props.page.id));
    setShow(false);
  };

  return (
    <StyledPageListItem key={`PageListItemParent-${index}`}>
      <PageTitleDiv>
        <Link to={`cms/canvas?id=${props.page.id}`}>{props.page.name}</Link>
      </PageTitleDiv>
      <AuthorDiv>
        <p>{props.page.createdBy}</p>
      </AuthorDiv>
      <TagsDiv>
        <p>{props.page.metaTags}</p>
      </TagsDiv>
      <DateDiv>
        {props.page.createdAt
          ? dayjs(props.page.createdAt).format("DD/MM/YYYY")
          : "-"}
      </DateDiv>
      <ActionsDiv className="d-flex">
        <button className="">
          <img
            src={pencilIcon}
            height={"20px"}
            alt="pencil"
            onClick={() => {
              history.push(`cms/canvas?id=${props.page.id}`);
            }}
          />
        </button>
        <button className="ms-2">
          <img
            alt="copy link"
            height={"20px"}
            src={excludeIcon}
            onClick={() => {
              copyTextToClipboard(
                `${window.location.origin}/${props.page.url}`
              );
            }}
          />
        </button>
        <button className="ms-2">
          <a
            href={`${window.location.origin}/${props.page.url}`}
            target="_blank"
            rel="noreferrer"
          >
            <FaExternalLinkAlt height={"20px"} color={"#213b73"} />
          </a>
        </button>
        <button className="ms-2">
          <img
            alt="exclude"
            height={"20px"}
            src={trashIcon}
            onClick={() => {
              setShow(true);
            }}
          />
        </button>
      </ActionsDiv>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Excluir página</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você tem certeza que deseja excluir essa página?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleDeletePage();
            }}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </StyledPageListItem>
  );
}
