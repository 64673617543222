import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  createPage,
  fetchFooter,
  fetchHeaders,
  fetchPageById,
  fetchSiteMap,
  createFooter,
  uptadeFooter,
  updatePage,
  updateHeader,
  createHeader,
  createHighlight,
  updateHighlight,
  fetchHighlights,
} from "./cmsService";
import { PageTypes } from "./constants";
import FooterReducer from "./reducers/footerReducer";
import HeaderReducer from "./reducers/headerReducer";
import PageReducer from "./reducers/pageReducer";
import SiteMapReducer from "./reducers/siteMapReducer";
import TemplateReducer from "./reducers/templateReducer";
import PostReducer from "./reducers/postReducer";
import ConfirmationModalReducer from "./reducers/confirmationModalReducer";
import WarningModalReducer from "./reducers/warningModalReducer";

const cmsAdapter = createEntityAdapter();

const initialState = cmsAdapter.getInitialState({
  status: "",
  cms: [],
  pageType: PageTypes.WEB_PAGE,
  isPageCreation: false,
  isHeadersEditing: false,
  isFooterEditing: false,
  currentPage: {},
  parentPage: {},
  siteMap: [],
  templates: [],
  headerPage: {},
  templatePage: {},
  canvasPage: { metaTags: [], components: [] },
  footerPage: { section: [] },
  headersNav: [],
  headersType: 1,
  countries: [],
  error: null,
  selectParentPage: false,
  featuredPage: { posts: [] },
  confirmationModal: false,
  warningModal: false,
  selectedLink: { type: "", url: "", name: "" },
  inputHomePageState: "",
  errorCode: undefined,
});

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    ...HeaderReducer,
    ...TemplateReducer,
    ...FooterReducer,
    ...PageReducer,
    ...SiteMapReducer,
    ...PostReducer,
    ...ConfirmationModalReducer,
    ...WarningModalReducer,
  },
  extraReducers: {
    [createPage.pending]: (state) => {
      state.status = "loading";
    },
    [createPage.fulfilled]: (state, { payload }) => {
      state.status = "createPage-success";
      state.canvasPage = payload;
    },
    [createPage.rejected]: (state, action) => {
      state.status = "createPage-failed";
      state.errorCode = action.payload.status;
      state.error = action.error;
    },
    [fetchPageById.pending]: (state) => {
      state.status = "loading";
    },
    [fetchPageById.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.canvasPage = payload;
    },
    [fetchPageById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [updatePage.pending]: (state) => {
      state.status = "updatePage-loading";
    },
    [updatePage.fulfilled]: (state, { payload }) => {
      state.status = "updatePage-success";
      state.headers = payload;
    },
    [updatePage.rejected]: (state, action) => {
      state.status = "updatePage-failed";
      state.errorCode = action.payload.status;
      state.error = action.error;
    },
    [fetchHeaders.pending]: (state) => {
      state.status = "loading";
    },
    [fetchHeaders.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.headerPage = payload[0];
    },
    [fetchHeaders.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [fetchFooter.pending]: (state) => {
      state.status = "loading";
    },
    [fetchFooter.fulfilled]: (state, { payload }) => {
      state.status = "success";

      //criar footer base caso venha vazio
      if (payload[0] !== undefined) {
        state.footerPage = payload[0];
      } else {
        state.footerPage = {
          id: undefined,
          section: [],
        };
      }
    },
    [fetchFooter.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [updateHeader.pending]: (state) => {
      state.status = "updateHeader-loading";
    },
    [updateHeader.fulfilled]: (state, { payload }) => {
      state.status = "updateHeader-success";
      state.footerPage = payload;
    },
    [updateHeader.rejected]: (state, action) => {
      state.status = "updateHeader-failed";
      state.error = action.error;
    },
    [createHeader.pending]: (state) => {
      state.status = "createHeader-loading";
    },
    [createHeader.fulfilled]: (state, { payload }) => {
      state.status = "createHeader-success";
      state.footerPage = payload;
    },
    [createHeader.rejected]: (state, action) => {
      state.status = "createHeader-failed";
      state.error = action.error;
    },
    [createHeader.pending]: (state) => {
      state.status = "createHeader-loading";
    },
    [createHeader.fulfilled]: (state, { payload }) => {
      state.status = "createHeader-success";
      state.footerPage = payload;
    },
    [createHeader.rejected]: (state, action) => {
      state.status = "createHeader-failed";
      state.error = action.error;
    },
    [createFooter.pending]: (state) => {
      state.status = "createFooter-loading";
    },
    [createFooter.fulfilled]: (state, { payload }) => {
      state.status = "createFooter-success";
      state.footerPage = payload;
    },
    [createFooter.rejected]: (state, action) => {
      state.status = "createFooter-failed";
      state.error = action.error;
    },
    [uptadeFooter.pending]: (state) => {
      state.status = "uptadeFooter-loading";
    },
    [uptadeFooter.fulfilled]: (state, { payload }) => {
      state.status = "uptadeFooter-success";
      state.footerPage = payload;
    },
    [uptadeFooter.rejected]: (state, action) => {
      state.status = "uptadeFooter-failed";
      state.error = action.error;
    },
    [fetchSiteMap.pending]: (state) => {
      state.status = "loading";
    },
    [fetchSiteMap.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.siteMap = payload;
    },
    [fetchSiteMap.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [createHighlight.pending]: (state) => {
      state.status = "createHighlight-loading";
    },
    [createHighlight.fulfilled]: (state, { payload }) => {
      state.status = "createHighlight-success";
      state.footerPage = payload;
    },
    [createHighlight.rejected]: (state, action) => {
      state.status = "createHighlight-failed";
      state.error = action.error;
    },
    [createHighlight.pending]: (state) => {
      state.status = "createHighlight-loading";
    },
    [createHighlight.fulfilled]: (state, { payload }) => {
      state.status = "createHighlight-success";
      state.footerPage = payload;
    },
    [createHighlight.rejected]: (state, action) => {
      state.status = "createHighlight-failed";
      state.error = action.error;
    },
    [updateHighlight.pending]: (state) => {
      state.status = "updateHighlight-loading";
    },
    [updateHighlight.fulfilled]: (state, { payload }) => {
      state.status = "updateHighlight-success";
      state.footerPage = payload;
    },
    [updateHighlight.rejected]: (state, action) => {
      state.status = "updateHighlight-failed";
      state.error = action.error;
    },
    [fetchHighlights.pending]: (state) => {
      state.status = "loading";
    },
    [fetchHighlights.fulfilled]: (state, { payload }) => {
      state.status = "fetchHighlights-success";
      const lenght = payload.length;
      state.featuredPage.id = payload[lenght - 1]?.id;
      state.featuredPage.posts = payload[lenght - 1]?.highlights ?? [];
      state.featuredPage.title = payload[lenght - 1]?.title;
      state.featuredPage.description = payload[lenght - 1]?.description;
    },
    [fetchHighlights.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
  },
});
export const {
  setDuplicateComponent,
  getSiteMap,
  getTemplates,
  getFooter,
  getFooterSuccess,
  getFooterFailed,
  getSetPageCreation,
  setPageCreation,
  setCancelPageCreation,
  setPageType,
  setHeaderType,
  setHeaderEditing,
  addHeaderItems,
  updateHeaderItem,
  removeHeaderItem,
  addFooterItems,
  updateFooterItem,
  removeFooterItem,
  setFooterEditing,
  addSection,
  renameSection,
  removeSection,
  setPageName,
  setPageUrl,
  setPageTags,
  setPageComponents,
  setTemplateEditionMode,
  cancelTemplateEditionMode,
  updateTemplate,
  setPageChange,
  addPostItem,
  removePostItem,
  updatePostItem,
  setConfirmationModal,
  setWarningModal,
  setSelectedLinkType,
} = cmsSlice.actions;

export default cmsSlice.reducer;
