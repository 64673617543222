import React from "react";
import { Subtitle, Text, Note } from "../../Global/Texts/styles";
import { ImageTextCardContainer} from "./styles";
export function ImageTextCard(props) {
    return (
        <ImageTextCardContainer>
            <img src={props.data.image.src} alt={props.data.image.alt}/>
            <Subtitle><b>{props.data.title}</b></Subtitle>
            <Text>{props.data.text}</Text>
            {props.data.warning ? <Note>{props.data.warning}</Note> : null}
        </ImageTextCardContainer>
    )
}