import styled from "styled-components";

export const PostHighlightContainer = styled.div`
    font-family: "Poppins", sans-serif;
    padding-top: 50px;
    color: #213B73;
    img{
        width: 85%;
        margin: 0 5%;
    }
    button{
        background-color: #8C4C9E;
        border: none;
        color: white;
        width: 50%;
        height: 50px;
        @media (max-width: 992px) {
            width: 100%;
        }
    }
    hr{
        opacity: 1;
    }
    a {
        color: #8C4C9E!important;
        text-decoration: none;
        font-weight: bold;
    }
    @media (max-width: 576px) {
        img{
            margin: 0;
        }
    }
`;

export const PostCategory = styled.div`
    max-height: fit-content;
    background-color: white;
    color: #A6ADB4;
    font-weight: bold;
    border: 1.8px solid #A6ADB4;
    font-size: 12px;
    border-radius: 20px;
    padding: 8px 12px;
    margin-right: 10px;
    @media(max-width: 992px){
        font-size: 9px;
    }
`;