import Loader from "react-loader-spinner";
import {LoaderContainer} from "./styles";

export function LoaderComponent(){
    return(
        <LoaderContainer>
            <Loader
                type="Oval"
                color="#eee"
                height={100}
                width={100}
            />
        </LoaderContainer>
    );
}