export const palettes = {
  brand_primary: "#FFEE00",
  brand_secondary: "#004F9A",
  /* System Tints/1 */
  tints_gray: "#E4E4E4",
  /* System Tints/2 */
  tints_yellow: "#FEF100",
  /* System Tints/3 */
  tints_purple: "#8C4C9E",
  /* System Tints/4 */
  tints_green: "#5FBEA5",
  /* System Tints/5 */
  tints_red: "#F55564",
  /* System Tints/6 */
  tints_pink: "#CD94FF",
  /* System Tints/7 */
  tints_blue: "#005DAA",
  /* System Tints/8 */
  tints_blue_darker: "#213B73",
  /* System Tints/9 */
  tints_blue_lighter: "#42B7E7",
  /* System Grays/1 */
  black: "#202124",
  /* System Grays/2 */
  system_gray_darker: "#A6ADB4",
  /* System Grays/3 */
  system_gray: "#EBEDED",
  /* System Grays/4 */
  system_gray_lighter: "#F6F8F9",
  /* System Grays/5 */
  white: "#FFFFFF"
};
