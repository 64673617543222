import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";
import { createUser } from "redux/user/userService";
import { LoaderComponent } from "components/Loader";
import {
  ErrorMessage,
  InputContainer,
  LargMax,
  UserCreationContainer,
} from "./styles";
const regexEmail =
  /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const regexPassword = /(?![a-zA-Z0-9~`!@#$%^&*()_\-+={\[}\]|:;"'<,>.?]).+$/;
const regexPasswordNumber = /[0-9]/;
const regexPasswordUppercase = /[A-Z]/;
export function UserCreation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user.status);
  const error = useSelector((state) => state.user.error);

  useEffect(() => {
    let message;
    let type;
    switch (userState) {
      case "createUser-failed":
        if (error)
          message =
            error.message === "Request failed with status code 409"
              ? "Ja existe este e-mail cadastro para outro usuário no sistema"
              : "Erro ao cadastrar usuario";
        type = "error";
        break;
      case "createUser-success":
        message = "Usuário cadastrado com sucesso";
        type = "success";
        break;
      default:
        return;
    }

    if (message) {
      dispatch(displaySnackbar({ message, type }));
      history.push("/backoffice/administracao-de-usuario");
    }
  }, [userState]);

  //funçao para criar novo usuario
  const handleCreateUser = async (newUser) => {
    newUser.preventDefault();
    //montar corpo da requisição
    if (
      corName === "#F55564" ||
      name === "" ||
      corLastName === "#F55564" ||
      lastName === "" ||
      corEmail === "#F55564" ||
      email === "" ||
      corPassword === "#F55564" ||
      password === ""
    ) {
      dispatch(
        displaySnackbar({
          message: "Corrija os campos que estão em vermelho ou estão vazios!",
          type: "error",
        })
      );
    } else {
      const body = {
        email: newUser.target.email.value,
        pass: newUser.target.password.value,
        firstName: newUser.target.nome.value,
        lastName: newUser.target.sobrenome.value,
        roles: [newUser.target.perfil.value],
      };
      newUser.preventDefault();
      dispatch(createUser(body));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const [name, setIsName] = useState("");
  const [lastName, setIsLastName] = useState("");
  const [email, setIsEmail] = useState("");
  const [password, setIsPassword] = useState("");
  const [corName, setIsCorName] = useState("#FFF");
  const [corLastName, setIscorLastName] = useState("#FFF");
  const [corEmail, setIsCorEmail] = useState("#FFF");
  const [corPassword, setIsCorPassword] = useState("#FFF");

  const handleName = (e) => {
    setIsName(e.target.value);

    if (e.target.value.length < 3 || e.target.value.length > 100) {
      console.log(e.target.value.length);
      setIsCorName("#F55564");
    } else {
      setIsCorName("#FFF");
    }
  };
  const handleLastName = (e) => {
    setIsLastName(e.target.value);
    if (e.target.value.length < 3 || e.target.value.length > 150) {
      setIscorLastName("#F55564");
    } else {
      setIscorLastName("#FFF");
    }
  };

  function validacaoEmail() {
    if (regexEmail.test(email)) {
      return true;
    }
    return false;
  }

  const handleEmail = (e) => {
    setIsEmail(e.target.value);
    if (!validacaoEmail()) {
      setIsCorEmail("#F55564");
    } else if (email.length > 250) {
      setIsCorEmail("#F55564");
    } else {
      setIsCorEmail("#FFF");
    }
  };

  const handlePassword = (e) => {
    setIsPassword(e.target.value);
    if (
      regexPassword.test(e.target.value) ||
      e.target.value.length < 8 ||
      e.target.value.length > 64 ||
      !regexPasswordNumber.test(e.target.value) ||
      !regexPasswordUppercase.test(e.target.value)
    ) {
      setIsCorPassword("#F55564");
    } else {
      setIsCorPassword("#FFF");
    }
  };

  return (
    <div>
      {userState === "createUser-loading" && <LoaderComponent />}
      <UserCreationContainer>
        <h3>
          <b>Criar novo usuário</b>
        </h3>
        <p>Depois do cadastro repasse a senha ao usuário</p>
        <form onSubmit={handleCreateUser} id="user">
          <LargMax>
            <InputContainer background={corName}>
              {corName === "#F55564" ? (
                <label></label>
              ) : (
                <label>
                  <b>Nome</b>
                </label>
              )}
              <input
                name="nome"
                type="text"
                placeholder="Nome"
                onChange={handleName}
                autocomplete="off"
              />
              {corName === "#F55564" ? (
                <ErrorMessage>
                  Minimo de 3 caracteres, máximo de 100.
                </ErrorMessage>
              ) : (
                ""
              )}
            </InputContainer>

            <InputContainer background={corLastName}>
              {corLastName === "#F55564" ? (
                <label></label>
              ) : (
                <label>
                  <b>Sobrenome</b>
                </label>
              )}
              <input
                name="sobrenome"
                type="text"
                placeholder="Sobrenome"
                onChange={handleLastName}
                autocomplete="off"
              />
              {corLastName === "#F55564" ? (
                <ErrorMessage>
                  Minimo de 3 caracteres, máximo de 150.
                </ErrorMessage>
              ) : (
                ""
              )}
            </InputContainer>
            <InputContainer background={corEmail}>
              {corEmail === "#F55564" ? (
                <label></label>
              ) : (
                <label>
                  <b>Email</b>
                </label>
              )}
              <input
                name="email"
                type="text"
                placeholder="Email"
                onChange={handleEmail}
                autocomplete="off"
              />
              {corEmail === "#F55564" ? (
                <ErrorMessage>
                  Email Invalido / Máximo de 150 caracteres.
                </ErrorMessage>
              ) : (
                ""
              )}
            </InputContainer>
            <InputContainer background="#FFF">
              <label>
                <b>Perfil</b>
              </label>
              <select name="perfil" id="perfil" form="user">
                <option value="ROLE_ADMIN">Administrador</option>
                <option value="ROLE_USER">Usuário</option>
              </select>
            </InputContainer>
            <InputContainer background={corPassword}>
              {corPassword === "#F55564" ? (
                <label></label>
              ) : (
                <label>
                  <b>Senha</b>
                </label>
              )}
              <input
                name="password"
                type="password"
                onChange={handlePassword}
                onKeyDown={handleKeyDown}
                placeholder="Senha"
                autocomplete="off"
              />
              {corPassword === "#F55564" ? (
                <ErrorMessage>
                  .Mínimo de 8 e no máximo 64 caracteres <br />
                  .Pelo menos 1 número e 1 letra maiúscula <br />
                </ErrorMessage>
              ) : (
                ""
              )}
            </InputContainer>
            <div className="d-flex justify-content-end">
              <button
                type="reset"
                id="cancel-button"
                onClick={() => {
                  history.push("/backoffice/administracao-de-usuario");
                }}
              >
                Cancelar
              </button>
              <button type="submit" id="save-button">
                Salvar
              </button>
            </div>
          </LargMax>
        </form>
      </UserCreationContainer>
    </div>
  );
}
