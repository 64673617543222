import styled from "styled-components";

export const ListOfCategoriesAndArticlesBackground = styled.div`
    color: #005DAA;
    padding: 0 1.5rem;
    p{
        margin-top: 2rem;
        margin-bottom: 0;
    }
    a{
        text-decoration: none;
        font-size: 14px;
    }
`;