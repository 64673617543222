import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React from "react";
import { BreadCrumb } from "../../components/BreadCrumb";
import { ImageHeader } from "../../components/ImageHeader";
import { PbkWidget } from "../../components/Widget";

export function Reservations(props) {

    const { setTitleText } = React.useContext(TitleContext);

    React.useEffect(() => {
        setTitleText("Alamo - Reservas");
    }, []);
    const components =
        <>
            <BreadCrumb data={props.data.breadCrumbData} />
            <ImageHeader data={props.data.imageHeaderData} />
            <PbkWidget />
        </>

    return (
        <StaticPageEngine components={components} />
    )
}

export default Reservations;