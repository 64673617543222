import React from "react";
import { ContentBlockText } from "./styles";

export function ContentBlock(props) {
	const paragraphs = props.data.paragraphs.map((paragraphs, i) => {
		return <p key={i}>{paragraphs}</p>;
	});
	return (
		<ContentBlockText>
			<h1>{props.data.title}</h1>
			{paragraphs}
		</ContentBlockText>
	);
}
