import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const PostListContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 4%;
`;
export const PostListItemContainer = styled(Col)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
export const PostListRow = styled(Row)`
  width: 100%;
`;
