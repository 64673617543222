import { palettes } from "assets/theme";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
`;
export const ImageContainer = styled.div`
  
  background: ${palettes.tints_blue_darker}  no-repeat center;
  width: 100%;
  height: 50vh !important;
  background-size: 100% auto;
  position: relative;
  justify-content: end;
  align-items: center
  background-size: 100% auto;

  label {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: #fff;
    span {
      margin-left: 10px;
    }
    p{
      font-weight: normal;
      font-size: 13px;
      text-align: center;
    }
  }
  @media(max-width: 768px){
    height: 30vh;
    div{
      height: 60px;
    }
  }
`;
export const Image = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const InputWapper = styled.div`
  width: 100%;
  border: 0;
  background: #0f1e39;
  display: flex;
  align-items: center;
  height: 50px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  input {
    padding-left: 10px;
    color: #fff;
    background: #0f1e39;
    margin: 0;
    border: none;
    width: 100%;
    font-weight: 700;
    font-size: 17px;
    :focus {
      outline: none;
    }
    ::placeholder {
      color: #fff;
      font-weight: 700;
      font-size: 17px;
    }
  }
`;
