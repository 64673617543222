import styled from "styled-components";
import {Button} from "react-bootstrap";

export const StyledButton = styled(Button)`
    background: ${(props) => props.color};
    color: ${(props) => props.colortext};
    border: none;
    border-radius: 0;
    font-size: 14px;
    font-weight: 700;
    transition: none;
    width: 212px;
    padding: 20px 0;
    align-items: center;
    &:hover{
        background: ${(props) => props.color};
        color: ${(props) => props.colortext};
    }
    
    &:focus{
        box-shadow: none;
        background: ${(props) => props.color};
        color: ${(props) => props.colortext};
    }

    @media(max-width: 991px){
        width: 100%;
    }
`;
