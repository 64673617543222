import { AccordionContext } from "@cms/components/Accordion";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import {
  AccordionHeaderButton,
  DownIcon,
  SeeAllContainer,
  SubtitleText,
  TextContainer,
  UpIcon
} from "./styles";

const AccordionHeader = ({
  name,
  title,
  subtitle,
  selected,
  reduced,
  hasTransparentBackground
}) => {
  const { openBody, accordionId, openCloseAccordion } =
    useContext(AccordionContext);
  const [initialState, setInitialState] = useState(selected);
  const [isOpen, setIsOpen] = useState(selected);
  const [theme, setTheme] = useState();
  const itemId = `${accordionId}_${name}`;

  useEffect(() => {
    if (initialState) {
      openCloseAccordion(itemId);
    } else {
      setIsOpen(openBody.some((e) => e === itemId));
    }
    setInitialState(false);
  }, [openBody]);

  useEffect(() => {
    setTheme(isOpen ? "open" : "close");
  }, [isOpen]);

  return (
    <AccordionHeaderButton
      theme={{
        mode: hasTransparentBackground ? "transparent" : theme,
        reduced: reduced ? "reduced" : theme
      }}
      onClick={() => {
        openCloseAccordion(itemId);
      }}
    >
      <TextContainer>
        {reduced ? (
          <span>{title}</span>
        ) : (
          <span>
            <b>{title}</b>
          </span>
        )}
        {subtitle && <SubtitleText>{subtitle}</SubtitleText>}
      </TextContainer>
      <SeeAllContainer>
        {isOpen ? (
          <UpIcon
            theme={{ mode: hasTransparentBackground ? "transparent" : theme }}
          />
        ) : (
          <DownIcon
            theme={{ mode: hasTransparentBackground ? "transparent" : theme }}
          />
        )}
      </SeeAllContainer>
    </AccordionHeaderButton>
  );
};

AccordionHeader.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selected: PropTypes.bool,
  hasShowHideText: PropTypes.bool,
  hasTransparentBackground: PropTypes.bool,
  reduced: PropTypes.bool,
  image: PropTypes.any
};

AccordionHeader.defaultProps = {
  hasShowHideText: true
};

export default AccordionHeader;
