import {
  setFilterPassengers,
  setFilterTransmition,
} from "@redux/vehicle/vehiclesSlice";
import React, { useState } from "react";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../Button/styles";
import { Checkbox } from "../Checkbox";
import {
  VehicleFilterBackground,
  VehicleFilterButton,
  VehicleFilterDropdown,
  VehicleFilterLabel,
  VehicleFilterModal,
  VehicleFilterResponsive,
} from "./styles";

export function VehicleFilter(props) {
  const { hasQueryCategory } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedPassengers, setSelectedPassengers] = useState("Todos");
  const [selectedTransmition, setSelectedTransmition] = useState("Todos");
  const categorytate = useSelector((state) => state.vehicle.categories);

  const checkBoxes = categorytate
    ?.filter((x) => x)
    .map((item, idx) => (
      <VehicleFilterLabel key={idx}>
        <label>
          <Checkbox value={item?.id} />
          <p>{item?.name}</p>
        </label>
      </VehicleFilterLabel>
    ));
  const setPassenger = (item) => {
    dispatch(setFilterPassengers(item));
    setSelectedPassengers(item);
  };
  const setTransmition = (item) => {
    dispatch(setFilterTransmition(item));
    setSelectedTransmition(item);
  };

  const dropdownItems = (item, type) =>
    item.map((vehicleType) => (
      <div key={vehicleType}>
        <Dropdown.Item
          onClick={() => {
            type === "passengers"
              ? setPassenger(vehicleType)
              : setTransmition(vehicleType);
          }}
        >
          {vehicleType}
        </Dropdown.Item>
        <hr />
      </div>
    ));

  return (
    <>
      <VehicleFilterBackground>
        <h4>{props.data.title}</h4>
        <hr />
        <p>
          <b>{props.data.text}</b>
        </p>
        {!hasQueryCategory && <>{checkBoxes}</>}
        <VehicleFilterDropdown>
          <p>Passageiros</p>
          <DropdownButton id="dropdown-basic-button" title={selectedPassengers}>
            {dropdownItems(props.data.passengers, "passengers")}
          </DropdownButton>
        </VehicleFilterDropdown>
        {!hasQueryCategory && (
          <VehicleFilterDropdown>
            <p>Transmissão</p>
            <DropdownButton
              id="dropdown-basic-button"
              title={selectedTransmition}
            >
              {dropdownItems(props.data.transmission, "transmission")}
            </DropdownButton>
          </VehicleFilterDropdown>
        )}
      </VehicleFilterBackground>

      <VehicleFilterResponsive>
        <VehicleFilterButton onClick={() => setShow(true)}>
          Filtros
        </VehicleFilterButton>
        <VehicleFilterModal
          show={show}
          fullscreen={true}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>{props.data.title}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>{props.data.text}</b>
            </p>
            {!hasQueryCategory && <>{checkBoxes}</>}
            <VehicleFilterDropdown>
              <p>Passageiros</p>
              <DropdownButton
                id="dropdown-basic-button"
                title={selectedPassengers}
              >
                {dropdownItems(props.data.passengers, "passengers")}
              </DropdownButton>
            </VehicleFilterDropdown>
            {!hasQueryCategory && (
              <VehicleFilterDropdown>
                <p>Transmissão</p>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={selectedTransmition}
                >
                  {dropdownItems(props.data.transmission, "transmission")}
                </DropdownButton>
              </VehicleFilterDropdown>
            )}
            <StyledButton
              color="#ffffff"
              colortext="#8c4c9e"
              onClick={() => setShow(!show)}
            >
              Ver Resultados
            </StyledButton>
          </Modal.Body>
        </VehicleFilterModal>
      </VehicleFilterResponsive>
    </>
  );
}
