import { ReactComponent as IconCarDoor } from "@assets/images/ic-car-door.svg";
import { ReactComponent as IconPerson } from "@assets/images/ic-person.svg";
import { ReactComponent as IconSuitcase } from "@assets/images/ic-suitcase.svg";
import { ReactComponent as IconTransmission } from "@assets/images/ic-transmission.svg";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { VehicleCardBackground, VehicleCardVehicleImage } from "./styles";

export function VehicleCard({ data }) {
  const {
    id,
    ehiwebName,
    exampleModel,
    transmission,
    passengerCapacity,
    luggageCapacity,
    otadoorCount,
    smallImage
  } = data;

  return (
    <>
      <VehicleCardBackground>
        <h5>{ehiwebName}</h5>
        <p id="carName">{exampleModel}</p>
        <Row>
          <Col xs={2} lg={2}>
            <IconTransmission width={16} height={16} fill="#213B73" />
          </Col>
          <Col xs={8} lg={8}>
            <p>{transmission}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={2} lg={2}></Col>
          <Col xs={1} lg={1}>
            <IconPerson width={16} height={16} fill="#213B73" />
          </Col>
          <Col xs={1} lg={1}>
            <p>{passengerCapacity === "null" ? 0 : passengerCapacity}</p>
          </Col>
          <Col xs={1} lg={1}>
            <IconSuitcase width={16} height={16} fill="#213B73" />
          </Col>
          <Col xs={1} lg={1}>
            <p>{luggageCapacity === "null" ? 0 : luggageCapacity}</p>
          </Col>
          <Col xs={1} lg={1}>
            <IconCarDoor width={16} height={16} fill="#213B73" />
          </Col>
          <Col xs={1} lg={1}>
            <p>{otadoorCount === "null" ? 0 : otadoorCount}</p>
          </Col>
        </Row>
        <VehicleCardVehicleImage src={smallImage} alt="vehicle" />
        <a href={`/veiculos/detalhes-de-subcategoria/${id}`} alt="">
          Visualizar detalhes &gt;
        </a>
      </VehicleCardBackground>
    </>
  );
}
