import { Form } from "@unform/web";
import React, { useEffect } from "react";
import Input from "components/CreatePasswordField/input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUserLogged } from "redux/login/auth/authService";
import { LoginFieldBackground, LoginForm } from "./styles";
import { LoaderComponent } from "components/Loader";
import { displaySnackbar } from "redux/snackbar/snackbarSlice";

export function LoginField(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const handleSubmit = (user) => {
    dispatch(fetchUserLogged(user));
  };

  useEffect(() => {
    if (auth?.status === "failed") {
      dispatch(
        displaySnackbar({
          message: "Email ou senha incorretos!",
          type: "error",
        })
      );
    } else if (auth?.status === "success" && auth?.userLogged) {
      history.push("/CMS");
    }
  }, [auth]);

  return (
    <>
      {auth.status === "loading" && <LoaderComponent />}
      <LoginFieldBackground>
        <h2>
          <b>Login</b>
        </h2>
        <LoginForm>
          <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
              <label>
                <b>email</b>
              </label>
              <Input name="email" type="email" />
              <label>
                <b>senha</b>
              </label>
              <Input name="password" type="password" />
              <button type="submit">Entrar</button>
            </div>
          </Form>
        </LoginForm>
      </LoginFieldBackground>
    </>
  );
}
