import styled from "styled-components";

export const StyledClosestAgenciesSuggestionBlock = styled.div`
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.color};
  padding-bottom: 30px;
`;

export const ClosestAgenciesSuggestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 10px;
  h2 {
    font-weight: bold;
    font-size: 26px;
    color: ${(props) => props.color};
  }
  a {
    font-weight: bold;
    color: ${(props) => props.linkColor};
    font-size: 17px;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 0px 20px 0px;
    h2 {
      font-size: 17px;
    }
    a {
      font-size: 14px;
    }
  }
`;
