import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { AccordionContext } from "@components/Accordion";
import {
  AccordionHeaderButton,
  DownIcon,
  IconContainer,
  SeeAllContainer,
  SeeAllText,
  SubtitleText,
  TextContainer,
  UpIcon,
  ImageContainer
} from "./styles";

const AccordionHeader = ({
  name,
  icon,
  title,
  subtitle,
  selected,
  reduced,
  hasTransparentBackground,
  hasShowHideText,
  image
}) => {
  const { openBody, accordionId, openCloseAccordion } =
    useContext(AccordionContext);
  const [initialState, setInitialState] = useState(selected);
  const [isOpen, setIsOpen] = useState(selected);
  const [theme, setTheme] = useState();
  const itemId = `${accordionId}_${name}`;
  const Icon = icon;

  useEffect(() => {
    if (initialState) {
      openCloseAccordion(itemId);
    } else {
      setIsOpen(openBody.some((e) => e === itemId));
    }
    setInitialState(false);
  }, [openBody]);

  useEffect(() => {
    setTheme(isOpen ? "open" : "close");
  }, [isOpen]);

  return (
    <AccordionHeaderButton
      theme={{
        mode: hasTransparentBackground ? "transparent" : theme,
        reduced: reduced ? "reduced" : theme
      }}
      onClick={() => {
        openCloseAccordion(itemId);
      }}
    >
      {icon && (
        <IconContainer>
          <Icon
            width={window.innerWidth < 768 ? 20 : 36}
            heigth={45}
            fill={isOpen ? "#213B73" : "#ffffff"}
          />
        </IconContainer>
      )}
      {image && (
        <ImageContainer>
          <img src={image} alt="icon" width={20} heigth={20} />
        </ImageContainer>
      )}
      <TextContainer>
        {reduced ? (
          <span>{title}</span>
        ) : (
          <span>
            <b>{title}</b>
          </span>
        )}
        {subtitle && <SubtitleText>{subtitle}</SubtitleText>}
      </TextContainer>
      <SeeAllContainer>
        {hasShowHideText && (
          <SeeAllText theme={{ mode: theme }}>
            {isOpen ? "Ocultar tudo" : "Ver tudo"}
          </SeeAllText>
        )}
        {isOpen ? (
          <UpIcon theme={{ mode: hasTransparentBackground ? "transparent" : theme }} />
        ) : (
          <DownIcon theme={{ mode: hasTransparentBackground ? "transparent" : theme }} />
        )}
      </SeeAllContainer>
    </AccordionHeaderButton>
  );
};

AccordionHeader.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selected: PropTypes.bool,
  hasShowHideText: PropTypes.bool,
  hasTransparentBackground: PropTypes.bool,
  reduced: PropTypes.bool,
  image: PropTypes.any
};

AccordionHeader.defaultProps = {
  hasShowHideText: true
};

export default AccordionHeader;
