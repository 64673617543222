import styled from "styled-components";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import dropdownIconBefore from "../../assets/images/ic-dropdown-arrow-expand.svg";
import dropdownIconAfter from "../../assets/images/ic-dropdown-arrow-compress.svg";

export const StyledHeader = styled.div`
  width: 100%;
  a {
    font-family: "Poppins", "sans-serif";
    text-decoration: none;
    color: #213b73 !important;
  }
  hr {
    color: #213b73;
    opacity: 0.5;
  }
  hr:not([size]) {
    height: 2px;
  }
`;

export const TopNavbar = styled(Navbar)`
  display: flex;
  justify-content: end;
  padding: 0px;
  background-color: #e4e4e4;

  @media (max-width: 876px) {
    display: none;
  }
`;

export const TopLink = styled(Nav.Link)`
  margin-right: 2rem;
  font-weight: 400;
  font-size: 14px;

  @media (max-width: 876px) {
    padding: 12px 16px !important;
  }
`;

export const TopDropdown = styled(NavDropdown)`
  margin-right: 2rem;
  font-size: 14px;
  background-size: 0;
  &.show {
    .dropdown-toggle::after {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 876px) {
    background: url(${dropdownIconBefore}) no-repeat 98% 50%;
    background-size: 18px;
    &.show {
      background: url(${dropdownIconAfter}) no-repeat 98% 8% !important;
    }
    .dropdown-toggle::after {
      display: none !important;
    }
    a {
      padding: 12px 16px !important;
    }
  }
`;

export const TopDropdownItem = styled(NavDropdown.Item)`
  font-weight: 400;

  @media (max-width: 876px) {
    display: flex;
    align-items: flex-start;
  }
`;

export const MainNavbar = styled(Navbar)`
  color: #213b73;
  display: flex;
  align-items: flex-end;

  a {
    display: flex;
    align-items: center;
    div {
      color: #213b73;
      font-size: 22px;
      font-weight: bold;
    }
  }

  @media (max-width: 876px) {
    display: none;
  }
`;

export const HeaderLogo = styled(Navbar.Brand)`
  display: flex;
  padding: 0.5rem 0.5rem 0 0.5rem;
  align-items: center;

  @media (max-width: 876px) {
    img {
      height: 40px;
    }
  }
`;

export const MainLink = styled(Nav.Link)`
  margin-right: 1.2rem;
  font-weight: 700;
  font-size: 22px;

  @media (max-width: 876px) {
    padding: 12px 16px !important;
  }
`;

export const MainDropdown = styled(NavDropdown)`
  font-size: 22px;
  font-weight: 700;
  margin-right: 1.2rem;
  background-size: 0;
  &.show {
    .dropdown-toggle::after {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 876px) {
    background: url(${dropdownIconBefore}) no-repeat 98% 50%;
    background-size: 18px;
    &.show {
      background: url(${dropdownIconAfter}) no-repeat 98% 11%;
    }
    .dropdown-toggle::after {
      display: none !important;
    }
    a {
      padding: 12px 16px !important;
    }
  }
`;

export const MainDropdownItem = styled(NavDropdown.Item)`
  font-weight: 700;

  @media (max-width: 876px) {
    font-weight: 400;
    display: flex;
    align-items: flex-start;
  }
`;

export const ResponsiveNavbar = styled(Navbar)`
  display: none;

  @media (max-width: 876px) {
    display: flex;
    align-items: flex-start;
    padding-top: 0;
  }
`;

export const Hamburguer = styled(Navbar.Toggle)`
  border-radius: 0 !important;
  padding: 1.5rem;
  background-color: #e4e4e4;
  &:focus {
    box-shadow: none !important;
  }

  @media (max-width: 876px) {
    padding: 0.5rem;
  }
`;

export const HamburguerMenu = styled(Navbar.Collapse)`
  display: flex;
  text-align: left;
  justify-content: flex-end;
  div {
    width: 100%;
    border-radius: 0;
    padding: 0;
  }

  @media (max-width: 875px) {
    justify-content: flex-start;
    background-color: #e4e4e4;
  }
`;

export const InputWrapper = styled.div`
  color: #213b73;
  padding: 3px 10px;
  border: 1px solid #213b73;
  border-radius: 20px;
  margin: 0 10px 20px;
  input {
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #213b73;
      font-weight: bold;
    }
  }
`;

export const MainNavBarNav = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
