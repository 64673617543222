import styled from "styled-components";
import theme from "styled-theming";
import { palettes, fonts, buttons } from "assets/theme";
import { ReactComponent as Trash } from "@assets/images/cms/ic-trash.svg";

const border = theme("mode", {
  true: `dashed 3px ${palettes.tints_blue}`,
  false: "dashed 3px transparent",
});
const cover = theme("mode", {
  true: `transparent`,
  false: "rgba(235, 237, 237,0.4)",
});
export const PageHeader = styled.h2`
  ${fonts.h2_bold}
  color: ${palettes.tints_blue_darker};
`;
export const PageSubHeader = styled.p`
  ${fonts.paragraph}
  color: ${palettes.tints_blue_darker};
`;

export const SetupHeaderContainer = styled.div`
  padding: 10px;
  background: ${palettes.system_gray};
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 180px;
    width: 4px;
    background: #a6adb4;
  }
`;
export const HeaderComponentContainer = styled.div`
  position: relative;
`;
export const HeaderNavContainer = styled.div`
  border: ${border};
  cursor: pointer;
  max-height: 130px;
  padding-bottom: 10px;
`;
export const HeaderNavCover = styled.div`
  background-color: ${cover};
  position: absolute;
  width: 100%;
  height: 120px;
  top: 0;
`;

export const PencilContainer = styled.div`
  background: ${palettes.tints_blue};
  position: absolute;
  width: 24px;
  height: 24px;
  padding-left: 6px;
  padding-bottom: 8px;
  top: 0;
  right: 0;
  z-index: 999999;
`;
export const HeaderContainer = styled.div`
  border: ${border};
  margin-bottom: 5px;
  cursor: pointer;
  min-height: 38px;
`;
export const HeaderCover = styled.div`
  background-color: ${cover};
  position: absolute;
  width: 74.8%;
  height: 40px;
  top: 3px;
`;
export const SubPageContainer = styled.div`
  margin-left: 40px;
`;
export const SubPageDropZone = styled.div``;
export const DraggableItemWrap = styled.div`
  border: 1px solid ${palettes.system_gray_darker};
  width: 474px;
  height: 50px;
  margin-bottom: 10px;
`;
export const DraggableItem = styled.div`
  ${fonts.h4_bold}
  width: 100%;
  height: 100%;
  color: ${palettes.tints_blue_darker};
  background: ${palettes.white};
  border-left: 8px solid ${palettes.system_gray};
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
`;
export const MainContainer = styled.div`
  ${fonts.paragraph}
  width: 100%;
  color: ${palettes.tints_blue_darker};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  margin: 40px 0;
  p {
    max-width: 633px;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  min-width: 200px;
  img {
    width: 174px;
    height: 78px;
    margin-bottom: 5px;
  }
`;
export const ImageHeader = styled.h3`
  ${fonts.h3_bold}
  color: ${palettes.tints_blue_darker};
`;
export const Image = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
export const ImageLabel = styled.label`
  ${fonts.h4_bold}
  color: ${palettes.tints_blue_darker};
  cursor: pointer;
`;

export const SaveChangesbutton = styled.button`
  ${buttons.secondary};
  width: 212px;
  height: 64px;
`;

export const DraggableSubItem = styled(DraggableItem)`
  ${fonts.h4}
`;
export const TrashIcon = styled(Trash)`
  cursor: pointer;
`;

export const DroppableContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  max-height: 100%;
  overflow-y: scroll;
  background-color: ${palettes.system_gray};
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    width: 14px;
    background: #a6adb4;
  }
`;
