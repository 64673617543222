import { Col, Row } from "react-bootstrap";
import icon from "../../assets/images/ic-airport.svg";
import Accordion from "../Accordion";
import AccordionBody from "../Accordion/AccordionBody";
import AccordionHeader from "../Accordion/AccordionHeader";
import AccordionItem from "../Accordion/AccordionItem";
import { StyledButton } from "../Button/styles";
import { LocationCardAccordionBody } from "./LocationCardAccordionBody";
import LocationCardAccordionBodyData from "./LocationCardAccordionBody/Resources/example.json";
import { LocationCardBackground } from "./styles";

export function LocationCard(props) {
	const location = props.dataFtp;
	var diaSemana = new Date();
	let locationDetail;

	if (location) {
		switch (diaSemana.getDay()) {
			case 0:
				locationDetail = {
					...location,
					...{
						todayOpen: location.branchOpenSun ?? "",
						todayClose: location.branchCloseSun ?? "",
					},
				};

				break;
			case 1:
				locationDetail = {
					...location,
					...{
						todayOpen: location.branchOpenMon ?? "",
						todayClose: location.branchCloseMon ?? "",
					},
				};

				break;
			case 2:
				locationDetail = {
					...location,
					...{
						todayOpen: location.branchOpenTue ?? "",
						todayClose: location.branchCloseTue ?? "",
					},
				};

				break;
			case 3:
				locationDetail = {
					...location,
					...{
						todayOpen: location.branchOpenWed ?? "",
						todayClose: location.branchCloseWed ?? "",
					},
				};

				break;
			case 4:
				locationDetail = {
					...location,
					...{
						todayOpen: location.branchOpenThu ?? "",
						todayClose: location.branchCloseThu ?? "",
					},
				};

				break;
			case 5:
				locationDetail = {
					...location,
					...{
						todayOpen: location.branchOpenFri ?? "",
						todayClose: location.branchCloseFri ?? "",
					},
				};

				break;
			case 6:
				locationDetail = {
					...location,
					...{
						todayOpen: location.branchOpenSat ?? "",
						todayClose: location.branchCloseSat ?? "",
					},
				};
				break;
			default:
				break;
		}
	}

	return (
		<LocationCardBackground>
			<Row>
				<Col lg={6} xs={12}>
					<Row>
						<Col lg={2} xs={2}>
							<h1>
								<b>{props.interador}</b>
							</h1>
						</Col>
						<Col lg={10} xs={10} className="d-flex align-items-center">
							<h6>
								<b>{props.dataFtp.branchName}</b>
							</h6>
						</Col>
					</Row>
					<Row>
						<Col lg={2} xs={0}></Col>
						<Col lg={10} xs={12} className="d-flex py-2">
							<img src={icon} alt={props.dataContent?.img.alt}></img>
							<p>
								{props.dataFtp.branchStationAddress1 + ", "+props.dataFtp.branchStationAddress2}
								<br /> 
                                {props.dataFtp.branchName + " - " + props.dataFtp.branchStationPostalCode}
							</p>
						</Col>
					</Row>
				</Col>
				<Col lg={6} xs={12}>
					<Row>
						<Col lg={12} xs={6} className="d-flex justify-content-end">
							<StyledButton
								color={props.dataContent?.button.color}
								colortext={props.dataContent?.button.colorText}
							>
								{props.dataContent?.button.text}
							</StyledButton>
						</Col>
						<Col lg={12} xs={6} className="">
							<Accordion>
								<AccordionItem mode={"right"}>
									<div>
										<AccordionHeader
											name={"a"}
											title={"Detalhes"}
											reduced={true}
											hasTransparentBackground={true}
											hasShowHideText={false}
										/>
										<AccordionBody
											name={"a"}
											size="210%"
										>
											<LocationCardAccordionBody
												data={[LocationCardAccordionBodyData, locationDetail]}
											/>
										</AccordionBody>
									</div>
								</AccordionItem>
							</Accordion>
						</Col>
					</Row>
				</Col>
			</Row>
		</LocationCardBackground>
	);
}
