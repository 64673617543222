import styled from "styled-components";
import { Col } from "react-bootstrap";

export const HorizontalImageTitleInfoAndButtonsBackground = styled.div`
    padding: 50px 0 30px 0;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    width: 100%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
`

export const HorizontalImageTitleInfoAndButtonsMainImage = styled(Col)`
    padding-bottom: 10px;
    display:flex;
    justify-content: center;
    align-items: end;
    img{
        width: 100%;
        max-height: 174px;
        max-width: 174px;
    }
    @media (max-width: 1200px) {
        align-items: center;
    }
`;

export const HorizontalImageTitleInfoAndButtonsTitles = styled(Col)`
    padding-top: 30px;
    h4{
        font-size: 26px;
        font-weight: 700;
    }
    h2{
        font-size: 42px;
        font-weight: bold;
    }
    @media (max-width: 768px) {
        h4{
            font-size: 17px;
        }
        h2{
            font-size: 35px;
        }
    }
`;

export const HorizontalImageTitleInfoAndButtonsInfoButton = styled(Col)`
    padding-top: 15px;
    button{
        width: 100%;
        &:hover{
            color: ${(props) => props.textColor} !important;
        }
    }
`;

export const HorizontalImageTitleInfoAndButtonsInfoButtons = styled(Col)`
    p{
        font-size: 22px;
    }
    display: grid !important;
    align-content: center;
    @media (max-width: 768px) {
        p{
            font-size: 16px;
        }
    }
`;