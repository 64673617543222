import { TitleContext } from "context/TitleContext";
import { StaticPageEngine } from "pages/StaticPageEngine";
import React from "react";
import { Container } from 'react-bootstrap';
import { BreadCrumb } from '../../components/BreadCrumb';
import { ClosestAgenciesSuggestionBlock } from '../../components/ClosestAgenciesSuggestionBlock';
import { ImageHeader } from '../../components/ImageHeader';
import { RentalPoliciesList } from '../../components/RentalPoliciesListWithTextBlock';
import { ThreeCardsWithTopImage } from '../../components/ThreeCardsWithTopImage';
import { PbkWidget } from '../../components/Widget';
import { TitleText } from './styles';

export function LocationAndOffers(props) {

  const { setTitleText } = React.useContext(TitleContext);

  React.useEffect(() => {
    setTitleText("Alamo - Agências e Ofertas");
  }, []);
  const components =
    <>
      <BreadCrumb data={props.data.breadCrumbData} />
      <ImageHeader data={props.data.imageHeaderData} />
      <PbkWidget />
      <ThreeCardsWithTopImage data={props.data.threeCardsWithTopImageData} />
      <TitleText>
        <Container>
          <h3><b>Explore World-Famous Miami Beaches and Much More</b></h3>
          <p>Known as both The Magic City and The Capital of Latin America, Miami is one of South Florida's most vibrant destinations. Whether your family likes to hit the beach, visit cultural attractions, or explore outdoor spots, you'll find something to love in Miami.</p>
        </Container>
      </TitleText>
      <RentalPoliciesList data={props.data.rentalPoliciesListData} />
      <ClosestAgenciesSuggestionBlock data={props.data.closestAgenciesSuggestionBlockData} />
    </>

  return (
    <StaticPageEngine components={components} />
  )
}

export default LocationAndOffers;