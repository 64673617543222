import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PostHighlightContainer, PostCategory } from "./styles";
import { Link } from "react-router-dom";

export function PostHighlight({
  data: { image, category, title, description, url },
}) {
  const categories = useSelector((state) => state?.blog?.categories);
  return (
    <PostHighlightContainer>
      <Container fluid="xxl">
        <div className="d-flex justify-content-between ">
          <Row>
            <Col className="d-flex justify-content-center" lg={7} md={12}>
              <Link to={"/blog/" + url}>
                <img src={image} alt="Post" className="img-fluid" />
              </Link>
            </Col>
            <Col lg={5} md={12}>
              <div className="d-flex flex-column pt-3">
                <div className="d-flex mb-3">
                  {category?.map((category, index) => (
                    <PostCategory key={index}>
                      {categories?.find((p) => p.id === category)?.name}
                    </PostCategory>
                  ))}
                </div>
                <Link
                  to={"/blog/" + url}
                  className="text-decoration-none normal-text-color"
                >
                  <h2>
                    <b>{title}</b>
                  </h2>
                </Link>
                <Link
                  to={"/blog/" + url}
                  className="text-decoration-none normal-text-color"
                >
                  <p>{description}</p>
                </Link>
                <hr />
                <Link
                  to={"/blog/" + url}
                  className="text-decoration-none fw-bold"
                >
                  Leia Mais &gt;
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </PostHighlightContainer>
  );
}
