import styled from "styled-components";

export const ContentLimpezaCompletaContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: "Poppins", sans-serif;
    padding: 20px;
    h1{
        width: 90%;
        font-size: 42px;
        font-weight: bold;
        color: #213B73;
    }
    img{
        height: 237px;
        margin-right: 29px;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        font-size: 32px;
        img{
            height: 115px;
            margin-right: 0;
            margin-bottom: 22px;
        }
        h1{
            font-size: 26px;
        }
    }
`;