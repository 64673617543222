import styled from "styled-components";

export const UserAdminContainer = styled.div`
  background-color: #dddee7;
  min-height: calc(100vh - 66px);
  padding: 30px 3rem;
  color: #213b73;
  font-family: "Poppins", sans-serif;
  span {
    color: black;
  }
`;

export const ProfilesDetails = styled.div`
  display: flex;
  margin-top: 20px;
  h6 {
    margin-right: 15px;
  }
  button {
    background-color: transparent;
    border: none;
    color: #213b73;
    text-align: center;
  }
`;

export const UserDetailsContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  padding: 10px 50px;
  p {
    margin: 0;
    min-width: 25%;
  }
  button {
    background-color: transparent;
    border: none;
  }
`;

export const InputWrapper = styled.div`
  color: #213b73;
  background-color: white;
  padding: 3px 10px;
  border: 1px solid #213b73;
  border-radius: 20px;
  margin: 0 10px 20px;
  input {
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #213b73;
      font-weight: bold;
    }
  }
`;

export const EditUserContainer = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  margin: 5px 0;
  padding: 10px 50px;
  p {
    margin: 0;
  }
  input,
  select {
    color: #213b73;
    border: none;
    background-color: transparent;
    &:focus {
      outline: #dddee6;
    }
    width: 25%;
  }
  .buttons {
    position: absolute;
    right: 15px;
    bottom: 5px;
  }
  button {
    width: 100px;
    height: 35px;
    font-weight: bold;
    margin-left: 15px;
  }
  #cancel-button {
    background-color: white;
    color: #8c4c9e;
    border: 1px solid #8c4c9e;
  }
  #save-button {
    background-color: #8c4c9e;
    color: white;
    border: none;
  }
`;
