import styled from "styled-components";

export const WidgetSection = styled.div`
    background-color: #005DAA;
    padding: 30px;
    p{
        font-family: 'Poppins', 'sans-serif';
        text-align: left;
        font-size: 26px;
        font-weight: 700;
        padding: 1rem 0;
        span{
            font-size: 12px;
            font-weight: 400;
            padding-left:.5rem;
        }
    }

    #enterprise-pbk .field-widget-summary-v2 .widget-summary-bar {
        position: unset !important;
    }
`;
export const WidgetText = styled.p`
  
    font-family: 'Poppins', 'sans-serif';
    text-align: left;
    font-size: 26px;
    font-weight: 700;
    padding: 1rem 0;
    color: #fff;
    span{
        font-size: 12px;
        font-weight: 400;
        padding-left:.5rem;
    }
`;

export const Pbk = styled.div`
    #enterprise-pbk{
        font-family: 'Poppins', 'sans-serif' !important;
        background-color: #213B73 !important;
        a{
            color: #FFFFFF !important;
            text-decoration: none !important;
        }
        .button-special{
            background: #FEF100 !important;
            color: #213B73 !important;
        }
        .label{
            color: #213B73 !important;
        }
        .widget-search-form-group:first-child .label{
            color: #ffffff !important;
        }
    }
   
`;
