import { fonts, palettes } from "assets/theme";
import styled from "styled-components";
export const PostContentImageContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  width: 100%;
  height: 50vh;
  background: no-repeat center;
  background-image: url(${(props) => props.imageUrl});
  background-size: 100% auto;
  font-family: "Poppins", sans-serif;
  div {
    background: rgba(0, 0, 0, 0.5);
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    p {
      margin: 0;
      padding: 2%;
      color: white;
      font-weight: bold;
    }
  }
  @media(max-width: 768px){
    height: 30vh;
    div{
      height: 60px;
    }
  }
`;
export const Title = styled.h1`
  ${fonts.banner_bold};
  color: ${palettes.tints_blue_darker};
`;
