import React from "react";
import {
  Button,
  ImageSection5,
  ImageSection5Wrapper,
  Section,
  Text,
  Title,
} from "../Secao";
import { templateImageUrl } from "redux/cms/constants";

export const Secao5A = ({ properties, mode, variant }) => {
  return (
    <Section
      theme={{ mode: mode, variant: variant }}
      className="align-items-center"
    >
      <div
        className={
          mode === "default"
            ? "d-flex flex-column-small maxSectionWidth w-100 justify-content-evenly"
            : "d-flex w-100 justify-content-evenly"
        }
      >
        <ImageSection5Wrapper className={mode === "default" ? "" : ""}>
          <ImageSection5
            src={
              properties[0].value === ""
                ? templateImageUrl
                : properties[0].value
            }
            theme={{ mode: mode }}
            alt="icon"
          />
        </ImageSection5Wrapper>
        <div
          className={
            "justify-content-between align-items-start" +
            (mode === "default" ? "subSection5Padding" : "py-1 w-50")
          }
        >
          <div className={mode === "default" ? "subSection5Padding" : "py-1"}>
            <Text theme={{ mode: mode }}>
              <b>{properties[1].value}</b>
            </Text>
            <Title theme={{ mode: mode }}>{properties[2].value}</Title>
            <Text
              theme={{ mode: mode }}
              dangerouslySetInnerHTML={{ __html: properties[3].value }}
            ></Text>
          </div>
          <div
            className={mode === "default" ? "subSection5Padding w-100" : "py-2"}
          >
            <Button
              theme={{ mode: mode, variant: variant }}
              href={properties[4].properties[0].value}
              target="_blank"
            >
              {properties[4].properties[1].value}
            </Button>
          </div>
        </div>
      </div>
    </Section>
  );
};

Secao5A.defaultProperties = {
  mode: "dafault",
};

export default Secao5A;
