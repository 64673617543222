import { StyledButton } from "components/Button/styles";
import React from "react";
import { Container } from "react-bootstrap";
import {
  Button,
  ButtonAsDiv,
  Image,
  Link,
  Section,
  SectionIcon,
  Subtitle,
  Text,
  Title,
} from "../Secao";
import { templateImageUrl } from "redux/cms/constants";

export const Secao2A = ({ properties, mode, variant }) => {
  return (
    <Section theme={{ mode: mode, variant: variant }}>
      <div className="d-flex flex-column align-items-center">
        <div
          className={
            (mode !== "template" ? "mb-3 " : "") +
            "d-flex maxSectionWidth w-100 justify-content-left"
          }
        >
          <Title theme={{ mode: mode }}>{properties[0].value}</Title>
        </div>
        <div className="d-flex maxSectionWidth w-100 justify-content-between flex-column-small">
          <div className="d-flex widthOnSmall ">
            <SectionIcon theme={{ mode: mode }}>
              <img src={properties[1].properties[0].value === "" ? templateImageUrl: properties[1].properties[0].value} alt="icon" />
            </SectionIcon>
            <Section
              className={
                (mode !== "template" ? "px-2 " : "") +
                " justify-content-between maxSubSectionWidth subSectionPadding"
              }
            >
              <div>
                <Subtitle theme={{ mode: mode }}>
                  <b>{properties[1].properties[1].value}</b>
                </Subtitle>
                <Text
                  theme={{ mode: mode }}
                  dangerouslySetInnerHTML={{
                    __html: properties[1].properties[2].value,
                  }}
                ></Text>

                <ButtonAsDiv theme={{ mode: mode, variant: variant }}>
                  <a href={properties[1].properties[3].value} target="_blank">
                    {properties[1].properties[4].value}
                  </a>
                </ButtonAsDiv>
              </div>
              <Link
                href={properties[1].properties[3].value}
                theme={{ mode: mode, variant: variant }}
                target="_blank"
                className="hideOnSmall"
              >
                {properties[1].properties[4].value}
              </Link>
            </Section>
          </div>
          <div className="d-flex widthOnSmall margin-top-small">
            <SectionIcon theme={{ mode: mode }}>
              <img src={properties[2].properties[0].value === "" ? templateImageUrl: properties[2].properties[0].value} alt="icon" />
            </SectionIcon>
            <Section
              className={
                (mode !== "template" ? "px-2 " : "") +
                " justify-content-between maxSubSectionWidth subSectionPadding"
              }
            >
              <div>
                <Subtitle theme={{ mode: mode }}>
                  <b>{properties[2].properties[1].value}</b>
                </Subtitle>
                <Text
                  theme={{ mode: mode }}
                  dangerouslySetInnerHTML={{
                    __html: properties[2].properties[2].value,
                  }}
                ></Text>
                <ButtonAsDiv theme={{ mode: mode, variant: variant }}>
                  <a href={properties[2].properties[3].value} target="_blank">
                    {properties[2].properties[4].value}
                  </a>
                </ButtonAsDiv>
              </div>
              <Link
                href={properties[2].properties[3].value}
                theme={{ mode: mode, variant: variant }}
                target="_blank"
                className="hideOnSmall"
              >
                {properties[2].properties[4].value}
              </Link>
            </Section>
          </div>
        </div>
      </div>
    </Section>
  );
};

Secao2A.defaultProperties = {
  mode: "dafault",
};

export default Secao2A;
