import { HorizontalImageTitleInfoAndButtonsBackground, HorizontalImageTitleInfoAndButtonsTitles, HorizontalImageTitleInfoAndButtonsInfoButtons, HorizontalImageTitleInfoAndButtonsMainImage, HorizontalImageTitleInfoAndButtonsInfoButton } from "./styles";
import { Container, Row, Image, Col } from "react-bootstrap";
import { StyledButton } from "../Button/styles.js";

export function HorizontalImageTitleInfoAndButtons(props) {

    //controle da quantidade de botoes
    const buttons = () => {
        if (props.data.buttons[1]) {
            return (
                <Row>
                    <HorizontalImageTitleInfoAndButtonsInfoButton xs={12} lg={5}>
                        <a href={props.data.buttons[0].href}>
                            <StyledButton color={props.data.buttons[0].color} colortext={props.data.buttons[0].colortext}>
                                {props.data.buttons[0].text}
                            </StyledButton>
                        </a>
                    </HorizontalImageTitleInfoAndButtonsInfoButton>
                    <HorizontalImageTitleInfoAndButtonsInfoButton xs={12} lg={5}>
                        <a href={props.data.buttons[1].href}>
                            <StyledButton color={props.data.buttons[1].color} colortext={props.data.buttons[1].colortext}>
                                {props.data.buttons[1].text}
                            </StyledButton>
                        </a>
                    </HorizontalImageTitleInfoAndButtonsInfoButton>
                </Row>
            );
        } else if (props.data.buttons[0]) {
            return (
                <a href={props.data.buttons[0].href}>
                    <StyledButton color={props.data.buttons[0].color} colortext={props.data.buttons[0].colortext}>{props.data.buttons[0].text}</StyledButton>
                </a>
            );
        } else {
            return (
                <></>
            )
        }
    }

    //controle de layout caso tenha so 1 botao
    let colSizeResponsive =  props.data.buttons[1] ? [7, 5] : [8, 4];


    //controle se tem imagem
    const haveImage = () => {
        if (props.data.image) {
            return (
                <Row>
                    <HorizontalImageTitleInfoAndButtonsMainImage lg={4}>
                        <Image src={props.data.image.src} alt={props.data.image.alt} fluid />
                    </HorizontalImageTitleInfoAndButtonsMainImage>
                    <HorizontalImageTitleInfoAndButtonsTitles lg={7}>
                        <h4>{props.data.subtitle}</h4>
                        <h2>{props.data.title}</h2>
                    </HorizontalImageTitleInfoAndButtonsTitles>
                </Row>
            );
        } else {
            return (
                <>
                    <h4>{props.data.subtitle}</h4>
                    <h2>{props.data.title}</h2>
                </>
            );
        }
    }

    return (
        <>
            <HorizontalImageTitleInfoAndButtonsBackground textColor={props.data.textColor} backgroundColor={props.data.backgroundColor}>
                <Container>
                    <Row>
                        <Col xs={12} lg={colSizeResponsive[0]}>
                            {haveImage()}
                        </Col>
                        <HorizontalImageTitleInfoAndButtonsInfoButtons xs={12} lg={colSizeResponsive[1]}>
                            <p>{props.data.text}</p>
                            {buttons()}
                        </HorizontalImageTitleInfoAndButtonsInfoButtons>
                    </Row>
                </Container>
            </HorizontalImageTitleInfoAndButtonsBackground>
        </>
    );
}