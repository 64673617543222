import {
  FooterBackground,
  FooterMenuCol,
  FooterMenuName,
  FooterMenu,
  FooterCopyright,
  FooterMenusDesktop,
  FooterMobile,
  FooterDropdown,
} from "./styles";
import { Container } from "react-bootstrap";

export function Footer(props) {
  //funçao que retorna uma lista de menus com seus links
  const footerMenusDesktop = (i) =>
    props.data.colunas[i].menus.map((menu, i) => (
      <FooterMenu key={i}>
        <FooterMenuName>{menu.nome}</FooterMenuName>
        {menu.submenu.map((item, i) => (
          <li key={i}>
            <a href={item.href} target={item.target}>
              {item.texto}
            </a>
          </li>
        ))}
      </FooterMenu>
    ));

  const footerMenusMobile = props.data.colunas.map((coluna) =>
    coluna.menus.map((menu, i) => (
      <div key={i}>
        <FooterDropdown title={menu.nome}>
          {menu.submenu.map((item, i) => (
            <div key={i}>
              <li>
                <a href={item.href} target={item.target}>
                  {item.texto}
                </a>
              </li>
              <hr />
            </div>
          ))}
        </FooterDropdown>
        <hr />
      </div>
    ))
  );

  return (
    <>
      <FooterBackground>
        <Container>
          <div>
            <FooterMenusDesktop>
              <hr />
              <FooterMenuCol sm={4} xm={4}>
                {footerMenusDesktop(0)}
              </FooterMenuCol>
              <FooterMenuCol sm={4} xm={4}>
                {footerMenusDesktop(1)}
              </FooterMenuCol>
              <FooterMenuCol sm={4} xm={4}>
                {footerMenusDesktop(2)}
              </FooterMenuCol>
            </FooterMenusDesktop>
          </div>
          <FooterMobile>
            <hr />
            {footerMenusMobile}
          </FooterMobile>
          <FooterCopyright>
            Copyright Ⓒ2022 Alamo. Todos os direitos reservados.
          </FooterCopyright>
        </Container>
      </FooterBackground>
    </>
  );
}
