import footerData from "@components/Footer/Resources/example.json";
import { BlogHeader } from "components/BlogHeader";
import Footer from "@cms-common/Footer";
import { HorizontalImageTitleInfoAndButtons } from "components/HorizontalImageTitleInfoAndButtons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterByCategory } from "redux/postList/postListSlice";
import { BreadCrumb } from "../components/BreadCrumb";
import { PostContent } from "../components/PostContent";
import { PostList } from "../components/PostList";
import mock from "./Resources/example";
import { Spacer } from "../components/PostList/styles";
import { fetchFooter } from "redux/cms/cmsService";

const BlogPost = ({ data: { post } }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.postList.postListItem);
  const footerPageState = useSelector((state) => state?.cms?.footerPage);
  useEffect(() => {
    dispatch(
      filterByCategory({ current: post.id, category: post.category[0] })
    );
  }, [posts]);
  useEffect(() => {
    dispatch(fetchFooter());
  }, []);
  return (
    <>
      <BlogHeader data={mock.headerData} />
      <BreadCrumb
        data={[
          ...mock.breadCrumbData,
          {
            path: "/blog" + post.url,
            pathName: post.title,
          },
        ]}
      />
      <PostContent data={post} />
      <HorizontalImageTitleInfoAndButtons
        data={mock.horizontalImageTitleInfoAndButtonsData}
      />
      <PostList />
      <Footer data={footerPageState?.section} />
    </>
  );
};

export default BlogPost;
