import commonData from "../Resources/commonData.json";
export const TemplateReducer = {
  getTemplates: (state) => {
    state.templates = commonData;
  },
  setTemplateEditionMode: (state, { payload }) => {
    state.templatePage = { ...state.templatePage, ...payload };
  },
  cancelTemplateEditionMode: (state) => {
    state.templatePage = {};
  },
  updateTemplate: (state, { payload }) => {
    const { index, newData, indexParent } = payload;
    if (indexParent !== undefined) {
      state.templatePage.current.properties[indexParent].properties[index] =
        newData;
    } else {
      state.templatePage.current.properties[index] = newData;
    }
  },
  setUpdateTemplate: (state) => {
    const { index, current } = state.templatePage;
    state.canvasPage.components[index] = current;
    state.templatePage = {};
  }
};

export default TemplateReducer;
