import { templateImageUrl } from "redux/cms/constants";
import { ImageFluid, ImageFluidWrapper, LargeButton, Section, Subtitle, Text, Title } from "../Secao";

export const Secao7B = ({ properties, mode, variant }) => {
  return (
    <Section
      theme={{ mode: mode, variant: variant }}
      className="align-items-center"
    >
      <div className="d-flex w-100 maxSectionWidth breakWhenMedium justify-content-between">
        <div className="d-flex w-100 flex-column-small breakWhenMedium ">
          <ImageFluidWrapper>
            <ImageFluid src={properties[0].value === "" ? templateImageUrl : properties[0].value} alt="" />
          </ImageFluidWrapper>
          <div className="ps-lg-4 mt-3">
            <Subtitle theme={{ mode: mode }} style={{ fontWeight: "bold" }}>
              {properties[1]?.value}
            </Subtitle>
            <Title theme={{ mode: mode }}>{properties[2]?.value}</Title>
          </div>
        </div>
        <div className="d-flex w-100 flex-column justify-content-between ps-lg-4">
          <div className="mt-3">
            <Text
              theme={{ mode: mode }}
              dangerouslySetInnerHTML={{ __html: properties[3]?.value }}
            ></Text>
          </div>
          {(properties[4]?.properties[0]?.value ||
            properties[4]?.properties[1]?.value) && (
              <div className="d-flex flex-column-small">
                <LargeButton
                  className="margin-top-medium ms-0 me-4"
                  theme={{ mode: mode, variant: variant }}
                  href={properties[4]?.properties[0]?.value}
                  target="_blank"
                >
                  <Subtitle theme={{ mode: mode }}>
                    <b>{properties[4]?.properties[1]?.value}</b>
                  </Subtitle>
                </LargeButton>
                <LargeButton
                  className="margin-top-medium mx-0"
                  theme={{ mode: mode, variant: variant }}
                  href={properties[4]?.properties[0]?.value}
                  target="_blank"
                >
                  <Subtitle theme={{ mode: mode }}>
                    <b>{properties[4]?.properties[1]?.value}</b>
                  </Subtitle>
                </LargeButton>
              </div>
            )}
        </div>
      </div>
    </Section>
  );
};

Secao7B.defaultProperties = {
  mode: "dafault"
};

export default Secao7B;
