export const HeaderReducer = {
  setHeaderEditing: (state, { payload }) => {
    state.isHeadersEditing = payload;
  },
  setHeaderType: (state, { payload }) => {
    state.headersType = payload;
  },
  setInputHomePageState: (state, { payload }) => {
    state.inputHomePageState = payload;
  },

  addHeaderItems: (state, { payload }) => {
    const { selectedMenu, addedItems } = payload;
    if (addedItems?.length > 0) {
      if (state.headersType === 1) {
        const top = state.headerPage?.topMenu;
        state.headerPage = {
          ...state.headerPage,
          topMenu: top ? [...top, ...addedItems] : [...addedItems],
        };
      }
      if (state.headersType === 2) {
        if (
          selectedMenu !== "-1" &&
          state.headerPage?.navMenu?.filter(
            (n) => n.id?.toString() === selectedMenu
          ).length > 0
        ) {
          let item = state.headerPage?.navMenu?.find(
            (n) => n.id?.toString() === selectedMenu
          );
          if (item.subLinks) {
            addedItems.map((p) => {
              state.headerPage?.navMenu
                ?.find((n) => n.id?.toString() === selectedMenu)
                .subLinks.push(p);
              return p;
            });
          } else {
            item.subLinks = [...addedItems];
            const menu = state.headerPage?.navMenu?.filter(
              (n) => n.id?.toString() !== selectedMenu
            );
            state.headerPage = {
              ...state.headerPage,
              navMenu: [...menu, item],
            };
          }
        } else {
          const subLinks = state.headerPage?.navMenu;
          state.headerPage = {
            ...state.headerPage,
            navMenu: subLinks ? [...subLinks, ...addedItems] : [...addedItems],
          };
        }
      }
    }
  },
  updateHeaderItem: (state, { payload }) => {
    const { current, type } = payload;

    if (type === 1) {
      state.headerPage.topMenu = current;
    } else {
      state.headerPage.navMenu = current;
    }
  },
  removeHeaderItem: (state, { payload }) => {
    const { parent, current, type } = payload;
    if (parent) {
      let item = state.headerPage?.navMenu?.find((n) => n.id === parent.id);
      if (item.subPage) {
        item.subPage = item.subPage.filter((p) => p.id !== current.id);
        const menu = state.headerPage?.navMenu?.filter(
          (n) => n.id !== parent.id
        );
        state.headerPage = {
          ...state.headerPage,
          navMenu: [...menu, item],
        };
      }
    } else {
      if (type === 1) {
        state.headerPage.topMenu = state.headerPage.topMenu.filter(
          (p) => p.id !== current.id
        );
      } else {
        state.headerPage.navMenu = state.headerPage.navMenu.filter(
          (p) => p.id !== current.id
        );
      }
    }
  },
  uploadImage: (state, { payload }) => {
    state.headerPage.image = payload;
    state.headerPage.typeImg = `.${payload.split(";")[0].split("/")[1]}`;
    state.headerPage.isNewLogo = true;
  },
  updateHomepage: (state, { payload }) => {
    state.headerPage = { ...state.headerPage, homePageURL: payload };
  },
};

export default HeaderReducer;
